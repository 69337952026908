import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from '../../../features/login/loginSlice';
import { AppDispatch, RootState } from '../../../app/store';
import Spinner from '../../ui/spinner/spinner';

export interface User {
  id: string;
  name: string;
  role: string;
  user: User | null;
}

const AdminRoute = ({ element: Component, ...rest }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isLoading = useSelector((state: RootState) => state.login?.isLoading);
  const isAuthChecked = useSelector(
    (state: RootState) => state.login.isAuthChecked
  );
  const user = useSelector(
    (state: RootState) => state.login.user
  ) as User | null;

  const actualUser = user?.user ? user.user : user;

  const isAdmin = actualUser?.role === 'administrator';
  const isManager = actualUser?.role === 'manager';

  // useEffect(() => {
  //   if (!isAuthChecked && localStorage.getItem('token')) {
  //     dispatch(checkAuth());
  //   } else if (!localStorage.getItem('token')) {
  //     navigate('/login');
  //   }
  // }, [dispatch, navigate, isAuthChecked, isLoading]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!isAuthChecked && token) {
      dispatch(checkAuth());
    } else if (!token && !isAuthChecked) {
      navigate('/login');
    }
  }, [dispatch, navigate, isAuthChecked]);

  if (isLoading || !isAuthChecked) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const currentPath = window.location.pathname;
  const isLoginPath = currentPath === '/login';

  if (!actualUser && !isLoginPath) {
    return <Navigate to='/login' />;
  }

  return (
    <>
      {isAdmin || isManager ? (
        <Component {...rest} />
      ) : (
        <Navigate to='/advertising-systems-office' />
      )}
    </>
  );
};

export default AdminRoute;
