import { Middleware } from '@reduxjs/toolkit';
import { RootState } from './store';

const syncMiddleware: Middleware = (store) => (next) => (action) => {
  const result = next(action);
  const state: RootState = store.getState();
  localStorage.setItem('reduxState', JSON.stringify(state));
  return result;
};

export default syncMiddleware;
