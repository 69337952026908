import React, { useEffect, useRef, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import RoutesList from '../../ui/routes-list/routes-list';
import './tickets.scss';
import ButtonComponent from '../../ui/button/button';
import CustomTable from '../../ui/table/custom-table';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import {
  closeModal,
  createTicket,
  getAllTicketsForAdmin,
  getMessagesByTicket,
  getTickets,
  getTicketsByEmails,
  getTicketsByManager,
  saveMessage,
  updateTicketStatus,
} from '../../../features/tickets/ticketsSlice';
import CustomSelectComponent from '../../ui/select-custom/custom-select-component';
import AttachIcon from '../../../assets/images/ion_attach.svg';
import ReopenIcon from '../../../assets/icons/ReopenIcon';
import CloseIconRed from '../../../assets/icons/CloseIconRed';
import { useDevice } from '../../../hooks/use-device';
import DatePickerComponent from '../../ui/date-picker/date-picker';
import CalendarIcon from '../../../assets/images/calendar-icon.png';
import DeleteIcon from '../../../assets/icons/delete-bucket.svg';
import { format } from 'date-fns';
import SearchInput from '../../ui/search-input/search-input';
import { Icon } from '@iconify/react';
import { getUsersByManager } from '../../../features/get-users-by-manager/get-users-by-manager-slice';
import { parse } from 'date-fns';
import io from 'socket.io-client';
import InputLabel from '../../input/inputLabel/Input-label';
import TicketChatClose from '../../../assets/icons/ticket-chat-close.svg';
import UserImg from '../../../assets/icons/user-img.svg';
import ErrorComponent from '../../ui/error/error-component';
import TicketDownload from '../../../assets/images/ticket-download.svg';
import Tooltip from '../../tooltip/tooltip';
import { getAllUsers } from '../../../features/get-all-users/get-all-users-slice';

const Tickets = () => {
  const isMobile = useDevice();

  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() - 6, 1);
  });
  const [endDate, setEndDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilters, setShowFilters] = useState(true);

  const [modalMessage, setModalMessage] = useState('');
  const [modalTheme, setModalTheme] = useState('');
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [createTicketModal, setCreateTicketModal] = useState(false);
  const [ticketIcons, setTicketIcons] = useState<{ [key: string]: string }>({});
  const [hasFetchedTickets, setHasFetchedTickets] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [ticketModal, setTicketModal] = useState<any>(true);

  const tickets = useSelector((state: any) => state?.tickets.tickets);

  const handleTicketClick = (ticket: any) => {
    setSelectedTicket(ticket);
  };

  const handleActionClick = (ticketKey: string) => {
    setTicketIcons((prev) => ({
      ...prev,
      [ticketKey]: prev[ticketKey] === 'reopen' ? 'close' : 'reopen',
    }));
  };
  const dispatch = useDispatch<AppDispatch>();
  const usersByManager =
    useSelector((state: any) => state.usersByManager.usersByManager) || [];

  const ticketsByEmails =
    useSelector((state: any) => state.tickets?.ticketsByEmails) || [];


  useEffect(() => {
    dispatch(getAllUsers());
    const emails = usersByManager.map((user: any) => user.email);

    if (emails.length > 0) {
      dispatch(getTicketsByEmails(emails));
    }
  }, [dispatch, usersByManager]);
  const allUsers = useSelector((state: any) => state.allUsers.allUsers) || [];
  const userEmailsToNamesMap = allUsers.reduce((acc: any, user: any) => {
    acc[user.email] = user.name;
    return acc;
  }, {});

  const ticketsByManager = useSelector(
    (state: any) => state?.tickets.ticketsByManager
  );
  const ticketsForAdmin = useSelector(
    (state: any) => state.tickets.ticketsForAdmin
  );
  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );

  const emailList = usersByManager?.map((item: any) => item.email);

  useEffect(() => {
    dispatch(getUsersByManager(user.email));
  }, [dispatch, user.email]);

  useEffect(() => {
    if (user.role === 'manager' && emailList.length > 0 && !hasFetchedTickets) {
      dispatch(getTicketsByManager(emailList));
      setHasFetchedTickets(true);
    }
  }, [dispatch, emailList, user.role, hasFetchedTickets]);

  useEffect(() => {
    dispatch(getTickets(user?.email));
    if (user.role === 'administrator') {
      dispatch(getAllTicketsForAdmin());
    }
  }, [dispatch, user?.email, user?.role]);

  useEffect(() => {
    if (ticketModal) {
      const modalElement = document.querySelector('.tickets-modal-container');
      if (modalElement) {
        modalElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [ticketModal]);

  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleFilterChange = (column: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value === 'Все' ? '' : value,
    }));
  };

  const handleOpenTicketModal = (ticket: any) => {
    if (ticket?.ticketId) {
      dispatch(getMessagesByTicket(ticket?.ticketId));
      setSelectedTicket(ticket);
      setTicketModal(true);
    }
  };

  const toggleFiltersVisibility = () => {
    setShowFilters((prevShowFilters) => !prevShowFilters);
  };

  const transformTickets = (tickets: any[]) => {
    return tickets?.map((ticket: any, index: number) => ({
      ticketId: ticket.ticketId,
      key: (index + 1).toString(),
      date: new Date(ticket.date).toLocaleDateString('ru-RU'),
      status: ticket.status,
      theme: ticket.theme,
      comment: ticket.comment,
      messages: ticket?.messages?.length,
      // manager: ticket.manager,
      user: user.role === 'user' ? ticket?.manager : ticket?.holderEmail,
    }));
  };

  // const transformTicketsManager = (tickets: any[]) => {
  //   return tickets?.map((ticket: any, index: number) => ({
  //     ticketId: ticket.ticketId,
  //     key: (index + 1).toString(),
  //     date: new Date(ticket.date).toLocaleDateString('ru-RU'),
  //     status: ticket.status,
  //     theme: ticket.theme,
  //     comment: ticket.comment,
  //     messages: ticket?.messages?.length,
  //     holderEmail: ticket?.holderEmail,
  //   }));
  // };

  const transformedTickets = transformTickets(tickets);
  // const transformedTicketsByManager = transformTicketsManager(ticketsByManager);
  const transformedTicketsByManager = transformTickets(ticketsByManager);
  const transformedTicketsForAdmin = transformTickets(ticketsForAdmin);

  const ticketsData =
    user.role === 'administrator'
      ? transformedTicketsForAdmin
      : transformedTickets;

  const handleReopenTicket = async (ticketId: string, ticket: any,) => {
    await dispatch(updateTicketStatus({ ticketId, status: 'Открыт' }));
    await dispatch(getTickets(user?.email));
    setTicketModal(true)
    setSelectedTicket(ticket)
  };

  const addActionsToTickets = (tickets: any[]) => {
    return tickets?.map((ticket) => ({
      ...ticket,
      actions:
        true ? (
          <div onClick={() => {
            if (ticket.status === 'Закрыт') {
              handleReopenTicket(ticket.ticketId, ticket);
              handleOpenTicketModal(ticket.ticketId)
            } else {
              handleActionClick(ticket.key);
            }
          }}>
            {ticket.status === 'Закрыт' ? (
              <Tooltip content="Возобновить">
                <ReopenIcon data-tooltip-id="tooltip" data-tooltip-content="Возобновить" />
              </Tooltip>
            ) : (
              <Tooltip content="Закрыть">
                <CloseIconRed />
              </Tooltip>
            )}
          </div>
        ) : null,
    }));
  };

  const filteredTickets = ticketsData?.filter((ticket) => {

    // Преобразуем дату из строки в объект Date
    const ticketDate = ticket.date ? parse(ticket.date, 'dd.MM.yyyy', new Date()) : null;

    // Проверка соответствия даты в заданном диапазоне или одного дня
    const matchesDateRange = ticketDate
      ? (startDate && endDate && startDate.getTime() === endDate.getTime()
        ? ticketDate.getTime() === startDate.getTime()
        : ticketDate >= startDate && ticketDate <= endDate)
      : true;

    const matchesHolderEmail =
      !filters.holderEmail ||
      ticket.user?.toLowerCase().includes(filters.holderEmail.toLowerCase());

    const matchesStatus = !filters.status || ticket.status === filters.status;

    const matchesTheme = !filters.theme || (ticket.theme && ticket.theme.toLowerCase().includes(filters.theme.toLowerCase()));

    return matchesDateRange && matchesHolderEmail && matchesStatus && matchesTheme;
  });

  const filteredTicketsByManager = transformedTicketsByManager?.filter(
    (ticket: any) => {

      const ticketDate = parse(ticket.date, 'dd.MM.yyyy', new Date());
      const matchesDateRange =
        (!startDate || ticketDate >= startDate) &&
        (!endDate || ticketDate <= endDate);

      const matchesManager =
        !filters.holderEmail ||
        ticket?.holderEmail
          ?.toLowerCase()
          .includes(filters.holderEmail.toLowerCase());

      const matchesStatus = !filters.status || ticket.status === filters.status;

      const matchesTheme = !filters.theme || (ticket.theme && ticket.theme.toLowerCase().includes(filters.theme.toLowerCase()));

      return (
        matchesDateRange && matchesManager && matchesStatus && matchesTheme
      );
    }
  );

  const handleOpenModalTicket = () => {
    setCreateTicketModal(true);
  };

  const adsOptions = [
    { value: 'Создание кабинета', label: 'Создание кабинета' },
    {
      value: 'Пополнение баланса ad.kitchen',
      label: 'Пополнение баланса ad.kitchen',
    },
    { value: 'Пополнение кабинета', label: 'Пополнение кабинета' },
    { value: 'Запрос от пользователя', label: 'Запрос от пользователя' },
  ];
  const fileInputRef: any = useRef(null);

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file && !['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)) {
      setSelectedFile(null);
      setErrorMessage('Допустимы только файлы PDF, JPEG, PNG.');
    } else {
      setSelectedFile(file);
      setErrorMessage('');
    }

    if (
      file &&
      ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type) &&
      file.size <= 5 * 1024 * 1024
    ) {
      setAttachedFile(file);
      setErrorMessage('');
    } else {
      setErrorMessage('Неверный формат файла или размер.');
    }
  };

  const handleEditModalClose = () => {
    setCreateTicketModal(false);
    setSelectedFile(null);
    setModalTheme('');
    setModalMessage('');
  };

  const handleChangeTktStatus = async (ticketId: any) => {
    await dispatch(updateTicketStatus({ ticketId: ticketId, status: 'Закрыт' }));
    await dispatch(getTickets(user?.email));
    await dispatch(getAllTicketsForAdmin());
    setTicketModal(false);
  };

  const handleChangeTktStatusClode = async (ticketId: any) => {
    await dispatch(updateTicketStatus({ ticketId: ticketId, status: 'Открыт' }));
    await dispatch(getTickets(user?.email));
    await dispatch(getAllTicketsForAdmin());
    setTicketModal(false);
  };

  const userColumns = {
    id: '#',
    date: 'Дата',
    status: 'Статус',
    firstTimeRecharge: 'Тема',
    comment: 'Комментарий',
    message: 'Сообщения',
    user: 'Менеджер',
    action: 'Действие',
  };

  const managerColumns = {
    id: '#',
    date: 'Дата',
    status: 'Статус',
    firstTimeRecharge: 'Тема',
    comment: 'Комментарий',
    message: 'Сообщения',
    user: 'Пользователь',
  };

  const [messages, setMessages] = useState<any>([]);
  const [message, setMessage] = useState('');
  const [attachedFile, setAttachedFile] = useState<any>(null);
  const [errorMessage, setErrorMessage] = useState('');
  // const [ticketStatus, setTicketStatus] = useState('open');
  // const [notifyChatModal, setNotifyChatModal] = useState(false);

  const storedMessages = useSelector((state: any) => state.tickets.messages);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API_URL}`);

    socket.on('chat message', (msg: any) => {
      setMessages((prevMessages: any) => [...prevMessages, msg]);
    });

    if (selectedTicket?.ticketId) {
      dispatch(getMessagesByTicket(selectedTicket?.ticketId));
    }
    return () => {
      socket.disconnect();
    };
  }, [dispatch, selectedTicket?.ticketId]);

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    if (
      file &&
      ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type) &&
      file.size <= 5 * 1024 * 1024
    ) {
      setAttachedFile(file);
      setErrorMessage('');
    } else {
      setErrorMessage('Неверный формат файла или размер.');
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile(null);
    setAttachedFile(null);
    setErrorMessage('');

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const onCloseTicket = () => {
    setTicketModal(false);
  };


  const handleSendMessageSlice = async (ticket?: any, createTicketed?: any, selectedFileModal?: any) => {
    try {
      if (!message && !attachedFile && !modalMessage) {
        return;
      }

      if (selectedTicket?.ticketId || createTicketed === true) {

        if (message) {
          const messageFormData = new FormData();
          messageFormData.append('text', message);
          messageFormData.append('sender', user?.name);
          messageFormData.append('ticketId', selectedTicket?.ticketId);

          await dispatch(saveMessage(messageFormData));
        }

        if (modalMessage && ticket) {
          const messageFormData = new FormData();
          messageFormData.append('text', modalMessage);
          messageFormData.append('sender', user?.name);
          messageFormData.append('ticketId', ticket?.ticketId);

          await dispatch(saveMessage(messageFormData));
        }

        console.log('selectedFile', selectedFile)

        if (selectedFile) {
          const fileFormData = new FormData();
          fileFormData.append('file', attachedFile);
          fileFormData.append('sender', user?.name);
          fileFormData.append('ticketId', selectedTicket?.ticketId);
          await dispatch(saveMessage(fileFormData));
        }


        if (attachedFile) {
          const fileFormData = new FormData();
          fileFormData.append('file', attachedFile);
          fileFormData.append('sender', user?.name);
          fileFormData.append('ticketId', selectedTicket?.ticketId);
          await dispatch(saveMessage(fileFormData));
        }

        if (selectedFileModal) {
          const fileFormData = new FormData();
          fileFormData.append('file', selectedFileModal);
          fileFormData.append('sender', user?.name);
          fileFormData.append('ticketId', ticket?.ticketId);

          await dispatch(saveMessage(fileFormData));
        }

        setMessage('');
        setAttachedFile(null);
      }

    } catch (error) {
      console.error('Ошибка при отправке сообщения или файла:', error);
    }
  };

  const handleCreateTicket = (selectedFile: any) => {
    const formData = new FormData();

    formData.append('uploadedFile', selectedFile);

    formData.append('holderEmail', user?.email);
    formData.append('status', 'Открыт');
    formData.append('theme', modalTheme);
    formData.append('comment', modalMessage);
    formData.append('manager', user.manager ? user.manager : '');
    formData.append('date', '');

    dispatch(createTicket(formData)).unwrap()
      .then((result: any) => {
        setSelectedTicket(result);
        handleSendMessageSlice(result, true, selectedFile);
        setTicketModal(false)
      })
      .catch((error: any) => {
        console.error('Error creating ticket:', error);
      });

    setModalMessage('')
    setSelectedFile('')
    setCreateTicketModal(false);

  };

  const displayFile = (filePath: string) => {
    const fileName = filePath.split(/[/\\]/).pop();

    return (
      <a
        href={`${process.env.REACT_APP_API_URL}/api/ticket/download/${fileName}`}
        download={fileName}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={TicketDownload} alt='TicketDownload' width={50} height={50} />
      </a>
    );
  };

  // const [ticketLimit, setTicketLimit] = useState(5);

  // const showAllTickets = () => {
  //   setTicketLimit(ticketsByEmails?.length);
  // };

  const isModalOpen = useSelector((state: any) => state.tickets.isModalOpen);
  const handleClosesTicketModal = () => {
    dispatch(closeModal());
  };

  const filterableColumns: any = [
    { name: 'email', label: 'Email' },
    { name: 'holderEmail', label: 'Пользователь' },
    { name: 'status', label: 'Статус тикета' },
    { name: 'theme', label: 'Тема тикета' },
    { name: 'createdAt', label: 'Дата создания' },
  ];

  return (
    <MainLayout>
      {ticketModal && selectedTicket && (
        <div className='tickets-modal-container'>
          <div className='addTicketsModal create-new-document create-ticket-modal modal-tkst-messages'>
            <div className='modal-header'>
              <p className='chat-ticket-num'>
                Тикет #{selectedTicket.ticketId}
              </p>
              <img
                src={TicketChatClose}
                alt='TicketChatClose'
                onClick={onCloseTicket}
                className='TicketChatClose'
              />
            </div>
            <div className='ticket-modal-chat-header'>
              <p className='tkt-modal-theme'>
                {selectedTicket.theme}{' '}
              </p>
              <p className={`tkt-modal-theme-span`}>
                {selectedTicket.status}
              </p>

              {selectedTicket.status === 'Закрыт' ?
                <ButtonComponent
                  btnText='Открыть тикет'
                  className='add-advetising-btn-create add-office-modal-save close-ticket-modal'
                  btnOnClick={() =>
                    handleChangeTktStatusClode(selectedTicket?.ticketId)
                  }
                />
                : <ButtonComponent
                  btnText='Закрыть тикет'
                  className='add-advetising-btn-create add-office-modal-save close-ticket-modal'
                  btnOnClick={() =>
                    handleChangeTktStatus(selectedTicket?.ticketId)
                  }
                />}


            </div>
            <div className='modal-body'>
              <div className='chat-messages'>
                {storedMessages?.map((msg: any, index: any) => (
                  <div
                    key={index}
                    className={`chat-message ${msg?.sender === user.name ? 'actual-sender-chat' : ''
                      }`}
                  >
                    <img
                      src={UserImg}
                      alt='UserImg'
                      className={`${msg?.sender === user.name ? 'not-actual-sender' : ''
                        }`}
                    />
                    <div>
                      <div
                        className={`chat-modal-container-msg-head ${msg?.sender === user.name ? 'actual-sender' : ''
                          }`}
                      >
                        <p>{msg?.sender},</p>{' '}
                        <p className='tkt-chat-message-date'>
                          {' '}
                          {msg?.date
                            ? format(new Date(msg.date), 'dd.MM.yyyy HH:mm')
                            : ''}
                        </p>
                      </div>
                      <div
                        className={`chat-modal-container-msg  ${msg?.sender === user.name ? 'actual-sender' : ''
                          }`}
                      >
                        {!msg.filePath && <p className='chat-modal-message'>{msg.text}</p>}
                        {msg.filePath && (
                          <div className='attached-file'>
                            {displayFile(msg.filePath)}
                          </div>
                        )}
                      </div>
                    </div>

                    <img
                      src={UserImg}
                      alt='UserImg'
                      className={`${msg?.sender !== user.name ? 'img-actual-sender' : ''
                        }`}
                    />
                    {msg.file && <p>Attached: {msg.file}</p>}


                  </div>
                ))}
              </div>
              <div className='message-input'>
                <InputLabel
                  className={`register-input add-balance-input`}
                  labelClass='register-label'
                  label=''
                  type='text'
                  currencyClassName='ads-balance-currency'
                  setValue={setMessage}
                  value={message}
                  requireLabel={false}
                  maxLength={2000}
                  placeholder='Сообщение'
                  onKeyDown={(e: any) => {
                    if (e.key === 'Enter') {
                      handleSendMessageSlice();
                    }
                  }}
                />
                {message.length === 2000 && <ErrorComponent
                  error='Вы достигли макс. количество символов (2000)'
                  errorClassName=''
                />}
                <div className='send-msg-container-ticket-chat'>
                  <div className='attach-box'>
                    <img
                      src={AttachIcon}
                      alt='AttachIcon'
                      width={24}
                      height={24}
                    />
                    <p className='attach-txt' onClick={handleFileUploadClick}>
                      Прикрепить файл
                    </p>
                    <input
                      type='file'
                      ref={fileInputRef}
                      style={{ display: 'none' }}
                      onChange={handleFileUpload}
                      accept="application/pdf, image/jpeg, image/png"
                    />
                  </div>
                  {attachedFile && (
                    <div className='attached-file'>
                      <p>{attachedFile.name}</p>
                      <button onClick={handleRemoveFile} className='delete-file-btn'>
                        <img src={DeleteIcon} alt="Удалить файл" />
                      </button>
                    </div>
                  )}
                  {errorMessage && <p className='error'>{errorMessage}</p>}
                  <ButtonComponent
                    btnText='Отправить'
                    className={`add-advetising-btn-create add-office-modal-save ticket-send-msg-btn ${message.length > 0 || attachedFile !== null
                      ? 'msg-added'
                      : ''
                      }`}
                    btnOnClick={handleSendMessageSlice}
                    disabled={message.length < 1 && attachedFile === null}
                  />
                </div>
                <ButtonComponent
                  btnText='Закрыть тикет'
                  className='add-advetising-btn-create add-office-modal-save close-ticket-modal close-ticket-modal-mbl'
                  btnOnClick={() =>
                    handleChangeTktStatus(selectedTicket?.ticketId)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {isModalOpen &&
        <div className='tickets-modal-container'>
          <div className='addTicketsModal create-new-document create-ticket-modal modal-tkst-messages notify-box-modal-main'>
            <div className='modal-header'>
              <div>
                <p className='new-ticket-notify'>Новые тикеты</p>
                <div className='notify-container-main-box'>
                  <div className='tickets-container-notify'>
                  </div>
                  <div className='tickets-modal-container notify-modal-messages-box'>
                    <div className='addTicketsModal create-new-document create-ticket-modal modal-tkst-messages notify-box-modal-main-child'>
                      <div className='modal-header notify-modal-header'>

                        <p className='chat-ticket-num'>
                          Тикет #{selectedTicket?.ticketId}
                        </p>
                        <div onClick={handleClosesTicketModal}>
                          <img
                            src={TicketChatClose}
                            alt='TicketChatClose'
                            onClick={onCloseTicket}
                            className='TicketChatClose'
                          />
                        </div>

                      </div>
                      <div className='ticket-modal-chat-header'>
                        <p className='tkt-modal-theme'>
                          {selectedTicket?.theme}{' '}
                        </p>
                        <p className={`tkt-modal-theme-span`}>
                          {selectedTicket?.status}
                        </p>

                        <ButtonComponent
                          btnText='Закрыть тикет'
                          className='add-advetising-btn-create add-office-modal-save close-ticket-modal'
                          btnOnClick={() =>
                            handleChangeTktStatus(selectedTicket?.ticketId)
                          }
                        />
                      </div>
                      <div className='modal-body'>
                        <div className='chat-messages notify-chat-messages'>
                          {storedMessages?.map((msg: any, index: any) => (
                            <div
                              key={index}
                              className={`chat-message ${msg?.sender === user.name ? 'actual-sender-chat' : ''
                                }`}
                            >
                              <img
                                src={UserImg}
                                alt='UserImg'
                                className={`${msg?.sender === user.name ? 'not-actual-sender' : ''
                                  }`}
                              />
                              <div>
                                <div
                                  className={`chat-modal-container-msg-head ${msg?.sender === user.name ? 'actual-sender' : ''
                                    }`}
                                >
                                  <p>{msg?.sender},</p>{' '}
                                  <p className='tkt-chat-message-date'>
                                    {' '}
                                    {msg?.date
                                      ? format(new Date(msg.date), 'dd.MM.yyyy HH:mm')
                                      : ''}
                                  </p>
                                </div>
                                <div
                                  className={`chat-modal-container-msg  ${msg?.sender === user.name ? 'actual-sender' : ''
                                    }`}
                                >
                                  {!msg.filePath && <p className='chat-modal-message'>{msg.text}</p>}
                                  {msg.filePath && (
                                    <div className='attached-file'>
                                      {displayFile(msg.filePath)}
                                    </div>
                                  )}
                                </div>
                              </div>

                              <img
                                src={UserImg}
                                alt='UserImg'
                                className={`${msg?.sender !== user.name ? 'img-actual-sender' : ''
                                  }`}
                              />
                              {msg.file && <p>Attached: {msg.file}</p>}


                            </div>
                          ))}
                        </div>
                        <div className='message-input'>
                          <InputLabel
                            className={`register-input add-balance-input`}
                            labelClass='register-label'
                            label=''
                            type='text'
                            currencyClassName='ads-balance-currency'
                            setValue={setMessage}
                            value={message}
                            requireLabel={false}
                            maxLength={2000}
                            placeholder='Сообщение'
                            onKeyDown={(e: any) => {
                              if (e.key === 'Enter') {
                                handleSendMessageSlice();
                              }
                            }}
                          />
                          {message.length === 50 && <ErrorComponent
                            error='Вы достигли макс. количество символов (2000)'
                            errorClassName=''
                          />}
                          <div className='send-msg-container-ticket-chat'>
                            <div className='attach-box'>
                              <img
                                src={AttachIcon}
                                alt='AttachIcon'
                                width={24}
                                height={24}
                              />
                              <p className='attach-txt' onClick={handleFileUploadClick}>
                                Прикрепить файл
                              </p>
                              <input
                                type='file'
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                onChange={handleFileUpload}
                                accept="application/pdf, image/jpeg, image/png"
                              />
                            </div>
                            {attachedFile && (
                              <div className='attached-file'>
                                <p>{attachedFile.name}</p>
                                <button onClick={handleRemoveFile} className='delete-file-btn'>
                                  <img src={DeleteIcon} alt="Удалить файл" />
                                </button>
                              </div>
                            )}
                            {errorMessage && <p className='error'>{errorMessage}</p>}
                            <ButtonComponent
                              btnText='Отправить'
                              className={`add-advetising-btn-create add-office-modal-save ticket-send-msg-btn ${message.length > 0 || attachedFile !== null
                                ? 'msg-added'
                                : ''
                                }`}
                              btnOnClick={handleSendMessageSlice}
                              disabled={message.length < 1 && attachedFile === null}
                            />
                          </div>
                          <ButtonComponent
                            btnText='Закрыть тикет'
                            className='add-advetising-btn-create add-office-modal-save close-ticket-modal close-ticket-modal-mbl'
                            btnOnClick={() =>
                              handleChangeTktStatus(selectedTicket?.ticketId)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {createTicketModal && (
        <div className='tickets-modal-container'>
          <div className='addTicketsModal create-new-document create-ticket-modal'>
            <div className='new-ticket-request'>Новый запрос</div>
            <p className='ticket-tickets-theme'>
              Тема запроса <span className=''>*</span>
            </p>
            <CustomSelectComponent
              className='register-select add-office-ads-select'
              selectOptions={adsOptions}
              setMessengerType={setModalTheme}
              selectWrapper='select-wrapper-class'
              defaultEmptyValue='Все'
            />
            <p className='ticket-tickets-theme'>
              Сообщение <span className=''>*</span>
            </p>
            <textarea
              id='comment'
              name='comment'
              value={modalMessage}
              onChange={(e) => setModalMessage(e.target.value)}
              className='comment-input'
              maxLength={200}
            />
            <div className='attach-box'>
              <img src={AttachIcon} alt='AttachIcon' width={24} height={24} />
              <p className='attach-txt' onClick={handleFileUploadClick}>
                Прикрепить файл
              </p>
              <input
                type='file'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="application/pdf, image/jpeg, image/png"
              />
            </div>
            {selectedFile && (
              <div className='file-info'>
                <p>{selectedFile.name}</p>
                <button onClick={handleRemoveFile} className='delete-file-btn'>
                  <img src={DeleteIcon} alt="Удалить файл" />
                </button>
              </div>
            )}
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}

            <div className='add-advetising-btns tickets-modal-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='tickets-btn-cancel'
                btnOnClick={handleEditModalClose}
              />

              <ButtonComponent
                btnText='Отправить'
                className={`tickets-btn-create add-tickets-modal-save ${modalMessage.length > 0 || modalTheme !== '' || selectedFile
                  ? 'editInputChanged'
                  : ''
                  }`}
                disabled={
                  modalMessage.length < 1 && modalTheme === '' && !selectedFile
                }
                btnOnClick={() => handleCreateTicket(selectedFile)}
              />
            </div>
          </div>
        </div>
      )}

      <div className='networks-box recovery-container recovery-container--justify-start'>
        <RoutesList routeClassName='finance-history-route' />
        <div className='ticket-menu'>
          <div className='ticket-menu__title'>
            <div className='ticket-menu__title-text'>Тикеты</div>
            {user.role === 'user' && (
              <ButtonComponent
                btnText={isMobile ? 'Новый запрос' : '+ Новый запрос'}
                className={`tickets-btn tickets-btn--mobile-hidden ${isMobile ? '' : 'new-ticket-not-mobile'
                  }`}
                btnOnClick={handleOpenModalTicket}
              />
            )}
          </div>

          {showFilters && (
            <div className='tickets-filters'>
              {user.role !== 'user' && (
                <div className='filter-item'>
                  <p className='tickets-filter-label'>Дата создания</p>
                  <div
                    className='select-box tickets-main-calendar'
                    onClick={handleCalendar}
                  >
                    {startDate
                      ? format(startDate, 'dd.MM.yyyy')
                      : 'Выберите дату'}{' '}
                    -
                    {endDate
                      ? format(endDate, 'dd.MM.yyyy')
                      : startDate
                        ? format(startDate, 'dd.MM.yyyy')
                        : 'Выберите дату'}
                    <img
                      src={CalendarIcon}
                      alt='Календарь'
                      className='tickets-CalendarIcon'
                    />
                  </div>
                  {showCalendar && (
                    <DatePickerComponent
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      onClose={() => setShowCalendar(false)}
                      calendarContainerClassName='ticket-calendar-container'
                    />
                  )}
                </div>
              )}

              {user.role !== 'user' && (
                <SearchInput
                  searchValue={filters['holderEmail'] || ''}
                  onSearchChange={(e) =>
                    handleFilterChange('holderEmail', e.target.value)
                  }
                  className='custom-table__search-container tickets-main-user-filter'
                  searchName='Пользователь'
                  searchWrapperClassname='tickets-search-wrapper'
                />
              )}

              <div className='filter-item'>
                <p className='tickets-filter-label'>Статус</p>
                <CustomSelectComponent
                  className={`custom-select ${user.role === 'user' ? 'tickets-user-status-wrapper' : ''
                    }`}
                  selectOptions={[
                    { value: 'Все', label: 'Все' },
                    { value: 'Открыт', label: 'Открыт' },
                    { value: 'Закрыт', label: 'Закрыт' },
                  ]}
                  setMessengerType={(value) =>
                    handleFilterChange('status', value)
                  }
                  defaultEmptyValue='Все'
                  selectWrapper='select-wrapper-class'
                  customSelectWrapperClassName={`tickets-custom-select-wrapper tickets-status-select ${user.role === 'user' ? 'tickets-user-status-select' : ''
                    }`}
                />
              </div>

              {user.role !== 'user' && (
                <div className='filter-item'>
                  <p className='tickets-filter-label'>Тема тикета</p>
                  <CustomSelectComponent
                    className='custom-select'
                    selectOptions={[
                      { value: 'Все', label: 'Все' },
                      {
                        value: 'создание кабинета',
                        label: 'Создание кабинета',
                      },
                      {
                        value: 'пополнение баланса ad.kitchen',
                        label: 'Пополнение баланса ad.kitchen',
                      },
                      {
                        value: 'пополнение кабинета',
                        label: 'Пополнение кабинета',
                      },
                      {
                        value: 'запрос от пользователя',
                        label: 'Запрос от пользователя',
                      },
                    ]}
                    setMessengerType={(value) =>
                      handleFilterChange('theme', value)
                    }
                    defaultEmptyValue='Все'
                    selectWrapper='select-wrapper-class'
                    customSelectWrapperClassName='tickets-custom-select-wrapper'
                  />
                </div>
              )}
            </div>
          )}
          <div className='toggle-filters'>
            <button className='toggle-filters-btn'>
              <div
                className='toggle-filters-wrapper'
                onClick={toggleFiltersVisibility}
              >
                {showFilters ? 'Скрыть фильтр' : 'Показать фильтр'}
                <Icon
                  className='manager-user__close-icon'
                  icon={
                    showFilters
                      ? 'iconamoon:arrow-up-2-bold'
                      : 'iconamoon:arrow-down-2'
                  }
                  width='24px'
                  color='#8F97A9'
                />
              </div>
            </button>
          </div>
          <div className='ticket-menu__select'>
            <CustomTable
              handleOpenTicketModal={handleOpenTicketModal}
              columnHeaders={
                user.role === 'user' ? userColumns : managerColumns
              }
              data={
                user.role === 'user'
                  ? addActionsToTickets(filteredTickets)
                  : user.role === 'manager'
                    ? addActionsToTickets(filteredTicketsByManager)
                    : addActionsToTickets(filteredTickets)
              }
              // filterableColumns={filterableColumns}
              showTicketChatIcon
              paginationClassName='tickets-pagination'
            />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default Tickets;
