import React from 'react';
import { useNavigate } from 'react-router-dom';
import ButtonWithIcon from '../../ui/button/buttonWithIcon';
import UserIcon from '../../../assets/icons/user-icon';
import MainLayout from '../../layouts/mainLayout';

const RecoveryPasswordCheck = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  setTimeout(() => {
    navigate('/login');
  }, 4000);

  return (
    <MainLayout>
      <div className='recovery-container'>
        <div className='recovery-main check-password'>
          <p className='recovery-title'>Проверьте почту</p>
          <p className='recovery-main-body'>
            Ссылка для сброса пароля успешно отправлена на вашу почту.{' '}
          </p>
        </div>
        <div className='registration__signin-section'>
          <p>Нет аккаунта?</p>
          <ButtonWithIcon
            btnText={'Зарегистрироваться'}
            withIcon={true}
            icon={<UserIcon />}
            btnIconClassName='register-header-btn-icon'
            className='registration-header-btn-icon'
            btnOnClick={handleClick}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default RecoveryPasswordCheck;
