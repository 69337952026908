import React from 'react';

const ReopenIcon = ({ className }: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4_23078)">
        <path d="M4 20C3.45 20 2.97933 19.8043 2.588 19.413C2.19667 19.0217 2.00067 18.5507 2 18V6C2 5.45 2.196 4.97933 2.588 4.588C2.98 4.19667 3.45067 4.00067 4 4H20C20.55 4 21.021 4.196 21.413 4.588C21.805 4.98 22.0007 5.45067 22 6V12C22 12.2833 21.904 12.521 21.712 12.713C21.52 12.905 21.2827 13.0007 21 13C20.7173 12.9993 20.48 12.9033 20.288 12.712C20.096 12.5207 20 12.2833 20 12V8H4V18H11C11.2833 18 11.521 18.096 11.713 18.288C11.905 18.48 12.0007 18.7173 12 19C11.9993 19.2827 11.9033 19.5203 11.712 19.713C11.5207 19.9057 11.2833 20.0013 11 20H4ZM19 24C18.05 24 17.1833 23.7627 16.4 23.288C15.6167 22.8133 15.0083 22.1757 14.575 21.375C14.4583 21.175 14.4377 20.9623 14.513 20.737C14.5883 20.5117 14.734 20.3577 14.95 20.275C15.1333 20.1917 15.3167 20.1917 15.5 20.275C15.6833 20.3583 15.825 20.4917 15.925 20.675C16.225 21.225 16.65 21.6667 17.2 22C17.75 22.3333 18.35 22.5 19 22.5C19.9667 22.5 20.7917 22.1583 21.475 21.475C22.1583 20.7917 22.5 19.9667 22.5 19C22.5 18.0333 22.1583 17.2083 21.475 16.525C20.7917 15.8417 19.9667 15.5 19 15.5C18.5333 15.5 18.0917 15.5833 17.675 15.75C17.2583 15.9167 16.8833 16.1667 16.55 16.5H17.25C17.4667 16.5 17.646 16.571 17.788 16.713C17.93 16.855 18.0007 17.034 18 17.25C17.9993 17.466 17.9283 17.6453 17.787 17.788C17.6457 17.9307 17.4667 18.0013 17.25 18H15C14.7167 18 14.4793 17.904 14.288 17.712C14.0967 17.52 14.0007 17.2827 14 17V14.75C14 14.5333 14.071 14.3543 14.213 14.213C14.355 14.0717 14.534 14.0007 14.75 14C14.966 13.9993 15.1453 14.0703 15.288 14.213C15.4307 14.3557 15.5013 14.5347 15.5 14.75V15.425C15.9833 14.975 16.525 14.625 17.125 14.375C17.725 14.125 18.35 14 19 14C20.3833 14 21.5627 14.4877 22.538 15.463C23.5133 16.4383 24.0007 17.6173 24 19C23.9993 20.3827 23.5117 21.562 22.537 22.538C21.5623 23.514 20.3833 24.0013 19 24Z" fill="#42A6D5" />
      </g>
      <defs>
        <clipPath id="clip0_4_23078">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ReopenIcon;
