import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const updateContactInfo: any = createAsyncThunk(
  'manager/updateContactInfo',
  async ({ email, phone, messenger, messengerType, phoneCountry, status, userType, networks }: 
    { email: string; phone: any; messenger: any; messengerType: string; phoneCountry: any; status: string; userType: string; networks: any }, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/manager/update-profile-info', { email, phone, messenger, messengerType, phoneCountry, status, userType, networks });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState = {
  updatedtData: null,
  isLoading: false,
  error: null,
};

const updateContactInfoSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateContactInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateContactInfo.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.updatedtData = action.payload;
      })
      .addCase(updateContactInfo.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default updateContactInfoSlice.reducer;
