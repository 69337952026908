import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../../core/api';
import { logoutUser } from '../../login/loginSlice';

interface GetOfficesParams {
  holderEmail: string;
  networkType: string;
}

interface OfficesState {
  offices: any;
  isLoading: boolean;
  error: string | null;
}

interface GetOfficesByManagerParams {
  managerEmail: string;
}

const initialState: OfficesState = {
  offices: null,
  isLoading: false,
  error: null,
};

export const getOfficesByEmailAndNetworkType = createAsyncThunk(
  'office/get-offices-by-email-and-networkType',
  async (
    { holderEmail, networkType }: GetOfficesParams,
    { rejectWithValue }
  ) => {
    try {
      const response = await $api.post(
        '/api/office/get-offices-by-email-and-networkType',
        { holderEmail, networkType }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// export const getOfficesByManagerEmail = createAsyncThunk(
//   'office/get-offices-by-manager-email',
//   async ({ managerEmail }: GetOfficesByManagerParams, { rejectWithValue }) => {
//     try {
//       const response = await $api.get(`/api/office/get-offices-by-manager?managerEmail=${managerEmail}`);
//       return response.data;
//     } catch (error: any) {
//       return rejectWithValue(error.response.data);
//     }
//   }
// );
export const getOfficesByManagerEmail = createAsyncThunk(
  'office/get-offices-by-manager-email',
  async ({ managerEmail }: GetOfficesByManagerParams, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/office/get-offices-by-manager', {
        managerEmail,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getOfficesByEmailSlice = createSlice({
  name: 'offices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getOfficesByEmailAndNetworkType.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getOfficesByEmailAndNetworkType.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.offices = action.payload;
        }
      )
      .addCase(
        getOfficesByEmailAndNetworkType.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      )
      .addCase(getOfficesByManagerEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getOfficesByManagerEmail.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.offices = action.payload;
        }
      )
      .addCase(
        getOfficesByManagerEmail.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      )
      .addCase(logoutUser, () => initialState);
  },
});

export default getOfficesByEmailSlice.reducer;
