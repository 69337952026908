import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuth } from '../../../features/login/loginSlice';
import { AppDispatch, RootState } from '../../../app/store';
import Spinner from '../../ui/spinner/spinner';
import ModalComponent from '../../ui/modal/modal';

export interface User {
  id: string;
  name: string;
  role: string;
  user: string;
}

const PrivateRoute = ({ element: Component, ...rest }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const isAuthenticated = useSelector((state: RootState) => !!state.login.user);
  const user = useSelector(
    (state: RootState) => state.login.user
  ) as User | null;
  const isAdmin = user?.role === 'administrator';
  const isLoading = useSelector((state: RootState) => state.login?.isLoading);
  const isAuthChecked = useSelector(
    (state: RootState) => state.login.isAuthChecked
  );
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (!isAuthChecked && localStorage.getItem('token')) {
      dispatch(checkAuth());
    } else if (!localStorage.getItem('token')) {
      navigate('/login');
    }
  }, [dispatch, navigate, isAuthChecked, isLoading]);

  useEffect(() => {
    if (
      isAuthChecked &&
      !isAdmin &&
      !isAuthenticated &&
      window.location.pathname !== '/login'
    ) {
      setShowModal(true);
    }
  }, [isAuthChecked, isAdmin, isAuthenticated]);

  const closeModal = () => {
    setShowModal(false);
    navigate('/login');
  };

  if (isLoading || !isAuthChecked) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const currentPath = window.location.pathname;
  const isLoginPath = currentPath === '/login';

  if (!isAuthenticated && !isLoginPath) {
    return <Navigate to='/login' />;
  }

  return (
    <>
      {isAdmin || isAuthenticated ? (
        <Component {...rest} />
      ) : (
        <Navigate to='/login' />
      )}
      {showModal && !isLoginPath && (
        <ModalComponent
          className='your-modal-classname'
          modalBtnClassName='your-modal-btn-classname'
          modalText='<h2>Доступно только Администратору</h2>'
          twoBtn={false}
          firstBtnText='OK'
          firstBtnClick={closeModal}
        />
      )}
    </>
  );
};

export default PrivateRoute;
