import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import './manager-page.scss';
import ContactInfo from '../../ui/contact-info/contact-info';
import { useNavigate, useParams } from 'react-router-dom';
import UserIcon from '../../../assets/images/user-icon.png';
import CheckedRedCircle from '../../../assets/icons/checked-red-circle.svg';
import UserList from '../../ui/user-list/user-list';
import { useDispatch, useSelector } from 'react-redux';
import { updateContactInfo } from '../../../features/update-manager-info/update-manager-info-slice';
import { removeManagerFromUsers } from '../../../features/remove-manager-from-users/remove-manager-from-users-slice';
import {
  getManagers,
  updateDataManager,
} from '../../../features/get-managers/get-managers-slice';
import { updateUserStatus } from '../../../features/update-status/update-status-slice';
import { assignManager } from '../../../features/assign-to-manager/assign-to-manager-slice';
import { phoneLengths } from '../../../utils/phone-lengths';
import { getUsersByManager } from '../../../features/get-users-by-manager/get-users-by-manager-slice';
import { AppDispatch } from '../../../app/store';

const ManagerPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { userId } = useParams(); // Используем userId для поиска менеджера
  // console.log("🚀 ~ userId:", userId);
  const managers = useSelector((state: any) => state.managers.managers) || [];
  // console.log("🚀 ~ managers:", managers)
  const [loading, setLoading] = useState<boolean>(true); // состояние загрузки данных
  const [currentUser, setCurrentUser] = useState<any>(null); // текущий менеджер
  // console.log("🚀 ~ currentUser:", currentUser)
  const [currentMessenger, setCurrentMessenger] = useState<string>('');
  const email = currentUser?.email || '';
  const phone = currentUser?.phone || '';
  // console.log("🚀 ~ phone:", phone)
  const status = currentUser?.status || '';
  const [currentPhone, setCurrentPhone] = useState<string>(phone || '');

  const [messengerType, setMessengerType] = useState<any>('');
  const navigate = useNavigate();
  const [pendingDelete, setPendingDelete] = useState<string[]>([]);
  const [phoneValueChanged, setphoneValueChanged] = useState<boolean>(false);
  const [mesengerValueChanged, setMesengerValueChanged] =
    useState<boolean>(false);
  const [statusValueChanged, setStatusValueChanged] = useState<boolean>(false);
  const [statusState, setStatusState] = useState<string>('active');
  const [addUserState, setAddUserState] = useState<boolean>(false);
  const [usersToadd, setUsersToadd] = useState<string[]>([]);
  const [phonelengthError, setPhonelengthError] = useState<boolean>(false);
  const [messengerlengthError, setMessengerlengthError] =
    useState<boolean>(false);
  const [selectedCountryState, setSelectedCountryState] = useState<any>({
    code: 'RU',
    mask: '+7 (999) 999-99-99',
    name: 'Russia',
  });
  const [countryChanged, setCountryChanged] = useState<boolean>(false);
  const [showModal, setShowModal] = useState(false);
  const [messengerTypeValueChanged, setMessengerTypeValueChanged] =
    useState<boolean>(false);

  // Загружаем данные о менеджере только после загрузки всех менеджеров
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (managers.length === 0) {
          // Загружаем менеджеров, если их еще нет в сторе
          await dispatch(getManagers({}));
        }
        // Находим менеджера только после загрузки данных
        const foundUser = managers.find((manager: any) => manager._id === userId);
        setCurrentUser(foundUser);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching managers:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch, managers, userId]);

  useEffect(() => {
    if (currentUser?.messenger) {
      setCurrentMessenger(currentUser.messenger);
    }
    if (currentUser?.messengerType) {
      setMessengerType(currentUser.messengerType);
    }
  }, [currentUser]);

  // Показываем loader пока данные загружаются
  if (loading) {
    return <div>Loading...</div>;
  }

  // Проверка, что менеджер найден
  // if (!currentUser) {
  //   return <div>Менеджер не найден</div>;
  // }

  const showModalWithTimeout = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
      dispatch(getManagers({}));
      navigate('/managers');
    }, 1000);
  };

  const handleSaveForm = async () => {
    try {
      if (pendingDelete.length > 0) {
        const removeResponse = await dispatch(
          removeManagerFromUsers({
            managerEmail: email,
            userEmails: pendingDelete,
          })
        );

        if (
          removeResponse.type !== removeManagerFromUsers.fulfilled.toString()
        ) {
          console.error('Error removing manager from users:', removeResponse);
          return;
        }
      }

      const formatPhoneNumber = (phone: string) => {
        return phone.replace(/\D/g, '');
      };

      const formattedPhone = formatPhoneNumber(currentPhone);

      if (addUserState === true && usersToadd.length > 0) {
        const assignResponse = await dispatch(
          assignManager({ managerEmail: email, userEmails: usersToadd })
        );

        if (assignResponse.type !== assignManager.fulfilled.toString()) {
          console.error('Error assigning manager:', assignResponse);
          return;
        }
      }

      if (
        selectedCountryState.code in phoneLengths &&
        formattedPhone.length !== phoneLengths[selectedCountryState.code]
      ) {
        setPhonelengthError(true);
        return;
      } else {
        setPhonelengthError(false);
      }

      if (currentMessenger.length < 2) {
        setMessengerlengthError(true);
        return;
      } else {
        setMessengerlengthError(false);
      }

      const networks = {
        'Google Ads': 20,
      };

      if (
        phoneValueChanged ||
        mesengerValueChanged ||
        messengerTypeValueChanged
      ) {
        const updateResponse = await dispatch(
          updateContactInfo({
            email,
            phone: formattedPhone,
            messenger: currentMessenger,
            messengerType,
            phoneCountry: selectedCountryState.name,
            networks: networks,
          })
        );

        if (updateResponse.type !== updateContactInfo.fulfilled.toString()) {
          console.error('Error updating contact info:', updateResponse);
          return;
        }

        dispatch(
          updateDataManager({
            id: userId,
            phone: formattedPhone,
            messenger: currentMessenger,
            messengerType,
            phoneCountry: selectedCountryState.name,
            networks: networks,
          })
        );
      }

      if (statusValueChanged) {
        const updateStatusResponse = await dispatch(
          updateUserStatus({ email: email, status: statusState })
        );

        if (
          updateStatusResponse.type !== updateUserStatus.fulfilled.toString()
        ) {
          console.error('Error updating user status:', updateStatusResponse);
          return;
        }

        dispatch(
          updateDataManager({
            id: userId,
            status: statusState,
          })
        );
      }

      showModalWithTimeout();
    } catch (error) {
      console.error('Error saving form:', error);
    }
  };

  const handleSaveButtonClick = async () => {
    const formattedPhone = currentPhone.replace(/\D/g, '');

    if (
      selectedCountryState.code in phoneLengths &&
      formattedPhone.length !== phoneLengths[selectedCountryState.code]
    ) {
      setPhonelengthError(true);
    } else {
      setPhonelengthError(false);
      await handleSaveForm();
    }
  };

  const backRoute = () => {
    navigate('/managers');
  };

  return (
    <MainLayout>
      <div className='manager-page__user-info'>
        <img src={UserIcon} alt='User avatar' className='user-info__avatar' />
        <div className='user-info__email'>{email}</div>
        <div
          className={`user-info__status ${status !== 'active' ? 'user-info__status--inactive' : ''
            }`}
        >
          <div
            className={`status__text ${status !== 'active' ? 'status__text--inactive' : ''
              }`}
          >
            {status === 'active' ? 'Активен' : 'Приостановлен'}
          </div>
        </div>
      </div>
      <div className='manager-page__container'>
        <ContactInfo
          email={email}
          phone={phone}
          messenger={currentMessenger}
          status={status}
          contactClassName='manager-class-name'
          setCurrentPhone={setCurrentPhone}
          currentPhone={currentPhone}
          setCurrentMessenger={setCurrentMessenger}
          currentMessenger={currentMessenger}
          setMessengerType={setMessengerType}
          messengerType={messengerType}
          setphoneValueChanged={setphoneValueChanged}
          phonelengthError={phonelengthError}
          messengerlengthError={messengerlengthError}
          setMesengerValueChanged={setMesengerValueChanged}
          setStatusValueChanged={setStatusValueChanged}
          setStatusState={setStatusState}
          setSelectedCountryState={setSelectedCountryState}
          setCountryChanged={setCountryChanged}
          setMessengerTypeValueChanged={setMessengerTypeValueChanged}
          phoneCountryState={currentUser?.phoneCountry}
        />
        <UserList
          userEmail={email}
          setPendingDelete={setPendingDelete}
          pendingDelete={pendingDelete}
          setAddUserState={setAddUserState}
          setUsersToadd={setUsersToadd}
          usersToadd={usersToadd}
        />
        <div className='manager-page__buttons'>
          <button className='manager-page__back-btn' onClick={backRoute}>
            Вернуться к списку
          </button>
          <button
            className={`${pendingDelete.length > 0 ||
              phoneValueChanged ||
              mesengerValueChanged ||
              statusValueChanged ||
              addUserState ||
              countryChanged ||
              messengerTypeValueChanged
              ? 'manager-page__save-btn'
              : 'manager-page__save-btn default'
              } `}
            onClick={handleSaveButtonClick}
            disabled={
              pendingDelete.length === 0 &&
              !phoneValueChanged &&
              !mesengerValueChanged &&
              !statusValueChanged &&
              !addUserState &&
              !countryChanged &&
              !messengerTypeValueChanged
            }
          >
            Сохранить
          </button>
        </div>
      </div>
      {showModal && (
        <div className='modal'>
          <div className='modal-content'>
            <img
              src={CheckedRedCircle}
              alt='User avatar'
              className='user-info__checked-circle'
            />
            <div>Мы успешно сохранили ваши изменения</div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default ManagerPage;
