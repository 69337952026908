import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const getManagers = createAsyncThunk(
  'getManagers',
  async ({}: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/manager/get-managers');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getmanagersSlice = createSlice({
  name: 'managers',
  initialState: {
    managers: [] as any[],
    isLoading: false,
    error: null,
  },
  reducers: {
    updateDataManager: (state, action: PayloadAction<any>) => {
      // Получаем обновлённые данные менеджера из экшена
      const updatedManager = action.payload;

      // Находим индекс менеджера в массиве
      const managerIndex = state.managers.findIndex(
        (manager: any) => manager.id === updatedManager.id
      );

      // Если менеджер найден, обновляем его данные
      if (managerIndex !== -1) {
        state.managers[managerIndex] = {
          ...state.managers[managerIndex],
          ...updatedManager,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManagers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getManagers.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.managers = action.payload;
      })
      .addCase(getManagers.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateDataManager } = getmanagersSlice.actions;
export default getmanagersSlice.reducer;
