import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import RegistrationPage from './components/pages/registrationPage/registration-page';
import LoginPage from './components/pages/loginPage/login-page';
import RecoveryPassword from './components/pages/recovery-password/recovery-password';
import RecoveryPasswordCheck from './components/pages/recovery-password/recovery-password-check';
import RecoveryPasswordForm from './components/pages/recovery-password/recovery-password-form';
import AdvertisingSystemsOffice from './components/pages/advertising-systems-office/advertising-systems-office';
import ApproveRegistrationCode from './components/pages/approve-registration-code/approve-registration-code';
import SuccessRegistrationCode from './components/pages/success-registration-code/success-registration-code';
import AddAdvertisingSystemsOffice from './components/pages/add-advertising-systems-office/add-advertising-systems-office';
import RecoveryPasswordDone from './components/pages/recovery-password/recovery-password-done';
import UserAgreement from './components/pages/user-agreement/user-agreement';
import OffertLaw from './components/pages/offert-law/offert-law';
import PrivateRoute from './components/services/private-route/PrivateRoute';
import LandingPage from './components/pages/landing/landing';
import AddBalance from './components/pages/add-balance/add-balance';
import FinanceHistory from './components/pages/finance-history/finance-history';
import Documents from './components/pages/documents/documents';
import Tickets from './components/pages/tickets/tickets';
import Faq from './components/pages/faq/faq';
import UserSettings from './components/pages/user-settings/user-settings';
import TwoFactorAuth from './components/pages/two-factor-auth/two-factor-auth';
import ManagerLogin from './components/pages/manager-login/manager-login';
import AdministratorHome from './components/pages/administrator-home/administrator-home';
import ManagerHome from './components/pages/manager-home/manager-home';
import ManagerUsers from './components/pages/manager-users/manager-users';
import ManagerUser from './components/pages/manager-user/manager-user';
import ManagerProfile from './components/pages/manager-profile/manager-profile';
import Managers from './components/pages/managers/managers';
import ManagerRecoveryPassword from './components/pages/manager-recovery-password/manager-recovery-password';
import ManagerPage from './components/pages/manager-page/manager-page';
import useUTMCookies from './components/hooks/useUTMCookies';
import AdminRoute from './components/services/admin-route/AdminRoute';
import AdvertisingSystemsOfficeHome from './components/pages/advertising-systems-office-home/advertising-systems-office-home';
import RoleBasedRedirect from './role-based-redirect';
import MainLayout from './components/layouts/mainLayout';
import FaqQuestion from './components/pages/faq/faq-question';
import FaqAddQuestion from './components/pages/faq/faq-add-question';
import Contacts from './components/pages/contacts/contacts';
import ApproveUserEmail from './components/pages/approve-registration-code/approve-user-email';
import FaqEditQuestion from './components/pages/faq/faq-edit-question';

function App() {
  return (
    <Router>
      <UTMCookiesWrapper /> 
      <Routes>
        <Route path='/' element={<LandingPage />} />
        <Route path='/redirect' element={<RoleBasedRedirect />} />
        <Route path='/register' element={<RegistrationPage />} />
        <Route path='/login' element={<LoginPage />} />
        <Route path='/admin' element={<ManagerLogin />} />
        <Route path='/contacts' element={<Contacts />} />

        <Route path='/two-factor-auth' element={<TwoFactorAuth />} />
        <Route
          path='/user-settings'
          element={<PrivateRoute element={UserSettings} />}
        />

        <Route
          path='/add-balance'
          element={<PrivateRoute element={AddBalance} />}
        />
        <Route
          path='/finance-history'
          element={<MainLayout><PrivateRoute element={FinanceHistory} /></MainLayout>}
        />

        <Route
          path='/documents'
          element={<PrivateRoute element={Documents} />}
        />

        <Route
          path='/advertising-systems-office-home'
          element={<PrivateRoute element={AdvertisingSystemsOfficeHome} />}
        />
        <Route
          path='/two-factor-auth-manager'
          element={<AdminRoute element={TwoFactorAuth} />}
        />

        <Route
          path='/administrator-home'
          element={<AdminRoute element={AdministratorHome} />}
        />
        <Route
          path='/manager-home'
          element={<AdminRoute element={ManagerHome} />}
        />
        <Route
          path='/manager-users'
          element={<AdminRoute element={ManagerUsers} />}
        />
        <Route
          path='/manager-user/:userId'
          element={<AdminRoute element={ManagerUser} />}
        />
        <Route
          path='/manager-profile'
          element={<AdminRoute element={ManagerProfile} />}
        />
        <Route path='/managers' element={<AdminRoute element={Managers} />} />
        <Route
          path='/manager-recovery-password'
          element={<ManagerRecoveryPassword />}
        />
        <Route
          path='/manager-page/:userId'
          element={<AdminRoute element={ManagerPage} />}
        />

        <Route path='/recovery-password' element={<RecoveryPassword />} />
        <Route
          path='/recovery-password-check'
          element={<RecoveryPasswordCheck />}
        />
        <Route
          path='/recovery-password-done'
          element={<RecoveryPasswordDone />}
        />
        <Route
          path='/recovery-password-form/:recoverPasswordLink/:encodedEmail/:timestamp'
          element={<RecoveryPasswordForm />}
        />

        <Route
          path='/advertising-systems-office'
          element={<PrivateRoute element={AdvertisingSystemsOffice} />}
        />

        <Route
          path='/tickets'
          element={<PrivateRoute element={Tickets} />}
        />

        <Route
          path='/faq/:questionId'
          element={<PrivateRoute element={FaqQuestion} />}
        />

        <Route
          path='/faq/add'
          element={<PrivateRoute element={FaqAddQuestion} />}
        />


        <Route
          path='/faq'
          element={<PrivateRoute element={Faq} />}
        />

        <Route
          path='/faq-edit-question'
          element={<PrivateRoute element={FaqEditQuestion} />}
        />

        <Route
          path='/add-advertising-systems-office'
          element={<PrivateRoute element={AddAdvertisingSystemsOffice} />}
        />
        <Route
          path='/approve-registration-code'
          element={<ApproveRegistrationCode />}
        />
        <Route
          path='/approve-user-email'
          element={<PrivateRoute element={ApproveUserEmail} />}
        />
        <Route
          path='/success-registration-code'
          element={<SuccessRegistrationCode />}
        />
        <Route path='/privacy-policy' element={<UserAgreement />} />
        <Route path='/user-agreement' element={<OffertLaw />} />
      </Routes>
    </Router>
  );
}

const UTMCookiesWrapper = () => {
  useUTMCookies();
  return null;
};

export default App;
