import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import ReactCountryFlag from 'react-country-flag';
import './manager-phone.scss';
import { countries } from '../../utils/countries';
import CopyBtn from '../../assets/images/copy-btn.png';
import DropDownIcon from '../../assets/icons/dropDownIcon';
// import { getCountryByPhone } from '../../utils/get-country-by-phone';
import { useDispatch } from 'react-redux';
import { Icon } from '@iconify/react';

interface ManagerPhoneProps {
  value: string | undefined | null;
  setValue: (value: string) => void;
  setphoneValueChanged?: any;
  copyBtn?: boolean;
  setSelectedCountryState?: any;
  phoneCountryState?: any;
  setPhoneCountry?: any;
  setCountryChanged?: (changed: boolean) => void;
  className?: string;
}

const ManagerPhone: React.FC<ManagerPhoneProps> = ({
  value,
  setValue,
  setphoneValueChanged,
  copyBtn = false,
  setSelectedCountryState,
  phoneCountryState,
  setCountryChanged,
  setPhoneCountry,
  className
}: any) => {
  const phoneCountry = countries.filter(
    (country) => country?.name === phoneCountryState
  );
  const [selectedCountry, setSelectedCountry] = useState(
    phoneCountry[0] || countries[0]
  );
  const [phoneValue, setPhoneValue] = useState(value || '');
  const [showDropdown, setShowDropdown] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  useEffect(() => {
    setPhoneValue(value);
  }, [value]);

  useEffect(() => {
    setSelectedCountryState(selectedCountry);
  }, [selectedCountry, setSelectedCountryState]);

  const handleCountryChange = (countryCode: string) => {
    const selectedCountry = countries.find(
      (country) => country?.code === countryCode
    );

    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
      setSelectedCountryState(selectedCountry);
      setPhoneValue('');
      setValue('');
      if (setPhoneCountry) {
        setPhoneCountry(selectedCountry);
      }
      if (setCountryChanged) {
        setCountryChanged(true);
      }
    }
    setShowDropdown(false);
  };

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneValue(value);
    setValue(value);
    if (setphoneValueChanged) {
      setphoneValueChanged(true);
    }
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(phoneValue);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 1000);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        showDropdown &&
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  return (
    <div className='relative' ref={containerRef}>
      <div className='phone-box'>
        <div
          className={`manager-phone__img phone-select ${className ? className : ''}`}
          onMouseUp={toggleDropdown}
        >
          <ReactCountryFlag
            className='emojiFlag'
            countryCode={selectedCountry.code}
            svg
          />
          {/* <DropDownIcon className='manager-phone__dropdown-style' /> */}
          <Icon
            className='custom-select__icon'
            icon='iconamoon:arrow-down-2-bold'
            width='24px'
            color='#6C6EA0'
          />
        </div>

        {showDropdown && (
          <div className='phone-dropdown' ref={dropdownRef}>
            {countries.map((country) => (
              <div
                key={country.code}
                className='phone-dropdown-item'
                onClick={() => handleCountryChange(country.code)}
              >
                <ReactCountryFlag
                  className='emojiFlag'
                  countryCode={country.code}
                  svg
                />
                {country.code}
              </div>
            ))}
          </div>
        )}
        <div className='manager-phone-input-wrapper'>
          <InputMask
            mask={selectedCountry.mask}
            className='phone-input'
            onChange={onPhoneChange}
            value={phoneValue}
            placeholder={selectedCountry.mask}
          />
          {copyBtn && phoneValue && (
            <img
              src={CopyBtn}
              alt='Copy'
              className='copy-icon'
              onClick={handleCopy}
            />
          )}
        </div>
      </div>
      {copySuccess && <div className='copy-success'>Скопировано!</div>}
    </div>
  );
};

export default ManagerPhone;
