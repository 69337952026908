import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const getAllUsers = createAsyncThunk(
  'user/get-all-users',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get('/api/user/get-all-users', {});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const calculateUserBalances = createAsyncThunk(
  'user/calculate-user-balances',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get('/api/user/calculate-user-balances');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllUsersSlice = createSlice({
  name: 'allUsers',
  initialState: {
    allUsers: [] as any[],
    userBalances: {} as Record<string, number>,
    isLoading: false,
    error: null,
  },
  reducers: {
    userDataUpdate: (state, action: PayloadAction<any>) => {
      // Обновляем данные конкретного пользователя
      const updatedUser = action.payload;
      const userIndex = state.allUsers.findIndex(
        (user: any) => user.id === updatedUser.id
      );

      if (userIndex !== -1) {
        state.allUsers[userIndex] = {
          ...state.allUsers[userIndex],
          ...updatedUser,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.allUsers = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(calculateUserBalances.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(calculateUserBalances.fulfilled, (state, action: PayloadAction<Record<string, number>>) => {
        state.isLoading = false;
        state.userBalances = action.payload;
      })
      .addCase(calculateUserBalances.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { userDataUpdate } = getAllUsersSlice.actions;
export default getAllUsersSlice.reducer;
