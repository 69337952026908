import React from 'react';
import './modal-position-absolute.scss';
import ButtonComponent from '../button/button';
import { useNavigate } from 'react-router-dom';

const ModalPositionAbsolute = ({
  className,
  modalBtnClassName,
  modalBtnHeaderClassName,
  modalText,
  twoBtn,
  firstBtnText,
  firstBtnClick,
  secondBtnText,
  secondBtnClick,
}: any) => {
  // const navigate = useNavigate();

  // const handleNavigateOffice = () => {
  //   navigate('/advertising-systems-office');
  // };

  return (
    <div className='modal-position-absolute__modal-container' >
      <div className='header-absolute-background-color'></div>
      <div
        className={`modal-position-absolute__description-modal  ${className}`}
        onClick={(e) => e.stopPropagation()}
      >
        <div dangerouslySetInnerHTML={{ __html: modalText }} />
        {twoBtn ? (
          <>
            <div className='two-btn'>
              <ButtonComponent
                className={modalBtnClassName}
                btnText={firstBtnText}
                btnOnClick={firstBtnClick}
              />
              <ButtonComponent
                className={modalBtnClassName}
                btnText={secondBtnText}
                btnOnClick={secondBtnClick}
              />
            </div>
          </>
        ) : (
          <>
            <ButtonComponent
              className={modalBtnClassName}
              btnText={firstBtnText}
              btnOnClick={firstBtnClick}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ModalPositionAbsolute;
