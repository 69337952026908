import React, { useState } from 'react';
import './modal-2fa.scss';
import ButtonComponent from '../button/button';

interface Modal2FAProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (code: string) => void;
}

const Modal2FA: React.FC<Modal2FAProps> = ({ isOpen, onClose, onConfirm }) => {
  const [token, setToken] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToken(e.target.value);
  };

  const handleSubmit = () => {
    if (token.length === 6) {
      onConfirm(token);
    } else {
    }
  };

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className='modal-content-2fa'>
        <h2>Google 2FA</h2>
        <p>Введите код из приложения</p>
        <div className='input-container'>
          <label htmlFor='code'>Код</label>
          <input
            id='code'
            type='text'
            value={token}
            onChange={handleChange}
            className='input-field'
          />
        </div>
        <div className='two-btn'>
          <ButtonComponent
            className='cancel-btn'
            btnText={'Отменить'}
            btnOnClick={onClose}
          />
          <ButtonComponent
            twoBtn
            className='confirm-btn'
            btnText='Продолжить'
            btnOnClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className='modal'>
      <div className='modal-overlay' onClick={onClose} />
      <div className='modal-content'>
        <button className='modal-close' onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

export default Modal2FA;
