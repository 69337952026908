import React, { useEffect, useState, useRef } from 'react';
import MainLayout from '../../layouts/mainLayout';
import ButtonComponent from '../../ui/button/button';
import CustomTable, {
  FilterableColumn,
} from '../../ui/table/custom-table';
import InputLabel from '../../input/inputLabel/Input-label';
import './manager-home.scss';
import Percent from '../../../assets/images/percent.png';
import BalanceRedCircle from '../../../assets/images/balance-red-circle.png';
import BalanceCircle from '../../../assets/images/balance-circle.png';
import { useDispatch, useSelector } from 'react-redux';
import { getAllOffices } from '../../../features/offices/get-offices/get-offices-slice';
import { addNewOffice } from '../../../features/add-office/add-office-slice';
import CustomSelectComponent from '../../ui/select-custom/custom-select-component';
import ErrorComponent from '../../ui/error/error-component';
import { getAllUsers } from '../../../features/get-all-users/get-all-users-slice';
import { updateOffice } from '../../../features/update-office/update-office-slice';
import RoutesList from '../../ui/routes-list/routes-list';
import { useNavigate } from 'react-router-dom';
import { getOfficesByEmailAndNetworkType, getOfficesByManagerEmail } from '../../../features/offices/get-offices-by-email/get-offices-by-email-slice';
import CheckedRedCircle from '../../../assets/icons/checked-red-circle.svg';
import { useDevice } from '../../../hooks/use-device';
import { getUsersByManager } from '../../../features/get-users-by-manager/get-users-by-manager-slice';
import { AppDispatch } from '../../../app/store';
import SearchIcon from '../../../assets/icons/search-icon';
import UserSelect from '../../ui/user-select/user-select';

export interface User {
  id: string;
  name: string;
  role: string;
  user: string;
}

const ManagerHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useDevice();

  const allOfficesStore =
    useSelector((state: any) => state.allOffices.allOffices) || [];

  const user = useSelector((state: any) => state.login.user);
  const allUsers = useSelector((state: any) => state.allUsers.allUsers) || [];
  const usersByManager =
    useSelector((state: any) => state?.usersByManager?.usersByManager) || [];

  const networkType = 'Facebook Ads';

  const actualUser = user?.user ? user.user : user;
  const isAdmin = actualUser?.role === 'administrator';
  const isManager = actualUser?.role === 'manager';
  const userEmail = actualUser?.email;

  const [showCalendar, setShowCalendar] = useState(false);
  const [createNewOffice, setCreateNewOffice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountName, setAccountName] = useState<string>('');
  const [landingPage, setLandingPage] = useState<string>();
  const [holderEmail, setHolderEmail] = useState<string>(userEmail);
  const [percentValue, setPercentValue] = useState<string>();
  const [statusState, setStatusState] = useState('На рассмотрении');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchUserState, setSearchUserState] = useState(true);

  const [modalData, setModalData] = useState<any>(null);
  const [networkTypeState, setNetworkTypeState] = useState<string>('Facebook Ads');
  const [officeAccountNameError, setOfficeAccountNameErrore] = useState(false);
  const [offcieHolderError, setoffcieHolderError] = useState(false);
  const [noValidEmail, setNoValidEmail] = useState(false);
  const [allOffices, setAllOffices] = useState<any[]>([]);
  const [editInputChanged, setEditInputChanged] = useState(false);
  const [newOfficeInputChanged, setNewOfficeInputChanged] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userOffices, setUserOffices] = useState<any[]>([]);
  const [managerOffices, setManagerOffices] = useState<any[]>([]);

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        if (allOfficesStore.length < 1) {
          dispatch(getAllOffices()).then((response: any) => {
            setAllOffices(response.payload);
          });
        }
        dispatch(getAllOffices()).then((response: any) => {
          setAllOffices(response.payload);
        });

        if (allUsers.length < 1) {
          await dispatch(getAllUsers());
        }

        if (isManager) {
          const officesResponse = await dispatch(getOfficesByManagerEmail({ managerEmail: userEmail }));
          setManagerOffices(officesResponse.payload);
          await dispatch(getUsersByManager(userEmail));
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, isManager, userEmail]);

  useEffect(() => {
    if (isManager && usersByManager.length > 0) {
      const fetchOfficesForUsers = async () => {
        try {
          const officesData: any[] = [];

          for (const user of usersByManager) {
            const response = await dispatch(
              getOfficesByEmailAndNetworkType({
                holderEmail: user.email,
                networkType,
              })
            );
            officesData.push(...response.payload);
          }

          const responsebox = await dispatch(
            getOfficesByEmailAndNetworkType({
              holderEmail: holderEmail,
              networkType,
            })
          );
          if (responsebox?.payload) {
            const updatedOfficesData = [...officesData, ...responsebox.payload];
            setUserOffices(updatedOfficesData);
          } else {
            console.error('No valid data in responsebox payload.');
          }
        } catch (error) {
          console.error('Error fetching offices:', error);
        }
      };

      fetchOfficesForUsers();
    }
  }, [dispatch, isManager, usersByManager]);

  const cabinetsColumnsHeaders = {
    id: 'Название кабинета',
    name: 'Пользователь',
    landingPage: 'Landing page / Аpp id',
    network: 'Рекламная сеть',
    sum: 'Сумма пополнений',
    commission: 'Комиссия',
    status: 'Статус кабинета',
    date: 'Дата создания',
  };

  let tableOffices = isAdmin ? allOfficesStore : isManager ? managerOffices : userOffices;

  const filteredOffices = tableOffices?.map((office: any) => ({
    accountName: office.accountName,
    email: office.holderEmail,
    landingPage: office.landingPage,
    networkType: office.networkType || 'Facebook Ads',
    sum: office.totalTransactionsAmount || 'Нет данных',
    commission: office.commission || 'Нет данных',
    status: office.status,
    createdAt: new Date(office.createdAt).toLocaleDateString('ru-RU'),
  }));

  const idMapping = tableOffices?.reduce((acc: any, office: any) => {
    acc[office.accountName] = office._id;
    return acc;
  }, {});

  const filterableColumns: FilterableColumn[] = [
    { name: 'networkType', label: 'Рекламная сеть' },
    { name: 'status', label: 'Статус' },
    { name: 'createdAt', label: 'Дата создания' },
  ];

  const showModalWithTimeout = () => {
    setShowModal(true);
    setTimeout(() => {
      setShowModal(false);
    }, 1000);
  };

  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleEditModalClose = () => {
    setIsModalOpen(false);
    setAccountName('');
    setLandingPage('');
    setHolderEmail('');
    setPercentValue('');
    setStatusState('На рассмотрении');
    setSearchUserState(false);
  };
  const [editAccountName, seteditAccountName] = useState(
    modalData?.accountName
  );

  const handleTableCellClick = (data: any) => {
    const id = idMapping[data.accountName];
    setModalData({ ...data, id });
    setAccountName(data.accountName);
    setLandingPage(data.landingPage);
    setFilteredUsers(allUsers);
    setHolderEmail(data.email);
    setPercentValue(data.percent || '');
    setStatusState(data.status);
    setNetworkTypeState(data.networkType || 'Facebook Ads');
    setIsModalOpen(true);
    setEditInputChanged(false);
    seteditAccountName(data.accountName);
  };

  const handleCloseOfficeModal = () => {
    setCreateNewOffice(false);
  };

  const handleStatusReview = () => {
    setStatusState('На рассмотрении');
    setEditInputChanged(true);
    setNewOfficeInputChanged(true);
  };

  const handleStatusActive = () => {
    setStatusState('Активен');
    setEditInputChanged(true);
    setNewOfficeInputChanged(true);
  };

  const handleStatusHold = () => {
    setStatusState('Приостановлен');
    setEditInputChanged(true);
    setNewOfficeInputChanged(true);
  };

  const handleStatusBan = () => {
    setStatusState('Отключён');
    setEditInputChanged(true);
    setNewOfficeInputChanged(true);
  };

  const adsOptions = [
    { value: 'Facebook Ads', label: 'Facebook Ads' },
    { value: 'Google Ads', label: 'Google Ads' },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [showUserInput, setShowUserInput] = useState(false);
  const [showChangeUserInput, setshowChangeUserInput] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState<any>();

  const handleOpenModal = () => {
    setCreateNewOffice(true);
    setFilteredUsers(allUsers);
    setNewOfficeInputChanged(false);
  };

  const handleAdminHomeBack = () => {
    navigate('/administrator-home');
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const networkType = networkTypeState;
    const date = new Date().toISOString();
    const status = statusState;
    const theme = statusState;
    const comment = accountName;
    const message = statusState;
    const manager = statusState;
    const FBAPPID = '';
    const BMID = '1234';
    const FPProfileAccess = '';
    const firstTimeRecharge = '';
    const IDXP = '1234';
    const messenger = '';
    const accountTimeZone = '';
    const messengerType = 'skype';

    const userData = {
      accountName,
      landingPage,
      FBAPPID,
      BMID,
      FPProfileAccess,
      firstTimeRecharge,
      IDXP,
      messenger,
      accountTimeZone,
      holderEmail,
      messengerType,
      networkType,
      date,
      status,
      theme,
      comment,
      message,
      manager,
      percentValue,
    };

    try {
      let result;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (accountName?.length < 1) {
        setOfficeAccountNameErrore(true);
      } else {
        setOfficeAccountNameErrore(false);
      }

      if (!holderEmail) {
        setoffcieHolderError(true);
      } else {
        setoffcieHolderError(false);
      }

      if (holderEmail && !emailRegex.test(holderEmail)) {
        setNoValidEmail(true);
      } else {
        setNoValidEmail(false);
      }

      if (accountName && !offcieHolderError) {
        result = await dispatch(addNewOffice(userData)).unwrap();
      }

      if (result) {
        dispatch(getAllOffices());
        setCreateNewOffice(false);
        setAccountName('');
        setLandingPage('');
        setHolderEmail('');
        setPercentValue('');
        showModalWithTimeout();
        const fetchOfficesForUsers = async () => {
          try {
            const officesData: any[] = [];

            for (const user of usersByManager) {
              const response = await dispatch(
                getOfficesByEmailAndNetworkType({
                  holderEmail: user.email,
                  networkType,
                })
              );
              officesData.push(...response.payload);
            }

            const responsebox = await dispatch(
              getOfficesByEmailAndNetworkType({
                holderEmail: holderEmail,
                networkType,
              })
            );
            if (responsebox?.payload) {
              const updatedOfficesData = [...officesData, ...responsebox.payload];
              setUserOffices(updatedOfficesData);
            } else {
              console.error('No valid data in responsebox payload.');
            }
          } catch (error) {
            console.error('Error fetching offices:', error);
          }
        };

        fetchOfficesForUsers();
      }
    } catch (rejectedValueOrSerializedError) { }
  };

  const handleUpdateOffice = async (event: React.FormEvent) => {
    event.preventDefault();
    console.log('modalData', modalData);

    const accountName = editAccountName;

    const officeId = modalData?.id;
    const userData = {
      accountName,
      landingPage,
      messenger: '',
      holderEmail,
      networkType: networkTypeState,
      date: new Date().toISOString(),
      status: statusState,
      percentValue,
    };

    try {
      let result;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (editAccountName?.length < 1) {
        setOfficeAccountNameErrore(true);
      } else {
        setOfficeAccountNameErrore(false);
      }

      if (!holderEmail) {
        setoffcieHolderError(true);
      } else {
        setoffcieHolderError(false);
      }

      if (holderEmail && !emailRegex.test(holderEmail)) {
        setNoValidEmail(true);
      } else {
        setNoValidEmail(false);
      }

      if (editAccountName && !offcieHolderError) {
        result = await dispatch(
          updateOffice({ id: officeId, userData })
        ).unwrap();
      }

      if (result) {
        dispatch(getAllOffices());
        setIsModalOpen(false);
        setAccountName('');
        setLandingPage('');
        setHolderEmail('');
        setPercentValue('');
        setSearchUserState(false);
        showModalWithTimeout();
        const fetchOfficesForUsers = async () => {
          try {
            const officesData: any[] = [];

            for (const user of usersByManager) {
              const response = await dispatch(
                getOfficesByEmailAndNetworkType({
                  holderEmail: user.email,
                  networkType,
                })
              );
              officesData.push(...response.payload);
            }

            const responsebox = await dispatch(
              getOfficesByEmailAndNetworkType({
                holderEmail: holderEmail,
                networkType,
              })
            );
            if (responsebox?.payload) {
              const updatedOfficesData = [...officesData, ...responsebox.payload];
              setUserOffices(updatedOfficesData);
            } else {
              console.error('No valid data in responsebox payload.');
            }
          } catch (error) {
            console.error('Error fetching offices:', error);
          }
        };

        fetchOfficesForUsers();

      }
    } catch (rejectedValueOrSerializedError) {
      console.error('Error updating office:', rejectedValueOrSerializedError);
    }
  };

  const handleEmailSelect = (email: string) => {
    setShowUserInput(false);
    setshowChangeUserInput(false);
    setHolderEmail(email);
    setSearchTerm(email);
  };

  useEffect(() => {
    if (searchTerm) {
      setFilteredUsers(
        allUsers.filter((user: any) =>
          user.email.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredUsers(allUsers);
    }
  }, [searchTerm, allUsers]);

  const handleInputChange = () => {
    setNewOfficeInputChanged(true);
  };

  const handleEditInputChange = () => {
    setEditInputChanged(true);
  };

  return (
    <MainLayout>
      {isModalOpen && (
        <div className='manager-home__modal-container'>
          <form
            className='addOfficeModal create-new-office'
            onSubmit={handleUpdateOffice}
          >
            <p className='new-office-txt-modal'>Редактирование кабинета</p>
            <div>
              <p className='manager-label'>Рекламная сеть</p>
              <CustomSelectComponent
                className='register-select add-office-ads-select'
                selectOptions={adsOptions}
                setMessengerType={(value) => {
                  setNetworkTypeState(value);
                  handleEditInputChange();
                }}
                selectWrapper='select-wrapper-class'
                selectedRole={
                  networkTypeState === 'Facebook Ads'
                    ? 'Facebook Ads'
                    : networkTypeState === 'Google Ads'
                      ? 'Google Ads'
                      : ''
                }
                defaultEmptyValue='Выберите сеть'
              />
            </div>
            <div>
              <p className='manager-label'>Название</p>
              <InputLabel
                className='register-input registration-input soc-media__input'
                labelClass='register-label'
                type='text'
                requireLabel={false}
                setValue={(value: string) => {
                  seteditAccountName(value);
                  handleEditInputChange();
                }}
                value={editAccountName}
              />
            </div>

            <div>
              <p className='manager-label'>Landing page / App id</p>
              <InputLabel
                className='register-input registration-input soc-media__input'
                labelClass='register-label'
                type='text'
                requireLabel={false}
                setValue={(value: string) => {
                  setLandingPage(value);
                  handleEditInputChange();
                }}
                value={landingPage}
              />
            </div>

            <UserSelect
              users={allUsers} 
              selectedEmail={holderEmail} 
              onSelect={(email: string) => {
                setHolderEmail(email);
                handleEditInputChange();
              }}
              disabled
            />

            <p className='individual-comission'>Индивидуальная комиссия</p>
            <div>
              <p className='percent-manager manager-label'>Процент</p>
              <div className='percent-box'>
                <InputLabel
                  className='registration-input soc-media__input percent-modal-input'
                  labelClass='register-label'
                  type='text'
                  requireLabel={false}
                  setValue={(value: any) => {
                    setPercentValue(value);
                    handleEditInputChange();
                  }}
                  value={percentValue}
                />
                <img src={Percent} alt='Percent' className='percent-img' />
              </div>
            </div>

            <p className='individual-comission'>Статус кабинета</p>
            <div className='offices-box'>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'На рассмотрении'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={() => {
                    handleStatusReview();
                    handleEditInputChange();
                  }}
                  alt='статус'
                />
                <p className='add-office-status-labels'>на рассмотрении</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Активен' ? BalanceRedCircle : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={() => {
                    handleStatusActive();
                    handleEditInputChange();
                  }}
                />
                <p className='add-office-status-labels'>активен</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Приостановлен'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={() => {
                    handleStatusHold();
                    handleEditInputChange();
                  }}
                  alt='статус'
                />
                <p className='add-office-status-labels'>приостановлен</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Отключён'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={() => {
                    handleStatusBan();
                    handleEditInputChange();
                  }}
                  alt='статус'
                />
                <p className='add-office-status-labels'>отключён</p>
              </div>
            </div>

            <div className='add-advetising-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='add-advetising-btn-cancel add-office-modal-cancel'
                btnOnClick={handleEditModalClose}
              />

              <ButtonComponent
                btnText='Сохранить'
                className={`add-advetising-btn-create add-office-modal-save ${editInputChanged ? 'editInputChanged' : ''
                  }`}
                disabled={editInputChanged == false}
              />
            </div>
          </form>
        </div>
      )}
      {createNewOffice && (
        <div className='manager-home__modal-container'>
          <form
            className='addOfficeModal create-new-office'
            onSubmit={handleSubmit}
          >
            <p className='new-office-txt-modal'>Новый кабинет</p>
            <div>
              <p className='manager-label'>Рекламная сеть</p>
              <CustomSelectComponent
                className='register-select add-office-ads-select'
                selectOptions={adsOptions}
                setMessengerType={(value) => {
                  setNetworkTypeState(value);
                  handleInputChange();
                }}
                selectWrapper='select-wrapper-class'
                selectedRole={
                  networkTypeState === 'Facebook Ads'
                    ? 'Facebook Ads'
                    : networkTypeState === 'Google'
                      ? 'Google Ads'
                      : ''
                }
                defaultEmptyValue='Выберите сеть'
              />
            </div>
            <div>
              <p className='manager-label'>Название</p>
              <InputLabel
                className='register-input registration-input soc-media__input'
                labelClass='register-label'
                type='text'
                requireLabel={false}
                setValue={(value: string) => {
                  setAccountName(value);
                  handleInputChange();
                }}
                value={accountName}
              />
            </div>
            {officeAccountNameError && (
              <ErrorComponent
                error='Название аккаунта обязательно для заполнения'
                errorClassName=''
              />
            )}
            <div>
              <p className='manager-label'>Landing page / App id</p>
              <InputLabel
                className='register-input registration-input soc-media__input'
                labelClass='register-label'
                type='text'
                requireLabel={false}
                setValue={(value: string) => {
                  setLandingPage(value);
                  handleInputChange();
                }}
                value={landingPage}
              />
            </div>
            <div>
              <p className='manager-label'>Пользователь</p>
              <div
                className='manager-search-label-box'
                onClick={() => setShowUserInput(true)}
              >
                <InputLabel
                  className='register-input registration-input soc-media__input'
                  labelClass='register-label'
                  type='email'
                  requireLabel={false}
                  setValue={(value: string) => {
                    setSearchTerm(value);
                    setHolderEmail(value);
                    handleInputChange();
                    setShowUserInput(true);
                  }}
                  value={holderEmail}
                  rightIcon={<SearchIcon className='input-icon' />}
                />
              </div>
              {showUserInput && (
                <div className='table-container manager-home-users-table'>
                  <table>
                    <tbody>
                      {filteredUsers?.map((user: any) => (
                        <tr
                          key={user.id}
                          className='user-item'
                          onClick={() => {
                            handleEmailSelect(user.email);
                            handleInputChange();
                          }}
                        >
                          <td className='user-info'>
                            <span className='user-email'>{user.email}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {offcieHolderError && (
              <ErrorComponent
                error='Пользователь обязательно для заполнения'
                errorClassName=''
              />
            )}

            {noValidEmail && (
              <ErrorComponent
                error='неправильный формат email'
                errorClassName=''
              />
            )}
            <p className='individual-comission'>Индивидуальная комиссия</p>
            <div>
              <p className='percent-manager manager-label'>Процент</p>
              <div className='percent-box'>
                <InputLabel
                  className='registration-input soc-media__input percent-modal-input'
                  labelClass='register-label'
                  type='text'
                  requireLabel={false}
                  setValue={(value: string) => {
                    setPercentValue(value);
                    handleInputChange();
                  }}
                  value={percentValue}
                />
                <img src={Percent} alt='Percent' className='percent-img' />
              </div>
            </div>

            <p className='individual-comission'>Статус кабинета</p>
            <div className='offices-box'>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'На рассмотрении'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusReview}
                />
                <p className='add-office-status-labels'>на рассмотрении</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Активен' ? BalanceRedCircle : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusActive}
                  alt='статус'
                />
                <p className='add-office-status-labels'>активен</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Приостановлен'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusHold}
                  alt='статус'
                />
                <p className='add-office-status-labels'>приостановлен</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Отключён'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusBan}
                  alt='статус'
                />
                <p className='add-office-status-labels'>отключён</p>
              </div>
            </div>
            <div className='add-advetising-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='add-advetising-btn-cancel add-office-modal-cancel'
                btnOnClick={handleCloseOfficeModal}
              />
              <ButtonComponent
                btnText='Сохранить'
                className={`add-advetising-btn-create add-office-modal-save ${newOfficeInputChanged ? 'newOfficeInputChanged' : ''
                  }`}
                disabled={newOfficeInputChanged === false}
              />
            </div>
          </form>
        </div>
      )}
      <div className='networks-box manager-home__wrapper'>
        <RoutesList />
        {isAdmin ? (
          <ButtonComponent
            btnText='К рекламным системам'
            className='manager-home__button-back manager-home__button-back--desktop-hidden'
            btnOnClick={handleAdminHomeBack}
          />
        ) : null}
        <div className='add-advetising-btns manager-home__manager-ads-btns'>
          <p className='manager-home__cabinetes-label'>
            Кабинеты Рекламных систем
          </p>

          <div className='manager-home__buttons-wrapper'>
            {isAdmin ? (
              <ButtonComponent
                btnText='К рекламным системам'
                className='manager-home__button-back manager-home__button-back--mobile-hidden'
                btnOnClick={handleAdminHomeBack}
              />
            ) : null}
            <ButtonComponent
              btnText={isMobile ? 'Создать' : '+ Создать кабинет'}
              className='manager-home__btn-create'
              btnOnClick={handleOpenModal}
            />
          </div>
        </div>
        <CustomTable
          columnHeaders={cabinetsColumnsHeaders}
          data={filteredOffices}
          className={'ads-offices-table'}
          searchBy='email'
          loading={loading}
          handleUserClick={handleTableCellClick}
          filterableColumns={filterableColumns}
          tableCalendarClass='finance-history-calendar bg-finance-history-calendar'
        />
      </div>
      {showModal && (
        <div className='modal'>
          <div className='modal-content'>
            <img
              src={CheckedRedCircle}
              alt='User avatar'
              className='user-info__checked-circle'
            />
            <div>Мы успешно сохранили ваши изменения</div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default ManagerHome;
