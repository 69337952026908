import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import './managers.scss';
import ButtonComponent from '../../ui/button/button';
import { getManagers } from '../../../features/get-managers/get-managers-slice';
import InputLabel from '../../input/inputLabel/Input-label';
import EyeIcon from '../../../assets/icons/eye-icon';
import SelectComponent from '../../ui/select/select-component';
import { registerUser } from '../../../features/register/authSlice';
import ErrorComponent from '../../ui/error/error-component';
import { useDispatch, useSelector } from 'react-redux';
import { useDevice } from '../../../hooks/use-device';
import Spinner from '../../ui/spinner/spinner';
import { useNavigate } from 'react-router-dom';
import CustomSelectComponent from '../../ui/select-custom/custom-select-component';
import ManagerPhone from '../../manager-phone/manager-phone';
import RoutesList from '../../ui/routes-list/routes-list';
import { AppDispatch } from '../../../app/store';
// import CustomTable from '../../ui/custom-table-v2/custom-table';
import CustomTable from '../../ui/table/custom-table';
import { getUsersByManager } from '../../../features/get-users-by-manager/get-users-by-manager-slice';

const Managers = () => {
  const [addManagerModal, setAddManagerModal] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [messenger, setMessenger] = useState('');
  const [messengerType, setMessengerType] = useState('skype');
  const [emailError, setEmailError] = useState(false);
  const [repeatPasswordError, setRepeatPasswordError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [messengerError, setMessengerError] = useState(false);
  const [registerError, setRegisterError] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedCountryState, setSelectedCountryState] = useState<any>({
    code: 'RU',
    mask: '+7 (999) 999-99-99',
    name: 'Russia',
  });
  const isMobile = useDevice();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const managers = useSelector((state: any) => state.managers.managers) || [];
  const myAdminMail = useSelector((state: any) => state.login.user.email || state.login?.user?.user.email);
  // const user = useSelector(
  //   (state: any) => state.login?.user?.user || state.login?.user
  // );
  // console.log("🚀 ~ Managers ~ myAdminMail:", myAdminMail)

  const resetForm = () => {
    setEmail('');
    setPassword('');
    setRepeatPassword('');
    setPhone('');
    setMessenger('');
    setMessengerType('skype');
    setEmailError(false);
    setRepeatPasswordError(false);
    setPhoneError(false);
    setMessengerError(false);
    setRegisterError('');
    setRole('');
  };

  useEffect(() => {
    const fetchData = async () => {
      if (managers.length === 0) {
        setLoading(true);
        const response = await dispatch(getManagers({}));
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, managers]);

  const roleTranslations: { [key: string]: string } = {
    administrator: 'Администратор',
    manager: 'Менеджер',
    user: 'Пользователь',
  };

  const rolesForSelect: { [key: string]: string } = {
    administrator: 'Администратор',
    manager: 'Менеджер',
  };

  const statusTranslations: { [key: string]: string } = {
    active: 'Активен',
    inactive: 'Неактивен',
  };

  const translatedManagers = managers
    ?.filter(
      (manager: any) => manager.role !== 'user' && manager.email !== myAdminMail
    )
    .map((manager: any) => ({
      key: manager._id,
      email: manager.email,
      users: manager.usersNumber || 0,
      role: roleTranslations[manager.role] || manager.role,
      status: statusTranslations[manager.status] || manager.status,
      phone: manager.phone,
      messenger: `${manager.messengerType} ${manager.messenger}`,
    }));

  const cabinetsInitialData: any = translatedManagers ? translatedManagers : [];

  const roleOptions = Object.entries(rolesForSelect).map(([key, value]) => ({
    value: key,
    label: value,
  }));

  const handleUserClick = async (user: any) => {
    // console.log('🚀 ~ handleUserClick ~ user:', user);
    const { email, phone, messenger, status, key: managerId } = user;
    // const queryParams = new URLSearchParams({
    //   email: email || '',
    //   phone: phone || '',
    //   messenger: messenger || '',
    //   status: status || '',
    // }).toString();
    await dispatch(getUsersByManager(user.email));

    navigate(`/manager-page/${managerId}`);
  };

  const cabinetsColumnsHeaders = {
    email: 'Менеджер',
    users: 'Пользователи',
    role: 'Уровень доступа',
    status: 'Статус',
    phone: 'Телефон',
    messenger: 'Мессенджер',
  };

  const filterableColumns: { name: any; label: string }[] = [
    { name: 'role', label: 'Уровень доступа' },
    { name: 'status', label: 'Статус' },
  ];

  const handleManagerModal = () => {
    setAddManagerModal(!addManagerModal);
    resetForm();
  };

  const formatPhoneNumber = (phone: string) => {
    return phone.replace(/\D/g, '');
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);

    const formattedPhone = formatPhoneNumber(phone);
    const name = 'manager';

    const userData = {
      email,
      password,
      name,
      phone: formattedPhone,
      messenger,
      status: 'active',
      messengerType,
      role,
    };

    try {
      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        setEmailError(true);
        setLoading(false);
        return;
      } else {
        setEmailError(false);
      }

      let result: any;

      if (
        email &&
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) &&
        password === repeatPassword
      ) {
        // @ts-ignore
        result = await dispatch(registerUser(userData));
        await dispatch(getManagers({}));
      }

      let resultPayload: any;

      if (typeof result?.payload == 'string') {
        resultPayload = result?.payload;
      }

      const phoneErrorExists = result?.payload?.errors?.some(
        (err: any) => err.msg === 'Неправильный номер мобильного телефона'
      );

      const messengerErrorExists = result?.payload?.errors?.some(
        (err: any) =>
          err.msg === 'Мессенджер должен быть строкой минимум из 2 символов'
      );

      const regex = /<pre>(.*?)<\/pre>/;
      const matches = resultPayload?.match(regex);

      if (matches && matches[1]) {
        const errorMessage = matches[1].split('<br>')[0];
        setRegisterError(errorMessage);
      }

      if (password !== repeatPassword) {
        setRepeatPasswordError(true);
        setLoading(false);
        return;
      } else {
        setRepeatPasswordError(false);
      }

      if (phoneErrorExists) {
        setPhoneError(true);
        setLoading(false);
        return;
      } else {
        setPhoneError(false);
      }

      if (messengerErrorExists) {
        setMessengerError(true);
        setLoading(false);
        return;
      } else {
        setMessengerError(false);
      }

      if (!result?.error && result !== undefined) {
        setAddManagerModal(false);
        resetForm();
      }
    } catch (rejectedValueOrSerializedError) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      {addManagerModal && (
        <div className='manager-modal-container'>
          <div className='addOfficeModal add-manager__modal-container'>
            {loading ? (
              <Spinner />
            ) : (
              <form className='managers__table'>
                <p className='modal-container__add-manager'>
                  Добавление менеджера
                </p>
                <InputLabel
                  className='register-input'
                  labelClass='register-label'
                  label='Email'
                  type='email'
                  setValue={setEmail}
                  value={email}
                  required
                />

                {emailError && (
                  <ErrorComponent
                    error='email должен быть действительным'
                    errorClassName=''
                  />
                )}

                <InputLabel
                  className='register-input'
                  labelClass='register-label'
                  label='Пароль'
                  type='password'
                  icon={<EyeIcon />}
                  setValue={setPassword}
                  value={password}
                  iconClass='eye-class'
                  required
                />

                <InputLabel
                  className='register-input'
                  labelClass='register-label'
                  label='Подтвердить пароль'
                  type='password'
                  icon={<EyeIcon />}
                  iconClass='eye-class'
                  setValue={setRepeatPassword}
                  value={repeatPassword}
                  required
                />

                {repeatPasswordError && (
                  <ErrorComponent
                    error='пароль не совпадает'
                    errorClassName=''
                  />
                )}

                <div>
                  <div className='label-container'>
                    <label className='register-label'>Уровень доступа</label>
                    {true && <p className='label-required'>*</p>}
                  </div>
                  <div className='select-box'>
                    <CustomSelectComponent
                      className='manager-select-wrapper'
                      selectOptions={roleOptions}
                      setMessengerType={setRole}
                      selectedRole={role}
                      selectWrapper='manager-select-wrapper'
                      defaultEmptyValue='Выбрать'
                    />
                  </div>
                </div>

                <div>
                  <div className='label-container'>
                    <label className='register-label'>Телефон</label>
                    {true && <p className='label-required'>*</p>}
                  </div>
                  <div className='phone-box'>
                    <ManagerPhone
                      value={phone}
                      setValue={setPhone}
                      setSelectedCountryState={setSelectedCountryState}
                    />
                  </div>
                  {phoneError && (
                    <ErrorComponent
                      error='Неправильный формат номера телефона'
                      errorClassName=''
                    />
                  )}
                </div>

                <div>
                  <div className='label-container'>
                    <label className='register-label'>Мессенджер</label>
                    {true && <p className='label-required'>*</p>}
                  </div>
                  <div className='select-box'>
                    <SelectComponent
                      className='register-select'
                      setMessengerType={setMessengerType}
                    />
                    <InputLabel
                      className='register-input registration-input'
                      labelClass='register-label'
                      type='text'
                      requireLabel={false}
                      setValue={setMessenger}
                      value={messenger}
                      required
                    />
                  </div>
                  {messengerError && (
                    <ErrorComponent
                      error='Мессенджер должен быть строкой минимум из 2 символов'
                      errorClassName=''
                    />
                  )}
                  {registerError && (
                    <ErrorComponent error={registerError} errorClassName='' />
                  )}
                </div>

                <div className='manager-modal__btn-box'>
                  <ButtonComponent
                    btnText='Отменить'
                    className='add-advetising-btn-cancel manager-modal__btn-cancel'
                    btnOnClick={handleManagerModal}
                  />

                  <ButtonComponent
                    btnText='Добавить'
                    className='add-advetising-btn-create manager-modal__btn-cancel'
                    btnOnClick={handleSubmit}
                  />
                </div>
              </form>
            )}
          </div>
        </div>
      )}
      <RoutesList />
      <div className='add-manager__box'>
        <p className='add-manager__managers'>Менеджеры</p>
        <ButtonComponent
          btnText={!isMobile ? 'Добавить менеджера' : 'Добавить'}
          className='add-advetising-btn-create add-manager__btn'
          btnOnClick={handleManagerModal}
        />
      </div>

      <CustomTable
        columnHeaders={cabinetsColumnsHeaders}
        data={cabinetsInitialData}
        className={'min-height-full'}
        filterableColumns={filterableColumns}
        searchBy='email'
        loading={loading}
        handleUserClick={handleUserClick}
      />
    </MainLayout>
  );
};

export default Managers;
