import React from 'react';
import DoneIcon from '../../../../assets/images/ic_round-done.svg';

interface SaveChangesProps {
  changesSaved: boolean | undefined;
  handleSaveForm: () => void;
  statusValueChanged: boolean;
  userTypeSection: boolean;
  balancePhoneChanged: boolean;
  balanceMessengerChanged: boolean;
  messengerTypeValueChanged: boolean | undefined;
}

const SaveChanges: React.FC<SaveChangesProps> = ({
  changesSaved,
  handleSaveForm,
  statusValueChanged,
  userTypeSection,
  balancePhoneChanged,
  balanceMessengerChanged,
  messengerTypeValueChanged,
}) => {
  return changesSaved ? (
    <div className='done-section'>
      <p>Изменения сохранены</p>
      <img src={DoneIcon} alt='Done' />
    </div>
  ) : (
    <button
      className='save-button'
      onClick={handleSaveForm}
      disabled={
        statusValueChanged === false &&
        userTypeSection === false &&
        balancePhoneChanged === false &&
        balanceMessengerChanged === false &&
        messengerTypeValueChanged === false
      }
    >
      Сохранить изменения
    </button>
  );
};

export default SaveChanges;
