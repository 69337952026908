import React from 'react';
import './modal.scss';
import ButtonComponent from '../../ui/button/button';

const ModalComponent = ({
  className,
  modalBtnClassName,
  modalText,
  twoBtn,
  firstBtnText,
  firstBtnClick,
  secondBtnText,
  secondBtnClick,
  rightButtonStyle,
  onClose // добавляем пропс для закрытия модалки по клику снаружи
}: any) => {

  const handleOutsideClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.target === e.currentTarget && onClose) {
      onClose(); // Закрываем модалку при клике вне её области
    }
  };

  return (
    <div className='modal-container' onClick={handleOutsideClick}>
      <div className={className} onClick={(e) => e.stopPropagation()}> {/* Предотвращаем закрытие при клике внутри */}
        <div className='modal-text' dangerouslySetInnerHTML={{ __html: modalText }} />
        {twoBtn ? (
          <div className='two-btn'>
            <ButtonComponent
              className={modalBtnClassName}
              btnText={firstBtnText}
              btnOnClick={firstBtnClick}
            />
            <ButtonComponent
              className={rightButtonStyle ? rightButtonStyle : modalBtnClassName}
              btnText={secondBtnText}
              btnOnClick={secondBtnClick}
            />
          </div>
        ) : (
          <ButtonComponent
            className={modalBtnClassName}
            btnText={firstBtnText}
            btnOnClick={firstBtnClick}
          />
        )}
      </div>
    </div>
  );
};

export default ModalComponent;