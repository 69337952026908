import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

// Async thunk for adding a new office
export const addNewOffice = createAsyncThunk(
  'office/add-office',
  async (userData: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/office/add-office', userData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for fetching offices by email
export const fetchOfficesByEmail = createAsyncThunk(
  'office/fetch-offices-by-email',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/office/get-transactions-by-email', { holderEmail: email });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Async thunk for adding a new office balance
export const addOfficeBalance = createAsyncThunk(
  'office/add-office-balance',
  async (balanceData: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/office/create-transaction', balanceData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface OfficeBalancePayload {
  officeId: string;
  description: string;
  image?: string;
  networkName: string;
  percent: number;
}

interface Office {
  id: string;
  name: string;
  status: string;
  // Other properties...
}

// Define the return type of the thunk
interface AddOfficeBalanceResponse {
  officeId: string;
  description: string;
  image?: string;
  networkName: string;
  percent: number;
}


export const officeSlice = createSlice({
  name: 'office',
  initialState: {
    office: null,
    officeBalances: [],
    offices: [] as Office[],
    officesByUser: [] as Office[],
    isLoading: false,
    error: null,
  },
  reducers: {
    updateOfficeStatus: (state, action: PayloadAction<{ id: string; status: string }>) => {
      const { id, status } = action.payload;
      const office = state.officesByUser.find((office: any) => {
        return office._id === id
      })
      // console.log("🚀 ~ office:", office)
      if (office) {
        office.status = status;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle addNewOffice
      .addCase(addNewOffice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewOffice.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.office = action.payload;
      })
      .addCase(addNewOffice.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Handle fetchOfficesByEmail
      .addCase(fetchOfficesByEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchOfficesByEmail.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.officesByUser = action.payload;
      })
      .addCase(fetchOfficesByEmail.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Handle addOfficeBalance
      .addCase(addOfficeBalance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOfficeBalance.fulfilled, (state, action: any) => {
        state.isLoading = false;
        // @ts-ignore
        state.officeBalances?.push(action.payload);
      })
      .addCase(addOfficeBalance.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateOfficeStatus } = officeSlice.actions;

export default officeSlice.reducer;
