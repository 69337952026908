import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

// Асинхронный thunk для получения пользователя по ID
export const getUserById = createAsyncThunk(
  'user/get-user-by-id',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/api/user/get-user-by-id/${id}`);
      return response.data;
    } catch (error: any) {
      // Возвращаем ошибку с использованием rejectWithValue
      return rejectWithValue(error.response.data);
    }
  }
);

// Интерфейс для описания пользователя
interface User {
  id: string;
  email: string;
  phone?: string;
  messengerType?: string;
  phoneCountry?: string;
  // Добавьте остальные поля, которые есть у вашего пользователя
}

// Интерфейс для описания состояния
interface UserByIdState {
  userById: User | null;
  isLoading: boolean;
  error: string | null;
}

// Начальное состояние
const initialState: UserByIdState = {
  userById: null,
  isLoading: false,
  error: null,
};

// Slice для получения пользователя по ID
export const getUserByIdSlice = createSlice({
  name: 'userById',
  initialState,
  reducers: {
    // Редуктор для обновления пользователя по ID
    updateUserById: (state, action: PayloadAction<Partial<User>>) => {
      if (state.userById) {
        state.userById = { ...state.userById, ...action.payload };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserById.fulfilled, (state, action: PayloadAction<User>) => {
        state.isLoading = false;
        state.userById = action.payload;
      })
      .addCase(getUserById.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

// Экспортируем редуктор и действия
export const { updateUserById } = getUserByIdSlice.actions;
export default getUserByIdSlice.reducer;
