import React, { useState, useEffect } from 'react';
import { EditorState, convertToRaw, AtomicBlockUtils } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import MainLayout from '../../layouts/mainLayout';
import RoutesList from '../../ui/routes-list/routes-list';
import { useNavigate } from 'react-router-dom';
import InputLabel from '../../input/inputLabel/Input-label';
import './faq-add-question.scss';
import ButtonComponent from '../../ui/button/button';
import { addNewQuestion, uploadImage } from '../../../features/questions/question-slice';
import { useDispatch } from 'react-redux';
import QuestionDelete from '../../../assets/images/question-delete.svg';
import draftToHtml from 'draftjs-to-html';
import ModalComponent from '../../ui/modal/modal';
import { getBaseURL } from '../../../core/config';

const FaqAddQuestion = () => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [description, setDescription] = useState('');
  const [questionTitle, setQuestionTitle] = useState('');
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [contentError, setContentError] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigateBack = () => {
    navigate('/faq');
  };

  const handleCreateDocument = () => {
    const content = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const plainText = content.replace(/<\/?[^>]+(>|$)/g, "");

    let isValid = true;

    if (!questionTitle.trim()) {
      setTitleError('Заголовок обязателен');
      isValid = false;
    } else {
      setTitleError('');
    }

    if (!description.trim()) {
      setDescriptionError('Описание обязательно');
      isValid = false;
    } else {
      setDescriptionError('');
    }

    if (!plainText.trim()) {
      setContentError('Контент обязателен');
      isValid = false;
    } else {
      setContentError('');
    }

    if (isValid) {
      //@ts-ignore
      dispatch(addNewQuestion({
        title: questionTitle,
        description: description,
        content: content,
        imageUrls: imageUrls
      }))
        .unwrap()
        .then(() => {
          navigate('/faq');
        })
        .catch((error: any) => {
          console.error('Error creating question:', error);
        });
    }
  };

  const onEditorStateChange = (newState: any) => {
    setEditorState(newState);
  };

  // useEffect для обновления editorState при изменении imageUrls
  useEffect(() => {
    if (imageUrls.length > 0) {
      let newEditorState = editorState;
      imageUrls.forEach((url) => {
        const contentState = newEditorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: url });
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        newEditorState = AtomicBlockUtils.insertAtomicBlock(
          EditorState.set(newEditorState, { currentContent: contentStateWithEntity }),
          entityKey,
          ' '
        );
      });
      setEditorState(newEditorState);
    }
  }, [imageUrls]);


  const imageUploadCallback = (file: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        //@ts-ignore
        const response = await dispatch(uploadImage(file)).unwrap();
        const fullImageUrl = `${getBaseURL()}${response.imageUrl}`;
        setImageUrls((prevUrls: any) => [...prevUrls, fullImageUrl]);
        resolve({ data: { link: fullImageUrl } });
      } catch (error) {
        console.error('Image upload failed:', error);
        reject(error);
      }
    });
  };

  const handleDeleteQuestion = () => {
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    setIsModalOpen(false);
    handleNavigateBack();
  };

  const handleCancelDelete = () => {
    setIsModalOpen(false);
  };

  return (
    <MainLayout>
      <RoutesList routeClassName='finance-history-route' />
      <p onClick={handleNavigateBack} className='faq-question-back'>назад</p>
      <div className='faq-add-qeustion-box'>
        <InputLabel
          className='register-input add-balance-input'
          labelClass='register-label'
          type='text'
          currencyClassName='ads-balance-currency'
          setValue={setQuestionTitle}
          value={questionTitle}
          requireLabel={false}
          placeholder='Заголовок'
        />
        {titleError && <p className="error-message">{titleError}</p>}

        <InputLabel
          className='register-input add-balance-input question-description'
          labelClass='register-label'
          type='text'
          currencyClassName='ads-balance-currency'
          setValue={setDescription}
          value={description}
          requireLabel={false}
          placeholder='Описание'
        />
        {descriptionError && <p className="error-message">{descriptionError}</p>}

        <Editor
          editorState={editorState}
          wrapperClassName='demo-wrapper'
          editorClassName='demo-editor'
          onEditorStateChange={onEditorStateChange}
          placeholder={"Введите Ваш текст..."}
          toolbar={{
            options: ['inline', 'list', 'textAlign', 'image'],
            //options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'history', 'image'],
            inline: { options: ['bold', 'italic', 'underline'] },
            // blockType: {
            //   options: ['Blockquote'],
            // },
            image: {
              uploadCallback: imageUploadCallback,
              alt: { present: true, mandatory: false }
            }
          }}
        />
        {contentError && <p className="error-message">{contentError}</p>}

        <div className='faq-question-add-box'>
          <img className='delete-faq-question' src={QuestionDelete} alt='QuestionDelete' onClick={handleDeleteQuestion} />
          <ButtonComponent
            btnText='Сохранить'
            className='add-advetising-btn-create save-added-question'
            btnOnClick={handleCreateDocument}
          />
        </div>
      </div>

      {isModalOpen && (
        <ModalComponent
          className='add-question-modal modal-delete-confirmation'
          modalBtnClassName='add-advetising-modal-btn modal-delete-btn'
          modalText='Вы уверены, что хотите удалить статью?'
          twoBtn={true}
          firstBtnText='Нет'
          firstBtnClick={handleCancelDelete}
          secondBtnText='Да'
          secondBtnClick={handleConfirmDelete}
          rightButtonStyle='red-button-white-text'
        />
      )}
    </MainLayout>
  );
};

export default FaqAddQuestion;
