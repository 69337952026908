import React, { ReactNode, useEffect, useState } from 'react';
import './layout.scss';
import HeaderComponent from '../../ui/header/header-component';
import FooterComponent from '../../ui/footer/footer-component';
import { useNavigate } from 'react-router-dom';
import Container from '../../container/container';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const WrappedHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location?.pathname;
  const user = useSelector((state: any) => state.login.user);

  const [showContent, setShowContent] = useState(false);

  const handleClick = () => {
    setShowContent(false);
    setTimeout(() => {
      navigate(navigateСondition ? '/register' : '/login');
    }, 600);
  };

  const navigateСondition =
    pathname === '/login' ||
    pathname === '/recovery-password' ||
    pathname === '/recovery-password-check' ||
    pathname === '/recovery-password-done' ||
    pathname === '/recovery-password-form';

  const headerOnForModal =
    pathname === '/add-advertising-systems-office' ? 'layout-main-fixed' : '';

  const isLading = pathname === '/landing';

  return (
    <HeaderComponent
      className={`layout-main__header ${headerOnForModal} ${user ? 'advertising-systems-header layout-main--white-header' : ''
        }`}
      btnIconClassName={`register-header-btn-icon ${user ? 'gap-none' : ''}`}
      rightSideClassname={
        !isLading
          ? 'header-right-side-container'
          : 'header-right-side-container--display-none'
      }
      leftsideText='layout-main__header__left-text'
      headerBtnClick={handleClick}
      headerBtnText={navigateСondition ? 'Зарегистрироваться' : 'Войти'}
      rightSideText={navigateСondition ? 'Нет аккаунта?' : 'Уже есть аккаунт?'}
      leftButton={!!user}
      rightSideMenu={!!user}
      isLogin={!!user}
      btnClassName={`${user ? 'advertising-systems-header-btn' : ''}`}
    />
  );
};

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const location = useLocation();
  const pathname = location?.pathname;

  const user = useSelector((state: any) => state.login.user);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setShowContent(true);
  }, []);

  const isLandingPage = pathname === '/landing';

  return (
    <Container className="layout-main-container">
      {user && <div className='header-absolute-background-color'></div>}
      <div
        className={`layout-main ${isLandingPage ? 'layout-main--white-background' : ''
          }`}
      >
        <WrappedHeader />
        <div
          className={`content-wrapper ${showContent ? 'content-wrapper--visible' : ''
            }`}
          style={{ flexGrow: 1 }}
        >
          {children}
        </div>
        <div className='recovery-footer recovery-footer--static'>
          <FooterComponent
            className='layout-main__footer main-footer-class main-footer-class--column-left user-agreement-section__footer'
            rightSideClassname='footer-right-side-container header-right-side-container--column'
            leftsideText='user-agreement-section__copy-rights'
          />
        </div>
      </div>
    </Container>
  );
};

export default MainLayout;
