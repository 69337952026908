import React, { useState } from 'react';
import InputLabel from '../../input/inputLabel/Input-label';
import ManagerPhone from '../../manager-phone/manager-phone';
import SelectMessenger from '../select-messenger/select-messenger';
import './contact-details.scss';
import ErrorComponent from '../error/error-component';
import { useDevice } from '../../../hooks/use-device';

interface ContactDetailsProps {
  name: string;
  email: string;
  phone: string | undefined;
  messenger: string | undefined;
  setPhone: (phone: string) => void;
  setMessenger: (messenger: string) => void;
  messengerType: string | undefined | null;
  setMessengerType: (type: string) => void;
  setSelectedCountryState: (state: any) => void;
  setPhoneValueChanged: (value: boolean) => void;
  setMessengerValueChanged: (value: boolean) => void;
  setPhonelengthErrorState?: any;
  phonelengthErrorState?: any;
  setBalancePhoneChanged?: any;
  setBalanceMessengerChanged?: any;
  mssengerlengthErrorState?: any;
  phoneCountryState?: any;
  setPhoneCountry?: any;
  setMessengerTypeValueChanged?: any;
  setChangesSaved?: any;
}

const ContactDetails: React.FC<ContactDetailsProps> = ({
  name,
  email,
  phone,
  messenger,
  setPhone,
  setMessenger,
  messengerType,
  setMessengerType,
  setSelectedCountryState,
  setPhoneValueChanged,
  setMessengerValueChanged,
  setPhonelengthErrorState,
  phonelengthErrorState,
  setBalancePhoneChanged,
  setBalanceMessengerChanged,
  mssengerlengthErrorState,
  phoneCountryState,
  setPhoneCountry,
  setMessengerTypeValueChanged,
  setChangesSaved,
}) => {

  const isMobile = useDevice();

  const handlePhoneChange = (value: string) => {
    setPhone(value);
    setPhoneValueChanged(true);
    setBalancePhoneChanged(true);
    setChangesSaved(false);
  };

  const handleMessengerChange = (value: string) => {
    setMessenger(value);
    setMessengerValueChanged(true);
    setBalanceMessengerChanged(true);
    setChangesSaved(false);
  };

  return (
    <div className='contact-details'>
      <div className='contact-info__title'>Контактные данные</div>
      {!isMobile && <InputLabel
        className='register-input manager-page__input balance-section-contact'
        labelClass='register-label'
        label='Имя'
        type='text'
        setValue={() => { }}
        value={name || ''}
        requireLabel={false}
        iconClass='manager-page__copy-btn'
      />}
      <InputLabel
        className='register-input manager-page__input'
        labelClass='register-label'
        label='Email'
        type='email'
        setValue={() => { }}
        value={email || ''}
        requireLabel={false}
        iconClass='manager-page__copy-btn'
        copy
      />
      <div>
        <div className='label-container'>
          <label className='register-label'>Телефон</label>
        </div>
        <ManagerPhone
          value={phone || ''}
          setValue={handlePhoneChange}
          copyBtn={true}
          setSelectedCountryState={setSelectedCountryState}
          phoneCountryState={phoneCountryState}
          setPhoneCountry={setPhoneCountry}
          className='contact-detail-phone'
        />
        {phonelengthErrorState === true && (
          <ErrorComponent error='Введите номер телефона полностью' />
        )}
      </div>
      <div className='z-index-for-messenger'>
        <div className='label-container'>
          <label className='register-label'>Мессенджер</label>
        </div>
        <div className='select-box-contact-details'>
          <SelectMessenger
            className='register-select'
            setMessengerType={setMessengerType}
            value={messengerType || ''}
            setMessengerTypeValueChanged={setMessengerTypeValueChanged}
          />
          <InputLabel
            className='register-input manager-page__input'
            labelClass='register-label'
            type='text'
            setValue={handleMessengerChange}
            value={messenger || ''}
            iconClass='manager-page__copy-btn'
            maxLength={50}
            copy
          />
        </div>
        {messenger && messenger.length === 50 && <ErrorComponent
                  error='Вы достигли макс. количество символов (50)'
                  errorClassName='max-length-error-users'
                />}
        {mssengerlengthErrorState === true && (
          <ErrorComponent error='Длина должен быть минимум 2 символа' />
        )}
      </div>
    </div>
  );
};

export default ContactDetails;
