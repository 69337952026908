import React from 'react';

const ButtonWithIcon = ({
  className,
  btnText,
  withIcon = false,
  icon,
  btnIconClassName,
  btnOnClick,
}: any) => {
  return (
    <>
      <button className={className} onClick={btnOnClick}>
        {withIcon ? (
          <div className={btnIconClassName}>
            {icon}
            {btnText}
          </div>
        ) : (
          btnText
        )}
      </button>
    </>
  );
};

export default ButtonWithIcon;
