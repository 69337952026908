import {
  createSlice,
  createAsyncThunk,
  createAction,
  PayloadAction,
} from '@reduxjs/toolkit';
import $api from '../../core/api';

interface User {
  id: string;
  email: string;
  phone?: string;
  messengerType?: string;
  user?: string;
  twoFactor?: boolean;
  // Добавьте остальные поля, которые есть у вашего пользователя
}

interface LoginState {
  user: User | null;
  isLoading: boolean;
  error: string | null;
  isAuthChecked: boolean;
}

const initialState: LoginState = {
  user: null,
  isLoading: false,
  error: null,
  isAuthChecked: false,
};

export const resetState = createAction('RESET_STATE');

export const loginUser = createAsyncThunk(
  'auth/login',
  async (userData: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/user/login', userData);
      if (response) {
        localStorage.setItem('token', response.data.accessToken);
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkAuth = createAsyncThunk(
  'auth/checkAuth',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get('/api/user/refresh');
      localStorage.setItem('token', response.data.accessToken);
      return response.data.user;
    } catch (error: any) {
      localStorage.removeItem('token');
      return rejectWithValue(error.response.data);
    }
  }
);

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    logoutUser: (state) => {
      localStorage.removeItem('token');
      localStorage.removeItem('reduxState');
      state.user = null;
      state.isAuthChecked = false;
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      if (state.user) {
        if (typeof state.user.user === 'object' && state.user.user !== null) {
          //@ts-ignore
          state.user.user = { ...state.user.user, ...action.payload };
        } else {
          state.user = { ...state.user, ...action.payload };
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(loginUser.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
        state.isAuthChecked = true;
      })
      .addCase(loginUser.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Ошибка авторизации';
        state.isAuthChecked = true;
      })
      .addCase(checkAuth.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(checkAuth.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.user = action.payload;
        state.error = null;
        state.isAuthChecked = true;
      })
      .addCase(checkAuth.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Authorization error';
        state.isAuthChecked = true; // проверка завершена, даже если неудачная
      });
  },
});

export const { logoutUser, updateUser } = loginSlice.actions;

export default loginSlice.reducer;
