import {
  configureStore,
  ThunkAction,
  Action,
  AnyAction,
  combineReducers,
} from '@reduxjs/toolkit';
import authReducer from '../features/register/authSlice';
import loginReducer from '../features/login/loginSlice';
import addOfficeSlice from '../features/add-office/add-office-slice';
import getOfficesByEmailReducer from '../features/offices/get-offices-by-email/get-offices-by-email-slice';
import getAllOfficesReducer from '../features/offices/get-offices/get-offices-slice';
import twoFactorAuthSlice from '../features/two-factor-auth/two-factor-auth-slice';
import ticketsSlice from '../features/tickets/ticketsSlice';
import recoveryPasswordSlice from '../features/recovery-password/recoveryPasswordSlice';
import getManagersSlice from '../features/get-managers/get-managers-slice';
import ApprovRegistrationCodeSlice from '../features/approve-registration-code/approve-registration-code-slice';
import getManagerUsersSlice from '../features/get-manager-users/get-manager-users-slice';
import getUsersByManagerSlice from '../features/get-users-by-manager/get-users-by-manager-slice';
import getAllUsersSlice from '../features/get-all-users/get-all-users-slice';
import syncMiddleware from './sync-middleware';
import getUserByEmailSlice from '../features/get-user-by-email/get-user-by-email-slice';
import getUserByIdSlice from '../features/get-user-by-id/get-user-by-id-slice';
import financeSlice from '../features/finance/finance-slice';
import documentSlice from '../features/document/document-slice';
import questionSlice from '../features/questions/question-slice';


const preloadedState = JSON.parse(localStorage.getItem('reduxState') || '{}');

const appReducers = {
  auth: authReducer,
  login: loginReducer,
  addOffice: addOfficeSlice,
  offices: getOfficesByEmailReducer,
  allOffices: getAllOfficesReducer,
  qrCode: twoFactorAuthSlice,
  tickets: ticketsSlice,
  approveRegister: ApprovRegistrationCodeSlice,
  recoveryPassword: recoveryPasswordSlice,
  managers: getManagersSlice,
  managerUsers: getManagerUsersSlice,
  usersByManager: getUsersByManagerSlice,
  allUsers: getAllUsersSlice,
  userByEmail: getUserByEmailSlice,
  userById: getUserByIdSlice,
  finances: financeSlice,
  documents: documentSlice,
  questions: questionSlice,
};

const rootReducer = (
  state: ReturnType<typeof combinedReducers> | undefined,
  action: AnyAction
): ReturnType<typeof combinedReducers> => {
  if (action.type === 'RESET_STATE') {
    localStorage.removeItem('token');
    return combinedReducers(undefined, action);
  }

  if (action.type === 'SYNC_STATE') {
    const syncedState = action.payload as ReturnType<typeof combinedReducers>;
    syncedState.login = {
      ...syncedState.login,
      isAuthChecked: syncedState.login?.isAuthChecked ?? false,
    };
    return syncedState;
  }
  return combinedReducers(state, action);
};

const combinedReducers = combineReducers(appReducers);

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(syncMiddleware),
  preloadedState,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
