import React, { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  EntityInstance,
  BlockMap,
  AtomicBlockUtils
} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../app/store';
import MainLayout from '../../layouts/mainLayout';
import RoutesList from '../../ui/routes-list/routes-list';
import InputLabel from '../../input/inputLabel/Input-label';
import ButtonComponent from '../../ui/button/button';
import ModalComponent from '../../ui/modal/modal';
import Spinner from '../../ui/spinner/spinner';
import {
  addNewQuestion,
  deleteQuestion,
  updateQuestion,
  uploadImage
} from '../../../features/questions/question-slice';
import QuestionDelete from '../../../assets/images/question-delete.svg';
import './faq-add-question.scss';
import { useQuery } from '../../../hooks/use-query/useQuery';
import { getBaseURL } from '../../../core/config';

const FaqEditQuestion = () => {
  const location = useLocation();
  // const id = location.state?._id
  const query = useQuery();
  const id = query.get('id');

  // console.log("🚀 ~ FaqEditQuestion ~ id:", id)

  const {
    title,
    content: initialContent,
    description: actualDescription,
    imageUrls: initialImageUrls = []
  } = location?.state || {};
  // console.log("🚀 ~ FaqEditQuestion ~ initialContent:", initialContent)

  // console.log("🚀 ~ FaqEditQuestion ~ initialImageUrls:", initialImageUrls)

  // States
  const [questionTitle, setQuestionTitle] = useState(title || '');
  const [description, setDescription] = useState(actualDescription || '');
  const [imageUrls, setImageUrls] = useState<string[]>(initialImageUrls);
  // console.log("🚀 ~ FaqEditQuestion ~ imageUrls:", imageUrls)
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  // console.log("🚀 ~ FaqEditQuestion ~ editorState:", editorState)

  // Error states
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [contentError, setContentError] = useState('');
  const [modalError, setModalError] = useState('');

  // UI states
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    const initializeEditor = () => {
      const contentBlock = htmlToDraft(initialContent);
      let contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks,
        contentBlock.entityMap
      );
      let newEditorState = EditorState.createWithContent(contentState);

      setEditorState(newEditorState);
    };

    initializeEditor();
  }, [imageUrls]);

  // Image upload handler
  const imageUploadCallback = async (file: File) => {
    try {
      const response = await dispatch(uploadImage(file)).unwrap();
      const fullImageUrl = `${getBaseURL()}${response.imageUrl}`;

      setImageUrls(prevUrls => [...prevUrls, fullImageUrl]);

      return { data: { link: fullImageUrl } };
    } catch (error) {
      console.error('Image upload failed:', error);
      throw error;
    }
  };

  // Form validation
  const validateForm = () => {
    let isValid = true;

    if (!questionTitle.trim()) {
      setTitleError('Заголовок обязателен');
      isValid = false;
    } else {
      setTitleError('');
    }

    if (!description.trim()) {
      setDescriptionError('Описание обязательно');
      isValid = false;
    } else {
      setDescriptionError('');
    }

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const hasContent = rawContentState.blocks.some(block => block.text.trim().length > 0);

    if (!hasContent) {
      setContentError('Контент обязателен');
      isValid = false;
    } else {
      setContentError('');
    }

    return isValid;
  };

  // Save handler
  const handleCreateOrUpdateDocument = async () => {
    if (!validateForm()) return;

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);

    const questionData = {
      title: questionTitle,
      description,
      content: htmlContent,
      imageUrls
    };

    try {
      if (id) {
        await dispatch(updateQuestion({ id, questionData })).unwrap();
      } else {
        await dispatch(addNewQuestion(questionData)).unwrap();
      }
      navigate('/faq');
    } catch (error) {
      console.error('Error saving question:', error);
    }
  };

  // Delete handler
  const handleConfirmDelete = async () => {
    if (!id) return;

    setIsLoading(true);
    try {
      await dispatch(deleteQuestion(id)).unwrap();
      setIsModalOpen(false);
      navigate('/faq');
    } catch (error) {
      console.error('Error deleting question:', error);
      setModalError('Ошибка при удалении статьи');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditorStateChange = (newEditorState: EditorState) => {
    try {
      setEditorState(newEditorState);
    } catch (error) {
      console.error('Error updating editor state:', error);
    }
  };

  return (
    <MainLayout>
      {isLoading && (
        <div className='faq-absolute-layer'>
          <Spinner />
        </div>
      )}
      <RoutesList routeClassName='finance-history-route' />
      <p onClick={() => navigate('/faq')} className='faq-question-back'>
        назад
      </p>

      <div className='faq-add-question-box'>
        <InputLabel
          className='register-input add-balance-input'
          labelClass='register-label'
          type='text'
          currencyClassName='ads-balance-currency'
          setValue={setQuestionTitle}
          value={questionTitle}
          requireLabel={false}
          placeholder='Заголовок'
        />
        {titleError && <p className="error-message">{titleError}</p>}

        <InputLabel
          className='register-input add-balance-input question-description'
          labelClass='register-label'
          type='text'
          currencyClassName='ads-balance-currency'
          setValue={setDescription}
          value={description}
          requireLabel={false}
          placeholder='Описание'
        />
        {descriptionError && <p className="error-message">{descriptionError}</p>}

        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorStateChange}
          wrapperClassName='demo-wrapper'
          editorClassName='demo-editor'
          placeholder="Введите Ваш текст..."
          toolbar={{
            options: ['inline', 'list', 'textAlign', 'image'],
            inline: { options: ['bold', 'italic', 'underline'] },
            image: {
              uploadCallback: imageUploadCallback,
              alt: { present: true, mandatory: false },
              previewImage: true,
              inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg'
            }
          }}
        />
        {contentError && <p className="error-message">{contentError}</p>}

        <div className='faq-question-add-box'>
          <img
            src={QuestionDelete}
            alt='QuestionDelete'
            className="delete-faq-question"
            onClick={() => setIsModalOpen(true)}
          />
          <ButtonComponent
            btnText='Сохранить'
            className='add-advetising-btn-create save-added-question'
            btnOnClick={handleCreateOrUpdateDocument}
          />
        </div>
      </div>

      {isModalOpen && (
        <ModalComponent
          className='add-question-modal modal-delete-confirmation'
          modalBtnClassName='add-advetising-modal-btn modal-delete-btn'
          modalText={modalError || 'Вы уверены, что хотите удалить статью?'}
          twoBtn={true}
          firstBtnText='Нет'
          firstBtnClick={() => setIsModalOpen(false)}
          secondBtnText='Да'
          secondBtnClick={handleConfirmDelete}
          rightButtonStyle='red-button-white-text'
        />
      )}
    </MainLayout>
  );
};

export default FaqEditQuestion;