import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import './manager-user.scss';
import { useNavigate, useParams } from 'react-router-dom';
import UserIcon from '../../../assets/images/user-icon.png';
import UserContactInfo from '../../ui/user-contact-info/user-contact-info';
import { useDispatch, useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
import { updateUserStatus } from '../../../features/update-status/update-status-slice';
import { updateContactInfo } from '../../../features/update-manager-info/update-manager-info-slice';
import { phoneLengths } from '../../../utils/phone-lengths';
import { AppDispatch } from '../../../app/store';
import { userDataUpdate } from '../../../features/get-all-users/get-all-users-slice';
import TicketsIcon from '../../../assets/icons/TicketsIcon';
import FinanceHistory from '../finance-history/finance-history';
import { getUsersByManager } from '../../../features/get-users-by-manager/get-users-by-manager-slice';

const ManagerUser = () => {
  const { userId } = useParams();
  const allUsers = useSelector((state: any) => state.allUsers.allUsers) || [];
  const finances = useSelector((state: any) => state.finances.finances);
  const currentUserFromAllUsers = allUsers.find(
    (user: any) => user._id === userId
  );

  const [currentUser, setCurrentUser] = useState<any>(
    currentUserFromAllUsers || null
  );

  const customUser = { email: currentUser?.email, role: currentUser?.role };

  const [currentMessenger, setCurrentMessenger] = useState<string>('');
  const [currentPhone, setCurrentPhone] = useState<string>(
    currentUser?.phone || ''
  );
  const [currentStatus, setCurrentStatus] = useState<any>(
    currentUser?.status || ''
  );
  const [messengerType, setMessengerType] = useState<string>('');
  const [selectedCountryState, setSelectedCountryState] = useState<any>({
    code: 'RU',
    mask: '+7 (999) 999-99-99',
    name: 'Russia',
  });
  const [phoneValueChanged, setPhoneValueChanged] = useState<boolean>(false);
  const [messengerValueChanged, setMessengerValueChanged] =
    useState<boolean>(false);
  const [statusValueChanged, setStatusValueChanged] = useState<boolean>(false);
  const [phonelengthError, setPhonelengthError] = useState<boolean>(false);

  // const getUserByEmail = (email: string) => {
  //   return allUsers?.find((user: any) => user.email === email);
  // };

  const [messengerlengthError, setMessengerlengthError] =
    useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      const user = allUsers.find((user: any) => user.id === userId);
      setCurrentUser(user);
    }
  }, [userId, allUsers]);

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );

  useEffect(() => {
    if (currentUser) {
      setCurrentMessenger(currentUser.messenger || '');
      setMessengerType(currentUser.messengerType || '');
      setCurrentPhone(currentUser.phone || '');
      setCurrentStatus(currentUser.status || '');
    }
  }, [currentUser]);

  const handleBackClick = () => {
    navigate('/tickets');
  };

  const handleListClick = () => {
    dispatch(getUsersByManager(user?.email))
    navigate('/manager-users');
  };

  return (
    <MainLayout>
      <div className='manager-user__buttons'>
        <button className='manager-user__back-btn' onClick={handleListClick}>
          Вернуться к списку
        </button>
      </div>
      <div className='manager-user__user-info'>
        <div className='manager-user__user-row'>
          <img src={UserIcon} alt='User avatar' className='user-info__avatar' />
          <div className='user-info__section'>
            <div className='user-info__email managers-user-info'>
              {currentUser?.email}
            </div>
            <Icon
              className='manager-user__close-page-icon'
              icon='carbon:close-filled'
              width='24px'
              color='#8F97A9'
              onClick={() => navigate('/manager-users')}
            />
          </div>
          <div
            className={`user-info__status ${currentUser?.status === 'спам' ? 'status-spam' : currentUser?.status === 'клиент' ? 'status-client'
              : currentUser?.status === 'горячий' ? 'status-hot' : currentUser?.status === 'active' || currentUser?.status === 'новый' ? 'status-new' : 'user-info__status--inactive'
              }`}
          >
            <div
              className={`status__text ${currentUser?.status === 'спам' ? 'status-spam' : currentUser?.status === 'active' ? 'status-inactive' : ''
                }`}
            >
              {currentUser?.status === 'active' || currentUser?.status === 'новый' ? 'новый' : currentUser?.status === 'горячий' ? 'горячий' : currentUser?.status === 'клиент' ? 'клиент' : currentUser?.status === 'спам' ? 'спам' : 'неактивный'}
            </div>
          </div>
        </div>
        <button className='manager-user__ticket-btn' onClick={handleBackClick}>
          <TicketsIcon />
          <div>Тикеты</div>
        </button>
      </div>
      <div className='manager-user__container'>
        <UserContactInfo
          name={currentUser?.name}
          email={currentUser?.email}
          phone={currentPhone}
          messenger={currentMessenger}
          status={currentUser?.status}
          messengerType={messengerType}
          setMessengerType={setMessengerType}
          setCurrentPhone={setCurrentPhone}
          currentMessenger={currentMessenger}
          setCurrentMessenger={setCurrentMessenger}
          setPhoneValueChanged={setPhoneValueChanged}
          setMessengerValueChanged={setMessengerValueChanged}
          setStatusValueChanged={setStatusValueChanged}
          currentStatus={currentStatus}
          setCurrentStatus={setCurrentStatus}
          phonelengthError={phonelengthError}
          messengerlengthError={messengerlengthError}
          statusValueChanged={statusValueChanged}
          currentUser={currentUser}
          setSelectedCountryState={setSelectedCountryState}
          selectedCountryState={selectedCountryState}
        />
      </div>
      <FinanceHistory user={customUser} />
    </MainLayout>
  );
};

export default ManagerUser;
