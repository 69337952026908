import React, { useState, useEffect } from 'react';
import InputLabel from '../../input/inputLabel/Input-label';
import BalanceRedCircle from '../../../assets/images/balance-red-circle.png';
import BalanceCircle from '../../../assets/images/balance-circle.png';
import './contact-info.scss';
import { useDispatch } from 'react-redux';
import SelectMessenger from '../select-messenger/select-messenger';
import ErrorComponent from '../../ui/error/error-component';
import ManagerPhone from '../../manager-phone/manager-phone';

interface ContactInfoProps {
  email: string | null;
  phone?: string | null;
  messenger?: string | null;
  status?: string | null;
  contactClassName?: string | null;
  setCurrentPhone?: any;
  currentPhone?: any;
  setCurrentMessenger?: any;
  currentMessenger?: any;
  setMessengerType?: any;
  messengerType?: any;
  setphoneValueChanged?: any;
  phonelengthError?: any;
  messengerlengthError?: boolean;
  setMesengerValueChanged?: any;
  setStatusValueChanged?: any;
  setStatusState?: any;
  setSelectedCountryState?: any;
  setCountryChanged?: any;
  setMessengerTypeValueChanged?: any;
  phoneCountryState?: any;
}

const ContactInfo: React.FC<ContactInfoProps> = ({
  email,
  phone,
  messenger,
  status,
  contactClassName,
  setCurrentPhone,
  setCurrentMessenger,
  currentMessenger,
  setMessengerType,
  messengerType,
  setphoneValueChanged,
  phonelengthError,
  messengerlengthError,
  setMesengerValueChanged,
  setStatusValueChanged,
  setStatusState,
  setSelectedCountryState,
  setCountryChanged,
  setMessengerTypeValueChanged,
  phoneCountryState,
}) => {
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [currentStatus, setCurrentStatus] = useState<string>('');

  // Определяем начальный статус на основе prop status
  const initialStatus = status === 'active' ? true : false;
  const [active, setActive] = useState<boolean>(initialStatus);

  const dispatch = useDispatch();

  const handleActiveStatus = () => {
    setActive(true);
    setStatusValueChanged(true);
    setStatusState('active');
  };

  const handleNotActiveStatus = () => {
    setActive(false);
    setStatusValueChanged(true);
    setStatusState('inactive');
  };

  // const handleCopy = (text: string) => {
  //   navigator.clipboard
  //     .writeText(text)
  //     .then(() => {})
  //     .catch((err) => {
  //       console.error('Failed to copy text: ', err);
  //     });
  // };

  useEffect(() => {
    if (email) {
      setCurrentEmail(email);
    }
    if (phone) {
      setCurrentPhone(phone);
    }
    if (messenger) {
      setCurrentMessenger(messenger);
    }
    if (status) {
      setCurrentStatus(status);
    }
  }, [email, phone, messenger, status]);

  return (
    <div
      className={`manager-contact-info ${
        contactClassName ? contactClassName : ''
      }`}
    >
      <p className='manager-contact-info__title'>Контактные данные</p>
      <div className='email-copy__box'>
        <InputLabel
          className='register-input manager-page__input'
          labelClass='register-label'
          label='Email'
          type='email'
          setValue={() => {}}
          value={currentEmail}
          requireLabel={false}
          iconClass='manager-page__copy-btn'
          copy
        />
      </div>

      <div className='input-box-container'>
        <div className='label-container'>
          <label className='register-label'>Телефон</label>
        </div>
        <div className='phone-box manager-contact-info-phone'>
          <ManagerPhone
            value={phone}
            setValue={setCurrentPhone}
            setphoneValueChanged={setphoneValueChanged}
            copyBtn={true}
            setSelectedCountryState={setSelectedCountryState}
            phoneCountryState={phoneCountryState}
            setCountryChanged={setCountryChanged}
          />
        </div>
        {phonelengthError === true && (
          <ErrorComponent error='Введите номер телефона полностью' />
        )}
      </div>
      <div className='input-box-container'>
        <div className='label-container'>
          <label className='register-label'>Мессенджер</label>
        </div>
        <div className='select-box'>
          <SelectMessenger
            className='contact-info__select'
            setMessengerType={setMessengerType}
            setMessengerTypeValueChanged={setMessengerTypeValueChanged}
            value={messengerType}
          />
          <InputLabel
            className='register-input registration-input'
            labelClass='register-label'
            type='text'
            requireLabel={false}
            setValue={setCurrentMessenger}
            setMesengerValueChanged={setMesengerValueChanged}
            value={currentMessenger}
            iconClass='manager-page__copy-btn'
            copy
          />
        </div>
        {messengerlengthError === true && (
          <ErrorComponent error='Длина мессенджера должен быть минимум 2 символа' />
        )}
      </div>

      <p className='manager-contact-info__status-title'>Статус</p>
      <div className='status-box'>
        <div className='manager-page__status' onClick={handleActiveStatus}>
          <img
            src={active ? BalanceRedCircle : BalanceCircle}
            width={20}
            height={20}
            onClick={handleActiveStatus} // исправлено
          />
          <p>Активный</p>
        </div>
        <div className='manager-page__status' onClick={handleNotActiveStatus}>
          <img
            src={active === false ? BalanceRedCircle : BalanceCircle}
            width={20}
            height={20}
            onClick={handleNotActiveStatus} // исправлено
          />
          <p>Неактивный</p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
