export const phoneLengths: any = {
  RU: 11,
  UA: 12,
  IN: 12,
  KZ: 11,
  UZ: 12,
  US: 11,
  KG: 12,
  BY: 12,
  PL: 12,
  CN: 13,
  GE: 12,
  HK: 11,
  AM: 11,
  MD: 11,
  DE: 12,
  TR: 12,
  NL: 11,
  GB: 12,
  SG: 10,
  TH: 11,
  AZ: 12,
};
