import React from 'react';

const CloseIconRed = ({ className }: any) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12.0013 13.4141L17.6583 19.0711C17.8469 19.2533 18.0995 19.3541 18.3617 19.3518C18.6239 19.3495 18.8747 19.2444 19.0601 19.059C19.2455 18.8736 19.3507 18.6227 19.353 18.3606C19.3552 18.0984 19.2544 17.8458 19.0723 17.6571L13.4153 12.0001L19.0723 6.34315C19.2544 6.15455 19.3552 5.90194 19.353 5.63975C19.3507 5.37755 19.2455 5.12674 19.0601 4.94133C18.8747 4.75592 18.6239 4.65075 18.3617 4.64848C18.0995 4.6462 17.8469 4.74699 17.6583 4.92915L12.0013 10.5861L6.34429 4.92915C6.15484 4.75149 5.9037 4.65451 5.64402 4.65873C5.38434 4.66295 5.13648 4.76803 4.9529 4.95174C4.76931 5.13546 4.66441 5.38339 4.66037 5.64307C4.65634 5.90276 4.7535 6.15382 4.93129 6.34315L10.5873 12.0001L4.93029 17.6571C4.83478 17.7494 4.7586 17.8597 4.70619 17.9817C4.65378 18.1037 4.62619 18.235 4.62504 18.3677C4.62388 18.5005 4.64919 18.6322 4.69947 18.7551C4.74975 18.878 4.824 18.9897 4.91789 19.0835C5.01179 19.1774 5.12344 19.2517 5.24633 19.302C5.36923 19.3523 5.50091 19.3776 5.63369 19.3764C5.76647 19.3752 5.89769 19.3477 6.01969 19.2953C6.1417 19.2428 6.25204 19.1667 6.34429 19.0711L12.0013 13.4141Z" fill="#FF1053" />
    </svg>
  );
};

export default CloseIconRed;
