import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Skype from '../../../assets/icons/skype-icon';
import Telegram from '../../../assets/icons/telegram-icon';
import './select-messenger.scss';
import useOnClickOutside from '../../../hooks/use-onclick-outside';

const SelectMessenger = ({
  className,
  selectOptions = [],
  setMessengerType,
  setMessengerTypeValueChanged,
  selectWrapper,
  selectedRole,
  value,
}: any) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const options = [
    { value: 'Skype', label: <Skype /> },
    { value: 'Telegram', label: <Telegram /> },
  ];

  const getDefaultValue = () => {
    if (value) {
      return (
        selectOptions.find((option: any) => option.value === value) ||
        options.find((option) => option.value === value)
      );
    }
    return selectOptions.length > 0 ? selectOptions[0] : options[0];
  };

  const [selectedOption, setSelectedOption] = useState(getDefaultValue());

  useEffect(() => {
    const newDefaultValue = getDefaultValue();
    if (
      newDefaultValue &&
      (!selectedOption || selectedOption.value !== newDefaultValue.value)
    ) {
      setSelectedOption(newDefaultValue);
      setMessengerType(newDefaultValue.value);
    }
  }, [value, selectOptions, selectedOption, setMessengerType]);

  useEffect(() => {
    if (selectedRole) {
      const newDefaultValue =
        selectOptions.find((option: any) => option.value === selectedRole) ||
        options[0];
      if (
        newDefaultValue &&
        (!selectedOption || selectedOption.value !== newDefaultValue.value)
      ) {
        setSelectedOption(newDefaultValue);
      }
    }
  }, [selectedRole, selectOptions, selectedOption]);

  const handleChange = (option: any) => {
    setSelectedOption(option);
    setMessengerType(option?.value);
    setMessengerTypeValueChanged(true);
  };

  useOnClickOutside(ref, () => setMenuIsOpen(false));

  return (
    <div className={selectWrapper} ref={ref}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        options={selectOptions.length > 0 ? selectOptions : options}
        className={'messenger-select'}
        classNamePrefix='custom-react-select'
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
        defaultValue={options[0]}
      />
    </div>
  );
};

export default SelectMessenger;
