import React, { useState, useEffect, useRef } from 'react';
import InputLabel from '../../input/inputLabel/Input-label';
import SearchIcon from '../../../assets/icons/search-icon';
import './user-select.scss';

interface User {
    id: string;
    email: string;
}

interface UserSelectProps {
    users: User[];
    selectedEmail: string;
    onSelect: (email: string) => void;
    disabled?: boolean;  // Новый необязательный проп
}

const UserSelect: React.FC<UserSelectProps> = ({
    users,
    selectedEmail,
    onSelect,
    disabled = false,  // По умолчанию disabled = false
}) => {
    const [searchTerm, setSearchTerm] = useState<string>(selectedEmail); // По умолчанию значение равное выбранному email
    const [filteredUsers, setFilteredUsers] = useState<User[]>(users);
    const [showUserInput, setShowUserInput] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null); // Реф для выпадающего списка
    const inputRef = useRef<HTMLInputElement>(null); // Реф для инпута

    useEffect(() => {
        if (searchTerm) {
            const filtered = users.filter((user) =>
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filtered);
        } else {
            setFilteredUsers(users); // Показывать всех пользователей при пустом поиске
        }
    }, [searchTerm, users]);

    // Обработчик кликов вне компонента
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowUserInput(false); // Закрыть список при клике вне компонента
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    const handleEmailSelect = (email: string) => {
        setSearchTerm(email);  // Отображаем выбранный email в инпуте
        onSelect(email);       // Вызываем callback для передачи выбранного email
        setShowUserInput(false);  // Закрываем выпадающий список после выбора
    };

    const handleEditClick = () => {
        setSearchTerm(selectedEmail);  // Устанавливаем в поле выбранное значение
        setFilteredUsers(users);  // Показываем всех пользователей
        if (inputRef.current) {
            inputRef.current.focus();  // Устанавливаем фокус на инпут при клике на "Изменить"
        }
        setShowUserInput(true);  // Открываем выпадающий список
    };

    return (
        <div className='user-select' ref={dropdownRef}>
            <div className='manager-change-label-box'>
                <p className='manager-label'>Пользователь</p>
                {!disabled && selectedEmail && (  // Скрываем "Изменить" если disabled = true
                    <p className='manager-change-label' onClick={handleEditClick}>
                        Изменить
                    </p>
                )}
            </div>
            <div
                className='user-select__input-wrapper'
                onClick={() => {
                    if (!disabled) {  // Открываем выпадающий список только если не заблокировано
                        setFilteredUsers(users);
                        setShowUserInput(true);  // Открываем выпадающий список
                    }
                }}
            >
                <InputLabel
                    ref={inputRef}  // Привязываем реф к инпуту
                    className='register-input registration-input soc-media__input'
                    labelClass='register-label'
                    type='email'
                    requireLabel={false}
                    setValue={(value: string) => setSearchTerm(value)}
                    value={searchTerm} // Поле поиска теперь зависит от searchTerm
                    rightIcon={<SearchIcon className='input-icon' />}
                    disabled={disabled}  // Блокируем инпут, если disabled = true
                />
            </div>
            {showUserInput && (
                <div className='user-select__dropdown'>
                    <table className='user-select__table'>
                        <tbody>
                            {filteredUsers.map((user) => (
                                <tr
                                    key={user.id}
                                    className='user-select__item'
                                    onClick={() => handleEmailSelect(user.email)}
                                >
                                    <td className='user-select__email'>{user.email}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

export default UserSelect;
