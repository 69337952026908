import React, { useEffect, useState } from 'react';
import { WrappedHeader } from '../../layouts/mainLayout';
import './landing.scss';
import LandingIcon from '../../../assets/images/landing-page.png';
import ButtonComponent from '../../ui/button/button';
import { useLocation, useNavigate } from 'react-router-dom';
import InputLabel from '../../input/inputLabel/Input-label';
import LandingSelect from '../../../assets/images/landing-select.png';
import LandingArrow from '../../../assets/images/landing-arrow.png';
import LandingFinance from '../../../assets/images/landing-finance.png';
import Capitalist from '../../../assets/images/capitalist.png';
import Crypto from '../../../assets/images/crypto.png';
import Partners from '../../../assets/images/partners.png';
import PlusIcon from '../../../assets/images/plus-icon.png';
import MinusIcon from '../../../assets/images/minus-icon.png';
import FooterComponent from '../../ui/footer/footer-component';
import StartWorkingOne from '../../../assets/images/start-worknig-one';
import StartWorkingFour from '../../../assets/images/start-worknig-four';
import StartWorkingTwo from '../../../assets/images/start-worknig-two';
import { useSelector } from 'react-redux';

const LandingPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );

  useEffect(() => {
    if (user) {
      if (user.role === 'administrator') {
        navigate('/administrator-home');
      } else if (user.role === 'manager') {
        navigate('/manager-home');
      } else {
        navigate('/advertising-systems-office-home');
      }
    }
  }, [user, navigate]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');
    const utmContent = params.get('utm_content');
    const utmTerm = params.get('utm_term');
  }, [location]);

  const handleRegister = () => {
    navigate('/register');
  };

  const handleLogin = () => {
    navigate('/login');
  };

  const [faq, setFaq] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);

  const handleFAQ = () => {
    setFaq(!faq);
  };

  const handleFAQ2 = () => {
    setFaq2(!faq2);
  };

  const handleFAQ3 = () => {
    setFaq3(!faq3);
  };

  const stepsData = [
    {
      type: 'registration',
      description: 'Регистрируетесь за 5 минут',
    },
    {
      type: 'office',
      description: 'Создаёте кабинет в нужной площадке',
    },
    {
      type: 'finance',
      description: 'Пополняете счёт удобным вам способом',
    },
    {
      type: 'ads',
      description: 'Запускаете рекламу в трастовом кабинете',
    },
  ];

  const blocksData = [
    {
      title: 'Для арбитражных <br /> команд',
      items: [
        'гибкие условия для больших <br />  команд',
        'трастовые кабинеты для <br />  стабильной работы',
      ],
    },
    {
      title: 'Для соло- <br /> арбитражников',
      items: ['простой запуск', 'поддержка', 'доступные способы пополнения'],
    },
    {
      title: 'Для таргетологов',
      items: [
        'пониженные комиссии <br />  для белых вертикалей',
        'никаких сложностей с созданием <br />  и пополнением кабинетов',
      ],
    },
  ];

  const LandingRegistrationForm = () => {
    return (
      <div className='registration-form__landing'>
        <p className='register__landing-text'>Регистрация</p>
        <InputLabel
          className='register-input register-input__landing '
          type='text'
          requireLabel={false}
        />
        <div className='select-box__landing'>
          <img
            src={LandingSelect}
            width={37}
            height={20}
            alt='landing select'
          />
          <InputLabel
            className='register-input registration-input registration-input__landing'
            labelClass='register-label'
            type='text'
            requireLabel={false}
          />
        </div>
        <ButtonComponent
          btnText='Создать аккаунт'
          className='landing-card__btn'
          btnOnClick={handleLogin}
        />
        <img src={LandingArrow} />
        <div className='landing-manager'>Manager</div>
      </div>
    );
  };

  const FinanceForm = () => {
    return (
      <div className=''>
        <img src={LandingFinance} width={145} height={165} />
      </div>
    );
  };

  const StepCard = ({ type, image, description }: any) => {
    return (
      <div className='step-card__box'>
        <div className='step-card'>
          {type === 'registration' ? (
            <StartWorkingOne />
          ) : type === 'office' ? (
            <StartWorkingTwo />
          ) : type === 'finance' ? (
            <FinanceForm />
          ) : (
            <StartWorkingFour />
          )}
        </div>
        <p
          className='step-card__description'
          dangerouslySetInnerHTML={{ __html: description }}
        ></p>
      </div>
    );
  };

  const StepsSection = ({ stepsData }: any) => {
    return (
      <div className='steps-section'>
        {stepsData.map((step: any, index: any) => (
          <StepCard key={index} {...step} />
        ))}
      </div>
    );
  };

  const BlockComponent = ({ title, items }: any) => {
    return (
      <div className='block-component'>
        <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
        <ul>
          {items.map((item: any, index: any) => (
            <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
          ))}
        </ul>
      </div>
    );
  };

  const financeComponent: any = [
    {
      img: Capitalist,
      text: 'Capitalist',
      width: '200',
      height: '65',
    },
    {
      img: Crypto,
      text: 'Криптовалюта',
    },
    {
      img: Partners,
      text: 'Партнерские программы',
    },
  ];

  return (
    <>
      <div className='landing-page-head--white-background'>
        <div className='landing-page-head--container'>
          <WrappedHeader />
          <div className='landing-page-head'>
            <div className='landing-page-head__left-section'>
              <p className='landing-page-head__title'>
                Создавай и пополняй <br /> рекламные кабинеты
              </p>
              <p className='landing-page-head__title__bottom landing-page-head__title__bottom'>
                Агентские кабинеты в один клик
              </p>
              <div className='landing-page-head__btn-box landing-page-head__btn-box--mobile-hidden'>
                <ButtonComponent
                  btnText='Зарегистрироваться'
                  className='landing-page__register-btn'
                  btnOnClick={handleRegister}
                />
                <ButtonComponent
                  btnText='Войти'
                  className='landing-page__auth-btn'
                  btnOnClick={handleLogin}
                />
              </div>
            </div>
            <img
              className='landing-page-head__img'
              src={LandingIcon}
              alt='Landing Icon'
            />
            <div className='landing-page-head__btn-box landing-page-head__btn-box--desktop-hidden'>
              <ButtonComponent
                btnText='Зарегистрироваться'
                className='landing-page__register-btn'
                btnOnClick={handleRegister}
              />
              <ButtonComponent
                btnText='Войти'
                className='landing-page__auth-btn'
                btnOnClick={handleLogin}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='landing-page-head--container'>
        <div className='blocks-container'>
          {blocksData.map((block, index) => (
            <BlockComponent
              key={index}
              title={block.title}
              items={block.items}
            />
          ))}
        </div>

        <div className='landing-page__start-work'>
          <p className='finance-title__text'>Как начать работу?</p>
          <StepsSection stepsData={stepsData} />

          <ButtonComponent
            btnText='Начать работу'
            className='landing-page__start-btn'
            btnOnClick={handleLogin}
          />
        </div>
        <p className='finance-title__text finance-title__text--add-money'>
          {' '}
          Как пополнить?{' '}
        </p>
        <div className='finance-container'>
          {financeComponent.map((item: any) => (
            <div className='finance-box' key={item.text}>
              <img src={item.img} width={item.width} height={item.height} alt='Ошибка' />
              <p>{item.text}</p>
              <ButtonComponent
                btnText='Пополнить'
                className='landing-page__finance-btn'
                btnOnClick={handleLogin}
              />
            </div>
          ))}
        </div>
        <p className='finance-title__text finance-title__text--faq'> FAQ </p>

        {faq ? (
          <div className='faq__head'>
            <div className='faq__box'>
              <div className='faq__container'>
                <p className='faq__title'>
                  Какие рекламные площадки сейчас доступны?
                </p>
                <img
                  src={MinusIcon}
                  width={44}
                  height={44}
                  onClick={handleFAQ}
                  alt='MinusIcon'
                />
              </div>
              <div className='faq__answers'>
                <p>
                  Сейчас наш сервис работает с Fb Accounts. Вы можете создавать
                  и пополнять рекламные кабинеты, управлять кампаниями и
                  использовать все возможности Fb для продвижения.
                </p>
                <p>
                  Совсем скоро мы добавим поддержку Google Ads. Вы сможете
                  создавать и пополнять кабинеты в Google Ads, управлять
                  рекламой и использовать все инструменты Google.
                </p>
                <p>
                  Мы планируем подключить другие популярные рекламные системы,
                  чтобы у вас было еще больше возможностей для продвижения. Если
                  у вас есть вопросы по новым рекламным площадкам, напишите нам
                  в поддержку.
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className='faq__head'>
            <div className='faq__box faq__minus-box'>
              <div className='faq__container'>
                <p className='faq__title'>
                  Какие рекламные площадки сейчас доступны?
                </p>
                <img
                  src={PlusIcon}
                  width={44}
                  height={44}
                  onClick={handleFAQ}
                />
              </div>
            </div>
          </div>
        )}

        {faq2 ? (
          <div className='faq__head'>
            <div className='faq__box'>
              <div className='faq__container'>
                <p className='faq__title'>
                  Какие преимущества дает использование ваших кабинетов?
                </p>
                <img
                  src={MinusIcon}
                  width={44}
                  height={44}
                  onClick={handleFAQ2}
                />
              </div>
              <p className='faq__answers'>
                Агентские кабинеты имеют повышенный траст, что значительно
                снижает риск блокировок со стороны рекламных платформ.  <br />{' '}
                <br />
                Кабинеты не имеют ограничений на бюджет, что позволит вам
                масштабировать рекламные кампании на одном аккаунте.  <br />{' '}
                <br />
                Трастовые кабинеты помогут оптимизировать расходы на рекламу,
                снизив затраты на покупку расходных материалов и инструментов.{' '}
                <br /> <br />
              </p>
            </div>
          </div>
        ) : (
          <div className='faq__head'>
            <div className='faq__box faq__minus-box'>
              <div className='faq__container'>
                <p className='faq__title'>
                  Какие преимущества дает использование ваших кабинетов?
                </p>
                <img
                  src={PlusIcon}
                  width={44}
                  height={44}
                  onClick={handleFAQ2}
                />
              </div>
            </div>
          </div>
        )}

        {faq3 ? (
          <div className='faq__head'>
            <div className='faq__box'>
              <div className='faq__container'>
                <p className='faq__title'>
                  Как я могу связаться с вашей службой поддержки?
                </p>
                <img
                  src={MinusIcon}
                  width={44}
                  height={44}
                  onClick={handleFAQ3}
                />
              </div>
              <p className='faq__answers'>
                По любым вопросам работы сервиса, сотрудничеству или
                предложениям вы можете написать нам на{' '}
                <a href='mailto:info@ad.kitchen'>info@ad.kitchen</a> <br />{' '}
                <br />
              </p>
            </div>
          </div>
        ) : (
          <div className='faq__head'>
            <div className='faq__box faq__minus-box'>
              <div className='faq__container'>
                <p className='faq__title'>
                  Как я могу связаться с вашей службой поддержки?
                </p>
                <img
                  src={PlusIcon}
                  width={44}
                  height={44}
                  onClick={handleFAQ3}
                />
              </div>
            </div>
          </div>
        )}

        <div className='landing__buttom'>
          <div>
            <p className='landing__buttom-title'>Ad.Kitchen</p>
            <p className='landing__main-title'>
              Нужна помощь?{' '}
              <span className='landing__main-title__span'>
                <a href='mailto:info@ad.kitchen' className='email-link'>
                  info@ad.kitchen
                </a>
              </span>
            </p>
          </div>
          <div className='landing__buttom__right'>
            <p>ТОО "MarketingCorp"</p>
            <p>Казахстан, город Алматы, Бостандыкский район, улица</p>
            <p>Ауэзова, здание 175, кв. 8а, почтовый индекс 050057</p>
          </div>
        </div>
        <div className='recovery-footer recovery-footer--static'>
          <FooterComponent
            className='layout-main__footer main-footer-class main-footer-class--column-left user-agreement-section__footer'
            rightSideClassname='header-right-side-container header-right-side-container--column'
            leftsideText='user-agreement-section__copy-rights'
          />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
