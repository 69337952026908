import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

interface FinanceState {
  finances: FinanceItem[];
  finance: FinanceItem | null;
  isLoading: boolean;
  error: string | null;
  transactions: any[];
  allTransactions: Transaction[];
  totalAmount: number;
  financesByEmail: FinanceItem[]; // Explicitly set the type for financesByEmail
}

interface FinanceItem {
  _id: string;
  date: string;
  amount: number;
  paymentMethod: string;
  bonus: number;
  status: string;
  totalAmount: number
  // Добавьте другие поля по необходимости
}

interface Transaction {
  _id: string;
  date: string;
  amount: number;
  paymentMethod: string;
  status: string;
  // Добавьте другие поля, если они есть в модели транзакции
}

export const addFinance = createAsyncThunk(
  'finance/add-finance',
  async (financeData: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/finance/add-to-balance', financeData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchFinancesByEmail = createAsyncThunk<any, string>(
  'finance/fetchFinancesByEmail',
  async (email, { rejectWithValue }) => {
    try {
      const response = await $api.post(`/api/finance/get-finance-by-user`, { email });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch finances');
    }
  }
);

export const fetchTransactions = createAsyncThunk<any>(
  'transaction/fetchTransactions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get('/api/office/get-all-offices-transactions');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch transactions');
    }
  }
);

export const deleteFinance = createAsyncThunk(
  'finance/deleteFinance',
  async (financeId: string, { rejectWithValue }) => {
    try {
      const response = await $api.delete(`/api/finance/${financeId}`);
      return financeId; // Возвращаем ID удаленного финанса
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete finance');
    }
  }
);

export const updateTransactionStatus = createAsyncThunk(
  'finance/updateTransactionStatus',
  async ({ transactionId, newStatus }: { transactionId: string; newStatus: string }, { dispatch, rejectWithValue }) => {
    try {
      const response = await $api.patch('/api/finance/update-transaction-status', { transactionId, newStatus });
      dispatch(updateLocalTransactionStatus({ transactionId, newStatus })); // Локально обновляем статус
      return response.data.transaction;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update transaction status');
    }
  }
);

export const updateFinanceTransactionStatus = createAsyncThunk(
  'finance/updateFinanceTransactionStatus',
  async ({ transactionId, newStatus }: { transactionId: string; newStatus: string }, { rejectWithValue }) => {
    try {
      const response = await $api.patch('/api/finance/update-finance-transaction-status', { transactionId, newStatus });
      return response.data.transaction;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to update transaction status');
    }
  }
);

export const fetchAllFinances = createAsyncThunk(
  'finance/fetchAllFinances',
  async ({ role, email }: { role: string; email: string }, { rejectWithValue }) => {
    try {
      // Передаем роль и email пользователя в теле запроса
      const response = await $api.post('/api/finance/get-all-finances', { role, email });
      return response.data;
    } catch (error: any) {
      // Обрабатываем ошибку, если запрос не удался
      return rejectWithValue(error.response?.data?.message || 'Не удалось получить данные по финансам');
    }
  }
);

export const fetchTotalFinancesByEmail = createAsyncThunk(
  'finance/fetchTotalFinancesByEmail',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await $api.post(`/api/finance/get-total-finances-by-email`, { email });
      return response.data.totalAmount; // Ожидаем, что сервер вернет объект с полем totalAmount
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch total finances');
    }
  }

);

const initialState: FinanceState = {
  finances: [],
  finance: null,
  isLoading: false,
  error: null,
  transactions: [],
  allTransactions: [],
  totalAmount: 0,
  financesByEmail: [], // Set initial state as an empty array of type FinanceItem[]
};

export const financeSlice = createSlice({
  name: 'finance',
  // initialState: {
  //   finances: [] as FinanceItem[],
  //   finance: null,
  //   isLoading: false,
  //   error: null,
  //   transactions: [],
  //   allTransactions: [] as Transaction[],
  //   totalAmount: 0,
  //   financesByEmail: [],
  // },
  initialState,
  reducers: {
    deleteTransactionFromStore: (state, action: any) => {
      state.allTransactions = state.allTransactions.filter(transaction => transaction._id !== action.payload);
    },
    updateLocalTransactionStatus: (state, action: PayloadAction<{ transactionId: string; newStatus: string }>) => {
      const { transactionId, newStatus } = action.payload;
      const transactionIndex = state.allTransactions.findIndex(transaction => transaction._id === transactionId);
      if (transactionIndex !== -1) {
        state.allTransactions[transactionIndex].status = newStatus;
      }
    },
    updateLocalFinanceStatus: (state, action: PayloadAction<{ financeId: string; newStatus: string }>) => {
      const { financeId, newStatus } = action.payload;
      const financeIndex = state.finances.findIndex(finance => finance._id === financeId);
      if (financeIndex !== -1) {
        state.finances[financeIndex].status = newStatus;
      }
    },
    updateLocalFinanceByEmailStatus: (state, action: PayloadAction<{ financeId: string; newStatus: string }>) => {
      const { financeId, newStatus } = action.payload;
      const financeIndex = state.financesByEmail.findIndex(finance => finance._id === financeId);
      if (financeIndex !== -1) {
        state.financesByEmail[financeIndex].status = newStatus;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Add finance
      .addCase(addFinance.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addFinance.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.finance = action.payload;
        // @ts-ignore
        state.finances.push(action.payload);
      })
      .addCase(addFinance.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchFinancesByEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFinancesByEmail.fulfilled, (state, action: PayloadAction<any[]>) => {
        state.isLoading = false;
        // @ts-ignore
        state.finances = action.payload;
        // @ts-ignore
        state.financesByEmail = action.payload;
      })
      .addCase(fetchFinancesByEmail.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllFinances.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllFinances.fulfilled, (state, action: PayloadAction<FinanceItem[]>) => {
        state.isLoading = false;
        state.finances = action.payload;
      })
      .addCase(fetchAllFinances.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        // @ts-ignore
        state.error = action.payload;
      })
      .addCase(fetchTotalFinancesByEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTotalFinancesByEmail.fulfilled, (state, action: PayloadAction<number>) => {
        state.isLoading = false;
        state.totalAmount = action.payload; // Сохраняем общую сумму финансов в состоянии
      })
      .addCase(fetchTotalFinancesByEmail.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchTransactions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTransactions.fulfilled, (state: any, action: PayloadAction<any[]>) => {
        state.isLoading = false;
        state.allTransactions = action.payload;
      })
      .addCase(fetchTransactions.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteFinance.fulfilled, (state, action: PayloadAction<string>) => {
        state.finances = state.finances.filter(finance => finance._id !== action.payload);
      })
      .addCase(deleteFinance.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
      })
      .addCase(updateTransactionStatus.fulfilled, (state, action: PayloadAction<Transaction>) => {
        const updatedTransaction = action.payload;
        const index = state.allTransactions.findIndex(transaction => transaction._id === updatedTransaction._id);
        if (index !== -1) {
          // console.log('updatedTransaction', updatedTransaction)
          state.allTransactions[index] = updatedTransaction;
        }
      })
      .addCase(updateFinanceTransactionStatus.fulfilled, (state, action: PayloadAction<{ transactionId: string; newStatus: string }>) => {
        const { transactionId, newStatus } = action.payload;
        const index = state.finances.findIndex((finance) => finance._id === transactionId);
        if (index !== -1) {
          state.finances[index].status = newStatus;
        }
      })
  },
});

export const { deleteTransactionFromStore, updateLocalTransactionStatus, updateLocalFinanceStatus, updateLocalFinanceByEmailStatus } = financeSlice.actions;
export default financeSlice.reducer;
