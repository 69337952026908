import React from 'react';
import MainLayout from '../../layouts/mainLayout';
import TwoFactorAuthImg from '../../../assets/images/two-factor-auth.png';
import './two-factor-auth.scss';
import QRCodeForm from '../../ui/forms/qr-code-form/qr-code-form';

const TwoFactorAuth = () => {
  return (
    <MainLayout>
      <div className='auth-two-factor__column'>
        <div className='registration__main'>
          <div className='auth-two-factor__left-side'>
            <img src={TwoFactorAuthImg} alt='TwoFactorAuthImg' />
          </div>
          <QRCodeForm />
        </div>
      </div>
    </MainLayout>
  );
};

export default TwoFactorAuth;
