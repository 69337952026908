import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { RootState, store } from './app/store';
import App from './App';
import './index.css';
import debounce from 'lodash.debounce';

const container = document.getElementById('root')!;
const root = createRoot(container);

const syncTabs = debounce(() => {
    const savedState = JSON.parse(localStorage.getItem('reduxAuth') || '{}');
    const currentAuthState = store.getState().auth;

    // Сравниваем, чтобы не обновлять, если состояние не изменилось
    if (JSON.stringify(savedState) !== JSON.stringify(currentAuthState)) {
        store.dispatch({ type: 'SYNC_AUTH', payload: savedState });
    }
}, 200);

window.addEventListener('storage', syncTabs);

// Включаем GTM только если переменная окружения REACT_APP_HOST равна 'live'
if (process.env.REACT_APP_HOST === 'live') {
    // Добавляем GTM в head
    const gtmScript = document.createElement('script');
    gtmScript.innerHTML = `
      (function(w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({'gtm.start': new Date().getTime(), event: 'gtm.js'});
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', 'GTM-TDXNMJSX');
    `;
    document.head.appendChild(gtmScript);

    // Добавляем GTM (noscript) в body
    const gtmNoScript = document.createElement('noscript');
    gtmNoScript.innerHTML = `
      <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TDXNMJSX"
              height="0" width="0" style="display:none;visibility:hidden"></iframe>
    `;
    document.body.appendChild(gtmNoScript);
}

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);
