import { DataItem } from "../../table/custom-table";
import { parse } from 'date-fns';

export const getStatusClassName = (status: string) => {
    switch (status.toLowerCase()) {
        case 'открыт':
            return 'status-open';
        case 'отключён':
            return 'status-closed';
        case 'на рассмотрении':
            return 'status-under-consideration';
        case 'активен':
            return 'status-active';
        case 'неактивен':
            return 'status-inactive';
        case 'отклонен':
            return 'status-rejected';
        case 'приостановлен':
            return 'status-suspended';
        case 'клиент':
            return 'status-client';
        case 'новый':
            return 'status-new';
        case 'горячий':
            return 'status-hot';
        case 'спам':
            return 'status-spam';
        case 'неактивный':
            return 'status-notactive';

        case 'выполнено':
            return 'status-recharge-completed'; 
        case 'в обработке':
            return 'status-recharge-processing';
        case 'отклонено':
            return 'status-recharge-rejected'; 
        case 'ожидает пополнения':
            return 'status-recharge-awaiting'; 

        default:
            return '';
    }
};

export const filterData = (data: any[], filterableColumns: any, filters: any, startDate: Date, endDate: Date) => {
    const result = filterableColumns
        ? data?.filter((item) => {
            const passesFilters = filterableColumns.every((column: any) => {
                const filterValue = filters[column.name] || 'Все';
                const itemValue = item[column.name] || item['advertisingNetwork'];
                return filterValue === 'Все' || itemValue === filterValue;
            });
            const itemDate = item.date ? parse(item.date, 'dd.MM.yyyy', new Date()) : null;

            const passesDateFilter = itemDate
                ? itemDate >= startDate && itemDate <= endDate
                : true;

            return passesFilters && passesDateFilter;
        })
        : data;
    return result;
};

export const searchInData = (data: any[], searchBy: string, search: string) => {
    return searchBy
        ? data?.filter((item) => {
            const searchValue = item[searchBy];
            return searchValue
                ? searchValue.toString().toLowerCase().includes(search.toLowerCase())
                : true;
        })
        : data;
};


export const getUniqueValues = (data: DataItem[], columnName: keyof DataItem): string[] => {
    const uniqueValues: { [key: string]: boolean } = {};
    data?.forEach((item) => {
        if (item[columnName] !== undefined) {
            uniqueValues[item[columnName] as string] = true;
        }
    });
    return Object.keys(uniqueValues);
};