import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import $api from '../../core/api';

// Асинхронное действие для генерации QR-кода
export const generateQrCode = createAsyncThunk(
  'twoFactorAuth/generateQrCode',
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/qr/generate', { userId });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Асинхронное действие для верификации токена
export const verifyToken = createAsyncThunk(
  'twoFactorAuth/verifyToken',
  async ({ token, userId }: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/qr/verify', { token, userId });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Асинхронное действие для отключения 2FA
export const disableTwoFactorAuth = createAsyncThunk(
  'twoFactorAuth/disableTwoFactorAuth',
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/qr/disable', { userId });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Создание слайса
const twoFactorAuthSlice = createSlice({
  name: 'twoFactorAuth',
  initialState: {
    qrCodeUrl: '',
    secret: '',
    verified: false,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateQrCode.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(generateQrCode.rejected, (state: any, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(verifyToken.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyToken.fulfilled, (state, action) => {
        state.isLoading = false;
        state.verified = action.payload;
      })
      .addCase(verifyToken.rejected, (state: any, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(generateQrCode.fulfilled, (state, action) => {
        state.isLoading = false;
        state.qrCodeUrl = action.payload.qrCodeUrl;
        state.secret = action.payload.secret;
      })
      .addCase(disableTwoFactorAuth.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(disableTwoFactorAuth.fulfilled, (state, action) => {
        state.isLoading = false;
        state.qrCodeUrl = '';
        state.secret = '';
        state.verified = false;
      })
      .addCase(disableTwoFactorAuth.rejected, (state: any, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default twoFactorAuthSlice.reducer;
