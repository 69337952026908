import React from 'react';
import SearchIcon from '../../../assets/icons/search-icon';
import './search-input.scss';

interface SearchInputProps {
  searchValue: string;
  onSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  className?: string;
  searchName?: string;
  searchWrapperClassname?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  searchValue,
  onSearchChange,
  placeholder = 'Найти',
  className,
  searchName,
  searchWrapperClassname,
}) => {
  return (
    <div className={`search-input-wrapper ${searchWrapperClassname ? searchWrapperClassname : ''}`}>
      <div className='custom-table__label'>{searchName}</div>
      <div className={`search-input-container ${className}`}>
        <input
          className='search-input'
          type='text'
          placeholder={placeholder}
          value={searchValue}
          onChange={onSearchChange}
        />
        <SearchIcon className='search-input-icon' />
      </div>
    </div>
  );
};

export default SearchInput;
