export function formatPhoneNumber(phone: string) {
  // Убираем все символы, кроме цифр и плюса
  let cleaned = phone.replace(/[^\d+]/g, '');

  if (!cleaned.startsWith('+')) {
    cleaned = `+${cleaned}`;
  }

  // Россия (+7) и Казахстан (+7) - 10 цифр после кода страны
  if (cleaned.startsWith('+7')) {
    cleaned = cleaned.replace('+7', '7');
    if (cleaned.length === 11) {
      // 1 цифра для кода страны и 10 цифр для номера
      return cleaned;
    }
  }
  // Украина (+380) - 9 цифр после кода страны
  else if (cleaned.startsWith('+380')) {
    cleaned = cleaned.replace('+380', '380');
    if (cleaned.length === 12) {
      // 3 цифры для кода страны и 9 цифр для номера
      return cleaned;
    }
  }
  // Индия (+91) - 10 цифр после кода страны
  else if (cleaned.startsWith('+91')) {
    cleaned = cleaned.replace('+91', '91');
    if (cleaned.length === 12) {
      // 2 цифры для кода страны и 10 цифр для номера
      return cleaned;
    }
  }
  // Узбекистан (+998) - 9 цифр после кода страны
  else if (cleaned.startsWith('+998')) {
    cleaned = cleaned.replace('+998', '998');
    if (cleaned.length === 12) {
      // 3 цифры для кода страны и 9 цифр для номера
      return cleaned;
    }
  }
  // Соединённые Штаты (+1) - 10 цифр после кода страны
  else if (cleaned.startsWith('+1')) {
    cleaned = cleaned.replace('+1', '1');
    if (cleaned.length === 11) {
      // 1 цифра для кода страны и 10 цифр для номера
      return cleaned;
    }
  }
  // Кыргызстан (+996) - 9 цифр после кода страны
  else if (cleaned.startsWith('+996')) {
    cleaned = cleaned.replace('+996', '996');
    if (cleaned.length === 12) {
      // 3 цифры для кода страны и 9 цифр для номера
      return cleaned;
    }
  }
  // Беларусь (+375) - 9 цифр после кода страны
  else if (cleaned.startsWith('+375')) {
    cleaned = cleaned.replace('+375', '375');
    if (cleaned.length === 12) {
      // 3 цифры для кода страны и 9 цифр для номера
      return cleaned;
    }
  }
  // Польша (+48) - 9 цифр после кода страны
  else if (cleaned.startsWith('+48')) {
    cleaned = cleaned.replace('+48', '48');
    if (cleaned.length === 11) {
      // 2 цифры для кода страны и 9 цифр для номера
      return cleaned;
    }
  }
  // Китай (+86) - 11 цифр после кода страны
  else if (cleaned.startsWith('+86')) {
    cleaned = cleaned.replace('+86', '86');
    if (cleaned.length === 13) {
      // 2 цифры для кода страны и 11 цифр для номера
      return cleaned;
    }
  }
  // Грузия (+995) - 9 цифр после кода страны
  else if (cleaned.startsWith('+995')) {
    cleaned = cleaned.replace('+995', '995');
    if (cleaned.length === 12) {
      // 3 цифры для кода страны и 9 цифр для номера
      return cleaned;
    }
  }
  // Гонконг (+852) - 8 цифр после кода страны
  else if (cleaned.startsWith('+852')) {
    cleaned = cleaned.replace('+852', '852');
    if (cleaned.length === 11) {
      // 3 цифры для кода страны и 8 цифр для номера
      return cleaned;
    }
  }
  // Армения (+374) - 8 цифр после кода страны
  else if (cleaned.startsWith('+374')) {
    cleaned = cleaned.replace('+374', '374');
    if (cleaned.length === 11) {
      // 3 цифры для кода страны и 8 цифр для номера
      return cleaned;
    }
  }
  // Молдова (+373) - 8 цифр после кода страны
  else if (cleaned.startsWith('+373')) {
    cleaned = cleaned.replace('+373', '373');
    if (cleaned.length === 11) {
      // 3 цифры для кода страны и 8 цифр для номера
      return cleaned;
    }
  }
  // Германия (+49) - 10 цифр после кода страны
  else if (cleaned.startsWith('+49')) {
    cleaned = cleaned.replace('+49', '49');
    if (cleaned.length === 12) {
      // 2 цифры для кода страны и 10 цифр для номера
      return cleaned;
    }
  }
  // Турция (+90) - 10 цифр после кода страны
  else if (cleaned.startsWith('+90')) {
    cleaned = cleaned.replace('+90', '90');
    if (cleaned.length === 12) {
      // 2 цифры для кода страны и 10 цифр для номера
      return cleaned;
    }
  }
  // Нидерланды (+31) - 9 цифр после кода страны
  else if (cleaned.startsWith('+31')) {
    cleaned = cleaned.replace('+31', '31');
    if (cleaned.length === 11) {
      // 2 цифры для кода страны и 9 цифр для номера
      return cleaned;
    }
  }
  // Великобритания (+44) - 10 цифр после кода страны
  else if (cleaned.startsWith('+44')) {
    cleaned = cleaned.replace('+44', '44');
    if (cleaned.length === 12) {
      // 2 цифры для кода страны и 10 цифр для номера
      return cleaned;
    }
  }
  // Сингапур (+65) - 8 цифр после кода страны
  else if (cleaned.startsWith('+65')) {
    cleaned = cleaned.replace('+65', '65');
    if (cleaned.length === 10) {
      // 2 цифры для кода страны и 8 цифр для номера
      return cleaned;
    }
  }
  // Таиланд (+66) - 9 цифр после кода страны
  else if (cleaned.startsWith('+66')) {
    cleaned = cleaned.replace('+66', '66');
    if (cleaned.length === 11) {
      // 2 цифры для кода страны и 9 цифр для номера
      return cleaned;
    }
  }
  // Азербайджан (+994) - 9 цифр после кода страны
  else if (cleaned.startsWith('+994')) {
    cleaned = cleaned.replace('+994', '994');
    if (cleaned.length === 12) {
      // 3 цифры для кода страны и 9 цифр для номера
      return cleaned;
    }
  }

  throw new Error('Неверный формат номера телефона');
}
