import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { IInputLabel } from './input-label-types';
import ClosedEyeIcon from '../../../assets/icons/closed-eye-icon';
import ReactCountryFlag from 'react-country-flag';
import './input-label.scss';
import DropDownIcon from '../../../assets/icons/dropDownIcon';
import { countries } from '../../../utils/countries';
import CopyBtn from '../../../assets/images/copy-btn.png';

function removeBackslashes(str: string) {
  return str.replace(/\\/g, '');
}

interface selectedCountryProp {
  name: string;
  code: string;
  mask: string;
}

const InputLabel = ({
  value,
  labelContainer,
  required,
  placeholder,
  type,
  name,
  label,
  setValue,
  disabled,
  className,
  step,
  icon,
  iconClass,
  labelClass,
  requireLabel = false,
  setFormError,
  formError,
  rightText,
  copy,
  currencyClassName,
  setMesengerValueChanged,
  onFocus,
  ownOnChange,
  ownOnChangeFunction,
  autoComplete = 'off',
  rightIcon,
  setPhoneError,
  maxLength,
  ...rest
}: any & { copy?: boolean }) => {
  // console.log("🚀 ~ setPhoneError:", setPhoneError)
  // console.log("🚀 ~ setPhoneError:", setPhoneError)
  const [showPassword, setShowPassword] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<selectedCountryProp>(
    countries[0]
  );
  const [phoneValue, setPhoneValue] = useState(value || '');
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [localPhoneError, setLocalPhoneError] = useState(false);
  // console.log("🚀 ~ localPhoneError:", localPhoneError)

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target as Node) &&
        containerRef.current && !containerRef.current.contains(e.target as Node)) {
        setShowDropdown(false); // Скрыть выпадающий список
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);


  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleCountryChange = (countryCode: string) => {
    const selectedCountry = countries.find(
      (country) => country.code === countryCode
    );
    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
    }
    setShowDropdown(false);
  };

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setPhoneValue(value);
    setValue(value);

    // Удаляем все символы, кроме цифр
    const digitsOnly = value.replace(/\D/g, '').replace(/_/g, '');

    // Извлекаем цифры из маски
    const requiredDigits = selectedCountry.mask.replace(/\D/g, '').length;

    // Проверка: если введено меньше цифр, чем требуется
    // console.log("🚀 ~ onPhoneChange ~ digitsOnly.length < requiredDigits:", digitsOnly.length < requiredDigits)
    if (digitsOnly.length < requiredDigits) {
      if (setPhoneError) {
        setPhoneError(true); // Показываем ошибку, если передана внешняя функция
      } else {
        setLocalPhoneError(true); // Устанавливаем локальную ошибку
      }
    } else {
      if (setPhoneError) {
        setPhoneError(false); // Убираем ошибку, если передана внешняя функция
      } else {
        setLocalPhoneError(false); // Убираем локальную ошибку
      }
    }
  };

  const toggleDropdown = () => {
    setShowDropdown((prevState) => !prevState);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (
        showDropdown &&
        containerRef.current &&
        !containerRef.current.contains(e.target as Node)
      ) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropdown]);

  if (required) {
    required = 'required';
  }

  if (!value) {
    value = '';
  }
  if (type === 'numberWallet') {
    value = value
      .toString()
      .replace(/\s/g, '')
      .replace(/(\d)(?=(\d{3})+(\D|$))/g, '$1 ');
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (setMesengerValueChanged !== undefined) {
      setMesengerValueChanged(true);
    }
    setValue(value);

    // console.log(5444)
  };

  return (
    <div className='relative' ref={containerRef}>
      <div className='label-container'>
        {label && <label className={labelClass}>{label}</label>}
        {requireLabel && <div className='label-required'>*</div>}
      </div>
      {type === 'tel' ? (
        <div className='phone-box'>
          <div className='phone-img phone-select' onMouseUp={toggleDropdown}>
            <ReactCountryFlag
              className='emojiFlag'
              countryCode={selectedCountry.code}
              svg
            />
            <DropDownIcon className={'dropdown-style'} />
          </div>

          {showDropdown && (
            <div className='phone-dropdown' ref={dropdownRef}>
              {countries?.map((country) => (
                <div
                  key={country.code}
                  className='phone-dropdown-item'
                  onClick={() => handleCountryChange(country.code)}
                >
                  <ReactCountryFlag
                    className='emojiFlag'
                    countryCode={country.code}
                    svg
                  />
                  {country.code}
                </div>
              ))}
            </div>
          )}
          <InputMask
            {...rest}
            mask={selectedCountry.mask}
            className={`${className}`}
            onChange={onPhoneChange}
            value={phoneValue}
            disabled={disabled}
            placeholder={removeBackslashes(selectedCountry.mask)}
            required={required}

          />
          {(localPhoneError) && (
            <span className="error">Неправильный формат номера телефона</span>
          )}
        </div>
      ) : (
        <div className='input-wrapper'>
          <input
            {...rest}
            className={`${className}`}
            onChange={(e) => {
              ownOnChange ? ownOnChangeFunction(e) : onChange(e);
            }}
            value={value}
            type={showPassword ? 'text' : type}
            disabled={disabled}
            placeholder={placeholder}
            required={required}
            onFocus={onFocus}
            step={step}
            autoComplete={autoComplete}
            maxLength={maxLength}
          />
          {copy && value && (
            <img
              src={CopyBtn}
              alt='Copy'
              className='copy-icon'
              onClick={handleCopy}
            />
          )}
          {rightIcon && <div className="right-icon">{rightIcon}</div>}
        </div>
      )}
      {rightText && <div className={`right-text ${currencyClassName ? currencyClassName : ""}`}>{rightText}</div>}
      <div className={iconClass} onClick={toggleShowPassword}>
        {showPassword ? <ClosedEyeIcon /> : icon}
      </div>
      {copySuccess && <div className='copy-success'>Скопировано!</div>}
    </div>
  );
};

export default InputLabel;
