import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const addNewQuestion = createAsyncThunk(
  'question/add-question',
  async (questionData: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/question/create-question', questionData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const uploadImage = createAsyncThunk(
  'question/upload-image',
  async (file: File, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('file', file);

      const response = await $api.post('/api/question/upload-image', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchAllQuestions = createAsyncThunk(
  'question/fetch-all-questions',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get('/api/question/questions');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchQuestionById = createAsyncThunk(
  'question/fetch-question-by-id',
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/api/question/questions/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  'question/delete-question',
  async (id: any, { rejectWithValue }) => {
    // console.log('id', id)
    try {
      const response = await $api.delete(`/api/question/questions/${id}`);
      return id;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  'question/update-question',
  // @ts-ignore
  async ({ id, ...questionData }: { id: any; questionData: any }, { rejectWithValue }) => {
    try {
      const response = await $api.put(`/api/question/update-question/${id}`, questionData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// question-slice.ts
export const updateQuestionsOrderOnServer = createAsyncThunk(
  'question/update-questions-order',
  async (questions: any[], { rejectWithValue }) => {
    try {
      const response = await $api.put('/api/question/update-order', { questions });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface Question {
  id: string;
  title: string;
  content: string;
}

interface QuestionState {
  questions: Question[];
  isLoading: boolean;
  error: string | null;
  currentQuestion: any,
  imageUrl: string | null;
}

const initialState: QuestionState = {
  questions: [],
  isLoading: false,
  currentQuestion: null,
  error: null,
  imageUrl: null,
};

// Экшен для локального удаления вопроса
export const deleteQuestionLocal = (id: string) => (dispatch: any, getState: any) => {
  const { questions } = getState().question;
  const updatedQuestions = questions.filter((question: any) => question.id !== id);

  dispatch(questionSlice.actions.deleteQuestionSuccess({ questions: updatedQuestions }));
};

export const questionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    deleteQuestionSuccess: (state, action: PayloadAction<{ questions: Question[] }>) => {
      state.questions = action.payload.questions;
    },
    updateQuestionsOrder: (state, action: PayloadAction<Question[]>) => {
      state.questions = action.payload.map((q, index) => ({ ...q, order: index }));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewQuestion.fulfilled, (state, action: PayloadAction<Question>) => {
        state.isLoading = false;
        state?.questions.push(action.payload);
      })
      .addCase(addNewQuestion.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchAllQuestions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllQuestions.fulfilled, (state, action: PayloadAction<Question[]>) => {
        state.isLoading = false;
        state.questions = action.payload;
      })
      .addCase(fetchAllQuestions.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchQuestionById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchQuestionById.fulfilled, (state, action: PayloadAction<Question>) => {
        state.isLoading = false;
        state.currentQuestion = action.payload;
      })
      .addCase(fetchQuestionById.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateQuestion.fulfilled, (state, action: PayloadAction<Question>) => {
        state.isLoading = false;
        state.questions = state.questions.map((question) =>
          question.id === action.payload.id ? action.payload : question
        );
      })
      .addCase(updateQuestion.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(updateQuestionsOrderOnServer.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateQuestionsOrderOnServer.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        // Обновление вопросов с сервера
        // Можно оставить это пустым, если сервер не возвращает измененные данные
      })
      .addCase(updateQuestionsOrderOnServer.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder.addCase(uploadImage.pending, (state) => {
      state.isLoading = true;
    })
      .addCase(uploadImage.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.imageUrl = action.payload.imageUrl;
      })
      .addCase(uploadImage.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })

      .addCase(deleteQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteQuestion.fulfilled, (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.questions = state.questions.filter(
          (question) => question.id !== action.payload
        );
      })
      .addCase(deleteQuestion.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateQuestionsOrder } = questionSlice.actions;

export default questionSlice.reducer;
