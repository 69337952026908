import React, { useEffect, useState } from 'react';
import ButtonComponent from '../../ui/button/button';
import Facebook from '../../../assets/images/facebook.png';
import GoogleAds from '../../../assets/images/google-ads.png';
import './advertising-systems-office-home.scss';
import NetworksDefauls from '../../../assets/images/networks-defauls.png';
import MainInput from '../../input/main-input/main-input';
import RoutesList from '../../ui/routes-list/routes-list';
import MainLayout from '../../layouts/mainLayout';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getOfficesByEmailAndNetworkType } from '../../../features/offices/get-offices-by-email/get-offices-by-email-slice';
import { getTickets } from '../../../features/tickets/ticketsSlice';
import { AppDispatch } from '../../../app/store';

const AdvertisingSystemsOfficeHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector((state: any) => state.login.user) || false;
  const actualUser = user?.user ? user.user : user;
  const isAdmin = actualUser?.role === 'administrator';

  const holderEmail = actualUser?.email;
  const networkType = 'Facebook Ads';

  const tickets = useSelector((state: any) => state?.tickets?.tickets);
  const officesState = useSelector((state: any) => state?.offices?.offices);

  const [searchTerm, setSearchTerm] = useState('');
  const [sortMethod, setSortMethod] = useState('popularity');

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (holderEmail && !officesState) {
      dispatch(getOfficesByEmailAndNetworkType({ holderEmail, networkType }));

      if (tickets?.length < 1) {
        dispatch(getTickets(holderEmail));
      }
    }
  }, [dispatch, holderEmail]);

  const handleClickNavigate = async () => {
    if (officesState?.length < 1) {
      const resultAction = await dispatch(
        getOfficesByEmailAndNetworkType({ holderEmail, networkType })
      );
      if (getOfficesByEmailAndNetworkType.fulfilled?.match(resultAction)) {
        navigate('/advertising-systems-office');
      } else {
        console.error('Failed to fetch offices');
      }
    } else {
      navigate('/advertising-systems-office');
    }
  };

  const networks = [
    {
      img: Facebook,
      title: 'Fb Accounts',
      status: 'Комиссия за пополнение 7%',
      description:
        'Условия для Whitehat и Greyhat кабинетов могут отличаться.',
      btnStatus: '',
      btnClick: handleClickNavigate,
    },
    {
      img: GoogleAds,
      title: 'Google Ads',
      status: 'Временно недоступен',
      description:
        'Кабинеты Google Ads будут скоро доступны.',
      btnStatus: 'disabled',
    },
    { title: 'default' },
  ];

  const filteredNetworks = networks.filter((item) =>
    item.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const sortedNetworks = [...filteredNetworks].sort((a, b) => {
    if (sortMethod === 'alphabetical') {
      return a.title.localeCompare(b.title);
    } else if (sortMethod === 'popularity') {
      return a.title === 'Facebook Ads' ? -1 : 1;
    }
    return 0;
  });

  return (
    <MainLayout>
      <p className='ads-home-mbl-txt'>Кабинеты Рекламных систем</p>

      <div className='networks-box networks-box-home'>
        <RoutesList />
        <MainInput
          placeholder='Найти рекламную систему'
          value={searchTerm}
          onChange={handleSearchChange}
        />
        <div className='networks-box-sort'>
          <div>Сортировать:</div>
          <div
            className={sortMethod === 'popularity' ? 'active-sort' : ''}
            onClick={() => setSortMethod('popularity')}
          >
            по популярности
          </div>
          <div
            className={sortMethod === 'alphabetical' ? 'active-sort' : ''}
            onClick={() => setSortMethod('alphabetical')}
          >
            по алфавиту
          </div>
        </div>

        <div className='networks-box-container'>
          {sortedNetworks.map((item) => (
            <React.Fragment key={item.title}>
              {item.title === 'default' ? (
                <div className='networks-item-elem'>
                  <img src={NetworksDefauls} alt='NetworksDefauls' />
                  <div className='coming-ads-soon'> Скоро здесь появится новая Рекламная система </div>
                </div>
              ) : (
                <div className='networks-item-elem'>
                  <img src={item.img} alt={item.title} />
                  <div className='networks-item-title'>{item.title} </div>
                  <div className='networks-item-status'> {item.status} </div>
                  <div className='networks-item-description'>
                    {item.description}
                  </div>
                  {item.btnStatus === 'disabled' ? (
                    <ButtonComponent
                      btnText='Подключить'
                      className='networks-item-elem-btn-connect btn-connect-disabled'
                    />
                  ) : (
                    <ButtonComponent
                      btnText={`${officesState?.length > 0 ? 'Открыть' : 'Подключить'
                        }`}
                      className={`networks-item-elem-btn-connect ${officesState?.length > 0 ? 'active-btn' : ''
                        }`}
                      btnOnClick={item.btnClick}
                    // btnOnClick={
                    //   officesState?.length > 0
                    //     ? () => navigate('/advertising-systems-office')
                    //     : item.btnClick
                    // }
                    />
                  )}
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default AdvertisingSystemsOfficeHome;
