import React, { useEffect } from 'react'
import MainLayout from '../../layouts/mainLayout'
import RoutesList from '../../ui/routes-list/routes-list'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestionById, deleteQuestion } from '../../../features/questions/question-slice'; // Добавляем deleteQuestion
import { AppDispatch } from '../../../app/store';
import './faq-question.scss';

import FaqEdit from '../../../assets/icons/iconamoon_edit.svg'; // Иконка редактирования
import FaqDelete from '../../../assets/icons/faq-delete.svg'; // Иконка удаления

const FaqQuestion = () => {
  const { questionId }: any = useParams();
  // console.log("🚀 ~ FaqQuestion ~ questionId:", questionId)
  const dispatch = useDispatch<AppDispatch>();

  const currentQuestion = useSelector((state: any) => state?.questions.currentQuestion);
  // console.log("🚀 ~ FaqQuestion ~ currentQuestion:", currentQuestion)
  const user = useSelector((state: any) => state.login?.user?.user || state.login?.user);

  const userRole = user?.role;

  const navigate = useNavigate();
  const imageUrls = currentQuestion?.imageUrls;
  // console.log("🚀 ~ FaqQuestion ~ imageUrls:", imageUrls)

  useEffect(() => {
    dispatch(fetchQuestionById(questionId));
  }, [dispatch, questionId]);

  const handleNavigateBack = () => {
    navigate('/faq');
  };

  // Обработчик для редактирования
  const handleEdit = () => {
    navigate(`/faq-edit-question?id=${questionId}`, { state: currentQuestion });
  };

  // Обработчик для удаления
  const handleDelete = () => {
    // if (window.confirm('Вы уверены, что хотите удалить этот вопрос?')) {
    dispatch(deleteQuestion(questionId))
      .unwrap()
      .then(() => {
        navigate('/faq');
      })
      .catch((error) => {
        console.error('Ошибка при удалении вопроса:', error);
      });
    // }
  };

  return (
    <MainLayout>
      <RoutesList routeClassName='finance-history-route' />
      <p onClick={handleNavigateBack} className='faq-question-back'>назад</p>

      <div className='faq-current-qeustion'>
        <p className='faq-current-qeustion-title'>{currentQuestion?.title} </p>

        <div className='faq-current-qeustion-container'>
          <div className='faq-current-qeustion-wrapper'>
            <div
              className='faq-current-qeustion-content'
              dangerouslySetInnerHTML={{ __html: currentQuestion?.content }}
            />
            {/* <div className='faq-current-qeustion-content'>{currentQuestion?.content}</div> */}
            {/* {imageUrls?.map((item: any, index: number) => (
              <div key={index}>
                <img src={item} alt={`Image ${index + 1}`} />
              </div>
            ))} */}
          </div>
        </div>

        {userRole !== 'user' && (
          <div className="faq-current-question-actions">
            {/* Иконка редактирования */}
            <img
              src={FaqEdit}
              alt="Edit"
              className="faq-edit-icon"
              onClick={handleEdit}
              style={{ cursor: 'pointer', marginRight: '10px' }}
            />

            {/* Иконка удаления */}
            <img
              src={FaqDelete}
              alt="Delete"
              className="faq-delete-icon"
              onClick={handleDelete}
              style={{ cursor: 'pointer' }}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default FaqQuestion;
