import React, { useEffect, useRef, useState } from 'react';
import ButtonComponent from '../button/button';
import UserIcon from '../../../assets/images/user_account.png';
import UserHeader from '../../../assets/images/user-header.png';
import ArrowDown from '../../../assets/images/arrow-down.png';
import FluentArrow from '../../../assets/images/fluent_arrow.png';
import ArrowUp from '../../../assets/images/arrow-up.png';
import './header-component.scss';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, resetState } from '../../../features/login/loginSlice';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import CreditCards from '../../../assets/images/credit-cards.png';
import SettingsRegular from '../../../assets/images/settings-regular.png';
import MainLogo from '../../../assets/icons/main-logo';
import BurgerMenuIcon from '../../../assets/icons/burger-menu-icon';
import { Icon } from '@iconify/react';
import { authLogoutUser } from '../../../features/register/authSlice';
import { AppDispatch } from '../../../app/store';

import './header-component.scss';
import { openModal } from '../../../features/tickets/ticketsSlice';
import io from 'socket.io-client';
import { fetchAllFinances, fetchTransactions } from '../../../features/finance/finance-slice';
import { useFinances } from '../../../hooks/use-finances/useFinances';

const SubMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const isActive = (path: string) => location.pathname === path;
  const currentPath = location.pathname;

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleFinanceRoute = () => {
    navigate('/finance-history');
    dispatch(fetchTransactions());
  };

  const handleTicketsRoute = () => {
    navigate('/tickets');
  };

  const handleManagersRoute = () => {
    navigate('/managers');
  };

  const handleDocumentsRoute = () => {
    navigate('/documents');
  };

  const handleManagerHomeRoute = () => {
    navigate(
      user.role === 'user'
        ? '/advertising-systems-office-home'
        : '/manager-home'
    );
  };

  const handleManagerUsersRoute = () => {
    navigate('/manager-users');
  };

  const handleFaqRoute = () => {
    navigate('/faq');
  };

  return (
    <>
      <div className='header-right-side-modal-box'>
        <div
          className={
            currentPath === '/advertising-systems-office-home'
              ? 'header-mbl-active-route'
              : ''
          }
          onClick={handleManagerHomeRoute}
        >
          Кабинеты Рекламных систем
        </div>
      </div>
      {(user.role === 'manager' || user.role === 'administrator') && (
        <div className='header-right-side-modal-box'>
          <div
            onClick={handleManagerUsersRoute}
            className={isActive('/manager-users') ? 'active' : ''}
          >
            Пользователи
          </div>
        </div>
      )}
      {user.role === 'administrator' && (
        <div className='header-right-side-modal-box'>
          <div
            onClick={handleManagersRoute}
            className={isActive('/managers') ? 'active' : ''}
          >
            Менеджеры
          </div>
        </div>
      )}
      <div className='header-right-side-modal-box'>
        <div
          className={
            currentPath === '/finance-history' ? 'header-mbl-active-route' : ''
          }
          onClick={handleFinanceRoute}
        >
          Финансы
        </div>
      </div>
      <div className='header-right-side-modal-box'>
        <div
          className={
            currentPath === '/tickets' ? 'header-mbl-active-route' : ''
          }
          onClick={handleTicketsRoute}
        >
          Тикеты
        </div>
      </div>
      <div className='header-right-side-modal-box'>
        <div
          className={
            currentPath === '/documents' ? 'header-mbl-active-route' : ''
          }
          onClick={handleDocumentsRoute}
        >
          Документы
        </div>
      </div>
      <div className='header-right-side-modal-box'>
        <div
          onClick={handleFaqRoute}
          className={isActive('/faq') ? 'active' : ''}
        >
          FAQ
        </div>
      </div>
      <hr className='header-line' />
    </>
  );
};

const HeaderComponent = ({
  className,
  btnIconClassName,
  rightSideClassname,
  rightSideText,
  leftsideText,
  headerBtnClick,
  headerBtnText,
  leftButton,
  rightSideMenu,
  btnClassName,
  isLogin,
}: any) => {
  const [showHeaderModal, setShowHeaderModal] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );
  // const financesArray = useSelector((state: any) => state.finances?.finances);

  // const totalAmount = financesArray.reduce((total: any, finance: any) => {
  //   if (finance.status === 'Выполнено') {
  //     const amount = typeof finance.amount === 'string'
  //       ? parseFloat(finance.amount.split(' ')[0])
  //       : finance.amount;

  //     return total + amount;
  //   }
  //   return total;
  // }, 0);
  const { totalAmount } = useFinances(user?.email);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'logout') {
        dispatch(logoutUser());
        dispatch(authLogoutUser());
        navigate('/login');
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [dispatch, navigate]);

  const handleLogout = () => {
    dispatch(logoutUser());
    dispatch(authLogoutUser());
    dispatch(resetState());
    localStorage.setItem('logout', Date.now().toString());
    navigate('/');
  };

  const toggleModal = () => {
    setShowHeaderModal(!showHeaderModal);
  };

  const handleUserSettings = () => {
    if (user?.role === 'user') {
      navigate('/user-settings');
    } else {
      navigate('/manager-profile');
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowHeaderModal(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAddBalance = () => {
    navigate('/add-balance');
  };

  const handleNavigate = () => {
    navigate('/add-balance');
  };

  const handleOpenTicketModal = () => {
    // dispatch(openModal());
    // setNotificationCount(0); // Сбрасываем счетчик уведомлений при открытии тикетов
    navigate('/tickets')
  };

  const [notificationCount, setNotificationCount] = useState(0);

  useEffect(() => {
    const socket = io(`${process.env.REACT_APP_API_URL}`);

    socket.on('chat message', (msg: any) => {
      console.log('Message received:', msg); // Debugging
      if (msg.sender !== user.name) {
        setNotificationCount((prevCount) => prevCount + 1);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [user?.name]);

  useEffect(() => {
    if (user) {
      dispatch(fetchAllFinances({ role: user.role, email: user.email }));
    }
  }, [dispatch, user]);

  return (
    <>
      {isLogin && <div className='header-absolute-background-color'></div>}
      <div className={className}>
        {!leftButton && (
          <Link
            to={!!user ? '/advertising-systems-office-home' : '/'}
            className={leftsideText}
          >
            <MainLogo />
          </Link>
        )}
        {leftButton && (
          <div
            className={`left-side-header 
              ${(user?.role === 'administrator' || user?.role === 'manager') &&
              'left-side-header--small-gap'
              }
            `}
          >
            <Link
              to={!!user ? '/advertising-systems-office-home' : '/'}
              className={leftsideText}
            >
              <MainLogo />
            </Link>
            {user?.role === 'administrator' || user?.role === 'manager' ? (
              <div className={'left-side-header__role'}>
                <div
                  className={
                    user.role === 'administrator'
                      ? 'left-side-header__role--admin'
                      : 'left-side-header__role--manager'
                  }
                >
                  {user.role === 'administrator' ? 'Admin' : 'Manager'}{' '}
                </div>
              </div>
            ) : (
              <>
                <div className='left-side-header__balance'>
                  <span>{totalAmount}</span> USD
                </div>
                <ButtonComponent
                  btnText='Пополнить'
                  withIcon={false}
                  btnIconClassName={btnIconClassName}
                  btnOnClick={handleAddBalance}
                  className={`${btnClassName} btn-add-balance`}
                />
              </>
            )}
          </div>
        )}
        {rightSideClassname && (
          <div className={rightSideClassname}>
            {rightSideMenu && true ? (
              <>
                <div className='header-right-side-user '>
                  {/* <img src={HeaderBell} alt='HeaderBell' /> */}
                  <div className='open-notify-tkt' onClick={handleOpenTicketModal}>
                    <Icon
                      icon='iconamoon:notification-fill'
                      width='24px'
                      color='#6C6EA0'
                    />
                    {notificationCount > 0 && (
                      <span className='notification-count'>{notificationCount}</span>
                    )}
                  </div>

                  <div className='header-right-side-email header-right-side-user--hide-on-mobile'>
                    <Icon
                      icon='bi:person-circle'
                      width='24px'
                      color='#6C6EA0'
                      onClick={toggleModal}
                    />
                    <p>{user?.email}</p>
                  </div>
                  <div
                    className='header-right-side-user--show-on-mobile'
                    onMouseUp={toggleModal}
                  >
                    <BurgerMenuIcon />
                  </div>
                  <div
                    className='header-right-side-img'
                    onMouseUp={toggleModal}
                  >
                    {showHeaderModal ? (
                      <div className='header-right-side-img__arrow'>
                        <img src={ArrowUp} alt='Arrow Up' />
                      </div>
                    ) : (
                      <div className='header-right-side-img__arrow'>
                        <img src={ArrowDown} alt='Arrow Down' />
                      </div>
                    )}
                  </div>
                </div>
                {showHeaderModal && (
                  <>
                    <div className='header-right-side-modal' ref={modalRef}>
                      <div className='header-right-side-modal__manager-tab-menu'>
                        <div className='header-right-side-modal__email-section'>
                          <img
                            src={UserHeader}
                            onMouseUp={toggleModal}
                            alt='UserHeader'
                          />
                          <div>{user?.email}</div>
                          <Icon
                            icon='iconamoon:notification-fill'
                            width='24px'
                            color='#6C6EA0'
                          />
                          <Icon
                            className='header-right-side-modal__icon'
                            icon='gg:close'
                            width='24px'
                            color='#8F97A9'
                            onClick={toggleModal}
                          />
                        </div>
                        <SubMenu />
                      </div>

                      <div className='header-right-side-modal-box'>
                        <img src={CreditCards} alt='Fluent Arrow' />
                        <div onClick={handleNavigate}>Пополнить</div>
                      </div>
                      <div className='header-right-side-modal-box'>
                        <img src={SettingsRegular} alt='Fluent Arrow' />
                        <div onClick={handleUserSettings}>Настройки</div>
                      </div>
                      <hr className='header-line header-line-bottom' />
                      <div className='header-right-side-modal-box'>
                        <img src={FluentArrow} alt='Fluent Arrow' />
                        <div onClick={handleLogout}>Выйти</div>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <div className='header-signin-section'>
                <p>{rightSideText}</p>
                <ButtonComponent
                  btnText={headerBtnText}
                  withIcon={true}
                  icon={UserIcon}
                  btnIconClassName={btnIconClassName}
                  btnOnClick={headerBtnClick}
                />
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderComponent;
