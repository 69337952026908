import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import Skype from '../../../assets/icons/skype-icon';
import Telegram from '../../../assets/icons/telegram-icon';
import './select-component.scss';
import useOnClickOutside from '../../../hooks/use-onclick-outside';

const SelectComponent = ({
  className,
  selectOptions,
  setMessengerType,
  selectWrapper,
  selectedRole,
}: any) => {

  const ref = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const options = [
    { value: 'Skype', label: <Skype /> },
    { value: 'Telegram', label: <Telegram /> },
  ];
  const defaultValue =
    selectOptions && selectOptions.length > 0 ? selectOptions[0] : options[0];
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  // console.log('selectOptions[0]', selectOptions[0])

  useEffect(() => {
    if (selectedRole) {
      const newDefaultValue =
        selectOptions.find((option: any) => option.value === selectedRole) ||
        options[0];
      setSelectedOption(newDefaultValue);
    }
  }, [selectedRole, selectOptions]);

  const handleChange = (option: any) => {
    setSelectedOption(option);
    setMessengerType(option?.value);
  };

  useOnClickOutside(ref, () => setMenuIsOpen(false));

  return (
    <div className={selectWrapper} ref={ref}>
      <Select
        value={selectedOption}
        onChange={handleChange}
        defaultValue={defaultValue}
        options={selectOptions ? selectOptions : options}
        className={className}
        classNamePrefix='custom-react-select'
        menuIsOpen={menuIsOpen}
        onMenuOpen={() => setMenuIsOpen(true)}
        onMenuClose={() => setMenuIsOpen(false)}
      />
    </div>
  );
};

export default SelectComponent;
