import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import './manager-users.scss';
import CustomTable, { DataItem } from '../../ui/table/custom-table';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getAllUsers, calculateUserBalances } from '../../../features/get-all-users/get-all-users-slice';
import RoutesList from '../../ui/routes-list/routes-list';
import { AppDispatch, RootState } from '../../../app/store';
import { fetchFinancesByEmail } from '../../../features/finance/finance-slice';
import { fetchOfficesByEmail } from '../../../features/add-office/add-office-slice';

const ManagerUsers = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.login?.user?.user || state.login?.user);
  const isAdmin = user.role === 'administrator';
  const allUsers = useSelector((state: any) => state.allUsers.allUsers) || [];
  const userBalances = useSelector((state: RootState) => state.allUsers.userBalances);
  const isLoading = useSelector((state: RootState) => state.allUsers.isLoading);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        await dispatch(getAllUsers());
        await dispatch(calculateUserBalances());
      } catch (error) {
        console.error('Error fetching users or balances:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);

  const cabinetsColumnsHeaders = {
    name: 'Пользователь',
    role: 'Тип пользователя',
    balance: 'Баланс',
    status: 'Статус',
    phone: 'Телефон',
    messenger: 'Мессенджер',
    manager: 'Менеджер',
  };

  const statusTranslations: { [key: string]: string } = {
    active: 'Новый',
    inactive: 'Неактивный',
  };

  const filteredUsers = user.role === 'manager'
    ? allUsers.filter((u: any) => u.manager === user.email)
    : allUsers;

  const allUsersState = filteredUsers.map((user: any) => ({
    email: user.email,
    role: user.userType,
    balance: userBalances && userBalances[user.email] !== undefined ? userBalances[user.email] : 'Нет данных',
    status: statusTranslations[user.status] || user.status,
    phone: user.phone,
    messenger: `${user.messengerType} ${user.messenger}`,
    manager: user.manager,
  }));

  const emailToUserIdMap = (user.role === 'administrator' ? allUsers : filteredUsers).reduce(
    (acc: Record<string, string>, user: any) => {
      acc[user.email] = user.id || user._id;
      return acc;
    },
    {}
  );

  const handleUserClick = (user: DataItem) => {
    const { email } = user;
    if (email) {
      const userId = emailToUserIdMap[email];
      dispatch(fetchFinancesByEmail(email));
      dispatch(fetchOfficesByEmail(email));

      if (userId) {
        navigate(`/manager-user/${userId}`);
      } else {
        console.error('User ID not found for email:', email);
      }
    }
    if (email) {
      dispatch(fetchFinancesByEmail(email));

    } else {
      console.error("Email is undefined");
    }

  };

  return (
    <MainLayout>
      <div className='networks-box networks-box-home'>
        <RoutesList />
        <p className='users-title'>Пользователи</p>
        <CustomTable
          columnHeaders={cabinetsColumnsHeaders}
          data={allUsersState}
          className='ads-offices-table'
          filterableColumns={[
            { name: 'role', label: 'Тип пользователя' },
            { name: 'status', label: 'Статус' },
          ]}
          searchBy='email'
          loading={loading || isLoading}
          handleUserClick={handleUserClick}
        />
      </div>
    </MainLayout>
  );
};

export default ManagerUsers;