import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

// Define the async thunk for updating user status
export const updateUserStatus = createAsyncThunk(
  'user/updateStatus',
  async ({ email, status }: { email: string; status: string }, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/user/update-status', { email, status });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Update the slice to handle the new async thunk
export const userSlice = createSlice({
  name: 'user',
  initialState: {
    managers: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateUserStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserStatus.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.managers = action.payload;
      })
      .addCase(updateUserStatus.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default userSlice.reducer;
