import React, { useState } from 'react';
import InputLabel from '../../../input/inputLabel/Input-label';
import ErrorComponent from '../../error/error-component';
import ButtonComponent from '../../button/button';
import EyeIcon from '../../../../assets/icons/eye-icon';
import './manager-login-form.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../../../features/login/loginSlice';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../spinner/spinner';
import { AppDispatch } from '../../../../app/store';

const ManagerLoginForm = ({ setShowLoginModal }: any) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [roleError, setRoleError] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isLoading = useSelector((state: any) => state.login?.isLoading);

  const handleSubmitManager = async (event: any) => {
    event.preventDefault();
    const fromPage = 'admin';

    const userData = {
      email,
      password,
      fromPage,
    };

    try {
      let result;
      if (email && password) {
        result = await dispatch(loginUser(userData)).unwrap();
      }

      if (result && result.user) {
        const user = result.user;

        if (user.role === 'administrator') {
          if (user.twoFactor === true) {
            setShowLoginModal(true);
          } else {
            navigate('/administrator-home');
          }
        } else if (user.role === 'manager') {
          navigate('/manager-home');
        } else {
          setRoleError(true);
        }
      } else {
        setError(true);
      }
    } catch (error) {
      setError(true);
    }
  };

  const handleRecoveryPassword = (event: any) => {
    event.preventDefault();
    navigate('/manager-recovery-password');
  };

  return (
    <div className='register-form'>
      <form onSubmit={handleSubmitManager}>
        <div className='register-container'>
          {isLoading && (
            <div className={'register-container__absolute-layer'}>
              <Spinner />
            </div>
          )}
          <h1>Вход</h1>
          <InputLabel
            className='register-input'
            labelClass='register-label'
            label='Email'
            type='email'
            setValue={setEmail}
            value={email}
            onChange={setEmail}
          />
          <InputLabel
            className='register-input'
            labelClass='register-label'
            label='Пароль'
            type='password'
            setValue={setPassword}
            value={password}
            onChange={setPassword}
            icon={<EyeIcon />}
            iconClass='eye-class'
          />
          <div className='manager-login__txt-box'>
            <p className='login-text-forget' onClick={handleRecoveryPassword}>
              Забыли пароль?
            </p>
          </div>

          {error && (
            <ErrorComponent
              error='Некорректный email или пароль'
              errorClassName='login-error'
            />
          )}
          {roleError && (
            <ErrorComponent
              error='Вы не являетесь администратором или менеджером'
              errorClassName='login-error'
            />
          )}
          <div className='registration-container login-box'>
            <ButtonComponent btnText={'Войти'} className='register-btn' />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ManagerLoginForm;
