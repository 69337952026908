import React, { useState, useEffect } from 'react';
// import MainLayout from '../../layouts/mainLayout';
import RoutesList from '../../ui/routes-list/routes-list';
import CustomTable, { DataItem } from '../../ui/table/custom-table';
import { useDispatch, useSelector } from 'react-redux';
import { addFinance, deleteFinance, deleteTransactionFromStore, fetchFinancesByEmail, fetchAllFinances, updateFinanceTransactionStatus, updateTransactionStatus, updateLocalTransactionStatus, updateLocalFinanceStatus, updateLocalFinanceByEmailStatus, fetchTotalFinancesByEmail } from '../../../features/finance/finance-slice';
import './finance-history.scss';
import { fetchOfficesByEmail, updateOfficeStatus } from '../../../features/add-office/add-office-slice';
import CustomSelectComponent from '../../ui/select-custom/custom-select-component';
import { getAllTransactionsByManager, deleteTransaction, getAllTransactionsAdmin, deleteOfficeTransaction } from '../../../features/get-manager-users/get-manager-users-slice';
import { deleteOfficeTransactionFromStore } from '../../../features/get-manager-users/get-manager-users-slice';
import { getAllUsers } from '../../../features/get-all-users/get-all-users-slice';
import InputLabel from '../../input/inputLabel/Input-label';
import BalanceRedCircle from '../../../assets/images/balance-red-circle.png';
import BalanceCircle from '../../../assets/images/balance-circle.png';
import ButtonComponent from '../../ui/button/button';
import { addOfficeBalance } from '../../../features/add-office/add-office-slice';
import { AppDispatch } from '../../../app/store';
import { fetchTransactions } from '../../../features/finance/finance-slice';
import Percents from '../../../assets/icons/percents.svg';
import SearchIcon from '../../../assets/icons/search-icon';
import { useLocation } from 'react-router-dom';

export interface FilterableColumn {
  name: keyof DataItem;
  label: string;
}

interface FinanceHistoryProps {
  user?: { email: string };
}

const FinanceHistory: React.FC<FinanceHistoryProps> = ({ user: passedUser }) => {

  const [activeTab, setActiveTab] = useState('adsSystems');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [addOfficeBalanceModal, setaddOfficeBalanceModal] = useState(false);
  const [addPersonalBalanceModal, setAddPersonalBalanceModal] = useState(false);
  const [newOfficeInputChanged, setNewOfficeInputChanged] = useState(false);
  const [officeName, setOfficeName] = useState<string>('');
  const [addSum, setAddSum] = useState<string>('');
  const [statusState, setStatusState] = useState('В обработке');
  const [editInputChanged, setEditInputChanged] = useState(false);
  const [networkTypeState, setNetworkTypeState] = useState<string>('');
  const [officeUser, setOfficeUser] = useState('');
  const [balanceOfficeUser, setBalanceOfficeUser] = useState('');
  const [showOfficeUsers, setShowOfficeUsers] = useState(false);
  const [comment, setComment] = useState('');
  const [bonusModal, setBonusModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  // const [shouldFetch, setShouldFetch] = useState(true);
  // const [data, setData] = useState<DataItem[]>([]);
  // const [shouldRefetch, setShouldRefetch] = useState(false);
  // console.log("🚀 ~ shouldRefetch:", shouldRefetch)

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );
  const finances = useSelector((state: any) => state.finances?.finances);
  // console.log("🚀 ~ finances:", finances)
  const financesByEmail = useSelector((state: any) => state.finances?.financesByEmail);
  // console.log("🚀 ~ financesByEmail:", financesByEmail)
  const transactions = useSelector((state: any) => state.finances?.allTransactions);
  // console.log('transactions', transactions)
  const managerTransactions = useSelector(
    (state: any) => state.managerUsers?.transactions
  );
  console.log("🚀 ~ managerTransactions:", managerTransactions)
  const officesByUser = useSelector(
    (state: any) => state?.addOffice?.officesByUser
  );
  // console.log("🚀 ~ officesByUser:", officesByUser)

  const allUsersStore =
    useSelector((state: any) => state.allUsers.allUsers) || [];
  const usersByManager =
    useSelector((state: any) => state.usersByManager.usersByManager) || [];

  const location = useLocation();
  const currentPath = location.pathname;

  const dispatch = useDispatch<AppDispatch>();

  const handleBonusModalClose = () => {
    setBonusModal(false)
  }

  const handleStatusReview = () => {
    setStatusState('В обработке');
    setEditInputChanged(true);
    setNewOfficeInputChanged(true);
  };

  const handleStatusActive = () => {
    setStatusState('Выполнено');
    setEditInputChanged(true);
    setNewOfficeInputChanged(true);
  };

  const addUserOfficeBalance = (event: any) => {
    event.preventDefault();
    const balanceData = {
      officeId: '66b9def23d68b49afd7cac30',
      accountName: officeName,
      advertisingNetwork: networkTypeState,
      withdrawalAmount: addSum,
      depositAmount: addSum,
      commission: '0%',
      status: statusState,
      userEmail: officeUser,
    }

    if (officeName?.length > 1 && networkTypeState?.length > 1 && addSum?.length > 1) {
      dispatch(addOfficeBalance(balanceData))
    }

    setaddOfficeBalanceModal(false)
  }

  const adduserFinanceFromModal = (event: any) => {
    event.preventDefault();

    const financeData = {
      date: new Date(),
      amount: parseFloat(addSum),
      paymentMethod: networkTypeState,
      bonus: networkTypeState === 'X-Partners' ? 3 : 0,
      status: statusState,
      user: balanceOfficeUser,
      comment: comment,
    }

    if (networkTypeState.length > 1 && addSum.length > 1) {
      dispatch(addFinance(financeData))
    }
    setAddPersonalBalanceModal(false);
  }

  const handleStatusHold = () => {
    setStatusState('Отклонено');
    setEditInputChanged(true);
    setNewOfficeInputChanged(true);
  };

  function formatDate(dateString: string) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
  }

  const idsMap = new Map<number, string>();
  const transactionIdsMap = new Map<number, string>();

  function removeKeysAndFormatDate(finances: any) {
    return finances?.map((item: any, index: number) => {
      const { _id, __v, user, updatedAt, createdAt, officeId, transactionId, userEmail, ...rest } = item;
      idsMap.set(index, _id);
      return {
        _id,
        transactionId,
        ...rest,
        date: formatDate(item.date),
      };
    });
  }

  function removeKeysAndFormatDateForTransactions(finances: any) {
    return finances?.map((item: any, index: number) => {
      const { _id, __v, user, updatedAt, createdAt, officeId, transactionId, userEmail, ...rest } = item;
      transactionIdsMap.set(index, _id);
      return {
        _id,
        transactionId,
        ...rest,
        date: formatDate(item.date),
      };
    });
  }

  function removeKeyForAdminFinances(finances: any) {
    return finances?.map((item: any, index: number) => {
      const { _id, officeId, __v, updatedAt, createdAt, user, financeId, ...rest } = item;

      idsMap.set(index, _id);

      return {
        _id,
        date: formatDate(item.date),
        user: user,
        amount: `${item?.amount?.toLocaleString()} USD`,
        paymentMethod: item.paymentMethod,
        bonus: `${item.bonus}%`,
        comment: item.comment || '-',
        status: item.status,
      };
    });
  }

  function removeKeyForUserFinances(finances: any) {
    return finances?.map((item: any, index: number) => {
      const { _id, officeId, __v, updatedAt, createdAt, user, financeId, ...rest } = item;
      idsMap.set(index, _id);

      return {
        date: formatDate(item.date),
        amount: `${item?.amount?.toLocaleString()} USD`,
        paymentMethod: item.paymentMethod,
        bonus: `${item.bonus}%`,

        status: item.status,
      };
    });
  }

  function removeKeyForUserTransactionsAdmin(finances: any) {
    return finances?.map((item: any, index: number) => {
      const { _id, officeId, __v, updatedAt, createdAt, transactionId, userEmail, ...rest } = item;
      transactionIdsMap.set(index, _id);
      return {
        _id,
        transactionId,
        ...rest,
        date: formatDate(item.date),
      };
    });
  }

  const handleEditModalClose = () => {
    setaddOfficeBalanceModal(false)
  }

  const handleEditPersonalBalanceModalClose = () => {
    setAddPersonalBalanceModal(false)
  }

  const cleanedOfficesByUser = removeKeysAndFormatDate(officesByUser);
  const transactionsForMainAdmin = removeKeyForUserTransactionsAdmin(transactions);
  const cleanedManagerTransactions = removeKeysAndFormatDateForTransactions(managerTransactions);

  const officesByUserCleaned = removeKeyForUserTransactionsAdmin(officesByUser);
  // console.log("🚀 ~ transactionsForMainAdmin:", officesByUserCleaned)

  const dataForServiceTab = user?.role === 'administrator' || user?.role === 'manager'
    ? removeKeyForAdminFinances(finances)
    : removeKeyForUserFinances(finances);

  const financesByEmailFiltered = removeKeyForAdminFinances(financesByEmail);

  useEffect(() => {
    const fetchData = async () => {
      if (!transactions || transactions.length === 0) {
        await dispatch(fetchTransactions());
      }

      if (activeTab === 'service' && user) {
        // @ts-ignore
        await dispatch(fetchAllFinances({ role: user.role, email: user.email }));
      }

      if (passedUser?.email) {
        dispatch(fetchOfficesByEmail(passedUser?.email));
      }

      if (allUsersStore.length < 1) {
        await dispatch(getAllUsers());
      }

      if (user?.role === 'administrator') {
        await dispatch(getAllTransactionsAdmin());
      } else if (user?.role === 'manager') {
        await dispatch(getAllTransactionsByManager(user.email));
      } else if (user?.role === 'user') {
        await dispatch(fetchOfficesByEmail(user.email));
      }
    };
    fetchData();
  }, [dispatch, user.email, user?.role, activeTab]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     if (shouldRefetch) {
  //       try {
  //         // Перезагружаем данные с бэкенда
  //         await dispatch(fetchTransactions());
  //         await dispatch(getAllTransactionsByManager(user.email));
  //       } catch (error) {
  //         console.error("Ошибка при получении данных:", error);
  //       } finally {
  //         // Сбрасываем флаг после обновления данных
  //         setShouldRefetch(false);
  //       }
  //     }
  //   };

  //   fetchData();
  // }, [shouldRefetch, dispatch, user.email]);

  const userColumnsService = {
    id: 'Дата',
    amount: 'Сумма пополнения',
    paymentMethod: 'Способ пополнения',
    bonus: 'Бонус',
    status: currentPath === '/finance-history' ? 'Статус пополнения' : 'Статус',
  };

  const adminColumnsService = {
    id: 'Дата',
    user: 'Пользователь',
    amount: 'Сумма пополнения',
    paymentMethod: 'Способ пополнения',
    bonus: 'Бонус',
    comment: 'Комментарий',
    status: 'Статус',
  };

  const filterableColumnsService: FilterableColumn[] = [
    { name: 'createdAt', label: 'Дата создания' },
    { name: 'paymentMethod', label: 'Способ пополнения' },
    { name: 'status', label: 'Статус пополнения' },
  ];

  const filterableColumnsAdsSystems: FilterableColumn[] = [
    { name: 'createdAt', label: 'Дата создания' },
    { name: 'networkType', label: 'Рекламная сеть' },
    { name: 'status', label: 'Статус пополнения' },
  ];

  const columnsAdsSystems = {
    id: 'ID (Номер операции)',
    date: 'Дата',
    accountName: 'Название кабинета',
    networkType: 'Рекламная сеть',
    sum: 'Сумма списания',
    rechargeAmount: 'Сумма пополнения',
    commission: 'Комиссия',
    status: 'Статус',
  };

  const adsOptions = [
    { value: 'Facebook Ads', label: 'Facebook Ads' },
    { value: 'Google Ads', label: 'Google Ads' },
  ];

  const addBalanceOption = [
    { value: 'Capitalist', label: 'Capitalist' },
    { value: 'USDT', label: 'USDT' },
    { value: 'Баланс вебмастера', label: 'Баланс вебмастера' },
  ];

  const handleInputChange = () => {
    setNewOfficeInputChanged(true);
  };

  const filteredUsersForModal = (user?.role === 'administrator' ? allUsersStore : usersByManager).filter(
    (userItem: any) =>
      user?.role === 'administrator' ||
      !usersByManager.some((u: any) => u.email === userItem.email)
  )
    .sort((a: any, b: any) => a.email.localeCompare(b.email));

  const handleUserClick = (email: any) => {
    setOfficeUser(email);
    setShowOfficeUsers(false);
  }

  const handleBalanceUserClick = (email: any) => {
    setBalanceOfficeUser(email);
    setShowOfficeUsers(false);
  }

  const handleShowTable = () => {
    if (officeUser?.length < 1) {
      setShowOfficeUsers(true)
    }
  }

  // const handleStatusChangeForAdsSystemsTab = async (item: DataItem, newStatus: string) => {
  //   // console.log("🚀 ~ handleStatusChangeForAdsSystemsTab ~ item:", item)
  //   const transactionId = item._id; // Используем _id вместо индекса
  //   console.log("🚀 ~ handleStatusChangeForAdsSystemsTab ~ transactionId:", transactionId)

  //   if (transactionId) {
  //     try {
  //       // Обновляем локальное состояние данных
  //       setData((prevData) => prevData.map((row) =>
  //         row._id === transactionId ? { ...row, status: newStatus } : row
  //       ));

  //       // Выполняем асинхронное обновление статуса на сервере
  //       await dispatch(updateTransactionStatus({ transactionId, newStatus }));
  //       await dispatch(getAllTransactionsByManager(user.email));
  //       await dispatch(fetchTransactions());
  //     } catch (error) {
  //       console.error("Ошибка при обновлении статуса транзакции:", error);
  //     }
  //   } else {
  //     console.error("ID транзакции не найден.");
  //   }
  // };

  // const handleStatusChangeForAdsSystemsTab = async (item: DataItem, newStatus: string) => {
  //   const transactionId = item._id; // Используем _id вместо индекса
  //   console.log("🚀 ~ handleStatusChangeForAdsSystemsTab ~ transactionId:", transactionId);

  //   if (transactionId) {
  //     try {
  //       // Сначала обновляем локальное состояние данных
  //       setData((prevData) =>
  //         prevData.map((row) => (row._id === transactionId ? { ...row, status: newStatus } : row))
  //       );

  //       // Выполняем асинхронное обновление статуса на сервере
  //       // dispatch(updateLocalTransactionStatus({ transactionId, newStatus }));
  //       await dispatch(updateTransactionStatus({ transactionId, newStatus }));

  //       // Обновляем данные после успешного запроса на сервер
  //       const updatedTransactions = await dispatch(getAllTransactionsByManager(user.email)).unwrap();
  //       setData(updatedTransactions); // Обновляем данные на UI после запроса

  //       await dispatch(fetchTransactions());
  //     } catch (error) {
  //       console.error("Ошибка при обновлении статуса транзакции:", error);
  //     }
  //   } else {
  //     console.error("ID транзакции не найден.");
  //   }
  // };

  // const handleStatusChangeForAdsSystemsTab = async (item: DataItem, newStatus: string) => {
  //   console.log("🚀 ~ handleStatusChangeForAdsSystemsTab ~ newStatus:", newStatus)
  //   console.log("🚀 ~ handleStatusChangeForAdsSystemsTab ~ item:", item)
  //   const transactionId = item._id; // Используем _id вместо индекса
  //   if (transactionId) {
  //     try {
  //       // Обновляем локальное состояние данных
  //       setData((prevData) =>
  //         prevData.map((row) => (row._id === transactionId ? { ...row, status: newStatus } : row))
  //       );

  //       // Выполняем асинхронное обновление статуса на сервере
  //       await dispatch(updateTransactionStatus({ transactionId, newStatus }));

  //       // Успешное обновление: устанавливаем флаг для перерисовки
  //       setShouldRefetch(true);
  //     } catch (error) {
  //       console.error("Ошибка при обновлении статуса транзакции:", error);
  //     }
  //   } else {
  //     console.error("ID транзакции не найден.");
  //   }
  // };

  const handleStatusChangeForAdsSystemsTab = async (item: DataItem, newStatus: string) => {
    // console.log("🚀 ~ handleStatusChangeForAdsSystemsTab ~ newStatus:", newStatus)
    console.log("🚀 ~ handleStatusChangeForAdsSystemsTab ~ item:", item)
    const transactionId = item._id; // Используем _id вместо индекса
    // console.log("🚀 ~ handleStatusChangeForAdsSystemsTab ~ transactionId:", transactionId);

    if (transactionId) {
      try {
        // Выполняем асинхронное обновление статуса на сервере
        dispatch(updateOfficeStatus({ id: transactionId, status: newStatus }));
        await dispatch(updateTransactionStatus({ transactionId, newStatus }));

        // Обновляем данные после успешного запроса на сервер
        await dispatch(getAllTransactionsByManager(user.email));
        await dispatch(fetchTransactions());
      } catch (error) {
        console.error("Ошибка при обновлении статуса транзакции:", error);
      }
    } else {
      console.error("ID транзакции не найден.");
    }
  };

  const handleStatusChangeForSecondTab = async (item: DataItem, newStatus: string) => {
    console.log("🚀 ~ handleStatusChangeForSecondTab ~ item:", item)
    const transactionId = item._id; // Используем _id вместо индекса

    if (transactionId) {
      try {

        dispatch(updateLocalFinanceByEmailStatus({ financeId: transactionId, newStatus }));
        dispatch(updateLocalFinanceStatus({ financeId: transactionId, newStatus }));
        await dispatch(updateFinanceTransactionStatus({ transactionId, newStatus }));
        await dispatch(fetchAllFinances({ role: user.role, email: user.email }));


      } catch (error) {
        console.error("Ошибка при обновлении статуса транзакции:", error);
      }
    } else {
      console.error("ID транзакции не найден.");
    }
  };


  const handleDeleteOfficeTransaction = async (index: number) => {
    const transactionId: any = idsMap.get(index);

    if (transactionId) {
      try {
        await dispatch(deleteOfficeTransaction(transactionId)).unwrap();
        dispatch(deleteOfficeTransactionFromStore(transactionId));
      } catch (error) {
        console.error("Ошибка при удалении транзакции:", error);
      }
    }
  };

  const handleDeleteTransaction = async (index: number) => {
    const transactionId: any = idsMap.get(index);
    if (transactionId) {
      try {
        await dispatch(deleteTransaction(transactionId)).unwrap();
        dispatch(deleteTransactionFromStore(transactionId));
      } catch (error) {
        console.error("Ошибка при удалении транзакции:", error);
      }
    }
  };

  useEffect(() => {
    if (user?.role === 'administrator' && allUsersStore.length > 0) {
      setFilteredUsers(allUsersStore);
    } else if (user?.role === 'manager' && allUsersStore.length > 0 && usersByManager.length > 0) {

      setFilteredUsers(usersByManager);
    }
  }, [filteredUsersForModal, allUsersStore, user?.role]);


  const handleInputChangeUser = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setBalanceOfficeUser(inputValue);
    setNewOfficeInputChanged(true);

    if (inputValue.length > 0) {
      const filtered = filteredUsersForModal.filter((user: any) =>
        user.email.toLowerCase().includes(inputValue.toLowerCase())
      );
      setFilteredUsers(filtered);
      setShowOfficeUsers(true);
    } else {
      setFilteredUsers(filteredUsersForModal);
      setShowOfficeUsers(false);
    }
  };

  const handleInputChangeUserOffice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event?.target?.value;
    setOfficeUser(inputValue);
    setNewOfficeInputChanged(true);

    if (inputValue?.length > 0) {
      const filtered = allUsersStore?.filter((user: any) =>
        user.email.toLowerCase().includes(inputValue?.toLowerCase())
      );
      setFilteredUsers(filtered);
      setShowOfficeUsers(true);
    } else {
      setFilteredUsers(filteredUsersForModal);
      setShowOfficeUsers(false);
    }
  };

  // useEffect(() => {
  //   if (transactions) {
  //     setData(transactions);
  //   }
  // }, [transactions]);

  return (
    <>
      {bonusModal &&
        <div className='add-balance__modal-container'>
          <div className='add-balance__modal-container__main-box bonus-modal'>
            <p>Настройка бонуса за пополнение баланса</p>

            <div className='bonus-modal-main-box-container'>
              <div className='add-bonus-to-finance-container'>
                <p className='bonus-finance-title'>Внутренний баер</p>
                <div className='bonus-finance-box'>
                  <p className='bonus-finance-txt'>Capitalist</p>
                  <div className='bonuses-input-box'>
                    <InputLabel
                      className={`register-input add-balance-input add-bonus-input`}
                      labelClass='register-label'
                      label='Процент'
                      type='text'
                      currencyClassName='ads-balance-currency'
                      setValue={setAddSum}
                      value={addSum}
                      requireLabel={false}
                    />
                    <img src={Percents} alt='Percents' className='percent-img-bonus' />
                  </div>
                </div>

                <div className='bonus-finance-box bonus-box-margin'>
                  <p className='bonus-finance-txt'>USDT</p>
                  <div className='bonuses-input-box'>
                    <InputLabel
                      className={`register-input add-balance-input add-bonus-input`}
                      labelClass='register-label'
                      label='Процент'
                      type='text'
                      currencyClassName='ads-balance-currency'
                      setValue={setAddSum}
                      value={addSum}
                      requireLabel={false}
                    />
                    <img src={Percents} alt='Percents' className='percent-img-bonus' />
                  </div>
                </div>
                <div className='bonus-finance-box'>
                  <p className='bonus-finance-txt'>Баланс вебмастера</p>
                  <div className='bonuses-input-box'>
                    <InputLabel
                      className={`register-input add-balance-input add-bonus-input`}
                      labelClass='register-label'
                      label='Процент'
                      type='text'
                      currencyClassName='ads-balance-currency'
                      setValue={setAddSum}
                      value={addSum}
                      requireLabel={false}
                    />
                    <img src={Percents} alt='Percents' className='percent-img-bonus' />
                  </div>
                </div>
              </div>


              <div className='add-bonus-to-finance-container'>
                <p className='bonus-finance-title'>Внешний вебмастер</p>
                <div className='bonus-finance-box'>
                  <p className='bonus-finance-txt'>Capitalist</p>
                  <div className='bonuses-input-box'>
                    <InputLabel
                      className={`register-input add-balance-input add-bonus-input`}
                      labelClass='register-label'
                      label='Процент'
                      type='text'
                      currencyClassName='ads-balance-currency'
                      setValue={setAddSum}
                      value={addSum}
                      requireLabel={false}
                    />
                    <img src={Percents} alt='Percents' className='percent-img-bonus' />
                  </div>
                </div>

                <div className='bonus-finance-box bonus-box-margin'>
                  <p className='bonus-finance-txt'>USDT</p>
                  <div className='bonuses-input-box'>
                    <InputLabel
                      className={`register-input add-balance-input add-bonus-input`}
                      labelClass='register-label'
                      label='Процент'
                      type='text'
                      currencyClassName='ads-balance-currency'
                      setValue={setAddSum}
                      value={addSum}
                      requireLabel={false}
                    />
                    <img src={Percents} alt='Percents' className='percent-img-bonus' />
                  </div>
                </div>
                <div className='bonus-finance-box'>
                  <p className='bonus-finance-txt'>Баланс вебмастера</p>
                  <div className='bonuses-input-box'>
                    <InputLabel
                      className={`register-input add-balance-input add-bonus-input`}
                      labelClass='register-label'
                      label='Процент'
                      type='text'
                      currencyClassName='ads-balance-currency'
                      setValue={setAddSum}
                      value={addSum}
                      requireLabel={false}
                    />
                    <img src={Percents} alt='Percents' className='percent-img-bonus' />
                  </div>
                </div>
              </div>

              <div className='add-bonus-to-finance-container'>
                <p className='bonus-finance-title'>Вебмастер XP</p>
                <div className='bonus-finance-box'>
                  <p className='bonus-finance-txt'>Capitalist</p>
                  <div className='bonuses-input-box'>
                    <InputLabel
                      className={`register-input add-balance-input add-bonus-input`}
                      labelClass='register-label'
                      label='Процент'
                      type='text'
                      currencyClassName='ads-balance-currency'
                      setValue={setAddSum}
                      value={addSum}
                      requireLabel={false}
                    />
                    <img src={Percents} alt='Percents' className='percent-img-bonus' />
                  </div>
                </div>

                <div className='bonus-finance-box bonus-box-margin'>
                  <p className='bonus-finance-txt'>USDT</p>
                  <div className='bonuses-input-box'>
                    <InputLabel
                      className={`register-input add-balance-input add-bonus-input`}
                      labelClass='register-label'
                      label='Процент'
                      type='text'
                      currencyClassName='ads-balance-currency'
                      setValue={setAddSum}
                      value={addSum}
                      requireLabel={false}
                    />
                    <img src={Percents} alt='Percents' className='percent-img-bonus' />
                  </div>
                </div>
                <div className='bonus-finance-box'>
                  <p className='bonus-finance-txt'>Баланс вебмастера</p>
                  <div className='bonuses-input-box'>
                    <InputLabel
                      className={`register-input add-balance-input add-bonus-input`}
                      labelClass='register-label'
                      label='Процент'
                      type='text'
                      currencyClassName='ads-balance-currency'
                      setValue={setAddSum}
                      value={addSum}
                      requireLabel={false}
                    />
                    <img src={Percents} alt='Percents' className='percent-img-bonus' />
                  </div>
                </div>
              </div>

            </div>
            <div className='add-advetising-btns bonus__add-new-bonus'>
              <ButtonComponent
                btnText='Отменить'
                className='add-advetising-btn-cancel add-office-modal-cancel'
                btnOnClick={handleBonusModalClose}
              />

              <ButtonComponent
                btnText='Сохранить'
                className={`add-advetising-btn-create add-office-modal-save ${editInputChanged || officeUser?.length > 0 || networkTypeState.length > 0 || addSum.length > 0 ? 'editInputChanged' : ''
                  }`}
                disabled={editInputChanged === false && officeUser?.length < 1 && networkTypeState.length < 1 && addSum.length < 1}
                btnOnClick={addUserOfficeBalance}
              />
            </div>
          </div>
        </div>
      }
      {addOfficeBalanceModal && (
        <div className='manager-home__modal-container'>
          <form
            className='addOfficeModal create-new-office add-office-balance-modal'>
            <p className='new-office-txt-modal'>Пополнение баланса кабинета</p>
            <div className='finance-user-search-icon'>
              <InputLabel
                className={`register-input add-balance-input`}
                labelClass='register-label'
                label='Пользователь'
                type='text'
                currencyClassName='ads-balance-currency'
                setValue={setOfficeUser}
                value={officeUser}
                requireLabel={false}
                onFocus={() => handleShowTable()}
                ownOnChangeFunction={handleInputChangeUserOffice}
                ownOnChange={true}
              />
              <SearchIcon className='finance-input-icon finance-search-icon' />
            </div>

            {filteredUsers.length > 0 && showOfficeUsers && (
              <div className='modal-header'>
                <div className='finance-table-container'>
                  <table>
                    <tbody>
                      {filteredUsers.map((user: any, index: any) => (
                        <tr key={user.id} className='user-item'>
                          <td className='user-info' onClick={() => handleUserClick(user.email)} >
                            <span className='user-email'>{user.email}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <p>Рекламная сеть</p>
            <CustomSelectComponent
              className='register-select add-office-ads-select'
              selectOptions={adsOptions}
              setMessengerType={(value) => {
                setNetworkTypeState(value);
                handleInputChange();
              }}
              selectWrapper='select-wrapper-class'
              selectedRole={
                networkTypeState === 'Facebook Ads'
                  ? 'Facebook Ads'
                  : networkTypeState === 'Google'
                    ? 'Google Ads'
                    : ''
              }
              defaultEmptyValue='Выберите сеть'
            />
            <InputLabel
              className={`register-input add-balance-input`}
              labelClass='register-label'
              label='Название кабинета'
              type='text'
              currencyClassName='ads-balance-currency'
              setValue={setOfficeName}
              value={officeName}
              requireLabel={false}
            />
            <InputLabel
              className={`register-input add-balance-input`}
              labelClass='register-label'
              label='Сумма пополнения'
              rightText='USD'
              currencyClassName='ads-balance-currency'
              setValue={setAddSum}
              value={addSum}
              requireLabel={false}
            />
            <div className='balance-footer-txt'>
              <p className='available-balance-foot-label'>На кабинет рекламной системы зачислится: {Number(addSum) * 0.93} USD </p>
              <p className='available-balance-foot-label'>Комиссия 7% </p>
            </div>

            <div className='office-add-balance-status-txt'>Статус пополнения</div>
            <div className='offices-box status-balance-office-box'>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'В обработке'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusReview}
                  alt='В обработке'
                />
                <p className='add-office-status-labels'>В обработке</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Выполнено' ? BalanceRedCircle : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusActive}
                  alt='Выполнено'

                />
                <p className='add-office-status-labels'>Выполнено</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Отклонено'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusHold}
                  alt='Отклонено'

                />
                <p className='add-office-status-labels'>Отклонено</p>
              </div>

            </div>
            <div className='add-advetising-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='add-advetising-btn-cancel add-office-modal-cancel'
                btnOnClick={handleEditModalClose}
              />

              <ButtonComponent
                btnText='Сохранить'
                className={`add-advetising-btn-create add-office-modal-save ${editInputChanged || officeUser?.length > 0 || networkTypeState.length > 0 || addSum.length > 0 ? 'editInputChanged' : ''
                  }`}
                disabled={editInputChanged === false && officeUser?.length < 1 && networkTypeState.length < 1 && addSum.length < 1}
                btnOnClick={addUserOfficeBalance}
              />
            </div>
          </form>
        </div>)}

      {addPersonalBalanceModal && (
        <div className='manager-home__modal-container finance-modal-container'>
          <form
            className='addOfficeModal create-new-office add-office-balance-modal  add-personal-balance-box'>
            <p className='new-office-txt-modal'>Пополнение баланса сервиса</p>
            <div className='finance-user-search-icon'>
              <InputLabel
                className={`register-input add-balance-input`}
                labelClass='register-label'
                label='Пользователь'
                type='text'
                currencyClassName='ads-balance-currency'
                setValue={setBalanceOfficeUser}
                value={balanceOfficeUser}
                requireLabel={false}
                onFocus={() => handleShowTable()}
                ownOnChangeFunction={handleInputChangeUser}
                ownOnChange={true}
              />
              <SearchIcon className='finance-input-icon finance-search-icon' />
            </div>
            {filteredUsers.length > 0 && showOfficeUsers && (
              <div className='modal-header'>
                <div className='finance-table-container'>
                  <table>
                    <tbody>
                      {filteredUsers.map((user: any, index: any) => (
                        <tr key={user.id} className='user-item'>
                          <td className='user-info' onClick={() => handleBalanceUserClick(user.email)}>
                            <span className='user-email'>{user.email}</span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <p className='pay-method'>Способ пополнения</p>
            <CustomSelectComponent
              className='register-select add-office-ads-select'
              selectOptions={addBalanceOption}
              setMessengerType={(value) => {
                setNetworkTypeState(value);
                handleInputChange();
              }}
              selectWrapper='select-wrapper-class'
              selectedRole={
                networkTypeState === 'Facebook Ads'
                  ? 'Facebook Ads'
                  : networkTypeState === 'Google'
                    ? 'Google Ads'
                    : ''
              }
            />
            <InputLabel
              className={`register-input add-balance-input`}
              labelClass='register-label add-personal-balance-modal'
              label='Сумма пополнения'
              rightText='USD'
              currencyClassName='ads-balance-currency'
              setValue={setAddSum}
              value={addSum}
              requireLabel={false}
            />
            <div className='balance-footer-txt'>
              <p className='available-balance-foot-label'>На кабинет рекламной системы зачислится: {addSum ? (Number(addSum) * 0.93) : 0} USD </p>
              <p className='available-balance-foot-label'>Комиссия 7% </p>
            </div>

            <p className='office-status-txt'>Статус кабинета</p>
            <div className='offices-box status-balance-office-box'>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'В обработке'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusReview}
                  alt='В обработке'
                />
                <p className='add-office-status-labels'>В обработке</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Выполнено' ? BalanceRedCircle : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusActive}
                  alt='Выполнено'
                />
                <p className='add-office-status-labels'>Выполнено</p>
              </div>
              <div className='office-status__box'>
                <img
                  src={
                    statusState === 'Отклонено'
                      ? BalanceRedCircle
                      : BalanceCircle
                  }
                  width={20}
                  height={20}
                  onClick={handleStatusHold}
                  alt='Отклонено'
                />
                <p className='add-office-status-labels'>Отклонено</p>
              </div>
            </div>
            <label className='finance-comment' htmlFor="comment">Комментарий</label>
            <textarea
              id="comment"
              name="comment"
              value={comment}
              onChange={e => setComment(e.target.value)}
              className="comment-input"
            />
            <div className='add-advetising-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='add-advetising-btn-cancel add-office-modal-cancel'
                btnOnClick={handleEditPersonalBalanceModalClose}
                type="button"
              />

              <ButtonComponent
                btnText='Сохранить'
                className={`add-advetising-btn-create add-office-modal-save ${editInputChanged || officeUser?.length > 0 || networkTypeState?.length > 0 || addSum?.length > 0 ? 'editInputChanged' : ''
                  }`}
                disabled={editInputChanged === false && officeUser?.length < 1 && networkTypeState?.length < 1 && addSum?.length < 1}
                btnOnClick={adduserFinanceFromModal}
              />
            </div>
          </form>
        </div>
      )}

      <div className='networks-box recovery-container recovery-container--justify-start'>
        {!passedUser ? <RoutesList routeClassName='finance-history-route' /> : <div className='finance-history-row'>История</div>}
        <div className='finance-history'>
          {isMobile ? (
            <div className='finance-history__custom-select--margin-bottom'>
              <p className='mobile-finance-txt'>История пополнений</p>
              <CustomSelectComponent
                className='custom-select'
                height='44px'
                setMessengerType={(value) => setActiveTab(value)}
                selectWrapper='select-wrapper'
                selectOptions={[
                  { value: 'adsSystems', label: 'Пополнения рекламных систем' },
                  { value: 'service', label: 'Пополнения сервиса' },
                ]}
                defaultEmptyValue='Пополнения рекламных систем'
              />
            </div>
          ) : (
            <div className='tabs'>
              <button
                className={`tab ${activeTab === 'adsSystems' ? 'active' : ''}`}
                onClick={() => setActiveTab('adsSystems')}
              >
                Пополнения рекламных систем
              </button>
              <button
                className={`tab ${activeTab === 'service' ? 'active' : ''}`}
                onClick={() => setActiveTab('service')}
              >
                Пополнения сервиса
              </button>
            </div>
          )}

          <div className='content'>
            {activeTab === 'adsSystems' ? (
              <CustomTable
                columnHeaders={columnsAdsSystems}
                // data={user?.role === 'administrator' ? transactionsForMainAdmin : user?.role === 'manager' ? cleanedManagerTransactions : cleanedOfficesByUser} // cleanedManagerTransactions managerTransactions
                data={
                  currentPath.includes('/manager-user')
                    ? officesByUserCleaned
                    : user?.role === 'administrator'
                      ? transactionsForMainAdmin
                      : user?.role === 'manager'
                        ? cleanedManagerTransactions
                        : cleanedOfficesByUser
                }
                className={'ads-offices-table'}
                filterableColumns={filterableColumnsAdsSystems}
                tableCalendarClass='finance-history-calendar bg-finance-history-calendar'
                searchBy='accountName'
                activeTab={activeTab}
                setAddOfficeBalance={setaddOfficeBalanceModal}
                setAddPersonalBalanceModal={setAddPersonalBalanceModal}
                showTicketChatIcon={user?.role === 'manager' || user?.role === 'administrator'}
                showDeleteIcon={false} //{user?.role === 'manager' || user?.role === 'administrator'}
                isStatusEditable={user?.role === 'manager' || user?.role === 'administrator'}
                // handleStatusChange={handleStatusChange}
                // handleStatusChange={(item: any, newStatus: any, index: number) => handleStatusChange(item, newStatus, index)}
                handleStatusChange={(item: any, newStatus: any) => handleStatusChangeForAdsSystemsTab(item, newStatus)}
                handleDeleteClick={(index: any) => handleDeleteOfficeTransaction(index)}
                displayBtn={true}
              />
            ) : (
              <CustomTable
                columnHeaders={user?.role === 'administrator' || user?.role === 'manager' ? adminColumnsService : userColumnsService}
                // data={user.role === 'administrator' || user.role === 'manager' && currentPath.includes('manager-user') ? financesByEmailFiltered : dataForServiceTab}
                data={
                  currentPath.includes('/manager-user')
                    ? financesByEmailFiltered
                    : dataForServiceTab
                }
                className={'service-table'}
                filterableColumns={filterableColumnsService}
                tableCalendarClass='finance-history-calendar bg-finance-history-calendar'
                activeTab={activeTab}
                setAddOfficeBalance={setaddOfficeBalanceModal}
                setAddPersonalBalanceModal={setAddPersonalBalanceModal}
                showTicketChatIcon={user?.role === 'manager' || user?.role === 'administrator'}
                showDeleteIcon={false} //{user?.role === 'manager' || user?.role === 'administrator'}
                isStatusEditable={user?.role === 'manager' || user?.role === 'administrator'}
                // handleStatusChange={handleStatusChangeForSecondTab}
                handleStatusChange={(item: any, newStatus: any) => handleStatusChangeForSecondTab(item, newStatus)}
                setBonusModal={setBonusModal}
                handleDeleteClick={(index: any) => handleDeleteTransaction(index)}
                displayBtn={true}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FinanceHistory;
