import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './custom-table.scss';
import TicketChatIcon from '../../../assets/icons/tiket-chat-icon';
import Spinner from '../spinner/spinner';
import SearchIcon from '../../../assets/icons/search-icon';
import CustomSelectComponent from '../select-custom/custom-select-component';
import { Icon } from '@iconify/react';
import MangerIcon from '../../../assets/icons/manager-icon.svg';
import ButtonComponent from '../../ui/button/button';
import cardIcon from '../../../assets/icons/card-icon.svg';
import DatePickerComponent from '../date-picker/date-picker';
import CalendarIcon from '../../../assets/images/calendar-icon.png';
import { format, parse } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '../../../assets/icons/delete-icon';
import CiSettings from '../../../assets/icons/ci_settings.svg';
import { AppDispatch } from '../../../app/store';
import { downloadDocument } from '../../../features/document/document-slice';
import { getColorClassName } from '../../../utils/get-color-classname';
import Tooltip from '../../tooltip/tooltip';
import { getStatusClassName } from '../custom-table-v2/utils/table-utils';

export interface DataItem {
  _id?: string;
  key?: string;
  email?: string | undefined;
  holderEmail?: string | undefined;
  users?: string;
  role?: string;
  status?: string;
  phone?: string;
  messenger?: string;
  number?: number;
  date?: any;
  theme?: any;
  name?: string;
  networkType?: string;
  advertisingNetwork?: string;
  createdAt?: string;
  sum?: string;
  commission?: string;
  accountName?: string;
  landingPage?: string;
  firstTimeRecharge?: string;
  IDXP?: number;
  paymentMethod?: string;
  index?: number;
  docType?: string;
  documentType?: string;
  manager?: string;
  id?: string;
  transactionId?: string;
}

export interface FilterableColumn {
  name: keyof DataItem;
  label: string;
}

interface CustomTableProps {
  columnHeaders: { [key: string]: string };
  data: DataItem[];
  filterableColumns?: FilterableColumn[];
  className?: string;
  searchBy?: any;
  loading?: boolean;
  handleUserClick?: (user: DataItem) => void;
  showTicketChatIcon?: boolean;
  handleDeleteClick?: any;
  showDeleteIcon?: boolean;
  onCellClick?: any;
  addBalanceClick?: any;
  tableCalendarClass?: string;
  activeTab?: string;
  setAddOfficeBalance?: any;
  setAddPersonalBalanceModal?: any;
  isStatusEditable?: boolean;
  handleStatusChange?: (item: DataItem, newStatus: string) => void; 
  setBonusModal?: any;
  lableClassName?: string;
  customSearchLablaleb?: string;
  paginationClassName?: string;
  customLabelClassName?: string;
  customSelectClassName?: string;
  inputWrapperClass?: string;
  handleOpenTicketModal?: (ticket: any) => void;
  displayBtn?: boolean;
  dontshowlable?: boolean;
  firstTableDataClassName?: string;
  notUseUseeffect?: boolean
}

const CustomTable: React.FC<CustomTableProps> = ({
  data,
  columnHeaders,
  filterableColumns,
  className,
  searchBy,
  loading,
  handleUserClick,
  handleDeleteClick,
  showTicketChatIcon,
  showDeleteIcon,
  onCellClick,
  addBalanceClick,
  tableCalendarClass,
  activeTab,
  setAddOfficeBalance,
  setAddPersonalBalanceModal,
  isStatusEditable,
  handleStatusChange,
  setBonusModal,
  lableClassName,
  customSearchLablaleb,
  paginationClassName,
  customLabelClassName,
  customSelectClassName,
  inputWrapperClass,
  handleOpenTicketModal,
  displayBtn,
  dontshowlable,
  firstTableDataClassName,
}) => {

  const location = useLocation();
  const pathname = location?.pathname;

  const dispatch = useDispatch<AppDispatch>();

  const calendarRef = useRef<HTMLDivElement>(null);

  const [filters, setFilters] = useState<{ [key: string]: string }>({});
  const [search, setSearch] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilters, setShowFilters] = useState(true);
  const [startDate, setStartDate] = useState(() => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth() - 1, 1);
  });
  const [endDate, setEndDate] = useState(new Date());
  const [showCalendar, setShowCalendar] = useState(false);
  const [documentType, setDocumentType] = useState('');
  const [updatedRows, setUpdatedRows] = useState<DataItem[]>([]);

  useEffect(() => {
    setUpdatedRows(data);
  }, [data]);

  const handleStatusChangeInternal = (item: DataItem, newStatus: string) => {
    setUpdatedRows((prevRows) => {
      const newRows = prevRows.map((row) => {
        if (row._id === item._id) {
          return { ...row, status: newStatus };
        }
        return row;
      });
      return newRows;
    });

    if (handleStatusChange) {
      handleStatusChange(item, newStatus);
    }
  };


  const user = useSelector(
    (state: any) => {
      return state.login?.user?.user || state.login?.user
    }
  );

  const handleOpenBodnusModal = () => {
    setBonusModal(true)
  }

  const getButtonText = () => {
    return activeTab === 'service' ? 'Пополнить баланс' : 'Пополнить кабинет';
  };

  const getBtnClick = () => {
    return activeTab === 'service' ? setAddPersonalBalanceModal(true) : openOfficeModal();
  }

  const openOfficeModal = () => {
    setAddOfficeBalance(true);
  }

  const handleCalendar = () => {
    setShowCalendar(!showCalendar);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      calendarRef.current &&
      !calendarRef.current.contains(event.target as Node)
    ) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getSearchName = () => {
    switch (searchBy) {
      case 'accountName':
        return 'Название кабинета';
      case 'email':
        if (pathname === '/managers') {
          return 'Менеджер';
        } else if (
          pathname === '/manager-users' ||
          pathname === '/manager-home' ||
          pathname === '/documents'
        ) {
          return 'Пользователь';
        }
        break;
      case 'none':
        break;
      default:
        return 'Поиск';
    }
  };

  const searchName = getSearchName();

  const itemsPerPage = 10;

  const docTypeOptions = [
    { value: 'Все', label: 'Все' },
    { value: 'closing_document', label: 'Закрывающий документ' },
    { value: 'Инвойс', label: 'Инвойс' },
    { value: 'contract', label: 'Договор' },
  ]

  const statusOptions = (() => {

    if (pathname.includes('/manager-user/')) {
      return [
        { value: 'Все', label: 'Все' },
        { value: 'В обработке', label: 'В обработке' },
        { value: 'Выполнено', label: 'Выполнено' },
        { value: 'Отклонено', label: 'Отклонено' },
      ];
    }

    switch (pathname) {
      case '/managers':
        return [
          { value: 'Все', label: 'Все' },
          { value: 'Активен', label: 'Активен' },
          { value: 'Неактивен', label: 'Неактивен' },
        ];

      case '/finance-history':
        return [
          { value: 'Все', label: 'Все' },
          { value: 'В обработке', label: 'В обработке' },
          { value: 'Выполнено', label: 'Выполнено' },
          { value: 'Отклонено', label: 'Отклонено' },
        ];

      case '/manager-users':
        return [
          { value: 'Все', label: 'Все' },
          { value: 'Новый', label: 'новый' },
          { value: 'Неактивный', label: 'неактивный' },
          { value: 'горячий', label: 'горячий' },
          { value: 'клиент', label: 'клиент' },
          { value: 'спам', label: 'спам' },
        ];

        case '/manager-home':
          return [
            { value: 'Все', label: 'Все' },
            { value: 'На рассмотрении', label: 'На рассмотрении' },
            { value: 'Активен', label: 'Активен' },
            { value: 'Приостановлен', label: 'Приостановлен' },
            { value: 'Отключён', label: 'Отключён' },
          ];

      default:
        return [
          { value: 'Все', label: 'Все' },
          { value: 'На рассмотрении', label: 'На рассмотрении' },
          { value: 'Активен', label: 'Активен' },
          { value: 'Приостановлен', label: 'Приостановлен' },
          { value: 'Отлючен', label: 'Отлючен' },
        ];
    }
  })();

  const statusManager = [
    { value: 'В обработке', label: 'В обработке' },
    { value: 'Выполнено', label: 'Выполнено' },
    { value: 'Отклонено', label: 'Отклонено' },
  ]

  const handleFilterChange = (column: string, value: string) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [column]: value === 'Все' ? '' : value,
    }));
    if (column === 'docType') {
      setDocumentType(value);
    }
    setCurrentPage(1);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    setCurrentPage(1);
  };

  const handleDownloadClick = (documentId: string) => {
    dispatch(downloadDocument(documentId));
  };

  const getUniqueValues = (columnName: keyof DataItem) => {
    const uniqueValues: { [key: string]: boolean } = {};
    data?.forEach((item) => {
      if (item[columnName] !== undefined) {
        uniqueValues[item[columnName] as string] = true;
      }
    });
    return Object.keys(uniqueValues);
  };

  const filteredData = filterableColumns
    ? updatedRows?.filter((item) => {
      const passesFilters = filterableColumns.every((column) => {
        const filterValue = filters[column.name] || 'Все';
        const itemValue = item[column.name] || item['advertisingNetwork'];

        return filterValue === 'Все' || itemValue === filterValue;
      });

      const passesDocTypeFilter = documentType
        ? item.docType && item.docType.toLowerCase() === documentType.toLowerCase()
        : true;

      const itemDate = item.date
        ? parse(item.date, 'dd.MM.yyyy', new Date())
        : null;


      const itemDates = item.createdAt
        ? parse(item.createdAt, 'dd.MM.yyyy', new Date())
        : null;


      const passesDateFilter = itemDates
        ? itemDates >= startDate && itemDates <= endDate
        : itemDate ? itemDate >= startDate && itemDate <= endDate : true;

      return passesFilters && passesDateFilter && passesDocTypeFilter;
    })
    : updatedRows;

  // const filteredData = filterableColumns
  //   ? updatedRows?.filter((item) => {
  //     console.log("Фильтруем данные:", filters);
  //     if (location.pathname === '/manager-user') {
  //       // Логика фильтрации только для страницы /manager-user/
  //       const passesFilters = filterableColumns.every((column) => {
  //         const filterValue = filters[column.name] || 'Все';
  //         const itemValue = item[column.name] || item['advertisingNetwork'];

  //         return filterValue === 'Все' || itemValue === filterValue;
  //       });
  //       return passesFilters;
  //     } else if (location.pathname === '/finance-history') {
  //       // Логика фильтрации только для страницы /finance-history/
  //       const passesFilters = filterableColumns.every((column) => {
  //         const filterValue = filters[column.name] || 'Все';
  //         const itemValue = item[column.name];

  //         return filterValue === 'Все' || itemValue === filterValue;
  //       });
  //       return passesFilters;
  //     }
  //     return true;
  //   })
  //   : updatedRows;

  const searchedData = searchBy
    ? filteredData?.filter((item) => {
      const searchByItem = customSearchLablaleb ? customSearchLablaleb : searchBy
      // @ts-ignore
      const searchValue = item[searchByItem];
      return searchValue
        ? searchValue.toString().toLowerCase().includes(search.toLowerCase())
        : true;
    })
    : filteredData;

  const paginatedData: DataItem[] = searchedData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const totalPages = Math.ceil(searchedData?.length / itemsPerPage);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const renderPagination = () => {
    const paginationButtons = [];

    paginationButtons.push(
      <button
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      >
        &laquo;
      </button>
    );

    paginationButtons.push(
      <button
        key="prev"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        &lsaquo;
      </button>
    );

    if (currentPage <= 3) {
      paginationButtons.push(
        <button
          key={1}
          className={`page-button ${currentPage === 1 ? 'active' : ''}`}
          onClick={() => handlePageChange(1)}
          disabled={currentPage === 1}
        >
          1
        </button>
      );
    }

    if (currentPage > 3) {
      paginationButtons.push(<span key="ellipsis-start">...</span>);
    }

    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    for (let page = startPage; page <= endPage; page++) {
      paginationButtons.push(
        <button
          key={page}
          className={`page-button ${page === currentPage ? 'active' : ''}`}
          onClick={() => handlePageChange(page)}
          disabled={page === currentPage}
        >
          {page}
        </button>
      );
    }

    // Показываем троеточие, если между текущей группой страниц и последней страницей есть разрыв
    if (currentPage < totalPages - 2) {
      paginationButtons.push(<span key="ellipsis-end">...</span>);
    }

    // Показываем последнюю страницу, если текущая страница находится на расстоянии двух шагов от последней
    if (currentPage >= totalPages - 2) {
      paginationButtons.push(
        <button
          key={totalPages}
          className={`page-button ${currentPage === totalPages ? 'active' : ''}`}
          onClick={() => handlePageChange(totalPages)}
          disabled={currentPage === totalPages}  // Некликабельная последняя страница, если активна
        >
          {totalPages}
        </button>
      );
    }

    // Кнопка перехода на следующую страницу (>)
    paginationButtons.push(
      <button
        key="next"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}  // Отключаем на последней странице
      >
        &rsaquo;
      </button>
    );

    // Кнопка перехода на последнюю страницу (>>)
    paginationButtons.push(
      <button
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}  // Отключаем на последней странице
      >
        &raquo;
      </button>
    );

    return paginationButtons;
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <div className={`table-container ${className || ''}`}>
      <div
        className={`table-container__filters ${showFilters ? '' : 'hidden'}`}
      >
        <div className={`table-container__filters ${searchBy === 'none' ? 'not-show-searchby' : ''}`}>
          {(searchBy) && (
            <div className={pathname === '/advertising-systems-office' ? 'table-container__full-width-search-container' : 'table-container__search-container'}>
              {dontshowlable !== false && <div className={`custom-table__label ${customLabelClassName ? customLabelClassName : ''}`}>{searchName}</div>}
              {searchBy !== 'none' && <div className={`input-icon-wrapper ${dontshowlable === false ? 'dont-show-table-input' : ''} ${inputWrapperClass ? inputWrapperClass : ''}`}>
                <input
                  className={pathname === '/advertising-systems-office' ? 'filter-container__input filter-container__input-left' : 'filter-container__input'}
                  type='text'
                  placeholder={`Найти`}
                  value={search}
                  onChange={handleSearchChange}
                />
                {<SearchIcon className={pathname === '/advertising-systems-office' ? 'input-icon-left' : 'input-icon'} />}
              </div>}
            </div>
          )}

          {filterableColumns && (
            <div className='filter-container'>
              {filterableColumns?.map((column: any) => (
                <div key={column.name} className='filter-group'>
                  <div className={`custom-table__label ${lableClassName ? lableClassName : ''}`}>{column.label}</div>
                  {column.name === 'networkType' ? (
                    <CustomSelectComponent
                      className='custom-select'
                      height='44px'
                      selectOptions={[
                        { value: 'Все', label: 'Все' },
                        { value: 'Facebook Ads', label: 'Facebook Ads' },
                        { value: 'Google Ads', label: 'Google Ads' },
                      ]}
                      setMessengerType={(value: any) =>
                        handleFilterChange(column.name, value)
                      }
                      selectWrapper='select-wrapper'
                      selectedRole={filters[column.name] || 'Все'}
                      defaultEmptyValue='Все'
                    />
                  ) :
                    column.name === 'documentType' ? (
                      <CustomSelectComponent
                        className={`manager-select-wrapper document-select ${customSelectClassName ? customSelectClassName : ''}`}
                        selectOptions={docTypeOptions}
                        // setMessengerType={setDocumentType}
                        setMessengerType={(value: string) => handleFilterChange('documentType', value)}
                        // selectedRole={documentType}
                        // selectedRole={filters[column.name] || 'Все'}
                        selectWrapper='manager-select-wrapper'
                        defaultEmptyValue='Все'
                      />
                    ) : column.name === 'status' ? (
                      <CustomSelectComponent
                        className='custom-select'
                        height='44px'
                        selectOptions={statusOptions}
                        setMessengerType={(value: any) =>
                          handleFilterChange(column.name, value)
                        }
                        selectWrapper='select-wrapper'
                        selectedRole={filters[column.name] || 'Все'}
                        defaultEmptyValue='Все'
                      />
                    ) : column.name === 'createdAt' ? (
                      <>
                        <div onClick={handleCalendar}
                          className={`select-box ${tableCalendarClass ? tableCalendarClass : ''
                            }`}
                        >
                          {startDate
                            ? format(startDate, 'dd.MM.yyyy')
                            : 'Invalid date'}{' '}
                          -{' '}
                          {endDate
                            ? format(endDate, 'dd.MM.yyyy')
                            : startDate
                              ? format(startDate, 'dd.MM.yyyy') // Если только одна дата выбрана, показываем её и как конец
                              : 'Invalid date'}
                          <img
                            src={CalendarIcon}

                            alt='Calendar Icon'
                            className='calendar-icon'
                          />
                        </div>
                        {showCalendar && (
                          <div ref={calendarRef}>
                            <DatePickerComponent
                              startDate={startDate}
                              setStartDate={setStartDate}
                              endDate={endDate}
                              setEndDate={setEndDate}
                              onClose={() => setShowCalendar(false)}
                            />
                          </div>
                        )}
                      </>
                    ) : (
                      <CustomSelectComponent
                        className='custom-select'
                        height='44px'
                        selectOptions={[
                          { value: 'Все', label: 'Все' },
                          ...getUniqueValues(column.name).map((value) => ({
                            value,
                            label: value,
                          })),
                        ]}
                        setMessengerType={(value: any) =>
                          handleFilterChange(column.name, value)
                        }
                        selectWrapper='select-wrapper'
                        selectedRole={filters[column.name] || 'Все'}
                        defaultEmptyValue='Все'
                      />
                    )}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
      {filterableColumns && filterableColumns.length > 0 && (
        <div className='hide-filters-container'>
          <button
            className='hide-filters-button'
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? 'Скрыть фильтр' : 'Показать фильтр'}
          </button>
          {showFilters ? (
            <Icon
              className='manager-user__close-icon'
              icon='iconamoon:arrow-up-2-bold'
              width='24px'
              color='#8F97A9'
              onClick={() => setShowFilters(!showFilters)}
            />
          ) : (
            <Icon
              className='manager-user__close-icon'
              icon='iconamoon:arrow-down-2'
              width='24px'
              color='#8F97A9'
              onClick={() => setShowFilters(!showFilters)}
            />
          )}
        </div>
      )}
      {(user?.role === 'administrator' || user?.role === 'manager') && pathname === '/finance-history' &&
        <div className={`table-add-balance-btn-box ${activeTab === 'service' ? 'add-balance-bonus' : ''}`}>
          {activeTab === 'service' &&
            <div className='add-balance-bonus-box'>
              <img src={CiSettings} alt='CiSettings' onClick={handleOpenBodnusModal} className='CiSettings' />
              <p>Настроить бонус за пополнение баланса</p>
            </div>}
          <ButtonComponent
            btnText={getButtonText()}
            btnIconClassName='register-header-btn-icon'
            className='table-add-balance-modal-btn'
            btnOnClick={() => getBtnClick()}
            disabled={activeTab === 'adsSystems'}
          />
        </div>
      }
      {displayBtn && location.pathname !== '/finance-history' &&
        <div className='display-finance-btn'>
          <ButtonComponent
            btnText={getButtonText()}
            btnIconClassName='register-header-btn-icon'
            className='table-add-balance-modal-btn'
            btnOnClick={() => getBtnClick()}
            disabled={activeTab === 'adsSystems'}
          />
        </div>
      }

      <div className='table-wrapper'>
        <table>
          <thead>
            <tr key='header-row'>
              {Object.keys(columnHeaders)?.map((key) => (
                <th className={key === 'action' ? 'table-action-head' : ''} key={key}>{columnHeaders[key]}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {paginatedData?.length > 0 &&
              paginatedData.map((item, index) => (
                <tr key={item._id || index}
                  onClick={() => {
                    if (item.status !== 'Закрыт') {
                      handleOpenTicketModal && handleOpenTicketModal(item);
                    }
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  {Object.entries(item).map(([key, value], fieldIndex) => {
                    if (key !== 'key' && key !== 'actions' && key !== '_id') {
                      return (
                        <td
                          // key={key}
                          key={`${item._id}-${key}`}
                          onClick={() => {
                            if (key === 'email' && handleUserClick) {
                              handleUserClick(item);
                            } else if (onCellClick) {
                              onCellClick(item);
                            }
                          }}
                          className={
                            key === 'email'
                              ? 'email-cell'
                              : key === 'status' && value === 'Закрыт'  // Добавляем проверку для закрытого статуса
                                ? 'status status-closed'  // Класс для закрытого тикета
                                : key === 'status' && value
                                  ? `status ${getStatusClassName(value.toString().toLowerCase())}`  // Применение других стилей для статуса
                                  : firstTableDataClassName && fieldIndex === 0 ? firstTableDataClassName : undefined
                          }
                          style={{ height: '100%', cursor: key === 'theme' ? 'pointer' : 'default', }}
                        >
                          {key === 'documentName' ? (
                            <span
                              onClick={() => handleDownloadClick(String(item?._id))}
                              className="download-link"
                              style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                            >
                              {value}
                            </span>
                          ) : key === 'email' ? (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <img
                                src={MangerIcon}
                                style={{ marginRight: '8px' }}
                                alt='manager-icon'
                              />
                              {value}
                            </div>
                          ) : key === 'status' ? (
                            isStatusEditable ? (
                              <div className="select-wrapper">
                                <select
                                  className='status-select-transparent'
                                  value={value.toString()}
                                  onChange={(e) => handleStatusChangeInternal(item, e.target.value)}
                                >
                                  {statusManager?.map((option) => (
                                    <option
                                      key={option.value}
                                      value={option.value}
                                      data-color={getColorClassName(option.value)}
                                    >
                                      {option.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            ) : (
                              <div>{value}</div>
                            )
                          ) : key === 'users' ? (
                            <span className='custom-table__user-cell'>
                              <Icon
                                icon='fluent:person-24-filled'
                                width='19px'
                                color='#8F97A9'
                              />
                              <div>{value}</div>
                            </span>
                          ) : (
                            typeof value === 'string' && value.length > 30
                              ? `${value.slice(0, 30)}...`
                              : value
                          )}
                        </td>
                      );
                    } else if (key === 'actions') {
                      if (location.pathname === '/advertising-systems-office') {
                        return (
                          <td key={key}>
                            <ButtonComponent
                              btnText={value}
                              className='table-container__put-money-button'
                              btnOnClick={() => addBalanceClick(item)}
                              disabled={item.status !== 'Активен'}
                              withIcon={true}
                              icon={cardIcon}
                              btnIconClassName='register-header-btn-icon'
                            />
                          </td>
                        );
                      } else if (location.pathname === '/tickets') {
                        return <td key={key}>{value}</td>;
                      }
                    }
                    return null;
                  })}

                  {showTicketChatIcon && (
                    <td key={`${item.key}-chat`} onClick={() => handleOpenTicketModal}>
                      <span className='icon' >
                        <Tooltip content="Ответить">
                          <TicketChatIcon />
                        </Tooltip>
                      </span>
                    </td>
                  )}
                  {showDeleteIcon && (
                    <td key={`${item.key}-delete`}>
                      <span className='icon' onClick={() => {
                        const id = location.pathname.includes('/documents') ? item._id : index;
                        handleDeleteClick(id);
                      }}>
                        <DeleteIcon />
                      </span>
                    </td>
                  )}
                </tr>
              ))}
          </tbody>
        </table>

      </div>
      {paginatedData?.length > 0 ? (
        totalPages > 1 && <div className={`pagination ${paginationClassName ? paginationClassName : ''}`}>{renderPagination()}</div>
      ) : (
        <div className='no-results-row'>
          <div className='no-results'>Нет данных</div>
        </div>
      )}
    </div>
  );
};

export default CustomTable;
