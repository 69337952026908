import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserType } from '../../../features/update-user-type/update-user-type-slice';
import BalanceRedCircle from '../../../assets/images/balance-red-circle.png';
import BalanceCircle from '../../../assets/images/balance-circle.png';
import './user-type-section.scss';

const UserTypeSection: React.FC<any> = ({ currentEmail, setUserTypeSection, setUserTypeStatus, setChangesSaved }: any) => {
  const dispatch = useDispatch();
  const allUsers = useSelector((state: any) => state.allUsers.allUsers) || [];

  const getUserByEmail = (email: string) => {
    return allUsers?.find((user: any) => user.email === email);
  };

  const currentUser = getUserByEmail(currentEmail);
  const [activeStatus, setActiveStatus] = useState<string>(
    currentUser?.userType || 'вебмастер XP'
  );

  useEffect(() => {
    setActiveStatus(currentUser?.userType || 'вебмастер XP');
  }, [currentUser]);

  const handleXpStatus = () => {
    setUserTypeSection(true)
    setActiveStatus('вебмастер XP');
    setUserTypeStatus('вебмастер XP');
    setChangesSaved(false);
  };

  const handleInnerBuyer = () => {
    setUserTypeSection(true)
    setActiveStatus('внутренний баер');
    setUserTypeStatus('внутренний баер');
    setChangesSaved(false);
  };

  const handleOuterBuyer = () => {
    setUserTypeSection(true)
    setActiveStatus('внешний вебмастер');
    setUserTypeStatus('внешний вебмастер');
    setChangesSaved(false);
  };

  return (
    <div className='contact-info__section'>
      <p className='user-type-section__title'>Тип пользователя</p>
      <div className='user-type'>
        <div className='user-type__option'>
          <img
            src={
              activeStatus === 'вебмастер XP' ? BalanceRedCircle : BalanceCircle
            }
            width={20}
            height={20}
            onClick={handleXpStatus}
            className='pointer'
          />
          <div className='label' onClick={handleXpStatus}>вебмастер XP</div>
        </div>
        <div className='user-type__option option__bayer'>
          <img
            src={
              activeStatus === 'внутренний баер'
                ? BalanceRedCircle
                : BalanceCircle
            }
            width={20}
            height={20}
            className='pointer'
            onClick={handleInnerBuyer}
          />
          <div className='label' onClick={handleInnerBuyer}>внутренний баер</div>
        </div>
        <div className='user-type__option option__web-master'>
          <img
            src={
              activeStatus === 'внешний вебмастер'
                ? BalanceRedCircle
                : BalanceCircle
            }
            width={20}
            height={20}
            className='pointer'
            onClick={handleOuterBuyer}
          />
          <div className='label pointer' onClick={handleOuterBuyer}>внешний вебмастер</div>
        </div>
      </div>
    </div>
  );
};

export default UserTypeSection;
