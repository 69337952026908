import React from 'react'

const BurgerMenuIcon = () => {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3 6.5H21M3 12.5H21M3 18.5H21" stroke="#6C6EA0" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default BurgerMenuIcon