import React, { useEffect, useState } from 'react';
import './user-list.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersByManager } from '../../../features/get-users-by-manager/get-users-by-manager-slice';
import ButtonComponent from '../button/button';
import MiDelete from '../../../assets/images/mi_delete.svg';
import JamPlus from '../../../assets/images/jam_plus.svg';
import { getAllUsers } from '../../../features/get-all-users/get-all-users-slice';
import Spinner from '../spinner/spinner';
import UserIcon from '../../../assets/images/user-icon.svg';
import CheckedIcon from '../../../assets/icons/checked.svg';
import CustomSelectComponent, {
  Option,
} from '../select-custom/custom-select-component';
import DeleteUserInitial from '../../../assets/images/mi_deleted.svg';
import { AppDispatch } from '../../../app/store';
import { useLocation } from 'react-router-dom';

const CustomCheckbox = ({ checked, onChange }: any) => (
  <label className='checkbox-container'>
    <input type='checkbox' checked={checked} onChange={onChange} />
    <span className='checkmark'>
      {checked && (
        <img src={CheckedIcon} className='custom-check-icon' alt='checked' />
      )}
    </span>
  </label>
);

const UserList: React.FC<any> = ({
  userEmail,
  setPendingDelete,
  pendingDelete,
  setAddUserState,
  setUsersToadd,
  usersToadd,
}: any) => {
  const [selectedUsers, setSelectedUsers] = useState<boolean[]>([]);
  const [createNewOffice, setCreateNewOffice] = useState(false);
  const [selectedModalUsers, setSelectedModalUsers] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null);
  const [selectedAdOption, setSelectedAdOption] = useState<Option | null>(null);
  const [selectedUserStatus, setSelectedUserStatus] = useState<Option | null>(
    null
  );

  const handleAdOptionChange = (option: Option) => {
    setSelectedAdOption(option);
  };

  const handleUserStatusChange = (option: Option) => {
    setSelectedUserStatus(option);
  };

  const usersByManager =
    useSelector((state: any) => state.usersByManager.usersByManager);

  const [uiDeletedUsers, setUiDeletedUsers] = useState<any>(usersByManager);


  useEffect(() => {
    setUiDeletedUsers(usersByManager);
  }, []);

  useEffect(() => {
    if (uiDeletedUsers.length > 0) {
      console.log('State updated, dispatching action...');
      dispatch(getUsersByManager(userEmail));
    }
  }, [uiDeletedUsers]);

  useEffect(() => {
    if (usersByManager.length > 0) {
      setUiDeletedUsers(usersByManager);
      localStorage.setItem('uiDeletedUsers', JSON.stringify(usersByManager));
    }
  }, []);

  const handleCloseModal = () => {
    setCreateNewOffice(false);
    setSelectedStatus(null);
    setSelectedUsers([]);
    setSelectedModalUsers([]);
    setAllSelected(false);
  };

  const adsOptions = [
    { value: 'вебмастер XP', label: 'Вебмастер XP' },
    { value: 'внутренний баер', label: 'Внутренний баер' },
    { value: 'внешний вебмастер', label: 'Внешний вебмастер' },
  ];

  const userStatusModal = [
    { value: 'active', label: 'новый' },
    { value: 'inactive', label: 'неактивный' },
    { value: 'active-spam', label: 'спам' },
    { value: 'active-hot', label: 'горячий' },
    { value: 'active-client', label: 'клиент' },
  ];

  const dispatch = useDispatch<AppDispatch>();
  const allUsers = useSelector((state: any) => state.allUsers.allUsers) || [];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await dispatch(getUsersByManager(userEmail));

      if (allUsers?.length < 1) {
        await dispatch(getAllUsers());
      }

      setLoading(false);
    };

    fetchData();
  }, [dispatch, userEmail, reload]);

  const allUsersStore =
    useSelector((state: any) => state.allUsers.allUsers) || [];

  useEffect(() => {
    setSelectedUsers(new Array(usersByManager.length).fill(false));
  }, [usersByManager]);

  const handleCheckboxChange = (index: number) => {
    setSelectedUsers((prevSelectedUsers) => {
      const newSelectedUsers = [...prevSelectedUsers];
      newSelectedUsers[index] = !newSelectedUsers[index];
      return newSelectedUsers;
    });
  };

  const hasTrueValue = selectedUsers?.some((value) => value === true);

  const handleAllUsersCheckboxChange = (email: string) => {
    setSelectedModalUsers((prevSelected) => {
      if (prevSelected.includes(email)) {
        return prevSelected.filter((e) => e !== email);
      } else {
        return [...prevSelected, email];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectedUsers(new Array(usersByManager.length).fill(true));
    if (usersToadd.length > 0) {
      setSelectedUsers(new Array(usersToadd.length).fill(true));
    }
  };

  const handleAssignUsers = async () => {
    setAddUserState(true);
    setLoading(true);
    const userEmails = selectedModalUsers.map((email) => email);

    setUsersToadd((prevUsers: any) => [...prevUsers, ...userEmails]);

    // @ts-ignore
    await dispatch(getUsersByManager(userEmail));
    setUiDeletedUsers(usersByManager);
    localStorage.setItem('uiDeletedUsers', JSON.stringify(usersByManager));

    setLoading(false);
    handleCloseModal();
    setReload(!reload);
  };

  const handleDeleteSelected = async () => {
    setLoading(true);
    let usersToDelete = uiDeletedUsers
      .filter((_: any, index: number) => selectedUsers[index])
      .map((user: any) => user.email);

    const updatedUsersByManager = uiDeletedUsers?.filter(
      (user: any) => !usersToDelete.includes(user.email)
    );

    setUiDeletedUsers(updatedUsersByManager)

    console.log('updatedUsersByManager', updatedUsersByManager)

    let usersToaddDelete: any;
    usersToaddDelete = usersToadd
      ?.filter(
        (_: any, index: number) => selectedUsers[index + uiDeletedUsers?.length]
      )
      .map((user: any) => user);

    const updatedUsersToadd = usersToadd.filter(
      (user: any) => !usersToaddDelete.includes(user)
    );

    setUsersToadd(updatedUsersToadd);

    setUiDeletedUsers(updatedUsersByManager);
    localStorage.setItem(
      'uiDeletedUsers',
      JSON.stringify(updatedUsersByManager)
    );
    setPendingDelete((prevPendingDelete: any) =>
      prevPendingDelete.concat(usersToDelete)
    );

    setSelectedUsers(new Array(uiDeletedUsers?.length).fill(false));

    setLoading(false);
    setReload(!reload);
  };

  const handleAddUser = () => {
    setCreateNewOffice(true);
  };

  const handleStatusChange = (selectedOption: any) => {
    setSelectedStatus(selectedOption ? selectedOption : null);
  };

  const handleToggleSelectAllModalUsers = () => {
    if (allSelected) {
      setSelectedModalUsers([]);
    } else {
      setSelectedModalUsers(
        filteredUsersForModal.map((user: any) => user.email)
      );
    }
    setAllSelected(!allSelected);
  };

  const filteredUsers = allUsersStore.filter((user: any) => {
    const matchesStatus = !selectedStatus || user.status === selectedStatus;
    const matchesAdOption =
      !selectedAdOption || user.userType === selectedAdOption.value;
    return matchesStatus && matchesAdOption;
  });

  const filteredUsersForModal = filteredUsers.filter(
    (user: any) =>
      !usersByManager?.some((u: any) => u.email === user.email) &&
      !usersToadd?.some((email: string) => email === user.email)
  )
    .sort((a: any, b: any) => a.email.localeCompare(b.email));

  return (
    <div className='user-list'>
      {loading && <Spinner />}
      {createNewOffice && (
        <div className='modal-container'>
          <div className='user-list__modal'>
            <div className='user-list__title'>Добавление пользователей</div>

            <div className='user-list__input-title'>Тип пользователя</div>
            <CustomSelectComponent
              className='custom-select'
              selectOptions={adsOptions}
              setMessengerType={() => { }}
              selectWrapper='select-wrapper'
              selectedRole={null}
              defaultEmptyValue='Выберите тип пользователя'
              onChange={handleAdOptionChange}
            />

            <div className='user-list__input-title'>Статус</div>
            <CustomSelectComponent
              className='custom-select'
              selectOptions={userStatusModal}
              setMessengerType={handleStatusChange}
              selectWrapper='select-wrapper'
              selectedRole={null}
              defaultEmptyValue='Выберите статус'
              onChange={handleUserStatusChange}
            />
            {filteredUsersForModal.length > 0 &&
              (selectedStatus || selectedAdOption) && (
                <div className='modal-header'>
                  <div className='user-list__input-title'>Пользователи</div>
                  <div>
                    <button
                      className='select-all-btn'
                      onClick={handleToggleSelectAllModalUsers}
                    >
                      {filteredUsersForModal.length > 0
                        ? allSelected
                          ? 'Снять выделение'
                          : 'Выбрать всех'
                        : ''}
                    </button>
                  </div>
                </div>
              )}

            {filteredUsersForModal?.length > 0 &&
              (selectedStatus || selectedAdOption) ? (
              <div className='table-container'>
                <table>
                  <tbody>
                    {filteredUsersForModal.map((user: any, index: any) => (
                      <tr key={user.id} className='user-item'>
                        <td className='user-info'>
                          <div className='avatar'>
                            <img src={UserIcon} />
                          </div>
                          <span className='user-email'>{user.email}</span>
                        </td>
                        <td className='action-btn'>
                          <CustomCheckbox
                            checked={selectedModalUsers.includes(user.email)}
                            onChange={() =>
                              handleAllUsersCheckboxChange(user.email)
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className='no-users-found-modal'></p>
            )}

            <div className='contact-info__modal-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='contact-info__cancel-btn button--red-border'
                btnOnClick={handleCloseModal}
              />
              <ButtonComponent
                btnText='Добавить'
                className={`contact-info__add-btn ${selectedModalUsers.length > 0 ? 'default-btn-color' : ''
                  }`}
                btnOnClick={handleAssignUsers}
                disabled={selectedModalUsers.length > 0 ? false : true}
              />
            </div>
          </div>
        </div>
      )}
      {(uiDeletedUsers?.length > 0 || usersToadd.length > 0) && (
        <>
          <div className='user-list-header'>
            <p className='user-list-title'>Список пользователей</p>
            <div className='user-list__button-select-all'>
              <button className='select-all-btn' onClick={handleSelectAll}>
                Выбрать всех
              </button>
            </div>
          </div>
          <div className='table-container'>
            <table>
              <tbody>
                {usersByManager && uiDeletedUsers?.map((user: any, index: number) => (
                  <tr key={index} className='user-item'>
                    <td className='user-info'>
                      <div className='avatar'>
                        <img src={UserIcon} />
                      </div>
                      <span className='user-email'>{user.email}</span>
                    </td>
                    <td className='action-btn'>
                      <CustomCheckbox
                        checked={selectedUsers[index] || false}
                        onChange={() => handleCheckboxChange(index)}
                      />
                    </td>
                  </tr>
                ))}
                {usersToadd.map((user: any, index: number) => (
                  <tr key={index + uiDeletedUsers?.length} className='user-item'>
                    <td className='user-info'>
                      <div className='avatar'>
                        <img src={UserIcon} />
                      </div>
                      <span className='user-email'>{user}</span>
                    </td>
                    <td className='action-btn'>
                      <CustomCheckbox
                        checked={
                          selectedUsers[index + uiDeletedUsers?.length] || false
                        }
                        onChange={() =>
                          handleCheckboxChange(index + uiDeletedUsers?.length)
                        }
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className='delete-user__box'>
            <div className='delete-user-manager-box'>
              <ButtonComponent
                btnText={'Удалить выбранных пользователей'}
                className={`delete-user-btn managers-dlt-btn ${hasTrueValue ? 'selected-delete-user-btn' : ''
                  }`}
                btnOnClick={handleDeleteSelected}
                disabled={hasTrueValue ? false : true}
                withIcon={true}
                icon={hasTrueValue ? MiDelete : DeleteUserInitial}
              />
            </div>
          </div>
        </>
      )}
      <div className='save-user__box'>
        <img src={JamPlus} alt='Add' className='jam-plus' />
        <button className='add-user-btn' onClick={handleAddUser}>
          Добавить пользователя
        </button>
      </div>
    </div>
  );
};

export default UserList;
