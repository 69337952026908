import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const updateOffice = createAsyncThunk(
  'office/updateOffice',
  async ({ id, userData }: any, { rejectWithValue }) => {
    try {
      const response = await $api.post(`/api/office/update-office`, {id, ...userData});
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const officeSlice = createSlice({
  name: 'office',
  initialState: {
    office: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateOffice.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        updateOffice.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.office = action.payload;
        }
      )
      .addCase(
        updateOffice.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      );
  },
});

export default officeSlice.reducer;
