import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import BalanceRedCircle from '../../../assets/images/balance-red-circle.png';
import BalanceCircle from '../../../assets/images/balance-circle.png';
import './status-section.scss';
import StatusInfo from '../../../assets/images/status-info.svg';
import Tooltip from '@mui/material/Tooltip';

interface StatusSectionProps {
  status: string | undefined;
  currentEmail: string;
  setStatusValueChanged: (value: boolean) => void;
  setStatusState: (status: string) => void;
  setBalanseSectionStatus?: any;
  phonelengthErrorState?: any;
  setChangesSaved?: any;
}

const StatusSection: React.FC<StatusSectionProps> = ({
  status,
  currentEmail,
  setStatusValueChanged,
  setStatusState,
  setBalanseSectionStatus,
  setChangesSaved,
}) => {
  const dispatch = useDispatch();
  const [activeStatus, setActiveStatus] = useState(status);

  const handleActiveStatus = () => {
    setActiveStatus('новый');
    setStatusValueChanged(true);
    setStatusState('новый');
    setBalanseSectionStatus('новый');
    setChangesSaved(false);
  };

  const handleNotActiveStatus = () => {
    setActiveStatus('неактивный');
    setStatusValueChanged(true);
    setStatusState('неактивный');
    setBalanseSectionStatus('неактивный');
    setChangesSaved(false);
  };


  const handleHotStatus = () => {
    setActiveStatus('горячий');
    setStatusValueChanged(true);
    setStatusState('горячий');
    setBalanseSectionStatus('горячий');
    setChangesSaved(false);
  };

  const handleClientStatus = () => {
    setActiveStatus('клиент');
    setStatusValueChanged(true);
    setStatusState('клиент');
    setBalanseSectionStatus('клиент');
    setChangesSaved(false);
  };

  const handleSpamStatus = () => {
    setActiveStatus('спам');
    setStatusValueChanged(true);
    setStatusState('спам');
    setBalanseSectionStatus('спам');
    setChangesSaved(false);
  };


  return (
    <div className='status-section'>
      <div className='status-section__title'>Статус</div>
      <div
        className='status-section__status-toggler'
        onClick={handleActiveStatus}
      >

        <img
          src={activeStatus === 'новый' ||  status === 'новый'  ||  status === 'active'  ? BalanceRedCircle : BalanceCircle}
          width={20}
          height={20}
          alt='Active status'
        />
        <div className='status-section__status-toggler-text'>новый</div>
        <Tooltip
          title="Пользователь зарегистрировался 
      и подтвердил почту"
          placement="top"
          arrow
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0], 
                },
              },
            ],
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#FFFFFF', 
                borderRadius: '10px', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', 
                color: '#000000', 
                padding: '10px 16px',
                fontSize: '14px', 
                width: '160px',
              },
            },
            arrow: {
              sx: {
                color: '#FFFFFF', 
              },
            },
          }}
        >
          <img src={StatusInfo} className='status-info-manager' />
        </Tooltip>
      </div>
      <div
        className='status-section__status-toggler manager-user__page-status'
        onClick={handleNotActiveStatus}
      >
        <img
          src={activeStatus === 'неактивный' ||  status === 'неактивный' ||  status === 'inactive' ? BalanceRedCircle : BalanceCircle}
          width={20}
          height={20}
          alt='Inactive status'
        />
        <div className='status-section__status-toggler-text'>неактивный</div>
        <Tooltip
          title={<>
          Пользователь зарегистрировался, <br/> <span style={{ color: 'red' }}>но не подтвердил <br /> почту</span>
        </>}
          placement="top"
          arrow
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0], 
                },
              },
            ],
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', 
                color: '#000000', 
                padding: '10px 16px', 
                fontSize: '14px', 
                width: '160px',
              },
            },
            arrow: {
              sx: {
                color: '#FFFFFF', 
              },
            },
          }}
        > 
        <img src={StatusInfo} className='status-info-manager' />
        </Tooltip>
      </div>
      <div
        className='status-section__status-toggler manager-user__page-status'
        onClick={handleHotStatus}
      >
        <img
          src={activeStatus === 'горячий' ||  status === 'горячий' ? BalanceRedCircle : BalanceCircle}
          width={20}
          height={20}
          alt='Inactive status'
        />
        <div className='status-section__status-toggler-text'>горячий</div>
        <Tooltip
          title={<>
         Модерация кабинетов, обмен реквизитами, ожидание платежа
        </>}
          placement="top"
          arrow
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#FFFFFF', 
                borderRadius: '10px', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', 
                color: '#000000', 
                padding: '10px 16px', 
                fontSize: '14px', 
                width: '160px',
              },
            },
            arrow: {
              sx: {
                color: '#FFFFFF',
              },
            },
          }}
        > 
        <img src={StatusInfo} className='status-info-manager' />
        </Tooltip>
      </div>
      <div
        className='status-section__status-toggler manager-user__page-status'
        onClick={handleClientStatus}
      >
        <img
          src={activeStatus === 'клиент' ||  status === 'клиент' ? BalanceRedCircle : BalanceCircle}
          width={20}
          height={20}
          alt='Inactive status'
        />
        <div className='status-section__status-toggler-text'>клиент</div>
        <Tooltip
          title={<>
         Пользователь сделал первое пополнение
        </>}
          placement="top"
          arrow
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0],
                },
              },
            ],
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#FFFFFF', 
                borderRadius: '10px', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', 
                color: '#000000',
                padding: '10px 16px', 
                fontSize: '14px', 
                width: '160px',
              },
            },
            arrow: {
              sx: {
                color: '#FFFFFF', 
              },
            },
          }}
        > 
        <img src={StatusInfo} className='status-info-manager' />
        </Tooltip>
      </div>
      <div
        className='status-section__status-toggler manager-user__page-status'
        onClick={handleSpamStatus}
      >
        <img
          src={activeStatus === 'спам'  ||  status === 'спам' ? BalanceRedCircle : BalanceCircle}
          width={20}
          height={20}
          alt='Inactive status'
        />
        <div className='status-section__status-toggler-text'>спам</div>
        <Tooltip
          title={<>
         Спам, скам, конкуренты, фрод
        </>}
          placement="top"
          arrow
          PopperProps={{
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, 0], 
                },
              },
            ],
          }}
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: '#FFFFFF', 
                borderRadius: '10px', 
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)', 
                color: '#000000',
                padding: '10px 16px', 
                fontSize: '14px', 
                width: '160px',
              },
            },
            arrow: {
              sx: {
                color: '#FFFFFF', 
              },
            },
          }}
        > 
        <img src={StatusInfo} className='status-info-manager' />
        </Tooltip>
      </div>
    </div>
  );
};

export default StatusSection;
