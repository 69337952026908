import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { updateContactInfo } from '../../../features/update-manager-info/update-manager-info-slice';
import './balance-section.scss';
// import { phoneLengths } from '../../../utils/phone-lengths';
import { calculateUserBalances, getAllUsers } from '../../../features/get-all-users/get-all-users-slice';
import { AppDispatch } from '../../../app/store';

interface BalanceSectionProps {
  currentName?: string;
  currentEmail: string;
  currentPhone: string | undefined;
  currentMessenger?: string | undefined;
  messengerType: any;
  handleSaveForm: any;
  statusValueChanged?: any;
  balanseSectionStatus?: any;
  userTypeSection?: any;
  userTypeStatus?: any;
  selectedCountryState?: any;
  setPhonelengthErrorState?: any;
  phonelengthErrorState?: any;
  balancePhoneChanged?: any;
  balanceMessengerChanged?: any;
  setMssengerlengthErrorState?: any;
  messengerTypeValueChanged?: boolean;
  selectedAdSystem?: any;
  changesSaved?: boolean;
  setChangesSaved?: any;
  networks?: any;
}

const BalanceSection: React.FC<BalanceSectionProps> = ({
  currentName,
  currentEmail = '',
  currentPhone,
  currentMessenger,
  messengerType,
  statusValueChanged,
  balanseSectionStatus,
  userTypeSection,
  userTypeStatus,
  selectedCountryState,
  setPhonelengthErrorState,
  balancePhoneChanged,
  balanceMessengerChanged,
  setMssengerlengthErrorState,
  messengerTypeValueChanged,
  selectedAdSystem,
  changesSaved,
  setChangesSaved,
  networks,
}) => {

  const dispatch: AppDispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    const fetchTotalAmount = async () => {
      try {
        // Вызовите calculateUserBalances через dispatch
        const actionResult = await dispatch(calculateUserBalances());

        // Извлекаем payload с балансами
        const balances = actionResult.payload;

        // Устанавливаем сумму в состояние
        setTotalAmount(balances[currentEmail] || 0);
      } catch (error) {
        console.error('Ошибка при вычислении баланса пользователя:', error);
      }
    };

    if (currentEmail) {
      fetchTotalAmount();
    }
  }, [currentEmail, dispatch]);

  return (
    <div className='contact-info__section balance-section'>
      <div className='contact-info__balance-title'>Текущий баланс</div>
      <div className='balance-box'>
        <div className='balance-item'>
          <div className='amount'>0.00</div>
          <div className='currency'>RUB</div>
        </div>
        <div className='balance-item'>
          <div className='amount'>0.00</div>
          <div className='currency'>EUR</div>
        </div>
        <div className='balance-item'>
          <div className='amount'>{totalAmount}</div>
          <div className='currency'>USD</div>
        </div>
        <div className='balance-item'>
          <div className='amount'>0.00</div>
          <div className='currency'>KZT</div>
        </div>
      </div>
      {/* <div className='user-contact-info--mobile'>
        <SaveChanges
          changesSaved={changesSaved}
          handleSaveForm={handleSaveForm}
          statusValueChanged={statusValueChanged}
          userTypeSection={userTypeSection}
          balancePhoneChanged={balancePhoneChanged}
          balanceMessengerChanged={balanceMessengerChanged}
          messengerTypeValueChanged={messengerTypeValueChanged}
        />
      </div> */}
    </div>
  );
};

export default BalanceSection;
