import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';
import { saveAs } from 'file-saver';

export const uploadDocument = createAsyncThunk(
  'document/uploadDocument',
  async (documentData: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/document/upload', documentData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to upload document');
    }
  }
);

export const fetchDocuments = createAsyncThunk<any>(
  'document/fetchDocuments',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get('/api/document/documents');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch documents');
    }
  }
);

export const fetchDocumentsByEmail = createAsyncThunk<any, string>(
  'document/fetchDocumentsByEmail',
  async (holderEmail: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/api/document/documents/by-email/${holderEmail}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch documents by email');
    }
  }
);

export const deleteDocument = createAsyncThunk(
  'document/deleteDocument',
  async (documentId: string, { rejectWithValue }) => {
    try {
      const response = await $api.delete(`/api/document/document/${documentId}`);
      return documentId;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete document');
    }
  }
);

export const fetchDocumentsByManager = createAsyncThunk(
  'document/fetchDocumentsByManager',
  async (emails: string[], { rejectWithValue }) => {
    try {
      if (!emails || emails.length === 0) {
        return rejectWithValue('Список email пуст, запрос не выполнен');
      }

      const response = await $api.post('/api/document/documents/by-manager', { emails });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch documents by manager');
    }
  }
);

export const downloadDocument = createAsyncThunk(
  'document/downloadDocument',
  async (documentId: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/api/document/document/${documentId}`, {
        responseType: 'blob',
      });

      const contentDisposition = response.headers['content-disposition'];
      let filename = 'downloaded-file.pdf';

      if (contentDisposition) {
        const filenameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
        if (filenameMatch.length > 1) {
          filename = filenameMatch[1];
        }
      }

      saveAs(response.data, filename);

      return documentId;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to download document');
    }
  }
);


export const documentSlice = createSlice({
  name: 'document',
  initialState: {
    documents: [],
    isLoading: false,
    error: null,
    documentsByManagers: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadDocument.fulfilled, (state: any, action: any) => {
        console.log('action.payload', action.payload)
        state.isLoading = false;
        state.documents.push(action.payload);
      })
      .addCase(uploadDocument.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchDocuments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDocuments.fulfilled, (state: any, action: PayloadAction<any[]>) => {
        state.isLoading = false;
        state.documents = action.payload;
      })
      .addCase(fetchDocuments.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action: PayloadAction<string>) => {
        state.isLoading = false;
        state.documents = state.documents.filter((doc: any) => doc._id !== action.payload);
      })
      .addCase(deleteDocument.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchDocumentsByEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDocumentsByEmail.fulfilled, (state: any, action: PayloadAction<any[]>) => {
        state.isLoading = false;
        state.documents = action.payload;
      })
      .addCase(fetchDocumentsByEmail.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(downloadDocument.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(downloadDocument.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(downloadDocument.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })


      .addCase(fetchDocumentsByManager.pending, (state: any) => {
        state.isLoading = true;
      })
      .addCase(fetchDocumentsByManager.fulfilled, (state: any, action: PayloadAction<any[]>) => {
        state.isLoading = false;
        state.documentsByManagers = action.payload;
      })
      .addCase(fetchDocumentsByManager.rejected, (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      })
  },
});

export default documentSlice.reducer;
