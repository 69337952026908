import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './recovery-password.scss';
import InputLabel from '../../input/inputLabel/Input-label';
import ButtonComponent from '../../ui/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { recoveryPassword } from '../../../features/recovery-password/recoveryPasswordSlice';
import ButtonWithIcon from '../../ui/button/buttonWithIcon';
import UserIcon from '../../../assets/icons/user-icon';
import MainLayout from '../../layouts/mainLayout';
import ErrorComponent from '../../ui/error/error-component';
import Spinner from '../../ui/spinner/spinner';
import { AppDispatch } from '../../../app/store';

const RecoveryPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [noExistEmailError, setNoExistEmailError] = useState(false);
  const isLoading = useSelector(
    (state: any) => state.recoveryPassword?.isLoading
  );

  const handleClick = () => {
    navigate('/');
  };

  const handleBtnClick = async (event: any) => {
    event.preventDefault();
    const userData = {
      email: email.toLowerCase(),
    };
    console.log('email', email)

    try {
      let result: any;

      if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }

      if (email && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
        result = await dispatch(recoveryPassword(userData));
      }

      let resultPayload: any;

      if (typeof result?.payload == 'string') {
        resultPayload = result?.payload;
      }
      const regex = /<pre>(.*?)<\/pre>/;
      const matches = resultPayload?.match(regex);

      if (matches && matches[1]) {
        const errorMessage = matches[1].split('<br>')[0];
        if (errorMessage.includes('Пользователь с почтовым адресом')) {
          setNoExistEmailError(true);
        } else {
          setNoExistEmailError(false);
        }
      } else {
        setNoExistEmailError(false);
      }

      if (!result.error) {
        navigate('/recovery-password-check');
      }
    } catch (rejectedValueOrSerializedError) {}
  };

  return (
    <MainLayout>
      <div className='recovery-container recovery-container--padding-bottom'>
        <div className='recovery-main'>
          {isLoading && (
            <div
              className={'advertising-systems-page-container__absolute-layer'}
            >
              <Spinner />
            </div>
          )}
          <p className='recovery-title'>Восстановить пароль</p>
          <p className='recovery-main-body'>
            Введите свой email и мы отправим вам инструкцию по сбросу пароля.
          </p>
          <InputLabel
            className='register-input recovery-input'
            labelClass='register-label'
            label='Email'
            type='email'
            setValue={setEmail}
            value={email}
            requireLabel={false}
          />
          {emailError && (
            <ErrorComponent
              error='email должен быть действительным'
              errorClassName=''
            />
          )}
          {noExistEmailError && (
            <ErrorComponent
              error='Пользователь с таким почтовым адресом не существует'
              errorClassName=''
            />
          )}
          <div className='recovery-btn-box'>
            <ButtonComponent
              btnText='Отправить'
              className='recovery-btn'
              btnOnClick={handleBtnClick}
              disabled={!email} // Кнопка отключена, если email пуст
            />
          </div>
        </div>
        <div className='registration__signin-section'>
          <p>Нет аккаунта?</p>
          <ButtonWithIcon
            btnText={'Зарегистрироваться'}
            withIcon={true}
            icon={<UserIcon />}
            btnIconClassName='register-header-btn-icon'
            className='registration-header-btn-icon'
            btnOnClick={handleClick}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default RecoveryPassword;
