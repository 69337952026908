import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import InputLabel from '../../input/inputLabel/Input-label';
import ErrorComponent from '../../ui/error/error-component';
import './user-settings.scss';
import ButtonComponent from '../../ui/button/button';
import { useNavigate } from 'react-router';
import RoutesList from '../../ui/routes-list/routes-list';
import ManagerPhone from '../../manager-phone/manager-phone';
import { useDispatch, useSelector } from 'react-redux';
import { updateManagerProfile } from '../../../features/manager-profile/manager-profile-slice';
import { AppDispatch, RootState } from '../../../app/store';
import CheckedRedCircle from '../../../assets/icons/checked-red-circle.svg';
import { updateUser } from '../../../features/login/loginSlice';
import { disableTwoFactorAuth } from '../../../features/two-factor-auth/two-factor-auth-slice';
import SelectMessenger from '../../ui/select-messenger/select-messenger';
import { calculateUserBalances } from '../../../features/get-all-users/get-all-users-slice';

const UserSettings = () => {
  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );

  const [email, setEmail] = useState(user?.email);
  const [emailError, setEmailError] = useState(false);
  const [accountName, setAccountName] = useState<string>(user?.name);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [approveNewPassword, setApproveNewPassword] = useState('');
  const [phone, setPhone] = useState(user?.phone);
  const [phoneError, setPhoneError] = useState(false);
  const [messengerType, setMessengerType] = useState(user?.messengerType);
  const [messenger, setMessenger] = useState(user?.messenger);
  const [accountNameError, setAccountNameError] = useState(false);
  const [emptyPasswordError, setEmptyPasswordError] = useState(false);
  const [noCorrectPasswordError, setNoCorrectPasswordError] = useState(false);
  const [phoneCountry, setPhoneCountry] = useState<string>('');
  const [selectedCountryState, setSelectedCountryState] = useState<any>({
    code: 'RU',
    mask: '+7 (999) 999-99-99',
    name: 'Russia',
  });
  const [showModal, setShowModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const handleNavigate = () => {
    navigate('/add-balance');
  };

  const dispatch: AppDispatch = useDispatch();

  const phoneCountryState = user?.phoneCountry;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (accountName?.length < 1) {
      setAccountNameError(true);
    } else {
      setAccountNameError(false);
    }

    if (oldPassword?.length < 1) {
      await setEmptyPasswordError(true);
    } else {
      await setEmptyPasswordError(false);
    }

    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }

    const phoneCountry = selectedCountryState.name;

    let result;
    let resultJson: any;
    if (!accountNameError && !emailError && oldPassword.length > 0) {
      result = await dispatch(
        updateManagerProfile({
          email,
          phone,
          messenger,
          messengerType,
          oldPassword,
          newPassword,
          name: accountName,
          phoneCountry,
        })
      ).then((response) => {
        resultJson = response;
      });
    }

    if (resultJson?.payload?.email) {
      dispatch(updateUser(resultJson.payload));
      setShowModal(true);
      setNoCorrectPasswordError(false);

      setTimeout(() => {
        setShowModal(false);
      }, 2000);
    } else {
      setNoCorrectPasswordError(true);
    }
  };

  const navigate = useNavigate();

  const handleConnectTwoAuth = () => {
    navigate('/two-factor-auth');
  };

  const handleDisconnectTwoAuth = async () => {
    try {
      const userId = user?.id;
      const response = await dispatch(disableTwoFactorAuth(userId)).unwrap();
      if (response.success) {
        console.log('2FA отключена');
        dispatch(updateUser({ twoFactor: false }));
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 2000);
      } else {
        console.error('Ошибка при отключении 2FA');
      }
    } catch (error) {
      console.error('Ошибка при отключении 2FA', error);
    }
  };

  useEffect(() => {
    setAccountName(user?.name);
  }, [user]);

  const handleContact = () => {
    navigate('/contacts')
  }

  useEffect(() => {
    const fetchTotalAmount = async () => {
      try {
        const actionResult = await dispatch(calculateUserBalances());

        const balances = actionResult.payload;

        setTotalAmount(balances[user.email] || 0);
      } catch (error) {
        console.error('Ошибка при вычислении баланса пользователя:', error);
      }
    };

    if (user?.email) {
      fetchTotalAmount();
    }
  }, [user, dispatch]);


  return (
    <MainLayout>
      <RoutesList />
      <div className='networks-box recovery-container recovery-container--justify-start networks-box-page'>
        <div className='networks-box__section'>
          <div className='networks-box__balance networks-balance'>
            <div className='networks-balance__title'>
              <h3>Текущий баланс</h3>
            </div>

            <div className='networks-balance__indicator'>
              <span id='balance'>{totalAmount}</span> USD
            </div>

            <div className='networks-balance__fillBTN'>
              <button className='button button-hover' onClick={handleNavigate}>
                Пополнить
              </button>
              <div className='networks-balance__fillBTN--hidden-question'>
                Возникли вопросы? <span onClick={handleContact} className=''>Напишите нам</span>{' '}
              </div>
            </div>
          </div>

          <div className='networks-box add-advetising-box networks-box-form'>
            <form onSubmit={handleSubmit}>
              <div className='user-settings__title'>Персональные настройки</div>
              <InputLabel
                className='settings-input names-input'
                labelClass='register-label names-inputs-label inputs-labels'
                label='Имя'
                type='text'
                setValue={setAccountName}
                value={accountName}
                requireLabel={true}
              />
              {accountNameError && (
                <ErrorComponent
                  error='Название аккаунта обязательно для заполнения'
                  errorClassName=''
                />
              )}
              <InputLabel
                className='settings-input emails-input'
                labelClass='register-label inputs-labels'
                label='Email'
                type='email'
                setValue={setEmail}
                value={email}
                placeholder='name@mail.com'
              />
              {emailError && (
                <ErrorComponent
                  error='email должен быть действительным'
                  errorClassName=''
                />
              )}

              <InputLabel
                className='register-input'
                labelClass='register-label inputs-labels'
                label='Старый пароль'
                type='password'
                setValue={setOldPassword}
                value={oldPassword}
                iconClass='eye-class'
                requireLabel={true}
              />
              {emptyPasswordError && (
                <ErrorComponent
                  error='Это поле обязательно для заполнения'
                  errorClassName=''
                />
              )}
              {noCorrectPasswordError && (
                <ErrorComponent error='Пароль неправильный' errorClassName='' />
              )}

              <InputLabel
                className='register-input'
                labelClass='register-label inputs-labels'
                label='Новый пароль'
                type='password'
                setValue={setNewPassword}
                value={newPassword}
                iconClass='eye-class'
                requireLabel={false}
              />

              <InputLabel
                className='register-input'
                labelClass='register-label inputs-labels'
                label='Подтвердить новый пароль'
                type='password'
                setValue={setApproveNewPassword}
                value={approveNewPassword}
                iconClass='eye-class'
                requireLabel={false}
              />

              <div className='phone-box-label-container'>
                <div className='label-container'>
                  <label className='register-label'>Телефон</label>
                  {true && <p className='label-required'>*</p>}
                </div>

                <div className='phone-box'>
                  <ManagerPhone
                    value={phone}
                    setValue={setPhone}
                    setSelectedCountryState={setSelectedCountryState}
                    phoneCountryState={phoneCountryState}
                    setPhoneCountry={setPhoneCountry}
                    className='user-setting-manage-phone'
                  />
                </div>
                {phoneError && (
                  <ErrorComponent
                    error='Неправильный формат номера телефона'
                    errorClassName=''
                  />
                )}
              </div>

              <div className='soc-media'>
                <div className='label-container soc-media__label-container'>
                  <label className='register-label inputs-labels'>
                    Мессенджер
                  </label>
                  {true && <p className='label-required'>*</p>}
                </div>

                <div className='select-box soc-media__content'>
                  <SelectMessenger
                    setMessengerType={setMessengerType}
                    value={messengerType}
                    setMessengerTypeValueChanged={() => { }}
                  />
                  <InputLabel
                    className='register-input registration-input soc-media__input'
                    labelClass='register-label'
                    type='text'
                    requireLabel={false}
                    setValue={setMessenger}
                    value={messenger}
                  />
                </div>
              </div>

              <div className='two-fatctor-auth__box'>
                <p>Двухфакторная аутентификация</p>
                {user?.twoFactor ? (
                  <div
                    onClick={handleDisconnectTwoAuth}
                    className='connect-two-auth'
                  >
                    Сбросить
                  </div>
                ) : (
                  <div
                    onClick={handleConnectTwoAuth}
                    className='connect-two-auth'
                  >
                    Подключить
                  </div>
                )}
              </div>
              <div className='registration-container US_save_BTN'>
                <ButtonComponent btnText='Сохранить' className='register-btn user-settings-save' />
              </div>
            </form>
          </div>
        </div>
      </div>
      {showModal && (
        <div className='modal'>
          <div className='modal-content'>
            <img
              src={CheckedRedCircle}
              alt='User avatar'
              className='user-info__checked-circle'
            />
            <div>Мы успешно сохранили ваши изменения</div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default UserSettings;
