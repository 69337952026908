import React, { useState, useEffect } from 'react';
import './user-contact-info.scss';
// import ShortSelect from '../short-select/short-select';
import { useDispatch, useSelector } from 'react-redux';
// import { useNavigate } from 'react-router-dom';
import { updateContactInfo } from '../../../features/update-manager-info/update-manager-info-slice';
import ContactDetails from '../contact-details/contact-details';
import UserTypeSection from '../user-type-section/user-type-section';
import StatusSection from '../status-section/status-section';
import CommissionSection from '../commission-section/commission-section';
import { Icon } from '@iconify/react';
import { formatPhoneNumber } from '../../../utils/format-phone-number';
import BalanceSection from '../balance-section/balance-section';
import SaveChanges from '../balance-section/save-changes/save-changes';
import { phoneLengths } from '../../../utils/phone-lengths';
import { getAllUsers, userDataUpdate } from '../../../features/get-all-users/get-all-users-slice';
import { AppDispatch } from '../../../app/store';
import { getUsersByManager } from '../../../features/get-users-by-manager/get-users-by-manager-slice';

interface UserContactInfoProps {
  name?: string | null;
  email: string | null;
  phone?: any;
  messenger?: string | null;
  status?: string | null;
  messengerType?: any;
  setMessengerType: any;
  setCurrentPhone: any;
  currentMessenger?: string;
  setCurrentMessenger: any;
  setPhoneValueChanged: any;
  setMessengerValueChanged: any;
  setStatusValueChanged: any;
  currentStatus?: string;
  setCurrentStatus: any;
  phonelengthError?: boolean;
  messengerlengthError?: boolean;
  statusValueChanged?: any;
  currentUser?: any;
  setSelectedCountryState?: any;
  selectedCountryState?: any;
}

const UserContactInfo: React.FC<UserContactInfoProps> = ({
  name,
  email,
  phone,
  messenger,
  status,
  messengerType,
  setMessengerType,
  setCurrentPhone,
  currentMessenger,
  setCurrentMessenger,
  setPhoneValueChanged,
  setMessengerValueChanged,
  setStatusValueChanged,
  currentStatus,
  setCurrentStatus,
  phonelengthError,
  messengerlengthError,
  statusValueChanged,
  currentUser,
  setSelectedCountryState,
  selectedCountryState,
}) => {
  // console.log("🚀 ~ phone:", phone)
  // console.log('🚀 ~ currentUser:', currentUser);
  // console.log('🚀 ~ phone:', phone);
  const [currentName, setCurrentName] = useState<string>('');
  const [currentEmail, setCurrentEmail] = useState<string>('');
  const [selectedAdSystem, setSelectedAdSystem] = React.useState<string | null>(
    null
  );
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [balanseSectionStatus, setBalanseSectionStatus] = useState<string>('');
  const [userTypeSection, setUserTypeSection] = useState<boolean>(false);
  const [userTypeStatus, setUserTypeStatus] = useState<string>('');
  const [phonelengthErrorState, setPhonelengthErrorState] =
    useState<boolean>(false);
  const [mssengerlengthErrorState, setMssengerlengthErrorState] =
    useState<boolean>(false);
  const [balancePhoneChanged, setBalancePhoneChanged] =
    useState<boolean>(false);
  const [balanceMessengerChanged, setBalanceMessengerChanged] =
    useState<boolean>(false);

  const phoneCountryState = currentUser?.phoneCountry;
  const [phoneCountry, setPhoneCountry] = useState<string>('');
  const [messengerTypeValueChanged, setMessengerTypeValueChanged] =
    useState<boolean>(false);
  const [changesSaved, setChangesSaved] = useState<boolean>(false);
  const [percentValueState, setPercentValueState] = useState<string>('');
  const [networks, setNetworks] = useState<any[]>(
    Array.isArray(currentUser?.networks) ? currentUser?.networks : []
  );

  const dispatch = useDispatch<AppDispatch>();
  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );

  useEffect(() => {
    if (name) {
      setCurrentName(name);
    }
    if (email) {
      setCurrentEmail(email);
    }
    if (phone) {
      setCurrentPhone(phone);
    }
    if (messenger) {
      setCurrentMessenger(messenger);
    }
    if (status) {
      setCurrentStatus(status);
    }
  }, [name, email, status]);

  const handleToggleVisibility = () => {
    setIsHidden(!isHidden);
  };

  const handleSaveForm = async () => {
    const email = currentEmail;
    const formatedPhone = formatPhoneNumber(phone || '');
    const messenger = currentMessenger || '';
    const phoneError =
      selectedCountryState.code in phoneLengths &&
      formatedPhone.length !== phoneLengths[selectedCountryState.code];
    // console.log(
    //   '🚀 ~ handleSaveForm ~ phoneLengths[selectedCountryState.code]:',
    //   phoneLengths[selectedCountryState.code]
    // );

    const phoneCountry = selectedCountryState.name;

    if (phoneError) {
      setPhonelengthErrorState(true);
    } else {
      setPhonelengthErrorState(false);
    }

    if ((messenger && messenger.length < 2) || messenger.length === 0) {
      setMssengerlengthErrorState(true);
    } else {
      setMssengerlengthErrorState(false);
    }

    const status = statusValueChanged
      ? balanseSectionStatus
      : currentUser?.status;
    const userType = userTypeSection ? userTypeStatus : currentUser?.userType;

    const networks = {
      'Google Ads': 20,
    };

    if (
      (!phoneError && balancePhoneChanged) ||
      (balanceMessengerChanged && messenger && messenger.length >= 2) ||
      statusValueChanged ||
      userTypeSection ||
      messengerTypeValueChanged
    ) {
      await dispatch(
        updateContactInfo({
          email,
          phone,
          messenger,
          messengerType,
          phoneCountry,
          status,
          userType,
          networks,
        })
      );
      // dispatch(getAllUsers());
      // Локально обновляем данные пользователя
      dispatch(
        userDataUpdate({
          id: currentUser.id, // Убедитесь, что ID корректно используется
          phone,
          messenger,
          messengerType,
          phoneCountry,
          status,
          userType,
          networks,
        })
      );
    }

    if (!phoneError) {
      setChangesSaved(true);
    }

    dispatch(getUsersByManager(user?.email))

    dispatch(getAllUsers());
  };

  return (
    <>
      <div className='contact-info__toggle' onClick={handleToggleVisibility}>
        {isHidden ? 'Показать информацию' : 'Скрыть информацию'}
        {isHidden ? (
          <Icon
            className='manager-user__close-icon'
            icon='iconamoon:arrow-up-2-bold'
            width='24px'
            color='#8F97A9'
          />
        ) : (
          <Icon
            className='manager-user__close-icon'
            icon='iconamoon:arrow-down-2'
            width='24px'
            color='#8F97A9'
          />
        )}
      </div>
      <div className='contact-info-wrapper'>
        <div
          className={`contact-info manager-user__contact-info contact-info__content ${isHidden ? 'hidden' : ''
            }`}
        >
          <ContactDetails
            name={currentName}
            email={currentEmail}
            phone={phone}
            messenger={currentMessenger}
            setPhone={setCurrentPhone}
            setMessenger={setCurrentMessenger}
            messengerType={messengerType}
            setMessengerType={setMessengerType}
            setSelectedCountryState={setSelectedCountryState}
            setPhoneValueChanged={setPhoneValueChanged}
            setMessengerValueChanged={setMessengerValueChanged}
            phonelengthErrorState={phonelengthErrorState}
            setBalancePhoneChanged={setBalancePhoneChanged}
            setBalanceMessengerChanged={setBalanceMessengerChanged}
            mssengerlengthErrorState={mssengerlengthErrorState}
            phoneCountryState={phoneCountryState}
            setPhoneCountry={setPhoneCountry}
            setMessengerTypeValueChanged={setMessengerTypeValueChanged}
            setChangesSaved={setChangesSaved}
          />
          <div>
            <StatusSection
              status={currentStatus}
              currentEmail={currentEmail}
              setStatusValueChanged={setStatusValueChanged}
              setStatusState={setCurrentStatus}
              setBalanseSectionStatus={setBalanseSectionStatus}
              phonelengthErrorState={phonelengthErrorState}
              setChangesSaved={setChangesSaved}
            />
            <UserTypeSection
              currentEmail={currentEmail}
              setUserTypeSection={setUserTypeSection}
              setUserTypeStatus={setUserTypeStatus}
              setChangesSaved={setChangesSaved}
            />
          </div>

          <CommissionSection
            setSelectedAdSystem={setSelectedAdSystem}
            selectedAdSystem={selectedAdSystem}
            setMessengerTypeValueChanged={setMessengerTypeValueChanged}
            setChangesSaved={setChangesSaved}
            percentValueState={percentValueState}
            setPercentValueState={setPercentValueState}
            networks={networks}
            setNetworks={setNetworks}
          />

          <BalanceSection
            currentEmail={currentEmail}
            currentMessenger={currentMessenger}
            currentPhone={phone}
            messengerType={messengerType}
            handleSaveForm={handleSaveForm}
            statusValueChanged={statusValueChanged}
            balanseSectionStatus={balanseSectionStatus}
            userTypeSection={userTypeSection}
            userTypeStatus={userTypeStatus}
            selectedCountryState={selectedCountryState}
            setPhonelengthErrorState={setPhonelengthErrorState}
            balancePhoneChanged={balancePhoneChanged}
            phonelengthErrorState={phonelengthErrorState}
            balanceMessengerChanged={balanceMessengerChanged}
            setMssengerlengthErrorState={setMssengerlengthErrorState}
            messengerTypeValueChanged={messengerTypeValueChanged}
            selectedAdSystem={selectedAdSystem}
            changesSaved={changesSaved}
            setChangesSaved={setChangesSaved}
            networks={networks}
          />
        </div>
        <div className='user-contact-info--desktop'>
          <SaveChanges
            changesSaved={changesSaved}
            handleSaveForm={handleSaveForm}
            statusValueChanged={statusValueChanged}
            userTypeSection={userTypeSection}
            balancePhoneChanged={balancePhoneChanged}
            balanceMessengerChanged={balanceMessengerChanged}
            messengerTypeValueChanged={messengerTypeValueChanged}
          />
        </div>
      </div>
    </>
  );
};

export default UserContactInfo;
