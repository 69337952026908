import React, { useEffect, useRef } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './date-picker-component.scss';

const DatePickerComponent = ({ startDate, setStartDate, endDate, setEndDate, onClose, calendarContainerClassName }: any) => {
    const datePickerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            // Проверяем, есть ли onClose, и был ли клик вне области календаря
            if (datePickerRef.current && !datePickerRef.current.contains(event.target as Node) && onClose) {
                onClose(); // Закрытие календаря при наличии onClose
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className={`date-picker-container ${calendarContainerClassName ? calendarContainerClassName : ''}`} ref={datePickerRef}>
            <DatePicker
                selected={startDate}
                onChange={dates => {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                }}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
            />
        </div>
    );
};

export default DatePickerComponent;
