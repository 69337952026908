import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const getUserByEmail = createAsyncThunk(
  'user/get-user-by-email',
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await $api.get(`/api/user/get-user-by-email`, {
        params: { email },
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

interface User {
  id: string;
  email: string;
  phone?: string;
  messengerType?: string;
  phoneCountry?: string;
  // Добавьте остальные поля, которые есть у вашего пользователя
}

interface UserByEmailState {
  userByEmail: User | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: UserByEmailState = {
  userByEmail: null,
  isLoading: false,
  error: null,
};

export const getUserByEmailSlice = createSlice({
  name: 'userByEmail',
  initialState,
  reducers: {
    updateUserByEmail: (state, action: PayloadAction<Partial<User>>) => {
      if (state.userByEmail) {
        state.userByEmail = { ...state.userByEmail, ...action.payload };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserByEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getUserByEmail.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.userByEmail = action.payload;
        }
      )
      .addCase(getUserByEmail.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { updateUserByEmail } = getUserByEmailSlice.actions;
export default getUserByEmailSlice.reducer;
