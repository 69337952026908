import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import RoutesList from '../../ui/routes-list/routes-list';
import './faq.scss';
import ButtonComponent from '../../ui/button/button';
import { useSelector, useDispatch } from 'react-redux';
import { addNewQuestion, deleteQuestion, fetchAllQuestions, updateQuestionsOrder, updateQuestionsOrderOnServer } from '../../../features/questions/question-slice';
import { AppDispatch } from '../../../app/store';
import FaqArrow from '../../../assets/icons/faq-arrow.svg';
import FaqDelete from '../../../assets/icons/faq-delete.svg';
import FaqArrowSort from '../../../assets/icons/one-arrow.svg';
import FaqEdit from '../../../assets/icons/iconamoon_edit.svg';
import { useNavigate } from 'react-router-dom';
import ModalComponent from '../../ui/modal/modal';

const Faq = () => {
    const user = useSelector((state: any) => state.login?.user?.user || state.login?.user);
    const navigate = useNavigate();

    const questions = useSelector((state: any) => state?.questions.questions);
    const dispatch = useDispatch<AppDispatch>();


    const [localQuestions, setLocalQuestions] = useState([...questions]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [questionToDelete, setQuestionToDelete] = useState<any>(null);

    console.log('questions', questions)

    useEffect(() => {
        dispatch(fetchAllQuestions());
    }, [dispatch]);

    useEffect(() => {
        setLocalQuestions(questions);
    }, [questions]);

    const [isLoading, setIsLoading] = useState(false);

    const handleNavigateToQuestion = (questionId: any) => {
        navigate(`/faq/${questionId}`);
    };

    const handleCreateDocument = () => {
        navigate('/faq/add');
    };

    const handleOpenDeleteModal = (question: any) => {
        setQuestionToDelete(question);
        setIsModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (questionToDelete) {
            await dispatch(deleteQuestion(questionToDelete._id));
            dispatch(fetchAllQuestions());
            setIsModalOpen(false);
            setQuestionToDelete(null);
        }
    };

    const handleCancelDelete = () => {
        setIsModalOpen(false);
        setQuestionToDelete(null);
    };

    const handleEditDocument = (question: any) => {
        navigate(`/faq-edit-question?id=${question._id}`, {
            state: {
                _id: question._id,
                title: question.title,
                content: question.content,
                description: question.description,
                imageUrls: question.imageUrls,
            },
        });
    };

    const moveQuestion = (index: number, direction: 'up' | 'down') => {
        const newIndex = direction === 'up' ? index - 1 : index + 1;
        if (newIndex >= 0 && newIndex < localQuestions.length) {
            const updatedQuestions = [...localQuestions];
            [updatedQuestions[index], updatedQuestions[newIndex]] = [updatedQuestions[newIndex], updatedQuestions[index]];

            setLocalQuestions(updatedQuestions);

            dispatch(updateQuestionsOrder(updatedQuestions));

            dispatch(updateQuestionsOrderOnServer(updatedQuestions));
        }
    };

    const uniqueLocalQuestions = localQuestions.reduce((acc, curr) => {
        if (!acc.some((q: any) => q._id === curr._id)) {
            acc.push(curr);
        }
        return acc;
    }, []);

    return (
        <MainLayout>
            <div className='networks-box recovery-container recovery-container--justify-start faq'>
                <RoutesList routeClassName='finance-history-route' />

                <div className='faq__add-qusetion-box'>
                    <p className='faq__head-title'>FAQ</p>
                    <p className='faq__mobile-head-title'>FAQ</p>
                    {user?.role !== 'user' && (
                        <ButtonComponent
                            btnText={isLoading ? 'Добавление...' : 'Добавить статью'}
                            className={`add-advetising-btn-create add-question-btn ${isLoading ? 'loading' : ''}`}
                            btnOnClick={handleCreateDocument}
                            disabled={isLoading}
                        />
                    )}
                </div>
                {uniqueLocalQuestions
                    .slice()
                    .sort((a: any, b: any) => a.order - b.order)
                    .map((question: any, index: number) => {
                        // console.log('Rendering question:', question);
                        return (<div key={question._id} className='question-box'>
                            <div className='question-box__content'>
                                <p className='faq-question-title'>{question.title}</p>
                                <p>{question.description}</p>
                            </div>
                            <div className={`faq-question-edit-box ${user.role === 'user' ? 'faq-question-edit-user-box' : 'faq-manager-edit-box'}`}>
                                <img
                                    src={FaqArrow}
                                    alt='FaqArrow'
                                    className='faq-arrow'
                                    width={44}
                                    height={44}
                                    onClick={() => handleNavigateToQuestion(question._id)}
                                />
                                {user.role !== 'user' && (
                                    <div className='edit-box-images'>
                                        <img
                                            src={FaqArrowSort}
                                            alt='Move Up'
                                            className='faq-arrow'
                                            onClick={() => moveQuestion(index, 'up')}
                                        />
                                        <img
                                            src={FaqArrowSort}
                                            alt='Move Down'
                                            className='faq-arrow'
                                            style={{ transform: 'rotate(180deg)' }}
                                            onClick={() => moveQuestion(index, 'down')}
                                        />
                                        <img
                                            src={FaqEdit}
                                            alt='Edit'
                                            className='faq-arrow'
                                            onClick={() => handleEditDocument(question)}
                                        />
                                        <img
                                            src={FaqDelete}
                                            alt='Delete'
                                            className='faq-arrow'
                                            onClick={() => handleOpenDeleteModal(question)}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                        )
                    })}

                {/* Модальное окно для подтверждения удаления */}
                {isModalOpen && (
                    <ModalComponent
                        className='add-question-modal modal-delete-confirmation'
                        modalBtnClassName='add-advetising-modal-btn modal-delete-btn'
                        modalText='Вы уверены, что хотите удалить статью?'
                        twoBtn={true}
                        firstBtnText='Нет'
                        firstBtnClick={handleCancelDelete}
                        secondBtnText='Да'
                        secondBtnClick={handleConfirmDelete}
                        rightButtonStyle='red-button-white-text'
                    />
                )}
            </div>
        </MainLayout>
    );
};

export default Faq;
