import React, { useEffect, useState } from 'react';
import '../registrationPage/registration-page.scss';
import FlatIlustration from '../../../assets/images/flat_illustration_37 1.png';
import { useNavigate } from 'react-router-dom';
import UserIcon from '../../../assets/icons/user-icon';
import ButtonWithIcon from '../../ui/button/buttonWithIcon';
import LoginForm from '../../ui/forms/login/login-form';
import MainLayout from '../../layouts/mainLayout';
import Modal2FA from '../../ui/modal-2fa/modal-2fa';
import { useDispatch, useSelector } from 'react-redux';
import { verifyToken } from '../../../features/two-factor-auth/two-factor-auth-slice';
import { AppDispatch } from '../../../app/store';
import { repeatConfirmCode } from '../../../features/register/authSlice';

const LoginPage: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );
  // console.log("🚀 ~ user:", user)

  const handleRepeatCode = () => {
    try {
      dispatch(repeatConfirmCode(user?.email));
      // setCodeModal(true)
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (user) {
      // Проверка на статус inactive
      if (user?.status === 'inactive') {
        console.error('Пользователь не активирован');
        handleRepeatCode()
        navigate('/approve-user-email')
        return; // Прекращаем выполнение, если статус inactive
      }

      // Если 2FA включена для пользователя, открываем модальное окно
      if (user?.twoFactor) {
        setModalOpen(true);
      } else if (user.role === 'administrator') {
        navigate('/administrator-home');
      } else if (user.role === 'manager') {
        navigate('/manager-home');
      } else {
        navigate('/advertising-systems-office-home');
      }
    }
  }, [user, navigate]);


  const handleLoginSuccess = () => {
    // Логика обработки успешного входа в систему
  };

  const handle2FASuccess = async (token: string) => {
    try {
      const userId = user?.id; // Предполагая, что userId доступен из объекта user
      const response = await dispatch(verifyToken({ token, userId })).unwrap();
      // console.log('🚀 ~ handle2FASuccess ~ response:', response);

      if (response.verified) {
        setModalOpen(false);
        navigate('/advertising-systems-office-home');
      } else {
        console.error('Verification failed: incorrect token');
      }
    } catch (error) {
      console.error('Verification failed', error);
    }
  };

  const handleClick = () => {
    navigate('/register');
  };

  return (
    <MainLayout>
      <div className='registration--min-height'>
        <div className='registration__column'>
          <div className='registration__main'>
            <div className='registration__left-side'>
              <img src={FlatIlustration} alt='Flat illustration' />
            </div>
            <LoginForm onLoginSuccess={handleLoginSuccess} />
          </div>
        </div>
        <div className='registration__signin-section'>
          <p>Нет аккаунта?</p>
          <ButtonWithIcon
            btnText='Зарегистрироваться'
            withIcon={true}
            icon={<UserIcon />}
            btnIconClassName='register-header-btn-icon'
            className='registration-header-btn-icon'
            btnOnClick={handleClick}
          />
        </div>
      </div>
      <Modal2FA
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handle2FASuccess}
      />
    </MainLayout>
  );
};

export default LoginPage;
