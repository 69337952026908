import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../app/store';
import { calculateUserBalances } from '../../features/get-all-users/get-all-users-slice';

export const useFinances = (userEmail: string) => {
    const dispatch = useDispatch<AppDispatch>();
    const [totalAmount, setTotalAmount] = useState(0);

    // Используем useMemo для кэширования данных баланса
    const cachedBalance = useMemo(() => {
        // Инициализируем кэш как пустой объект
        const balanceCache: Record<string, number> = {};
        return balanceCache;
    }, []);

    useEffect(() => {
        const fetchTotalAmount = async () => {
            try {

                // Проверяем, есть ли уже кэшированный баланс
                if (cachedBalance[userEmail] !== undefined) {
                    setTotalAmount(cachedBalance[userEmail]);
                    return;
                }

                // Вызовите calculateUserBalances через dispatch
                const actionResult = await dispatch(calculateUserBalances());

                // Извлекаем payload с балансами
                const balances = actionResult.payload;

                // Сохраняем в кэш и обновляем состояние
                cachedBalance[userEmail] = balances[userEmail] || 0;
                // Устанавливаем сумму в состояние
                setTotalAmount(balances[userEmail] || 0);
            } catch (error) {
                console.error('Ошибка при вычислении баланса пользователя:', error);
            }
        };

        if (userEmail) {
            fetchTotalAmount();
        }
    }, [userEmail, dispatch, cachedBalance]);

    return { totalAmount };
};
