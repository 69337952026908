import React from 'react';
import './container.scss';

interface ContainerProps {
  children: React.ReactNode;
  className?: string; // Добавляем поддержку className
}

const Container: React.FC<ContainerProps> = ({ children, className = '' }) => {
  return (
    <div className={`container ${className}`}>
      {children}
    </div>
  );
};

export default Container;
