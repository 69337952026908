import React, { useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import ManagerLoginIcon from '../../../assets/images/manager-login.png';
import ManagerLoginForm from '../../ui/forms/manager-login/manager-login-form';
import InputLabel from '../../input/inputLabel/Input-label';
import ButtonComponent from '../../ui/button/button';
import { verifyToken } from '../../../features/two-factor-auth/two-factor-auth-slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './manager-login.scss';
import { AppDispatch } from '../../../app/store';

const ManagerLogin = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [token, setToken] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const user = useSelector((state: any) => state.login.user);
  const userId = user ? user.user.id : null;
  // console.log('🚀 ~ ManagerLogin ~ userId:', userId);

  const handleCancelModal = () => {
    setShowLoginModal(false);
  };

  const handleVerify = async () => {
    setIsLoading(true);
    const response = await dispatch(verifyToken({ token, userId }));
    setIsLoading(false);

    if (response.payload.verified) {
      navigate('/administrator-home');
    }
  };

  return (
    <MainLayout>
      {showLoginModal && (
        <div className='modal'>
          <div className='modal-overlay' onClick={handleCancelModal} />
          <div className='modal-content'>
            <button className='modal-close' onClick={handleCancelModal}>
              &times;
            </button>
            <div className='modal-content-2fa'>
              <h2>Google 2FA</h2>
              <p>Введите код из приложения</p>
              <div className='input-container'>
                <label htmlFor='code'>Код</label>
                <InputLabel
                  className='input-field'
                  labelClass='register-label'
                  type='text'
                  setValue={setToken}
                  value={token}
                  requireLabel={false}
                />
              </div>
              <div className='two-btn'>
                <ButtonComponent
                  className='cancel-btn'
                  btnText={'Отменить'}
                  btnOnClick={handleCancelModal}
                />
                <ButtonComponent
                  twoBtn
                  className='confirm-btn'
                  btnText={isLoading ? 'Проверка...' : 'Продолжить'}
                  btnOnClick={handleVerify}
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='registration__column'>
        <div className='registration__main'>
          <div className='registration__left-side'>
            <img src={ManagerLoginIcon} alt='FlatIlustration' />
          </div>
          <ManagerLoginForm setShowLoginModal={setShowLoginModal} />
        </div>
      </div>
    </MainLayout>
  );
};

export default ManagerLogin;
