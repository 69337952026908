import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './advertising-systems-office.scss';
import Facebook from '../../../assets/images/facebook.png';
import ButtonComponent from '../../ui/button/button';
import ArrowUp from '../../../assets/icons/arrow-up';
import ArrowDown from '../../../assets/icons/arrow-down';
import MainLayout from '../../layouts/mainLayout';
import { useDispatch, useSelector } from 'react-redux';
import { getOfficesByEmailAndNetworkType } from '../../../features/offices/get-offices-by-email/get-offices-by-email-slice';
import ModalComponent from '../../ui/modal/modal';
import CustomTable, { DataItem } from '../../ui/table/custom-table';
import { Icon } from '@iconify/react';
import ModalPositionAbsolute from '../../ui/modal-position-absolute/modal-position-absolute';
import { AppDispatch } from '../../../app/store';
import InputLabel from '../../input/inputLabel/Input-label';
import ErrorComponent from '../../ui/error/error-component';
import { addOfficeBalance } from '../../../features/add-office/add-office-slice';
import { createTicket } from '../../../features/tickets/ticketsSlice';
import { useFinances } from '../../../hooks/use-finances/useFinances';

const AdvertisingSystemsOffice = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );
  const offices = useSelector((state: any) => state.offices.offices);

  const { totalAmount } = useFinances(user?.email);

  const [showDetails, setShowDetails] = useState(true);
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [showRulesModal, setShowRulesModall] = useState(false);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [showAddToBalance, setShowAddToBalance] = useState(false);
  const [createTicketModal, setCreateTicketModal] = useState(false);
  const [addBalanceAmount, setAddBalanceAmount] = useState<any>('');
  const [emptyBalanceSum, setEmptyBalanceSum] = useState(false);
  const [selectedOffice, setSelectedOffice] = useState<DataItem | null>(null);
  const [ticketId, setTicketId] = useState<string | null>(null);

  const holderEmail = user?.email;
  const networkType = 'Facebook Ads';

  const descriptionModalRef = useRef<HTMLDivElement>(null);
  const rulesModalRef = useRef<HTMLDivElement>(null);
  const connectModalRef = useRef<HTMLDivElement>(null);

  const handleAddBalanceAmountChange = (value: string) => {
    // Убираем знак минус, если он есть
    const sanitizedValue = value.replace(/-/g, '');
    setAddBalanceAmount(sanitizedValue);
  };


  const handleCreateTicket = async () => {
    const balanceData = {
      officeId: selectedOffice?.id,
      accountName: selectedOffice?.accountName,
      advertisingNetwork: 'Facebook Ads',
      withdrawalAmount: addBalanceAmount, //+ 'USD'
      depositAmount: (addBalanceAmount * 0.93).toFixed(2), // + 'USD',
      commission: '7%',
      status: 'В обработке',
      userEmail: holderEmail,
    };

    if (addBalanceAmount.length < 1) {
      setEmptyBalanceSum(true);
    } else {
      setShowAddToBalance(false);
      setEmptyBalanceSum(false);
      setAddBalanceAmount('');
      dispatch(addOfficeBalance(balanceData));

      const ticketData = {
        holderEmail: holderEmail,
        status: 'Открыт',
        theme: 'Пополнение кабинета',
        comment: addBalanceAmount,
        message: '',
        manager: '',
        date: new Date(),
      };

      const result = await dispatch(createTicket(ticketData));
      if (result?.payload?.ticketId) {
        setTicketId(result.payload.ticketId);
        setCreateTicketModal(true);
      }
    }
  };

  const handleNavogateTicket = () => {
    navigate('/tickets')
  }

  const handleCancelOffices = () => {
    setCreateTicketModal(false);
  };

  const handleAddBalanceClick = (item: DataItem) => {
    setSelectedOffice(item);
    setShowAddToBalance(true);
  };

  useEffect(() => {
    if (holderEmail) {
      dispatch(getOfficesByEmailAndNetworkType({ holderEmail, networkType }));
    }
  }, [dispatch, holderEmail, networkType, location]);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const handleOpenDescriptionModal = () => {
    setShowDescriptionModal(true);
  };

  const handleRulesModal = () => {
    setShowRulesModall(true);
  };

  const handleConnectModal = () => {
    setShowConnectModal(true);
  };

  const handleNavigateAddOffice = () => {
    navigate('/add-advertising-systems-office');
  };

  const handleCloseRolesModal = () => {
    setShowRulesModall(false);
  };

  const handleCloseConnectModal = () => {
    setShowConnectModal(false);
  };

  const handleBackClick = () => {
    navigate('/advertising-systems-office-home');
  };

  useEffect(() => {
    const handlePopState = (event: any) => {
      if (location.pathname === '/advertising-systems-office') {
        navigate('/advertising-systems-office');
      }
    };

    window.addEventListener('popstate', handlePopState);
  }, [location, navigate]);

  const columns = {
    id: 'ID',
    accountName: 'Название кабинета',
    landingPage: 'Landing page / Аpp id',
    totalTransactionsAmount: 'Сумма пополнений',
    percentValue: 'Комиссия',
    status: 'Статус',
    actions: '',
  };

  const mapOfficesToDataItems = (offices: any) =>
    offices?.map((office: any) => ({
      id: office.officeId,
      accountName: office.accountName,
      landingPage: office.landingPage || 'N/A',
      totalTransactionsAmount: office?.totalTransactionsAmount || '0',
      percentValue: office.commission || '-',
      status: office.status,
      actions: 'Пополнить',
    }));

  const dataItems = mapOfficesToDataItems(offices);

  const handleCancel = () => {
    setShowAddToBalance(false);
  };

  const handleClickOutside = (e: MouseEvent) => {
    if (descriptionModalRef.current && !descriptionModalRef.current.contains(e.target as Node)) {
      setShowDescriptionModal(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <MainLayout>
      {showDescriptionModal && (
        <div className='modal-background' onClick={() => setShowDescriptionModal(false)}>
          <div className='modalPositionAbsolute' ref={descriptionModalRef} >
            <ModalPositionAbsolute
              className='description-box'
              modalBtnClassName='add-advetising-modal-btn'
              modalBtnHeaderClassName='add-advetising-modal-header'
              modalText='
            <div class="description__header">
                <h1>Описание</h1>
            </div>

            <div class="description">
              <div class="description__content">
                  <p><span>Facebook Ads</span> — это способ продвижения бренда, товаров и услуг через многомиллионную социальную сеть с использованием передовых технологий. Facebook позволяет создавать броские и эффективные рекламные объявления, которые можно таргетировать на свою аудиторию.</p>
              </div>
        
              <div class="description__platforms">
                  <h2>Через Ads Manager можно разместить рекламу на четырех платформах:</h2>
                  <ul>
                      <li>в Facebook;</li>
                      <li>в Instagram;</li>
                      <li>в сервисе для звонков и обмена сообщениями Messenger;</li>
                      <li>в Audience Network — партнерской сети сайтов и приложений, не принадлежащих Facebook.</li>
                  </ul>
              </div>
        
              <div class="description__parameters">
                  <h2>Основные параметры ФБ рекламы:</h2>
                  <ol> 
                      <li>большая аудитория (аудитория Facebook-более трети всего населения планеты и не перестает расти);</li>
                      <li>возможности таргетинга (Facebook Ads, помимо базового таргетинга по соцдему и местоположению, позволяет настраивать объявления на основании покупательского опыта клиентов, их состава семьи, места работы, профессии и так далее);</li>
                      <li>рентабельность (Facebook утверждает, что вы можете проводить результативные рекламные кампании с любым бюджетом);</li>
                      <li>эффективные инструменты для успешной работы (например, вы можете создавать цепляющие рекламные креативы, формировать разные типы аудитории, планировать публикации и продвижение постов, устанавливать график показа рекламы, и многое другое).</li>
                  </ol>
              </div>
        
              <div class="description__formats">
                  <h2>Форматы рекламы:</h2>
                  <ul>
                      <li>одиночное изображение;</li>
                      <li>одиночное видео;</li>
                      <li>слайд-шоу;</li>
                      <li>карусель.</li>
                  </ul>
              </div>
        
              <div class="description__campaign">
                  <p>Доступные форматы рекламы зависят от выбранной цели кампании.</p>
              </div>
            </div>'
              firstBtnText='Понятно'
              firstBtnClick={() => setShowDescriptionModal(false)}
            />
          </div>
        </div>
      )}

      {showRulesModal && (
        <div
          className='modal-background'
          onClick={() => setShowRulesModall(false)}
        >
          <div
            className='rules-modal-box'
            ref={rulesModalRef}
          >
            <ModalComponent
              className='description-box description-modal rules-modal-box'
              modalBtnClassName='add-advetising-modal-btn'
              modalBtnHeaderClassName='add-advetising-modal-header'
              modalText='
            <div>
              <h1>Правила</h1>
              <label for="rules-modal-list">Запрещается:</label>
              <ul id="rules-modal-list">
                <li>клоачить,</li>
                <li>изображать в креативах проявления насилия, ресчлененку,</li>
                <li>адалт.</li> 
              </ul>
              <p>При нарушении правил происходит блокировка аккаунта.</p>
            </div>
            '
              firstBtnText='Понятно'
              firstBtnClick={handleCloseRolesModal}
            />
          </div>
        </div>
      )}

      {showConnectModal && (
        <div
          className='modal-background'
          onClick={() => setShowConnectModal(false)}>
          <div className='rules-modal-box' ref={connectModalRef}>
            <ModalComponent
              className='description-modal facebook-ads-box'
              modalBtnClassName='add-advetising-modal-btn'
              modalBtnHeaderClassName='add-advetising-modal-header'
              modalText='
            <div>
              <h1>Как подключить аккаунт Facebook Ads</h1>
              <ol>
                <li>Нажмите на кнопку «Добавить кабинет».</li>
                <li>Введите данные</li>
                <li>Далее "Создать"</li>
                <li>На почту,  придёт пароль для доступа в рекламную сеть.</li>
              </ol>
            </div>
            '
              firstBtnText='Понятно'
              firstBtnClick={handleCloseConnectModal}
            />
          </div>
        </div>
      )}

      {showAddToBalance && (
        <div className='add-ads-balance-modal'>
          <div className='add-ads-balance-modal-content'>
            <p className='add-ads-balance-modal__title'>
              Пополнение баланса кабинета
            </p>
            <div className='available-balance'>
              <p>Доступная сумма</p>
              <p>
                <span className='available-balance-sum'>{totalAmount}</span> USD
              </p>
            </div>
            <InputLabel
              className={`register-input add-balance-input`}
              labelClass='register-label'
              label='Сумма пополнения'
              type='number'
              rightText='USD'
              currencyClassName='ads-balance-currency ads-currency'
              setValue={handleAddBalanceAmountChange}
              value={addBalanceAmount}
              requireLabel={false}
            />
            <p className='available-balance-foot-label'>На кабинет рекламной системы зачислится:   {addBalanceAmount ? (addBalanceAmount - addBalanceAmount * 0.07).toFixed(2) : 0} {addBalanceAmount ? 'USD' : ''}  </p>
            {emptyBalanceSum && <ErrorComponent
              error='Введите сумму'
              errorClassName='empty-balance-office'
            />}
            <div className='add-balance__modal-container__main-box__btns'>
              <ButtonComponent
                btnText={'Отменить'}
                btnIconClassName='register-header-btn-icon'
                className='cancel-add-balance-modal-btn'
                btnOnClick={handleCancel}
              />
              <ButtonComponent
                btnText={'Создать тикет'}
                btnIconClassName='register-header-btn-icon'
                className='add-balance-modal-btn'
                btnOnClick={handleCreateTicket}
                disabled={totalAmount < addBalanceAmount}
              />
            </div>
          </div>
        </div>
      )}

      {createTicketModal && (
        <div className='add-ads-balance-modal'>
          <div className='add-ads-balance-modal-content create-ticket-modal'>
            <p className='create-ticket-txt'>
              Создан тикет <span className='purple-text'>#{ticketId}</span> на
              пополнение
            </p>
            <p className='create-ticket-email-title'>
              Сообщение о результате пополнения будет направлено на email{' '}
              <span className='create-ticket-email'>{user?.email}</span>
            </p>
            <div className='add-balance__modal-container__main-box__btns'>
              <ButtonComponent
                btnText={'К кабинетам'}
                btnIconClassName='register-header-btn-icon'
                className='cancel-add-balance-modal-btn create-ticket-btn'
                btnOnClick={handleCancelOffices}
              />
              <ButtonComponent
                btnText={'К тикетам'}
                btnIconClassName='register-header-btn-icon'
                className='add-balance-modal-btn create-ticket-btn '
                btnOnClick={handleNavogateTicket}
              />
            </div>
          </div>
        </div>
      )}

      <div className='networks-box recovery-container recovery-container--justify-start ads-offices'>
        <div
          className='advertising-systems-office__back-button'
          onClick={handleBackClick}
        >
          <Icon
            icon='iconamoon:arrow-left-2-bold'
            width='24px'
            color='#8F97A9'
          />
          назад
        </div>

        <div className='networks-item'>
          <div className='networks-item--left'>
            <div className='networks-left-side-box'>
              <div className='networks-left-side-box__child'>
                <img src={Facebook} alt='Facebook Ads' />
                <div className='networks-title'>Fb Accounts</div>
              </div>

              <div className='networks-status'>
                Подключенных кабинетов <span> {offices?.length} </span>
              </div>
              <ButtonComponent
                btnText='+ Добавить кабинет'
                className='advertising-btn advertising-btn--mobile-hidden'
                btnOnClick={handleNavigateAddOffice}
              />
            </div>

            <div className='available-balance-main'>
              <p className='ads-available-sum'>Доступная сумма</p>
              <p>
                <span className='available-balance-sum-main'> <span className='available-balance-sum-main-black'>{totalAmount}</span> </span> USD
              </p>
            </div>
          </div>

          <div className='networks-item__right-side'>
            <div
              className='networks-item__hidden-function'
              onClick={toggleDetails}
            >
              <div>{showDetails ? 'Скрыть' : 'Показать'} подробности</div>
              {showDetails ? <ArrowUp /> : <ArrowDown />}
            </div>

            {showDetails && (
              <div className='networks-right-side-box'>
                <p className='networks-right-side-text'>
                  Валюта:{' '}
                  <span className='networks-right-side-span'> USD </span>
                </p>
                <p className='networks-right-side-text'>
                  Минимальное пополнение:{' '}
                  <span className='networks-right-side-span'> $100 </span>
                </p>
                <p className='networks-right-side-text'>
                  ВАЖНО:{' '}
                  <span className='networks-right-side-span'>
                    {' '}
                    Для доступов к рекламным кабинетам необходимо указать email
                    вне RU зоны. На email в зонах .ru, .su, .рф доступы выданы
                    не будут.{' '}
                  </span>
                </p>
              </div>
            )}

            <div className='networks-right-side-btns-box'>
              <ButtonComponent
                btnText='Описание'
                className='networks-right-side-btn description-ads'
                btnOnClick={handleOpenDescriptionModal}
              />
              <ButtonComponent
                btnText='Правила'
                className='networks-right-side-btn description-ads'
                btnOnClick={handleRulesModal}
              />
              <ButtonComponent
                btnText='Как подключить'
                className='networks-right-side-btn description-ads'
                btnOnClick={handleConnectModal}
              />
            </div>
            <div className='networks-item--center'>
              <ButtonComponent
                btnText='+ Добавить кабинет'
                className='advertising-btn advertising-btn--desktop-hidden  mobile-ads-system'
                btnOnClick={handleNavigateAddOffice}
              />
            </div>
          </div>
        </div>
        {offices?.length > 0 ? (
          <>
            <CustomTable
              columnHeaders={columns}
              data={dataItems}
              className={'ads-offices-table'}
              searchBy='accountName'
              addBalanceClick={handleAddBalanceClick}
              dontshowlable={false}
              firstTableDataClassName='first-data-classname'
            />
          </>
        ) : (
          <div className='no-cabinet'>
            <h2 className='no-cabinet__title'>Нет кабинетов</h2>
            <p className='no-cabinet__description'>
              Ознакомьтесь{' '}
              <span
                className='no-cabinet__description-link'
                onClick={handleRulesModal}
              >
                c правилами подключения
              </span>{' '}
              кабинетов
            </p>
            <ButtonComponent
              btnText='+ Добавить кабинет'
              className='no-cabinet__button advertising-btn advertising-btn-third '
              btnOnClick={handleNavigateAddOffice}
            />
          </div>
        )}
      </div>
    </MainLayout>
  );
};

export default AdvertisingSystemsOffice;
