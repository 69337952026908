import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const getUsersByManager = createAsyncThunk(
  'manager/get-users-by-managers',
  async ( managerEmail : any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/manager/get-users-by-managers',  { managerEmail } );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getUsersByManagerSlice = createSlice({
  name: 'offices',
  initialState: {
    usersByManager: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUsersByManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUsersByManager.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.usersByManager = action.payload;
      })
      .addCase(getUsersByManager.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default getUsersByManagerSlice.reducer;
