import React from 'react'
import MainLayout from '../../layouts/mainLayout'
import './contacts.scss'
import contactEmail from '../../../assets/images/contactEmail.svg'
import contactSkype from '../../../assets/images/contactSkype.svg'
import contactTelegram from '../../../assets/images/contactTelegram.svg'
import contactWhatsApp from '../../../assets/images/contactWhatsApp.svg'
import ContactCopy from '../../../assets/images/contactCopy.svg';

const Contacts = () => {
  const elems = [
    { title: 'Telegram', imgSrc: contactTelegram, text: '@Ad_Kitchen' },
    { title: 'WhatsApp', imgSrc: contactWhatsApp, text: '+7 926 118-19-93' },
    { title: 'Skype', imgSrc: contactSkype, text: 'live:.cid.963c929f2d9f2dbc' },
    { title: 'Email', imgSrc: contactEmail, text: 'info@ad.kitchen' },
  ]

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    // setCopySuccess(true);
    // setTimeout(() => {
    //   setCopySuccess(false);
    // }, 1000);
  };
  return (
    <MainLayout>
      <div className='contacts-container'>
        <p className='contacts-main-title'>Контакты</p>
        <div className='contacts-elements-main-box'>
        {elems.map((item) => (
          <div className='contacts-soc-item'>
            <div className='contac-text-left'>
              <img src={item.imgSrc} />
              <div className='contaci-text'>
                <p className='contact-item-title'>{item.title} </p>
                <p>{item.text} </p>
              </div>
            </div>
            <img
              src={ContactCopy}
              alt='Copy'
              className='contact-sopy-btn'
              onClick={() => handleCopy(item.text)}
            />
          </div>
        ))}

        </div>
        <p className='contact-company-name'>ТОО "MarketingCorp"</p>
        <p className='contact-company-adress'>Казахстан, город Алматы, Бостандыкский район, улица Ауэзова,</p>
        <p className='contact-company-adress-second'>здание 175, кв. 8а, почтовый индекс 050057</p>
      </div>
    </MainLayout>
  )
}

export default Contacts