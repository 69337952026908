import React, { useState } from 'react';
import ButtonComponent from '../../ui/button/button';
import { useNavigate } from 'react-router-dom';
import InputLabel from '../../input/inputLabel/Input-label';
import './add-advertising-systems-office.scss';
import '../registrationPage/registration-page.scss';
import { useDispatch, useSelector } from 'react-redux';
import { addNewOffice } from '../../../features/add-office/add-office-slice';
import Facebook from '../../../assets/images/facebook.png';
import ModalComponent from '../../ui/modal/modal';
import MainLayout from '../../layouts/mainLayout';
import ErrorComponent from '../../ui/error/error-component';
import TimeZoneSelectComponent from '../../ui/time-zone-select/time-zone-select';
import Spinner from '../../ui/spinner/spinner';
import { getOfficesByEmailAndNetworkType } from '../../../features/offices/get-offices-by-email/get-offices-by-email-slice';
import { AppDispatch } from '../../../app/store';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const AddAdvertisingSystemsOffice: React.FC = () => {
  const [accountName, setAccountName] = useState('');
  const [landingPage, setLandingPage] = useState('');
  const [FBAPPID, setFBAPPID] = useState('');
  const [BMID, setBMID] = useState('');
  const [FPProfileAccess, setFPProfileAccess] = useState('');
  const [IDXP, setIDXP] = useState('');
  const [accountTimeZone, setAccountTimeZone] = useState('GMT+03');
  const [messengerType, setMessengerType] = useState('skype');
  const [showModal, setShowModal] = useState(false);
  const [landingError, setLandingError] = useState(false);
  const [appIdError, setAppIdError] = useState(false);
  const [BMIDError, setBMIDError] = useState(false);
  const [firstTimeRechargeError, setFirstTimeRechargeError] = useState(false);
  const [IDXPError, setIDXPError] = useState(false);
  const [accountNameError, setAccountNameError] = useState(false);
  const [messengerError, setMessengerError] = useState(false);
  const isLoading = useSelector((state: any) => state.addOffice?.isLoading);

  const IDXPNumber = Number(IDXP);

  const navigate = useNavigate();

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );
  const dispatch = useDispatch<AppDispatch>();

  const handleOffices = () => {
    navigate('/advertising-systems-office');
  };

  const holderEmail = user?.email;

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setAccountNameError(false);
    setBMIDError(false);
    setIDXPError(false);
    setMessengerError(false);

    let hasError = false;
    if (!accountName) {
      setAccountNameError(true);
      hasError = true;
    }

    if (!BMID) {
      setBMIDError(true);
      hasError = true;
    }

    if (!IDXP) {
      setIDXPError(true);
      hasError = true;
    }

    if (hasError) {
      return;
    }

    const networkType = 'Facebook Ads';

    const date = new Date().toISOString();
    const status = 'На рассмотрении';
    const theme = 'На рассмотрении';
    const comment = '	Создание кабинета';
    const message = 'На рассмотрении';
    const manager = user.manager;

    const userData = {
      accountName,
      landingPage,
      FBAPPID,
      BMID,
      FPProfileAccess,
      IDXP,
      accountTimeZone,
      holderEmail,
      messengerType,
      networkType,
      date,
      status,
      theme,
      comment,
      message,
      manager,
    };

    try {
      if (landingPage && !/^(ftp|http|https):\/\/[^ "]+$/.test(landingPage)) {
        setLandingError(true);
      } else {
        setLandingError(false);
      }

      if (
        FBAPPID &&
        !(typeof FBAPPID === 'string' || typeof FBAPPID === 'number')
      ) {
        setAppIdError(true);
      } else {
        setAppIdError(false);
      }

      if (typeof BMID !== 'string' && typeof BMID !== 'number') {
        setBMIDError(true);
      } else {
        setBMIDError(false);
      }

      if (isNaN(IDXPNumber)) {
        setIDXPError(true);
      } else {
        setIDXPError(false);
      }

      let result;

      if (
        !landingError &&
        !appIdError &&
        !BMIDError &&
        !firstTimeRechargeError &&
        !IDXPError
      ) {
        result = await dispatch(addNewOffice(userData)).unwrap();
      }

      if (result?._id) {
        dispatch(getOfficesByEmailAndNetworkType({ holderEmail, networkType }));
        setShowModal(true);

        // const ticketData = {
        //   holderEmail: user?.email,
        //   status: "Открыт",
        //   theme: "Создание кабинета",
        //   comment: "",
        //   message: "",
        //   manager: "",
        //   date: new Date(),
        // };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'formSuccess',
          formName: 'fb',
        });
      }
    } catch (rejectedValueOrSerializedError) { }
  };

  const handleCancelBtn = () => {
    navigate('/advertising-systems-office');
  };

  const timeZoneOptions = [
    'GMT+00',
    'GMT+01',
    'GMT+02',
    'GMT+03',
    'GMT+04',
    'GMT+05',
    'GMT+06',
    'GMT+07',
    'GMT+08',
    'GMT+09',
    'GMT+10',
    'GMT+11',
    'GMT+12',
    'GMT-01',
    'GMT-02',
    'GMT-03',
    'GMT-04',
    'GMT-05',
    'GMT-06',
    'GMT-07',
    'GMT-08',
    'GMT-09',
    'GMT-10',
    'GMT-11',
  ];

  return (
    <MainLayout>
      {showModal && (
        <>
          <div className='header-absolute-background-color position-fixed'></div>
          <ModalComponent
            className='add-advetising-modal'
            firstBtnText='К кабинетам'
            modalBtnClassName='add-advetising-modal-btn'
            modalBtnHeaderClassName='add-advetising-modal-header'
            firstBtnClick={() => handleOffices()}
            modalText='<p className={modalBtnHeaderClassName}>
            Спасибо, данные отправлены <br /> на модерацию.
          </p>
          <p>С вами свяжется менеджер</p>'
          />
        </>
      )}
      <div className='networks-box add-advetising-box'>
        <form onSubmit={handleSubmit}>
          <div className='advertising-systems-page-container'>
            {isLoading && (
              <div
                className={'advertising-systems-page-container__absolute-layer'}
              >
                <Spinner />
              </div>
            )}
            <div className='add-advetising'>
              <div className='add-advetising__fb-ads'>
                <img src={Facebook} alt='Facebook Ads' />
                <div className='networks-title'>Fb Accounts</div>
              </div>
              <div>
                Агентский кабинет Fb.
              </div>
            </div>
            <InputLabel
              className={`register-input ${accountNameError ? 'error-input' : ''
                }`}
              labelClass='register-label'
              label='Название аккаунта'
              type='text'
              setValue={setAccountName}
              value={accountName}
              requireLabel={true}
            />
            {accountNameError && (
              <ErrorComponent
                error='Название аккаунта обязательно для заполнения'
                errorClassName=''
              />
            )}
            <InputLabel
              className={`register-input`}
              labelClass='register-label'
              label='Landing page'
              type='text'
              setValue={setLandingPage}
              value={landingPage}
              requireLabel={false}
            />
            {landingError && (
              <ErrorComponent
                error='Landing страница должна быть действующим URL-адресом'
                errorClassName=''
              />
            )}
            <InputLabel
              className={`register-input`}
              labelClass='register-label'
              label='FB APP ID'
              type='text'
              setValue={setFBAPPID}
              value={FBAPPID}
              requireLabel={false}
            />
            {appIdError && (
              <ErrorComponent
                error='FB APP ID должен быть строкой или числом'
                errorClassName=''
              />
            )}
            <InputLabel
              className={`register-input ${BMIDError ? 'error-input' : ''}`}
              labelClass='register-label'
              label='BM ID'
              type='text'
              setValue={setBMID}
              value={BMID}
              requireLabel={true}
            />
            {BMIDError && (
              <ErrorComponent
                error='BM ID обязательно для заполнения'
                errorClassName=''
              />
            )}

            <InputLabel
              className={`register-input ${IDXPError ? 'error-input' : ''}`}
              labelClass='register-label'
              label='ID вебмастера ПП X-Partners'
              type='text'
              setValue={setIDXP}
              value={IDXP}
              requireLabel={true}
              placeholder='Введите ID'
            />
            {IDXPError && (
              <ErrorComponent
                error='ID вебмастера XP обязательно для заполнения'
                errorClassName=''
              />
            )}
            <div className='full-width'>
              <div className='label-class'>
                В каком часовом поясе открывать аккаунт
              </div>
              <TimeZoneSelectComponent
                className='time-zone-select add-office-time-zone'
                options={timeZoneOptions}
                value={accountTimeZone}
                onChange={(e: any) => setAccountTimeZone(e.target.value)}
              />
            </div>
            <InputLabel
              className={`register-input`}
              labelClass='register-label'
              label='Расшарить права ФП на этот профиль'
              type='email'
              setValue={setFPProfileAccess}
              value={FPProfileAccess}
              requireLabel={false}
              placeholder='Введите email'
            />

            <div className='add-advetising-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='add-advetising-btn-cancel add-advetising-btn-cancel-ads'
                btnOnClick={handleCancelBtn}
              />
              <ButtonComponent
                btnText='Создать кабинет'
                className='add-office-btn-create'
                btnOnClick={handleSubmit}
              />
            </div>
          </div>
        </form>
      </div>
    </MainLayout>
  );
};

export default AddAdvertisingSystemsOffice;
