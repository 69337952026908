import React, { useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import './add-balance.scss';
import Capital from '../../../assets/images/balance-capital.png';
import BalanceLogo from '../../../assets/images/balance-logo.png';
import BalanceTenzor from '../../../assets/images/balance-tenzor.png';
import BalanceCircle from '../../../assets/images/balance-circle.png';
import BalanceRedCircle from '../../../assets/images/balance-red-circle.png';
import CopyBtn from '../../../assets/images/copy-btn.png';
import ButtonComponent from '../../ui/button/button';
import InputLabel from '../../input/inputLabel/Input-label';
import { useDispatch, useSelector } from 'react-redux';
import { addFinance } from '../../../features/finance/finance-slice';
import CloseModal from '../../../assets/icons/modal-finance-close.svg';
import ErrorComponent from '../../ui/error/error-component';
import { useNavigate } from 'react-router-dom';
import { createTicket } from '../../../features/tickets/ticketsSlice';
import { AppDispatch } from '../../../app/store';
import { useFinances } from '../../../hooks/use-finances/useFinances';

const AddBalance = () => {
  const [selectCapitalist, setSelectCapitalist] = useState(false);
  const [selectTenzor, setTelectTenzor] = useState(false);
  const [selectPartners, setSelectPartners] = useState(false);
  const [financeModal, setFinanceModal] = useState(false);
  const [emptyBalance, setEmptyBalance] = useState(false);
  const [emptySystem, setEmptySystem] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [ticketId, setTicketId] = useState<string | null>(null);

  const [balanceSum, setBalanceSum] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );

  const { totalAmount } = useFinances(user?.email);

  const handleBalanceInputChange = (value: string) => {
    // Убираем знак минус, если он есть
    const sanitizedValue = value.replace(/-/g, '');
    setBalanceSum(sanitizedValue);
  };


  const selectCapitalistBox = () => {
    setSelectCapitalist(!selectCapitalist);
    setTelectTenzor(false);
    setSelectPartners(false);
  };

  const selectTenzorBox = () => {
    setTelectTenzor(!selectTenzor);
    setSelectCapitalist(false);
    setSelectPartners(false);
  };

  const selectPartnersBox = () => {
    setSelectPartners(!selectPartners);
    setSelectCapitalist(false);
    setTelectTenzor(false);
  };
  const navigate = useNavigate();

  const handleCancel = () => {
    setSelectPartners(false);
    setSelectCapitalist(false);
    setTelectTenzor(false);
    navigate('/advertising-systems-office');
  };

  const walletNumber = 'U12740506';
  const USDTNumber = 'TBPaMBNvpv9VG3ZvhvfiSzrv8xYtqimGez';

  const balanceRules = [
    { text: 'Курс обмена:<br> 1 USDT (TRC 20) = 1.00 USD', bg: 'blue' },
    {
      text: 'Минимальная сумма <br> пополнения: 500 USD или USDT (TRC20)',
      bg: 'blue',
    },
    { text: 'Максимальная сумма <br> пополнения: не ограничена', bg: 'blue' },
    { text: 'Комиссия при пополнении: 1.00%', bg: '' },
    {
      text: 'Обратите внимание, что на стороне платежной системы могут быть дополнительные комиссии.',
      bg: '',
    },
    {
      text: 'Правила возврата: сумма возврата не менее 100. комиссия 3%, но не менее 10. Срок возврата: до 7 рабочих дней.',
      bg: '',
    },
  ];

  const addBalanceOption = [
    { text: 'Capitalist', icon: Capital, id: 1 },
    { text: 'USDT', icon: BalanceTenzor, id: 2 },
    { text: 'Баланс вебмастера ', icon: BalanceLogo, id: 3 },
  ];

  const handleCopy = (text: string) => {
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
    navigator.clipboard
      .writeText(text)
      .then(() => {
        console.log('Text copied to clipboard');
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });

  };

  const handleCreateTicket = async () => {
    const paymentMethod: any = selectCapitalist
      ? 'Capitalist'
      : selectTenzor
        ? 'USDT'
        : selectPartners
          ? 'X-Partners'
          : null;

    if (balanceSum.length < 1) {
      setEmptyBalance(true);
    } else {
      setEmptyBalance(false);
    }

    if (paymentMethod === null) {
      setEmptySystem(true);
    } else {
      setEmptySystem(false);
    }

    if (paymentMethod && balanceSum) {
      const financeData = {
        date: new Date(),
        amount: parseFloat(balanceSum),
        paymentMethod: paymentMethod,
        bonus: paymentMethod === 'X-Partners' ? 3 : 0,
        status: 'В обработке',
        user: user?.email,
      };

      const financeResult: any = await dispatch(addFinance(financeData));

      if (financeResult) {
        const ticketData = {
          holderEmail: user?.email,
          status: "Открыт",
          theme: "Пополнение баланса ad.kitchen",
          comment: balanceSum + " USD",
          message: "Please resolve this issue ASAP",
          manager: user?.manager,
          date: new Date(),
        };

        const ticketResult: any = await dispatch(createTicket(ticketData));

        if (ticketResult?.payload?.ticketId) {
          setTicketId(ticketResult.payload.ticketId);
          setFinanceModal(true);
          setBalanceSum('');
        }
      }
    } else {
      console.error('Please select a payment method and enter a valid amount.');
    }
  };

  const handleToTicketRoute = () => {
    navigate('/tickets')
  }

  const handleCloseModal = () => {
    setFinanceModal(false)
  }

  return (
    <MainLayout>
      {financeModal &&
        // add-balance__modal-container
        <div className='add-ads-balance-modal'>
          {/* add-balance__modal-container__main-box mobile-modal-box-balance */}
          <div className='add-ads-balance-modal-content add-balance__modal-container__main-box'>
            <img src={CloseModal} className='close-finance-modal' onClick={handleCloseModal} alt='close-finance-modal' />
            <p>Создан тикет <span className='purple-text'>#{ticketId}</span>  на пополнение</p>
            <p className='add-balance__modal-container__main-box__content'>Пожалуйста, произведите оплату и дождитесь подтверждения. Обычно мы зачисляем средства в течение 120 минут после перевода</p>
            <div className='add-balance__modal-container__main-box__content'>Сообщение о результате пополнения будет направлено на email <span className='add-balance__user-email'>{user.email}</span></div>
            <div className='add-balance__modal-container__main-box__btns'>
              <ButtonComponent
                btnText={'К кабинетам'}
                btnIconClassName='register-header-btn-icon'
                className='add-balance-modal-btn'
                btnOnClick={handleCancel}
              />
              <ButtonComponent
                btnText={'К тикетам'}
                btnIconClassName='register-header-btn-icon'
                className='add-balance-modal-btn'
                btnOnClick={handleToTicketRoute}
              />
            </div>
          </div>

        </div>}

      <div className='networks-box recovery-container recovery-container--justify-start'>
        <div className='add-balance-main-box'>
          <div className='add-balance-container'>
            <div className='current-balance'>Текущий баланс</div>
            <div className='balance-display'>
              <div>
                <span className='balance-amount'>{totalAmount}{" "}</span>
                <span className='balance-currency'>USD</span>
              </div>
            </div>
          </div>
          <div className='add-balance__main'>

            <div className='add-balance__main-head'>Пополнение баланса</div>
            <div className='pay-method__container'>
              {addBalanceOption.map((item) => (
                <div
                  key={item.id}
                  className={`pay-method-box ${(item.id === 1 && selectCapitalist) ||
                    (item.id === 2 && selectTenzor) ||
                    (item.id === 3 && selectPartners)
                    ? 'active-balance-box'
                    : ''
                    } `}
                  onClick={
                    item.id === 1
                      ? selectCapitalistBox
                      : item.id === 2
                        ? selectTenzorBox
                        : selectPartnersBox
                  }
                >
                  <div className='pay-method-box__head'>
                    {item.text}
                    {(item.id === 1 && selectCapitalist) ||
                      (item.id === 2 && selectTenzor) ||
                      (item.id === 3 && selectPartners) ? (
                      <img src={BalanceRedCircle} width={24} height={24} alt='BalanceRedCircle' />
                    ) : (
                      <img src={BalanceCircle} alt='BalanceCircle' />
                    )}
                  </div>
                  <img src={item.icon} alt='itemIcon' />
                </div>
              ))}
            </div>

            {selectCapitalist && (
              <>
                <p className='capitalist-abb-balance-txt'>1. Создайте тикет на пополнение на этой странице</p>
                <p className='capitalist-abb-balance-txt'>2. Осуществите перевод по реквизитам ниже</p>
                <div className='wallet-num-box'>
                  {copySuccess && <div className='copy-success'>Скопировано!</div>}
                  <p>Номер кошелька для перевода </p>
                  <div
                    className='copy-box'
                    onClick={() => handleCopy(walletNumber)}
                  >
                    {walletNumber}
                    <img src={CopyBtn} alt='Copy' />
                  </div>
                </div>

                <p className='wallet-num-box__head'>Платёж будет обработан быстрее, если вы укажете в поле "назначение платежа" ваш логин </p>
                <div className='wallet-num-box'>
                  <p>в AdKitchen</p>
                  <div
                    className='copy-box'
                    onClick={() => handleCopy(user?.email)}
                  >
                    {user?.email}
                    <img src={CopyBtn} alt='Copy' />
                  </div>
                </div>
              </>
            )}
            {selectTenzor && (
              <>
                <p className='capitalist-abb-balance-txt'>1. Создайте тикет на пополнение на этой странице</p>
                <p className='capitalist-abb-balance-txt'>2. Осуществите перевод по реквизитам ниже</p>
                <div className='USDT-balance__head-box'>
                  <p className='capitalist-abb-balance-txt'>Адрес USDT (TRC20) для перевода со счета USDT-TRC.:</p>
                  {copySuccess && <div className='copy-success'>Скопировано!</div>}
                  <div
                    className='copy-box'
                    onClick={() => handleCopy(USDTNumber)}
                  >
                    {USDTNumber}
                    <img src={CopyBtn} alt='Copy' className='copy-btn' />
                  </div>
                </div>
                <p className='capitalist-abb-balance-txt'>Мы принимаем платежи только в сети TRON (TRC20)</p>
                <p className='capitalist-abb-balance-txt'>Для подтверждения оплаты мы можем запросить хэш транзации.</p>
              </>
            )}
            {selectPartners && (
              <>
                <p>
                  Пополнение производится в ручном
                  режиме. <br /> Чтобы воспользоваться этим способом, создайте заявку
                  на пополнение на этой странице. Менеджер XP свяжется с вами
                  для подтверждения выплаты на баланс AdKitchen.
                </p>
              </>
            )}
            {emptySystem && <ErrorComponent error='Выберите способ оплаты' errorClassName='add-balance-finance-error' />}

            <div className='add-balance__main-body'>
              <div className='balance-amount-box'>Сумма</div>
              <InputLabel
                className='balance-amount-input'
                type='number'
                placeholder='0.00000000'
                value={balanceSum}
                setValue={handleBalanceInputChange}
              />
              <p className='add-balance__usd-label'>USD</p>
            </div>
            {emptyBalance && <ErrorComponent error='Ведите сумму' errorClassName='add-balance-finance-error' />}
            <div className='add-balance__buttons-box'>
              <ButtonComponent
                btnText={'Отменить'}
                btnIconClassName='register-header-btn-icon'
                className='balance-cancel-btn'
                btnOnClick={handleCancel}
              />
              <ButtonComponent
                btnText={'Создать тикет'}
                btnIconClassName='register-header-btn-icon'
                className='balance-create-btn'
                btnOnClick={handleCreateTicket}
              />
            </div>
          </div>
          <div className='add-balance__rules-box'>
            {balanceRules.map((item, index) => (
              <div
                key={index}
                className={`rules-box ${item.bg === 'blue' ? 'blue-bg' : 'rose-bg'
                  }`}
              >
                <span dangerouslySetInnerHTML={{ __html: item.text }} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default AddBalance;
