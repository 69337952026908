import React, { useEffect, useRef, useState } from 'react';
import './custom-select-component.scss';
import useOnClickOutside from '../../../hooks/use-onclick-outside';
import { Icon } from '@iconify/react';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';

export interface Option {
  value: string;
  label: string;
}

interface CustomSelectComponentProps {
  className: string;
  selectOptions: Option[];
  setMessengerType?: (value: string) => void;
  selectWrapper: string;
  selectedRole?: string | null;
  defaultEmptyValue?: string;
  height?: string;
  onChange?: (option: Option) => void;
  customSelectWrapperClassName?: string;
}

const CustomSelectComponent: React.FC<CustomSelectComponentProps> = ({
  className,
  selectOptions,
  setMessengerType,
  selectWrapper,
  selectedRole,
  defaultEmptyValue,
  height = '54px',
  onChange,
  customSelectWrapperClassName,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | null>(null);

  useEffect(() => {
    if (defaultEmptyValue) {
      setSelectedOption(null);
    } else if (selectedRole) {
      const newDefaultValue =
        selectOptions.find((option) => option.value === selectedRole) || null;
      setSelectedOption(newDefaultValue);
    } else {
      setSelectedOption(selectOptions[0]);
    }
  }, []);

  useEffect(() => {
    if (selectedRole) {
      const newDefaultValue =
        selectOptions.find((option) => option.value === selectedRole) || null;
      setSelectedOption(newDefaultValue);
    }
  }, [selectedRole, selectOptions]);

  const handleChange = (option: Option) => {
    setSelectedOption(option);
    if (setMessengerType) { setMessengerType(option.value); }
    setMenuIsOpen(false);
    if (onChange) {
      onChange(option);
    }
  };

  useOnClickOutside(ref, () => setMenuIsOpen(false));

  useEffect(() => {
    if (!selectedRole) {
      setSelectedOption(null); // Сбрасываем выбор после добавления
    }
  }, [selectedRole]);


  return (
    <div className={selectWrapper} ref={ref}>
      <div
        className={`custom-select ${className}`}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
        style={{ height }}
      >
        <div
          className={`custom-select__selected  ${!selectedOption ? 'unselected' : ''
            } ${customSelectWrapperClassName ? customSelectWrapperClassName : ''}`}
        >
          {selectedOption ? selectedOption.label : defaultEmptyValue}{' '}
          <Icon
            className='custom-select__icon'
            icon='iconamoon:arrow-down-2-bold'
            width='24px'
            color='#8F97A9'
          />
        </div>
        {menuIsOpen && (
          <div className='custom-select__options'>
            {selectOptions.map((option) => (
              <div
                key={option.value}
                className='custom-select__option'
                onClick={() => handleChange(option)}
              >
                {capitalizeFirstLetter(option.label)}
                {selectedOption && selectedOption.value === option.value && (
                  <Icon
                    className='custom-select__check-icon'
                    icon='ic:round-done'
                    width='20px'
                    color='#000000'
                  />
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelectComponent;
