// require('dotenv').config();
export const API_URL = process.env.REACT_APP_API_URL;
export const APP_HOST = process.env.REACT_APP_HOST;

export const STATUS_OPENED = 'opened';
export const STATUS_CLOSED = 'closed'

// Функция для определения базового URL
export const getBaseURL = () => {
    // Если APP_HOST равен 'live', добавляем `/api/question` к API_URL
    return APP_HOST === 'live' ? `${API_URL}/api/question` : API_URL;
};