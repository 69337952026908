import React from 'react';
import RegistrationForm from '../../ui/forms/registration/RegistrationForm';
import './registration-page.scss';
import FlatIlustration from '../../../assets/images/flat_illustration_37 1.png';
import { Link } from 'react-router-dom';
import UserIcon from '../../../assets/icons/user-icon';
import ButtonWithIcon from '../../ui/button/buttonWithIcon';
import MainLayout from '../../layouts/mainLayout';

const RegistrationPage = () => {

  return (
    <MainLayout>
      <div className='registration--min-height'>
        <>
          <div className='registration__column'>
            <div className='registration__main'>
              <div className='registration__left-side'>
                <img src={FlatIlustration} alt='FlatIlustration' />
              </div>
              <RegistrationForm />
            </div>
          </div>
        </>
        <div className='registration__signin-section'>
          <p>Уже есть аккаунт?</p>
          <Link to='/login'>
            <ButtonWithIcon
              btnText={'Войти'}
              withIcon={true}
              icon={<UserIcon />}
              btnIconClassName='register-header-btn-icon'
              className='registration-header-btn-icon'
            />
          </Link>
        </div>
      </div>
    </MainLayout>
  );
};

export default RegistrationPage;
