import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { checkAuth } from './features/login/loginSlice';
import { AppDispatch, RootState } from './app/store';

const RoleBasedRedirect: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const isAuthChecked = useSelector(
    (state: RootState) => state.login.isAuthChecked
  );
  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );

  useEffect(() => {
    if (!isAuthChecked && localStorage.getItem('token')) {
      dispatch(checkAuth());
    }
  }, [dispatch, isAuthChecked]);

  useEffect(() => {
    if (isAuthChecked) {
      if (user?.role === 'administrator') {
        navigate('/administrator-home');
      } else if (user?.role === 'manager') {
        navigate('/manager-home');
      } else {
        navigate('/advertising-systems-office-home');
      }
    }
  }, [isAuthChecked, user, navigate]);

  return null;
};

export default RoleBasedRedirect;
