import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import './manager-profile.scss';
import InputLabel from '../../input/inputLabel/Input-label';
import ButtonComponent from '../../ui/button/button';
import { useDispatch, useSelector } from 'react-redux';
import { updateManagerProfile } from '../../../features/manager-profile/manager-profile-slice';
import { useNavigate } from 'react-router-dom';
import CheckedRedCircle from '../../../assets/icons/checked-red-circle.svg';
import ErrorComponent from '../../ui/error/error-component';
import RoutesList from '../../ui/routes-list/routes-list';
import SelectMessenger from '../../ui/select-messenger/select-messenger';
import ManagerPhone from '../../manager-phone/manager-phone';
import { updateUser } from '../../../features/login/loginSlice';
// import { getUserByEmail } from '../../../features/get-user-by-email/get-user-by-email-slice';
import { AppDispatch } from '../../../app/store';
import { disableTwoFactorAuth } from '../../../features/two-factor-auth/two-factor-auth-slice';

const ManagerProfile = () => {
  // const user = useSelector((state: any) => state.userByEmail?.userByEmail);
  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );
  // console.log('🚀 ~ ManagerProfile ~ user:', user);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [messengerType, setMessengerType] = useState(user?.messengerType);
  const [messenger, setMessenger] = useState(user?.messenger);
  const [showModal, setShowModal] = useState(false);
  const [emptyMessengerError, setEmptyMessengerError] = useState(false);
  const [email, setEmail] = useState(user?.email);
  const [selectedCountryState, setSelectedCountryState] = useState<any>({
    code: 'RU',
    mask: '+7 (999) 999-99-99',
    name: 'Russia',
  });
  const [phoneCountry, setPhoneCountry] = useState<any>(
    selectedCountryState.name || ''
  );
  const [emptyPasswordError, setEmptyPasswordError] = useState(false);
  const [noCorrectPasswordError, setNoCorrectPasswordError] = useState(false);
  const phoneCountryState = user?.phoneCountry;

  const formatPhoneNumber = (phone: string) => {
    return phone.replace(/\D/g, '');
  };

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleTwoAuth = () => {
    navigate('/two-factor-auth-manager');
  };

  const handleDisconnectTwoAuth = async () => {
    try {
      const userId = user?.id;
      const response = await dispatch(disableTwoFactorAuth(userId)).unwrap();
      if (response.success) {
        console.log('2FA отключена');
        dispatch(updateUser({ twoFactor: false }));
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
        }, 2000);
      } else {
        console.error('Ошибка при отключении 2FA');
      }
    } catch (error) {
      console.error('Ошибка при отключении 2FA', error);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formattedPhone = formatPhoneNumber(phone);

    if (oldPassword?.length < 1) {
      await setEmptyPasswordError(true);
    } else {
      await setEmptyPasswordError(false);
    }

    const userData = {
      email,
      phone: formattedPhone,
      oldPassword,
      newPassword,
      messenger,
      messengerType,
      phoneCountry: phoneCountry?.name,
    };
    try {
      if (!messenger || messenger.length < 2) {
        setEmptyMessengerError(true);
      } else {
        setEmptyMessengerError(false);
      }

      let resultJson: any;
      let result: any;
      if (email && oldPassword && messenger) {
        result = await dispatch(updateManagerProfile(userData)).then(
          (response: any) => {
            resultJson = response;
          }
        );

        if (resultJson?.payload?.email) {
          dispatch(updateUser({ ...resultJson.payload }));
          // dispatch(getUserByEmail(email));
          setNoCorrectPasswordError(false);
          setShowModal(true);

          setTimeout(() => {
            setShowModal(false);
          }, 2000);
        } else {
          setNoCorrectPasswordError(true);
        }

        const updatedUser = result?.user;
        if (updatedUser) {
          navigate('/manager-profile');
        } else {
          console.error('Updated user data is not available', result);
        }
      }
    } catch (rejectedValueOrSerializedError) {
      console.error('Error updating profile:', rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    setPhone(user?.phone || '');
  }, [user]);

  return (
    <MainLayout>
      <div className='networks-box-home'>
        <RoutesList />
        <div className='manager-profile__container'>
          <div className='manager-profile__box'>
            <p className='manager-profile__title'>Персональные настройки</p>
            <InputLabel
              className='register-input'
              labelClass='register-label'
              label='Email'
              type='email'
              setValue={setEmail}
              value={email}
              placeholder='name@mail.com'
              required
              requireLabel={true}
            />
            <InputLabel
              className='register-input'
              labelClass='register-label'
              label='Старый пароль'
              type='text'
              setValue={setOldPassword}
              value={oldPassword}
              required
              requireLabel={true}
            />
            {emptyPasswordError && (
              <ErrorComponent
                error='Это поле обязательно для заполнения'
                errorClassName=''
              />
            )}
            {noCorrectPasswordError && (
              <ErrorComponent error='Пароль неправильный' errorClassName='' />
            )}
            <InputLabel
              className='register-input'
              labelClass='register-label'
              label='Новый пароль'
              type='text'
              setValue={setNewPassword}
              value={newPassword}
              // requireLabel={false}
            />
            <InputLabel
              className='register-input'
              labelClass='register-label'
              label='Подтвердить новый пароль'
              type='text'
              setValue={setRepeatNewPassword}
              value={repeatNewPassword}
              requireLabel={false}
            />
            <div className='phone-container'>
              <div className='label-container'>
                <label className='register-label'>Телефон</label>
                {true && <p className='label-required'>*</p>}
              </div>
              <div className='phone-box'>
                <ManagerPhone
                  value={phone}
                  setValue={setPhone}
                  setSelectedCountryState={setSelectedCountryState}
                  phoneCountryState={phoneCountryState}
                  setPhoneCountry={setPhoneCountry}
                />
              </div>
            </div>
            <div className='phone-container'>
              <div className='label-container'>
                <label className='register-label'>Мессенджер</label>
                {true && <p className='label-required'>*</p>}
              </div>
              <div className='select-box'>
                <SelectMessenger
                  className='register-select'
                  setMessengerType={setMessengerType}
                  value={messengerType || ''}
                  setMessengerTypeValueChanged={() => {}}
                />
                <InputLabel
                  className='register-input registration-input'
                  labelClass='register-label'
                  type='text'
                  requireLabel={false}
                  setValue={setMessenger}
                  value={messenger}
                  required
                />
              </div>
              {emptyMessengerError && (
                <ErrorComponent
                  error='Это обязательно должен содержать минимум 2 символа'
                  errorClassName=''
                />
              )}
            </div>
            <div className='two-fatctor-settings__box'>
              <p>Двухфакторная аутентификация</p>
              {user?.twoFactor ? (
                <div
                  onClick={handleDisconnectTwoAuth}
                  className='connect-two-auth'
                >
                  Сбросить
                </div>
              ) : (
                <div onClick={handleTwoAuth} className='connect-two-auth'>
                  Подключить
                </div>
              )}
            </div>

            <div className='manager-profile__btn-box'>
              <div className='registration-container manager-profile__btn'>
                <ButtonComponent
                  btnText='Сохранить'
                  className='register-btn'
                  btnOnClick={handleSubmit}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showModal && (
        <div className='modal'>
          <div className='modal-content'>
            <img
              src={CheckedRedCircle}
              alt='User avatar'
              className='user-info__checked-circle'
            />
            <div>Мы успешно сохранили ваши изменения</div>
          </div>
        </div>
      )}
    </MainLayout>
  );
};

export default ManagerProfile;
