import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const removeManagerFromUsers = createAsyncThunk(
  'manager/remove-manager-from-users',
  async ({ managerEmail, userEmails }: { managerEmail: string, userEmails: string[] }, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/manager/remove-manager-from-users', { managerEmail, userEmails });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeManagerFromUsersSlice = createSlice({
  name: 'offices',
  initialState: {
    usersByManager: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(removeManagerFromUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeManagerFromUsers.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.usersByManager = action.payload;
      })
      .addCase(removeManagerFromUsers.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default removeManagerFromUsersSlice.reducer;
