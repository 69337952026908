import React, { useEffect, useRef, useState } from 'react'
import MainLayout from '../../layouts/mainLayout'
import ButtonComponent from '../../ui/button/button'
import './documents.scss'
import InputLabel from '../../input/inputLabel/Input-label'
import { useDispatch, useSelector } from 'react-redux'
import CustomSelectComponent from '../../ui/select-custom/custom-select-component'
import AttachIcon from '../../../assets/images/ion_attach.svg';
import { deleteDocument, fetchDocuments, fetchDocumentsByEmail, fetchDocumentsByManager, uploadDocument } from '../../../features/document/document-slice'
import { AppDispatch } from '../../../app/store'
import RoutesList from '../../ui/routes-list/routes-list'
import { FilterableColumn } from '../../ui/table/custom-table'
// import CustomTable from '../../ui/custom-table-v2/custom-table'
import CustomTable from '../../ui/table/custom-table'
import { useDevice } from '../../../hooks/use-device'
import SearchIcon from '../../../assets/icons/search-icon'
import { getUsersByManager } from '../../../features/get-users-by-manager/get-users-by-manager-slice'

const Documents = () => {

  const dispatch = useDispatch<AppDispatch>();
  const isMobile = useDevice();

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );
  const documents = useSelector((state: any) => state.documents.documents);
  const documentsByManagers = useSelector((state: any) => state.documents.documentsByManagers);
  // console.log("🚀 ~ Documents ~ documents:", documents)
  // console.log("🚀 user", user)
  // console.log("🚀 documentsByManagers", documentsByManagers)

  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [officeUser, setOfficeUser] = useState('');
  const [showOfficeUsers, setShowOfficeUsers] = useState(false);
  const [documentName, setDocumentName] = useState('');
  const [selectedFile, setSelectedFile] = useState<any>(null);
  // const [searchEmail, setSearchEmail] = useState(''); // Для поиска по email
  // const [filters, setFilters] = useState<{ [key: string]: string }>({});
  // const [showCalendar, setShowCalendar] = useState(false);
  const [isSaveButtonEnabled, setIsSaveButtonEnabled] = useState(false);
  const [documentType, setDocumentType] = useState('');  // Начальное состояние — пустое значение

  const fileInputRef: any = useRef(null);

  const documentNumber: any = 1;
  const usersByManager = useSelector((state: any) => state.usersByManager.usersByManager) || [];

  const emailList = usersByManager?.map((item: any) => item.email);
  // console.log(emailList);

  useEffect(() => {
    dispatch(getUsersByManager(user.email));
    dispatch(fetchDocumentsByManager(emailList));


    if (user.role === 'administrator') {
      dispatch(fetchDocuments());
    } else {
      dispatch(fetchDocumentsByEmail(user.email));
    }
  }, [dispatch, user.email]);

  useEffect(() => {
    // Проверка: если все поля заполнены, активируем кнопку
    if (officeUser && documentName && selectedFile) {
      setIsSaveButtonEnabled(true);
    } else {
      setIsSaveButtonEnabled(false);
    }
  }, [officeUser, documentName, selectedFile]);

  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (showOfficeUsers && !document.querySelector('.finance-table-container')?.contains(e.target as Node) &&
        !document.querySelector('.register-input')?.contains(e.target as Node)) {
        setShowOfficeUsers(false); // Скрываем список пользователей при клике вне
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showOfficeUsers]);


  const columns = {
    user: 'Пользователь',
    docType: 'Тип документа',
    number: 'Номер  ',
    download: 'Скачать',
    date: 'Дата',
  };

  const userColumns = {
    docType: 'Тип документа',
    number: 'Номер  ',
    download: 'Скачать',
    date: 'Дата',
  };

  const documentsFormatted = documents?.map(({ _id, date, data, documentName, documentNumber, createdAt, updatedAt, __v, contentType, ...rest }: any) => {
    const formattedDate = new Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date(date));

    return {
      ...rest,
      // _id,
      documentName,
      date: formattedDate,
    };
  });

  const userFormatedData = documentsFormatted.map((doc: any) => {
    const { holderEmail, ...rest } = doc;
    return rest;
  });;

  const formateddocumentsByManagers = documentsByManagers?.map(({ _id, date, data, documentName, documentNumber, createdAt, updatedAt, __v, contentType, ...rest }: any) => {
    const formattedDate = new Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    }).format(new Date(date));

    return {
      ...rest,
      _id,
      documentName,
      date: formattedDate,
    };
  });

  const allUsersStore =
    useSelector((state: any) => state.allUsers.allUsers) || [];

  const filteredUsersForModal = allUsersStore.filter(
    (user: any) =>
      !usersByManager?.some((u: any) => u.email === user.email) &&
      user.email.toLowerCase().includes(officeUser.toLowerCase())
  );

  const filteredManagerUsersForModal = usersByManager.filter(
    (user: any) =>
      user.email.toLowerCase().includes(officeUser.toLowerCase()) // Filter by the officeUser input
  );

  const handleUserClick = async (email: any) => {
    setOfficeUser(email);
    setShowOfficeUsers(false);
  }

  const handleShowTable = () => {
    if (officeUser.length < 1) {
      setShowOfficeUsers(true)
    }
  }

  const handleCreateDocument = () => {
    setShowDocumentsModal(true);
  }

  const handleEditModalClose = () => {
    setShowDocumentsModal(false);
    setOfficeUser('');      // Сброс значения пользователя
    setDocumentName('');    // Сброс названия документа
    setSelectedFile(null);  // Сброс прикрепленного файла
  };

  const handleDeleteDocument = async (documentId: string) => {
    await dispatch(deleteDocument(documentId));
    dispatch(fetchDocumentsByManager(emailList));
  };

  const documentTypeOptions = [
    { value: 'closing_document', label: 'Закрывающий документ' },
    { value: 'invoice', label: 'Инвойс' },
    { value: 'contract', label: 'Договор' },
  ];


  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      // Проверяем тип файла
      if (file.type !== 'application/pdf') {
        alert('Пожалуйста, загрузите только PDF-файлы.');
        // Сбрасываем выбор файла
        event.target.value = '';
        return;
      }

      // Если файл PDF, сохраняем его
      setSelectedFile(file);
    }
  };

  const handleSaveDocument = async (e: any) => {
    e.preventDefault();
    if (selectedFile && officeUser && documentName) {
      const formData = new FormData();
      formData.append('holderEmail', officeUser);
      formData.append('documentName', documentName);
      formData.append('documentType', 'application/pdf');
      formData.append('document', selectedFile);
      formData.append('documentNumber', documentNumber);

      // dispatch(uploadDocument(formData));
      // setShowDocumentsModal(false);
      try {
        const resultAction = await dispatch(uploadDocument(formData));

        if (resultAction) {
          setSelectedFile(null);
          setOfficeUser('');
          setDocumentName('');
          setDocumentType('');
          dispatch(fetchDocumentsByManager(emailList));
        }
        if (uploadDocument.fulfilled?.match(resultAction)) {
          setShowDocumentsModal(false); // Закрываем модалку, если загрузка прошла успешно
        } else {
          // Обработка ошибок
          console.error('Ошибка загрузки документа:', resultAction.payload);
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }
  }

  const filterableColumnsService: FilterableColumn[] = [
    { name: 'documentType', label: 'Тип документа' }, //'docType'
    { name: 'createdAt', label: 'Дата' },
    // { name: 'paymentMethod', label: 'Способ оплаты' },
  ];

  // const handleFilterChange = (column: string, value: string) => {
  //   setFilters((prevFilters) => ({
  //     ...prevFilters,
  //     [column]: value,
  //   }));
  // };


  // console.log('selectedFile', selectedFile)

  return (
    <MainLayout>
      <RoutesList />

      {showDocumentsModal && (
        <div className='documents-modal-container'>
          <form className={`addDocumentsModal create-new-document add-office-balance-modal ${officeUser ? 'user-selected' : ''
            }`}>

            <p className='new-document-txt'>Новый документ</p>
            <InputLabel
              className={`register-input add-balance-input`}
              labelClass='register-label'
              label='Пользователь'
              type='text'
              currencyClassName='ads-balance-currency'
              setValue={setOfficeUser} //{setOfficeUser}
              value={officeUser} //{officeUser}
              requireLabel={false}
              onFocus={() => handleShowTable()}
              rightIcon={<SearchIcon className='input-icon' />}
            />
            {user.role !== 'manager' && filteredUsersForModal.length > 0 && showOfficeUsers && (
              <div className='modal-header'>
                <div className='finance-table-container'>
                  <table>
                    <tbody>
                      {filteredUsersForModal?.map((user: any, index: any) => (
                        <tr key={user.id} className='user-item'>
                          <td className='user-info' onClick={() => handleUserClick(user.email)}>
                            <span className='user-email'>{user.email}</span>
                          </td>
                          <td className='action-btn'></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            {user.role === 'manager' && filteredManagerUsersForModal.length > 0 && showOfficeUsers && (
              <div className='modal-header'>
                <div className='finance-table-container'>
                  <table>
                    <tbody>
                      {filteredManagerUsersForModal?.map((user: any) => (
                        <tr key={user.id} className='user-item'>
                          <td className='user-info' onClick={() => handleUserClick(user.email)}>
                            <span className='user-email'>{user.email}</span>
                          </td>
                          <td className='action-btn'></td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
            <p className='doc-type'>Тип документа</p>
            <CustomSelectComponent
              className='custom-select documents-select select-document-type'
              height='44px'
              selectWrapper='select-wrapper'
              selectOptions={documentTypeOptions} //{adsOptions}
              selectedRole={documentType || 'Все'}
              setMessengerType={(value) => {
                setDocumentType(value);
              }}
              defaultEmptyValue=' '
            />

            <InputLabel
              className={`register-input add-balance-input`}
              labelClass='register-label'
              label='Название документа'
              type='text'
              currencyClassName='ads-balance-currency'
              setValue={setDocumentName}
              value={documentName}
              requireLabel={false}
            />
            <div className='attach-box'>
              <img src={AttachIcon} alt='AttachIcon' width={24} height={24} />
              <p onClick={handleFileUploadClick} className='attach-txt'>Прикрепить файл</p>
              <input
                type='file'
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
                accept="application/pdf"
              />
            </div>
            {/* {selectedFile !== null && selectedFile?.name} */}
            {selectedFile !== null && typeof selectedFile === 'object' && selectedFile.name}

            <div className='add-advetising-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='add-advetising-btn-cancel add-documents-modal-btn document-cancel-btn'
                btnOnClick={handleEditModalClose}
              />

              <ButtonComponent
                btnText='Сохранить'
                // className={`add-advetising-btn-create add-documents-modal-save ${officeUser.length > 0 ? 'editInputChanged' : ''
                //   }`}
                // disabled={officeUser.length < 1}
                className={`add-advetising-btn-create add-documents-modal-save ${isSaveButtonEnabled ? 'editInputChanged' : ''
                  }`}
                disabled={!isSaveButtonEnabled}
                btnOnClick={handleSaveDocument}
              />
            </div>

          </form>
        </div>
      )}
      <div className='documents-main-container'>
        <div className='documents-header'>
          <p className='documents-header-txt'>Документы</p>
          {user?.role !== 'user' && <ButtonComponent
            btnText={isMobile ? 'Добавить' : 'Добавить документ'}
            className='add-advetising-btn-create'
            btnOnClick={handleCreateDocument}
          />}
          {/* {user?.role !== 'user' && <ButtonComponent
          btnText='Добавить'
          className='add-advetising-btn-create mobile-document'
          btnOnClick={handleCreateDocument}
        />} */}
        </div>

        <CustomTable
          columnHeaders={user.role === "user" ? userColumns : columns}
          data={user.role === "user" ? userFormatedData : user.role === 'manager' ? formateddocumentsByManagers : documentsFormatted || []}
          className={'ads-offices-table'}
          showDeleteIcon={user.role !== "user"}
          handleDeleteClick={handleDeleteDocument}
          filterableColumns={filterableColumnsService}
          // addBalanceClick={handleAddBalanceClick}
          tableCalendarClass='document-calendar'
          searchBy={user.role !== "user" ? 'email' : 'none'}
          lableClassName='document-type-label'
          customSearchLablaleb='holderEmail'
          customSelectClassName='documents-select-table'
          inputWrapperClass='document-input-wrapper'
        />
      </div>
    </MainLayout>
  )
}

export default Documents