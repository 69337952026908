export const getColorClassName = (value: string) => {
    switch (value.toLowerCase()) {
        case 'выполнено':
            return 'green';
        case 'в обработке':
            return 'orange';
        case 'отклонено':
            return 'red';
        case 'ожидает пополнения':
            return 'yellow';
        default:
            return '';
    }
};

