import React from 'react';
import './button.scss';

const ButtonComponent = ({
  className = '',
  btnText,
  withIcon = false,
  icon,
  btnIconClassName,
  btnOnClick,
  disabled = false,
}: any) => {
  return (
    <>
      <button
        className={`button button-hover ${className} ${
          disabled ? 'disabled' : ''
        }`}
        onClick={btnOnClick}
        disabled={disabled}
      >
        {withIcon ? (
          <div
            className={`button--align-height button--right-padding ${btnIconClassName}`}
          >
            <img src={icon} alt='button icon' />
            <div>{btnText}</div>
          </div>
        ) : (
          btnText
        )}
      </button>
    </>
  );
};

export default ButtonComponent;
