import React, { useState } from 'react';
import ShortSelect from '../short-select/short-select';
import './commission-section.scss';
import CustomSelectComponent from '../select-custom/custom-select-component';
import InputLabel from '../../input/inputLabel/Input-label';
import CheckPercent from '../../../assets/icons/check-percent.svg';
import CheckPercentWhite from '../../../assets/icons/check-percent-white.svg';
import ErrorComponent from '../error/error-component';

const CommissionSection: React.FC<any> = ({ setSelectedAdSystem, selectedAdSystem, setMessengerTypeValueChanged, setChangesSaved, percentValueState, setPercentValueState, networks, setNetworks }: any) => {
  const adsOptions = [
    { value: 'Facebook Ads', label: 'Facebook Ads' },
    { value: 'Google Ads', label: 'Google Ads' },
  ];

  const [editKey, setEditKey] = useState<string | null>(null);
  const [newValue, setNewValue] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string | null>(null); // Добавляем состояние для ошибки

  const updateNetwork = (key: string) => {
    setNetworks((prevNetworks: any) =>
      prevNetworks.map((network: any) =>
        network.hasOwnProperty(key) ? { [key]: newValue } : network
      )
    );
    setEditKey(null);
    setNewValue('');
  };

  const doesNetworkContainSelectedAdSystem = (networks: any[], selectedAdSystem: string) => {
    if (!Array.isArray(networks)) {
      console.error('Networks is not an array', networks);
      return false;
    }
    return networks.some(network => network.hasOwnProperty(selectedAdSystem));
  };

  const networkContainsSelectedAdSystem = doesNetworkContainSelectedAdSystem(networks, selectedAdSystem);

  const deleteNetwork = (key: string) => {
    setNetworks((prevNetworks: any) => prevNetworks?.filter((network: any) => !network.hasOwnProperty(key)));
  };

  // const addCommissionToNetwork = (e: any) => {
  //   e.preventDefault();
  //   if (selectedAdSystem && percentValueState && networkContainsSelectedAdSystem === false) {
  //     const newNetwork = { [selectedAdSystem]: percentValueState };
  //     setNetworks((prevNetworks: any) => {
  //       const updatedNetworks = [...prevNetworks, newNetwork];
  //       return updatedNetworks;
  //     });
  //     setErrorMessage(null); // Убираем ошибку, если все успешно
  //   }
  //   setSelectedAdSystem(null);
  //   setPercentValueState('');
  // };

  const addCommissionToNetwork = (e: any) => {
    e.preventDefault();

    // if (networkContainsSelectedAdSystem) {
    //   setError('Для этой рекламной системы уже добавлена индивидуальная комиссия');
    //   return;
    // }

    if (selectedAdSystem && percentValueState) {
      const newNetwork = { [selectedAdSystem]: percentValueState };
      setNetworks((prevNetworks: any) => [...prevNetworks, newNetwork]);

      // Очистка полей после добавления
      setSelectedAdSystem(null);  // Сбрасываем выбранную сеть
      setPercentValueState('');    // Сбрасываем процент
      // setError('');

      // Устанавливаем значение по умолчанию в select
      setMessengerTypeValueChanged(false);  // Сбрасываем состояние компонента
    }
  };

  const handleSelectAdSystem = (value: string) => {
    // Проверяем, существует ли уже выбранная система в networks
    if (doesNetworkContainSelectedAdSystem(networks, value)) {
      setErrorMessage('Для этой рекламной системы уже добавлена индивидуальная комиссия');
    } else {
      setErrorMessage(null); // Убираем ошибку, если такая система не выбрана
      setSelectedAdSystem(value);
      setMessengerTypeValueChanged(true);
      setChangesSaved(false);
    }
  };

  return (
    <div className='commission-section'>
      <div className='contact-info__commission'>Индивидуальная комиссия</div>
      {Array.isArray(networks) && networks?.map((network: any, index: number) => (
        <div key={index} >
          {networks && Object.entries(network)?.map(([key, value]: any) => (
            <div className='network-table-elem' key={key}>
              <div className='network-table-elem-value'>
                <p className='network-table-elem-key'>{key}</p>
                {editKey === key ? (
                  <input
                    type='text'
                    value={newValue}
                    onChange={(e) => setNewValue(e.target.value)}
                    className='percent-input-change-value'
                  />
                ) : (
                  <span>{value}%</span>
                )}
              </div>
              <div className='network-table-elem-change'>
                {editKey !== key && <p onClick={() => {
                  setEditKey(key);
                  setNewValue(value);
                }}>Изменить</p>}
                {editKey !== key && <p onClick={() => deleteNetwork(key)}>Удалить</p>}
                {editKey === key && (
                  <button className='save-percent-btn' onClick={() => updateNetwork(key)}>Сохранить</button>
                )}
              </div>
            </div>
          ))}

        </div>
      ))}
      <div className='commission-box'>
        <div className='advertising-system'>
          <label className='register-label'>Рекламная система</label>
          <CustomSelectComponent
            className='manager-select-wrapper'
            selectOptions={adsOptions}
            setMessengerType={handleSelectAdSystem} // Используем новую функцию для выбора
            selectWrapper='commission-section__select'
            selectedRole={selectedAdSystem}
            defaultEmptyValue='Выбрать'
            height='44px'
          />
        </div>

        <div className='comission-percent-box'>
          <label className='register-label'>Процент</label>
          <div className='percentage-box-img'>%</div>
          <InputLabel
            className={`register-input percentage-box percent-input`}
            labelClass='register-label'
            type='text'
            setValue={setPercentValueState}
            value={percentValueState}
          />
        </div>
        <div className={`percent-done-box ${percentValueState?.length > 0 && selectedAdSystem?.length > 0 && networkContainsSelectedAdSystem === false ? 'percent-add' : ''}`} onClick={addCommissionToNetwork}>
          <img src={percentValueState?.length > 0 && selectedAdSystem?.length > 0 && networkContainsSelectedAdSystem === false ? CheckPercentWhite : CheckPercent} className='percent-done-box-img' />
        </div>
      </div>
      {errorMessage && (
        <ErrorComponent
          error={errorMessage}
          errorClassName='network-comission-error'
        />
      )}
      <div className='add-adv-system'>+ Добавить рекламную систему</div>
    </div>
  );
};

export default CommissionSection;
