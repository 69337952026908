import React from 'react';
import './main-input.scss';
import FindIcon from '../../../assets/images/find-icon.png';

const MainInput = ({ value = '', onChange = () => {} }: any) => {
  return (
    <div className='main-input-box'>
      <div className='main-input-icon-box'>
        <img
          src={FindIcon}
          width={24}
          height={24}
          className='main-input-icon'
          alt='input icon'
        />
      </div>
      <input
        className='main-input'
        placeholder='Найти рекламную систему'
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default MainInput;
