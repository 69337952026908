import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';


export const getTickets = createAsyncThunk(
  'tickets',
  async (holderEmail: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/ticket/get-tickets', { holderEmail });
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const createTicket = createAsyncThunk(
  'tickets/create',
  async (ticketData: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/ticket/create-ticket', ticketData);
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllTicketsForAdmin = createAsyncThunk(
  'tickets/getAll',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get('/api/ticket/get-all-tickets');
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTicketsByManager = createAsyncThunk(
  'tickets/getByManager',
  async (emails: string[], { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/ticket/get-tickets-by-manager', { emails });
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const saveMessage = createAsyncThunk(
  'tickets/saveMessage',
  async (messageData: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/ticket/save-message', messageData);
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const updateTicketStatus = createAsyncThunk(
  'tickets/updateStatus',
  async ({ ticketId, status }: { ticketId: string, status: string }, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/ticket/update-status', { ticketId, status });
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getMessagesByTicket = createAsyncThunk(
  'tickets/getMessagesByTicket',
  async (ticketId: string, { rejectWithValue }) => {
    // console.log('ticketId slice', ticketId)
    try {
      const response = await $api.get(`/api/ticket/get-messages/${ticketId}`);
      if (response) {
        return response.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getTicketsByEmails = createAsyncThunk(
  'tickets/getByEmails',
  async (emails: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/ticket/get-tickets-by-emails', { emails });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState: {
    tickets: null,
    ticketsForAdmin: null,
    ticketsByManager: null,
    isLoading: false,
    error: null,
    messages: null,
    ticketsByEmails: null,
    isModalOpen: false,
  },
  reducers: {
    openModal: (state) => {
      state.isModalOpen = true;
    },
    closeModal: (state) => {
      state.isModalOpen = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTickets.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTickets.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.tickets = action.payload;
        state.error = null;
      })
      .addCase(getTickets.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Ошибка авторизации';
      })
      .addCase(createTicket.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createTicket.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        if (state?.tickets) {
          state.tickets = [...state.tickets, action.payload];
        } else {
          state.tickets = [action.payload];
        }
        state.error = null;
      })
      .addCase(createTicket.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Ошибка создания тикета';
      })



      .addCase(getAllTicketsForAdmin.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getAllTicketsForAdmin.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.ticketsForAdmin = action.payload;
        state.error = null;
      })
      .addCase(getAllTicketsForAdmin.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Ошибка получения всех тикетов';
      })


      .addCase(getTicketsByManager.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTicketsByManager.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.ticketsByManager = action.payload;
        state.error = null;
      })
      .addCase(getTicketsByManager.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Ошибка получения тикетов по менеджеру';
      })


      .addCase(saveMessage.pending, (state: any) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveMessage.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        if (state.messages) {
          state.messages = [...state.messages, action.payload];
        } else {
          state.messages = [action.payload];
        }
        state.error = null;
      })
      .addCase(saveMessage.rejected, (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Ошибка сохранения сообщения';
      })

      .addCase(getMessagesByTicket.pending, (state: any) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getMessagesByTicket.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.messages = action.payload;
        state.error = null;
      })
      .addCase(getMessagesByTicket.rejected, (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Ошибка получения сообщений по тикету';
      })



      .addCase(updateTicketStatus.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      // .addCase(updateTicketStatus.fulfilled, (state: any, action: PayloadAction<any>) => {
      //   state.isLoading = false;
      //   // Update the specific ticket's status in the tickets array
      //   if (state.tickets) {
      //     state.tickets = [...state.tickets, action.payload];
      //   }
      //   state.error = null;
      // })
      .addCase(updateTicketStatus.fulfilled, (state: any, action: PayloadAction<any>) => {
        state.isLoading = false;

        // Находим тикет в массиве `tickets` и обновляем его статус
        const updatedTicketIndex = state.tickets.findIndex(
          (ticket: any) => ticket.ticketId === action.payload.ticketId
        );

        if (updatedTicketIndex !== -1) {
          // Если тикет найден, обновляем его статус
          state.tickets[updatedTicketIndex].status = action.payload.status;
        } else {
          state.tickets = [...state.tickets, action.payload];
        }

        state.error = null;
      })
      .addCase(updateTicketStatus.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload || 'Ошибка обновления статуса тикета';
      })


    .addCase(getTicketsByEmails.pending, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(getTicketsByEmails.fulfilled, (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.ticketsByEmails = action.payload; 
      state.error = null;
    })
    .addCase(getTicketsByEmails.rejected, (state, action: PayloadAction<any>) => {
      state.isLoading = false;
      state.error = action.payload || 'Ошибка получения тикетов по emails';
    });
  },
});

export const { openModal, closeModal } = ticketsSlice.actions;

export default ticketsSlice.reducer;