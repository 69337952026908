import React from 'react';
import './routes-list.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { fetchTransactions } from '../../../features/finance/finance-slice';
import { getAllTransactionsByManager } from '../../../features/get-manager-users/get-manager-users-slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../app/store';

const RoutesList = ({ routeClassName }: any) => {
  const location = useLocation();
  const pathName = location?.pathname;
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const user = useSelector(
    (state: any) => state.login?.user?.user || state.login?.user
  );
  const role = user?.role;

  const handleFinanceRoute = async  () => {
    await dispatch(fetchTransactions());
    await dispatch(getAllTransactionsByManager(user.email));
    navigate('/finance-history');
  };

  const handleAdvertisingSystemsRoute = () => {
    navigate(
      role === 'user' ? '/advertising-systems-office-home' : '/manager-home'
    );
  };

  const handleDocumentsRoute = () => {
    navigate('/documents');
  };

  const handleTicketsRoute = () => {
    navigate('/tickets');
  };

  const handleFaqRoute = () => {
    navigate('/faq');
  };

  const handleManagerUsersRoute = () => {
    navigate('/manager-users');
  };

  const handleManagersRoute = () => {
    navigate('/managers');
  };

  return (
    <div className={`router-list ${routeClassName ? routeClassName : ''}`}>
      <div
        className={
          pathName === '/advertising-systems-office-home' ||
          pathName === '/manager-home'
            ? 'active-route'
            : ''
        }
        onClick={handleAdvertisingSystemsRoute}
      >
        Кабинеты Рекламных систем
      </div>
      {(role === 'manager' || role === 'administrator') && (
        <div
          className={pathName === '/manager-users' ? 'active-route' : ''}
          onClick={handleManagerUsersRoute}
        >
          Пользователи
        </div>
      )}
      {role === 'administrator' && (
        <div
          className={pathName === '/managers' ? 'active-route' : ''}
          onClick={handleManagersRoute}
        >
          Менеджеры
        </div>
      )}
      <div
        onClick={handleFinanceRoute}
        className={pathName === '/finance-history' ? 'active-route' : ''}
      >
        Финансы
      </div>
      <div
        onClick={handleTicketsRoute}
        className={pathName === '/tickets' ? 'active-route' : ''}
      >
        Тикеты
      </div>
      <div
        onClick={handleDocumentsRoute}
        className={pathName === '/documents' ? 'active-route' : ''}
      >
        Документы
      </div>
      <div
        onClick={handleFaqRoute}
        className={pathName === '/faq' || pathName === '/faq/add' ||  pathName.startsWith('/faq/') ? 'active-route' : ''}
      >
        FAQ
      </div>
    </div>
  );
};

export default RoutesList;
