import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

export const assignManager = createAsyncThunk(
  'user/assignManager',
  async ({ managerEmail, userEmails }: { managerEmail: string; userEmails: string[] }, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/user/assign-manager', { managerEmail, userEmails });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const assignManagerSlice = createSlice({
  name: 'managers',
  initialState: {
    managers: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(assignManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(assignManager.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.managers = action.payload;
      })
      .addCase(assignManager.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default assignManagerSlice.reducer;
