import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GoogleAuthenticator from '../../../../assets/images/google-authenticator.png';
import MicrosoftAuthenticator from '../../../../assets/images/microsoft-authenticator.png';
import TwilioAuthy from '../../../../assets/images/twilio-authy.png';
import QRCode from 'react-qr-code';
import {
  generateQrCode,
  verifyToken,
} from '../../../../features/two-factor-auth/two-factor-auth-slice';
import './qr-code-form.scss';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../../app/store';
import ButtonComponent from '../../button/button';
import InputLabel from '../../../input/inputLabel/Input-label';

const QRCodeForm = () => {
  const dispatch = useDispatch<AppDispatch>();
  const qrcodeState = useSelector((state: any) => state.qrCode);
  const secret = qrcodeState?.secret;
  // console.log('🚀 ~ QRCodeForm ~ secret:', secret);
  const qrCodeUrl = qrcodeState?.qrCodeUrl;
  const [token, setToken] = useState('');
  const [copySuccess, setCopySuccess] = useState(false);

  const user =
    useSelector((state: any) => state.login?.user?.user || state.login?.user) ||
    false;
  const userId = user?.id;
  const navigate = useNavigate();

  useEffect(() => {
    if (userId && !secret) {
      dispatch(generateQrCode(userId));
    }
  }, [dispatch, userId, secret]);

  const handleCopy = () => {
    navigator.clipboard.writeText(secret);
    setCopySuccess(true);
    setTimeout(() => {
      setCopySuccess(false);
    }, 2000);
  };

  const handleVerify = async () => {
    try {
      const response = await dispatch(verifyToken({ token, userId })).unwrap();
      // console.log('🚀 ~ handleVerify ~ userId:', userId);
      // console.log('🚀 ~ handleVerify ~ token:', token);
      if (response.verified) {
        navigate('/manager-profile');
      }
    } catch (err) {
      console.error('Verification failed:', err);
    }
  };

  const twoFactorSystems = [
    {
      img: GoogleAuthenticator,
      title: 'Google Authenticator',
    },
    {
      img: MicrosoftAuthenticator,
      title: 'Microsoft Authenticator',
    },
    {
      img: TwilioAuthy,
      title: 'Twilio Authy',
    },
  ];

  return (
    <div className='qrcode'>
      <h1>
        Подключите двухфакторную авторизацию для безопасности ваших финансов
      </h1>
      <p className='qrcode__subtitle'>
        Отсканируйте QR-код любым приложением аутентификатором и введите
        полученный код. Например, можно использовать такие:
      </p>
      <div className='two-factor-systems'>
        {twoFactorSystems.map((item, index) => (
          <div key={index}>
            <div className='two-factor-system-image'>
              <img src={item.img} alt={item.title} />
            </div>
            <p>{item.title}</p>
          </div>
        ))}
      </div>

      <div className='qrcode-container'>
        {qrCodeUrl ? <QRCode value={qrCodeUrl} /> : <p>Loading QR Code...</p>}
      </div>

      <p>
        Если ваше мобильное приложение 2FA не поддерживает QR-коды, введите в
        приложении данный номер:
      </p>

      <InputLabel
        value={secret || 'Loading secret...'}
        // label='Секретный код'
        type='text'
        setValue={() => { }}
        iconClass='manager-page__copy-btn'
        className='register-input manager-page__input'
        copy={true}
      />

      <input
        className='qrcode__input register-input'
        type='text'
        placeholder='Введите код из приложения'
        value={token}
        onChange={(e) => setToken(e.target.value)}
      />
      <div className='qrcode--center-wrapper'>
        <ButtonComponent
          btnText='Подключить'
          className='qrcode__add-btn'
          btnOnClick={handleVerify}
          disabled={!secret}
        />
      </div>
    </div>
  );
};

export default QRCodeForm;
