import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

interface UpdateManagerProfilePayload {
  email: string;
  phone: any;
  messenger: any;
  messengerType?: string;
  phoneCountry?: any;
  status?: string;
  userType?: string;
  networks?: any; 
  oldPassword?: string;
  newPassword?: string;
  name?: string;
}

interface UpdateManagerProfileState {
  updateProfileData: any;
  isLoading: boolean;
  error: any;
}

export const updateManagerProfile = createAsyncThunk(
  'auth/updateManagerProfile',
  async (userData: UpdateManagerProfilePayload, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/manager/update-profile', userData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const initialState: UpdateManagerProfileState = {
  updateProfileData: null,
  isLoading: false,
  error: null,
};

// Create the slice
export const updateManagerProfileSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateManagerProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateManagerProfile.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.updateProfileData = action.payload;
      })
      .addCase(updateManagerProfile.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default updateManagerProfileSlice.reducer;
