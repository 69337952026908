import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ButtonComponent from '../../ui/button/button';
import ButtonWithIcon from '../../ui/button/buttonWithIcon';
import UserIcon from '../../../assets/icons/user-icon';
import MainLayout from '../../layouts/mainLayout';
import { Base64 } from 'js-base64';
import { useDispatch } from 'react-redux';
import { getUserByEmail } from '../../../features/get-user-by-email/get-user-by-email-slice';
import { unwrapResult } from '@reduxjs/toolkit';
import { AppDispatch } from '../../../app/store';

const RecoveryPasswordDone = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const { email } = location.state || {};

  const [user, setUser] = useState<any>('');

  useEffect(() => {
    if (email) {
      dispatch(getUserByEmail(email))
        .then(unwrapResult)
        .then((result: any) => {
          setUser(result);
        });
    }
  }, [navigate, email]);

  const handleClick = () => {
    navigate('/');
  };

  const handleDone = () => {
    if (user.role == 'user') {
      navigate('/login');
    } else {
      navigate('/admin');
    }
  };

  return (
    <MainLayout>
      <div className='recovery-container '>
        <div className='recovery-main check-password recovery-password-done'>
          <div>
            <p className='recovery-title'>Пароль изменен</p>
            <p className='recovery-main-body'>
              Ваш пароль успешно изменен. Теперь вы можете использовать новый
              пароль для входа.{' '}
            </p>
          </div>
          <ButtonComponent
            btnText='Готово'
            className='recovery-form-btn'
            btnOnClick={handleDone}
          />
        </div>
        <div className='registration__signin-section'>
          <p>Нет аккаунта?</p>
          <ButtonWithIcon
            btnText={'Зарегистрироваться'}
            withIcon={true}
            icon={<UserIcon />}
            btnIconClassName='register-header-btn-icon'
            className='registration-header-btn-icon'
            btnOnClick={handleClick}
          />
        </div>
      </div>
    </MainLayout>
  );
};

export default RecoveryPasswordDone;
