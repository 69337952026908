import React, { useEffect, useState } from 'react';
import MainLayout from '../../layouts/mainLayout';
import MainInput from '../../input/main-input/main-input';
import Facebook from '../../../assets/images/facebook.png';
import GoogleAds from '../../../assets/images/google-ads.png';
import ButtonComponent from '../../ui/button/button';
import DeleteOffice from '../../../assets/images/delete-office.png';
import EditOffice from '../../../assets/images/edit-office.png';
import PlusThin from '../../../assets/images/ph_plus-thin.png';
import { useLocation, useNavigate } from 'react-router-dom';
import RectrangleImg from '../../../assets/images/rectrangle-img.png';
import InputLabel from '../../input/inputLabel/Input-label';

import './administrator-home.scss';
import '../advertising-systems-office-home/advertising-systems-office-home.scss';
import RoutesList from '../../ui/routes-list/routes-list';

const AdministratorHome = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const handleBackClick = () => {
    navigate('/administrator-home');
  };

  useEffect(() => {
    const handlePopState = (event: any) => {
      if (location.pathname === '/administrator-home') {
        navigate('/administrator-home');
      }
    };

    window.addEventListener('popstate', handlePopState);
  }, [location, navigate]);

  const [addOfficeModal, setAddOfficeModal] = useState(false);

  const handleCloseModal = () => {
    setAddOfficeModal(false);
  };

  const handleClickNavigate = async () => {
  };

  const handleOpenOfficemodal = () => {
    setAddOfficeModal(true);
  };

  const networks = [
    {
      img: Facebook,
      title: 'Facebook Ads',
      status: 'Комиссия за пополнение 7%',
      description:
        'Условия для Whitehat и Greyhat кабинетов могут отличаться.',
      btnStatus: '',
      btnClick: handleClickNavigate,
    },
    {
      img: GoogleAds,
      title: 'Google Ads',
      status: 'Временно недоступен',
      description:
        'Кабинеты Google Ads будут скоро доступны.',
      btnStatus: 'disabled',
    },
    { title: 'default' },
  ];

  const handleNavigateHome = () => {
    navigate('/manager-home');
  };

  return (
    <MainLayout>
      {addOfficeModal && (
        <div className='modal-container'>
          <div className='addOfficeModal'>
            <p>Создание новой рекламной системы</p>
            <div>
              <img src={RectrangleImg} width={64} height={64} />
              <img
                src={PlusThin}
                width={24}
                height={24}
                alt='PlusThin'
                onClick={handleOpenOfficemodal}
              />
              <p>Добавить логотип</p>
            </div>

            <p>Название Рекламной сети</p>
            <InputLabel
              className={`register-input ads-network-name`}
              labelClass='register-label'
              type='text'
              requireLabel={false}
            />

            <p>Комиссия</p>
            <div className='add-advetising-btns'>
              <ButtonComponent
                btnText='Отменить'
                className='add-advetising-btn-cancel'
                btnOnClick={handleCloseModal}
              />
              <ButtonComponent
                btnText='Сохранить'
                className='add-advetising-btn-create'
              />
            </div>
          </div>
        </div>
      )}
      <div className='networks-box networks-box-home'>
        <RoutesList />
        <MainInput />
        <div className='administrator-home__flex-wrapper'>
          <div className='administrator-home__networks-box-sort'>
            <div>Сортировать:</div>
            <div>по популярности</div>
            <div>по алфавиту</div>
          </div>
          <button
            className='administrator-home__button'
            onClick={handleNavigateHome}
          >
            К кабинетам
          </button>
        </div>

        <div className='networks-box-container'>
          {networks.map((item) => (
            <React.Fragment key={item.title}>
              {item.title === 'default' ? (
                <div className='networks-item-elem'>
                  <img
                    src={PlusThin}
                    alt='PlusThin'
                    onClick={handleOpenOfficemodal}
                  />
                  <div> Создать новую рекламную систему </div>
                </div>
              ) : (
                <div className='networks-item-elem'>
                  <img src={item.img} alt='item.img' />
                  <div className='networks-item-title'>{item.title} </div>
                  <div className='networks-item-status'> {item.status} </div>
                  <div className='networks-item-description'>
                    {item.description}
                  </div>

                  <div>
                    <img src={DeleteOffice} alt='DeleteOffice' />
                    <img src={EditOffice} alt='EditOffice' />
                  </div>
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </MainLayout>
  );
};

export default AdministratorHome;
