import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../core/api';

interface Transaction {
  _id: string;
  date: string;
  amount: number;
  paymentMethod: string;
  status: string;
  // Добавьте другие поля, если они есть в модели транзакции
}


export const getManagerUsers = createAsyncThunk(
  'getManagerUsers',
  async ({ }: any, { rejectWithValue }) => {
    try {
      const response = await $api.post('/api/manager/get-manager-users');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAllTransactionsByManager = createAsyncThunk(
  'getAllTransactionsByManager',
  async (managerEmail: string, { rejectWithValue }) => {
    // console.log("🚀 ~ managerEmail:", managerEmail)
    try {
      const response = await $api.post('/api/manager/get-all-transactions-by-manager', { managerEmail });
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTransaction = createAsyncThunk(
  'getManagerUsers/deleteTransaction',
  async (transactionId: string, { rejectWithValue }) => {
    try {
      const response = await $api.delete(`/api/manager/transaction/${transactionId}`);
      return transactionId; // Возвращаем ID удаленной транзакции
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete transaction');
    }
  }
);

export const deleteOfficeTransaction = createAsyncThunk(
  'getManagerUsers/deleteTransaction',
  async (transactionId: string, { rejectWithValue }) => {
    try {
      const response = await $api.delete(`/api/manager/office/transaction/${transactionId}`);
      return transactionId; // Возвращаем ID удаленной транзакции
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to delete transaction');
    }
  }
);

export const getAllTransactionsAdmin = createAsyncThunk(
  'getAllTransactionsAdmin',
  async (_, { rejectWithValue }) => {
    try {
      const response = await $api.get('/api/manager/get-all-transactions');
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getManagerUsersSlice = createSlice({
  name: 'managerUsers',
  initialState: {
    managerUsers: null,
    transactions: [],
    isLoading: false,
    error: null,
    allOfficeTransactions: [],
  },
  reducers: {
    deleteOfficeTransactionFromStore: (state, action: any) => {
      state.allOfficeTransactions = state.allOfficeTransactions.filter((transaction: any) => transaction._id !== action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManagerUsers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getManagerUsers.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.managerUsers = action.payload;
        }
      )
      .addCase(
        getManagerUsers.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      )
      .addCase(getAllTransactionsByManager.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getAllTransactionsByManager.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.transactions = action.payload;
        }
      )
      .addCase(
        getAllTransactionsByManager.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      )
      .addCase(deleteTransaction.fulfilled, (state, action: PayloadAction<string>) => {
        if (state.transactions) {
          state.transactions = state.transactions.filter((transaction: any) => transaction._id !== action.payload);
        }
      })
      .addCase(deleteTransaction.rejected, (state, action: PayloadAction<any>) => {
        // console.log("🚀 ~ .addCase ~ action:", action)
        state.error = action.payload;
      })

      .addCase(getAllTransactionsAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        getAllTransactionsAdmin.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.allOfficeTransactions = action.payload;
        }
      )
      .addCase(
        getAllTransactionsAdmin.rejected,
        (state, action: PayloadAction<any>) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      )
  },
});

export const { deleteOfficeTransactionFromStore } = getManagerUsersSlice.actions;
export default getManagerUsersSlice.reducer;
