import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import $api from '../../../core/api';

interface ApiError {
  message: string;
}

export const getAllOffices = createAsyncThunk<
  any[],
  void,
  { rejectValue: ApiError }
>('office/getAllOffices', async (_, { rejectWithValue }) => {
  try {
    const response = await $api.get('/api/office/get-all-offices');
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response.data);
  }
});

export const getAllOfficesSlice = createSlice({
  name: 'allOffices',
  initialState: {
    allOffices: null,
    isLoading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllOffices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllOffices.fulfilled, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.allOffices = action.payload;
      })
      .addCase(getAllOffices.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default getAllOfficesSlice.reducer;
