import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import InputLabel from '../../../input/inputLabel/Input-label';
import EyeIcon from '../../../../assets/icons/eye-icon';
import ButtonComponent from '../../button/button';
import ErrorComponent from '../../error/error-component';
import { loginUser } from '../../../../features/login/loginSlice';
import '../registration/registration.scss';
import './login-form.scss';
import Spinner from '../../spinner/spinner';
import { AppDispatch, RootState } from '../../../../app/store';

interface LoginFormProps {
  onLoginSuccess?: () => void;
  on2FARequest?: (userId: string) => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onLoginSuccess,
  on2FARequest,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);
  const [notUserError, setNotUserError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isLoading = useSelector((state: RootState) => state.login?.isLoading);

  const handleClickRecover = () => {
    navigate('/recovery-password');
  };

  const fromPage = 'user';

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setNotUserError(false);
    setError(false);

    const userData = {
      email,
      password,
      fromPage,
    };

    if (email.length < 1) {
      setEmailError(true)
    } else {
      setEmailError(false)
    }

    if (password.length < 1) {
      setPasswordError(true)
    } else {
      setPasswordError(false)
    }

    try {
      let result;
      if (email && password) {
        // @ts-ignore
        result = await dispatch(loginUser(userData)).unwrap();
        // console.log("🚀 ~ handleSubmit ~ result:", result)
      }

      if (result === 'У пользователя роль не user') {
        setNotUserError(true);
      }

      const user = result?.user;
      // console.log("🚀 ~ handleSubmit ~ user:", user)

      if (user?.status === 'inactive') {
        console.error('Пользователь не активирован');
        setError(true);
        return; // Останавливаем дальнейшие действия, если статус inactive
      }

      if (result !== undefined && user) {
        if (user.twoFactor === true) {
          // Если двухфакторная аутентификация включена, вызываем соответствующий callback
          on2FARequest?.(user.id);
        } else if (result.verified === true) {
          // Навигация происходит только если верификация успешна
          onLoginSuccess?.();
          // navigate('/advertising-systems-office-home');
        } else {
          setError(true); // Установка ошибки, если верификация не прошла
        }
      }
    } catch (rejectedValueOrSerializedError) {
      setError(true);
    }
  };

  return (
    <div className='register-form'>
      <form onSubmit={handleSubmit}>
        <div className='register-container'>
          {isLoading && (
            <div className={'register-container__absolute-layer'}>
              <Spinner />
            </div>
          )}
          <h1>Вход</h1>
          <InputLabel
            className='register-input'
            labelClass='register-label'
            label='Email'
            type='email'
            setValue={setEmail}
            value={email}
            onChange={setEmail}
          />
          {emailError && <ErrorComponent
            error='Поле обязательно для заполнения'
            errorClassName=''
          />}
          <InputLabel
            className='register-input'
            labelClass='register-label'
            label='Пароль'
            type='password'
            setValue={setPassword}
            value={password}
            onChange={setPassword}
            icon={<EyeIcon />}
            iconClass='eye-class'
          />
          {passwordError && <ErrorComponent
            error='Поле обязательно для заполнения'
            errorClassName=''
          />}
          <p className='login-text-forget' onClick={handleClickRecover}>
            Забыли пароль?
          </p>
          {error && (
            <ErrorComponent
              error={`${notUserError
                ? 'У пользователя роль не user'
                : 'Некорректный email или пароль'
                }`}
              errorClassName='login-error'
            />
          )}
          <div className='registration-container login-box'>
            <ButtonComponent btnText={'Войти'} className='register-btn' />
          </div>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
