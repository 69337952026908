import { useState } from "react";
import {
    useFloating,
    offset,
    flip,
    shift,
    useHover,
    useInteractions,
    FloatingPortal
} from "@floating-ui/react";
import './tooltip.scss';


const Tooltip = ({ content, children }: { content: string; children: React.ReactNode }) => {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'top',
        middleware: [offset(5), flip(), shift()],
    });

    const hover = useHover(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    return (
        <>
            <FloatingPortal>
                {isOpen && (
                    <div ref={refs.setFloating} style={floatingStyles} className="tooltip-content" {...getFloatingProps()}>
                        {content}
                    </div>
                )}
            </FloatingPortal>
            <span ref={refs.setReference} {...getReferenceProps()}>
                {children}
            </span>
        </>
    );
};

export default Tooltip;
