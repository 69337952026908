import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useUTMCookies = () => {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const utmSource = params.get('utm_source');
    const utmMedium = params.get('utm_medium');
    const utmCampaign = params.get('utm_campaign');
    const utmContent = params.get('utm_content');
    const utmTerm = params.get('utm_term');
    const userAgent = navigator.userAgent; 

    const hasUTMParams = utmSource || utmMedium || utmCampaign || utmContent || utmTerm;

    if (hasUTMParams) {
      document.cookie = `utm_source=${utmSource || ""}; path=/`;
      document.cookie = `utm_medium=${utmMedium || ""}; path=/`;
      document.cookie = `utm_campaign=${utmCampaign || ""}; path=/`;
      document.cookie = `utm_content=${utmContent || ""}; path=/`;
      document.cookie = `utm_term=${utmTerm || ""}; path=/`;
    }

    document.cookie = `userAgent=${encodeURIComponent(userAgent)}; path=/`;
  }, [location]);
};

export default useUTMCookies;
